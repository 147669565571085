<template>
  <div
    v-if="canRead"
    class="container container--fluid bg"
  >
    <div class="padded">
      <EntityList
        :filters="filters"
        :selected-filter="selectedFilter"
        :page-limit="limit"
        :page-offset="offset"
        :records-count="recordsCount"
        :is-button="true"
        :links="entityLinks"
        :loading="loading"
        :show-pagination="!noAssociatedFiles"
        @filter-selected="filterSelected"
        @page-changed="handlePageChanged"
      >
        <div
          role="list"
          class="list list-card card-stack"
        >
          <FileCard
            v-for="(item, index) in files"
            :key="index"
            :item="item"
            :downloading="downloading && selectedFile.id === item.id"
            :class="{
              't-radius-0': index > 0,
              'b-radius-0': index < files.length - 1,
            }"
            @open="handleOpen(item)"
            @download="handleDownload"
          />
        </div>
        <template v-if="noAssociatedFiles">
          <div class="entity-list-no-items">
            <h4 class="entity-list-no-items--heading">
              {{ t('You currently have no files') }}
            </h4>
            <CustomButton
              :label="t('Add file')"
              @on-click="addFile"
            />
          </div>
        </template>
      </EntityList>
    </div>
    <FileDetails
      v-if="fileDetailsVisible && selectedFile.id"
      :file="selectedFile"
      :can-delete="canDelete"
      @close-modal="closeDetailsModal"
      @file-deleted="loadPageData"
    />
    <FileUpload
      v-if="fileUploadVisible && canCreate"
      :entity-type="entityType"
      :entity-id="entityId"
      @close-modal="closeUploadModal"
      @files-uploaded="loadPageData"
    />
  </div>
</template>

<script setup>
/** Files.vue */
import { t } from '@sales-i/utils';
import { ref, computed, onMounted } from 'vue';
import { useRoute, } from 'vue-router';
import { useStore } from 'vuex';
import { DOWNLOAD_BY_ID } from '@/crm/store/actionType';
import { GET_ALL } from '@/shared/store/actionType';
import { baseUrl, filesSection } from '@/crm/router/urlBits';
import { files as fielsPerm } from '@/shared/store/data/policies';
import { CustomButton } from '@sales-i/dsv3';
import EntityList from '@/crm/components/EntityList/EntityList.vue';
import FileCard from '@/crm/components/Files/FileCard.vue';
import FileDetails from '@/crm/components/Files/FileDetails.vue';
import FileUpload from '@/crm/components/Files/FileUpload.vue';

import { usePagination, paginationProps } from '@/shared/composables/usePagination';
import usePermissions from '@/shared/composables/usePermissions';
import { navigateToUrl } from 'single-spa';
import useSystem from '@/shared/composables/useSystem';

const store = useStore();
const vroute = useRoute();

const { hasAccess } = usePermissions();
const { entity } = useSystem({ store });

const canRead = computed(() => hasAccess(fielsPerm, 'read'));
const canDelete = computed(() => hasAccess(fielsPerm, 'delete'));
const canCreate = computed(() => hasAccess(fielsPerm, 'create'));

const emit = defineEmits(['pageChanged']);
const props = defineProps(paginationProps);
const { limit, offset, handlePageChanged } = usePagination(props, loadPageData, emit);

const selectedFile = ref({});
const fileDetailsVisible = ref(false);
const filters = [
  { text: t('File Name: ASC-DES'), value: 'file_name:asc' },
  { text: t('File Name: DES-ASC'), value: 'file_name:desc' },
  { text: t('File Size: ASC-DES'), value: 'file_size:asc' },
  { text: t('File Size: DES-ASC'), value: 'file_size:desc' },
  { text: t('File Type: ASC-DES'), value: 'file_type:asc' },
  { text: t('File Type: DES-ASC'), value: 'file_type:desc' },
  { text: t('Created: ASC-DES'), value: 'created_date:asc' },
  { text: t('Created: DES-ASC'), value: 'created_date:desc' },
  { text: t('Updated: ASC-DES'), value: 'updated_date:asc' },
  { text: t('Updated: DES-ASC'), value: 'updated_date:desc' },
  { text: t('Updated By: ASC-DES'), value: 'updated_by:asc' },
  { text: t('Updated By: DES-ASC'), value: 'updated_by:desc' },
];
const selectedFilter = ref('file_name:asc');
const entityLinks = canCreate.value
  ? [
    {
      label: t('Add file'),
      small: true,
      function: addFile,
    },
  ]
  : [];

const files = computed(() => store.state.crm.files.all.data);
const loading = computed(() => store.state.crm.files.all.loading);
const downloading = computed(() => store.state.crm.files.selected.downloading);
const entityId = computed(() => vroute.params.id);
const entityType = computed(() => vroute.fullPath.split('/')[2]);
const fileUploadVisible = computed(() => vroute.params.mode === 'upload');
const noAssociatedFiles = computed(() => files.value.length === 0);
const recordsCount = computed(() => files.value.length || 0);

onMounted(() => {
  loadPageData();
});

const getEntityFiles = params => store.dispatch(`crm/files/${GET_ALL}`, params);
const downloadFile = async params => store.dispatch(`crm/files/${DOWNLOAD_BY_ID}`, params);

async function handleDownload(file) {
  selectedFile.value = file;
  await downloadFile({ file });
  selectedFile.value = {};
}
function addFile() {
  navigateToUrl(`${baseUrl}/${entity.value}/${entityId.value}/${filesSection}/upload`);
}
function loadPageData() {
  getEntityFiles({
    id: entityId.value,
    entity_type: entityType.value,
    limit: limit.value,
    offset: offset.value,
    sort: selectedFilter.value,
  });
}
function filterSelected({ value }) {
  selectedFilter.value = value;
  loadPageData();
}
function handleOpen(file) {
  selectedFile.value = file;
  fileDetailsVisible.value = true;
}
function closeUploadModal() {
  navigateToUrl(`${baseUrl}/${entityType.value}/${entityId.value}/${filesSection}`);
}
function closeDetailsModal() {
  fileDetailsVisible.value = false;
  selectedFile.value = {};
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.bg {
  background-color: var(--colour-panel-g-2);
  flex: 1;
}

.card-stack.list {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    grid-gap: 0;

    .b-radius-0 {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .t-radius-0 {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}
</style>
