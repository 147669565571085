<template>
  <SearchList
    :selected-items="selectedItems"
    :items="searchOptions"
    :loading="loading"
    key-field="rule_id"
    @search="loadAssociations({ 
      q: $event, offset: 0, sort 
    })"
    @load-page="loadAssociations({ 
      q, offset: $event, sort 
    })"
    @clear-search="clearAssociationsSearch"
    @update:selected-items="handleSelectedAssociations"
  >
    <template #item="{ item, disabled }">
      <AssociationInlineDisplay
        :item="item"
        :disabled="disabled" 
      />
    </template>
    <template #selected="{ item }">
      <AssociationInlineDisplay
        :item="item"
      />
    </template>
  </SearchList>
</template>

<script setup>
import { ref } from 'vue';
import SearchList from '@/shared/components/SearchList/SearchList.vue';
import { useAssociations } from '@/intelligence/composables/useAssociations';
import AssociationInlineDisplay from './AssociationInlineDisplay.vue';

const emit = defineEmits(['update:selectedItems']);

const { getAssociations } = useAssociations();

defineProps({
  selectedItems: {
    type: Array,
    default: () => [],
  },
  sort: {
    type: String,
    default: 'confidence:desc',
  },
});

const searchOptions = ref([]);
const q = ref('');
const offset = ref(0);
const loading = ref(false);

const loadAssociations = async (query) => {
  loading.value = true;
  q.value = query.q;
  // we do not make call to api in case it is the last page, when offsetValue == offset.value and > 0
  if (query.offset === 0) {
    searchOptions.value = await getAssociations(query);
  // it is a new page request if new offset bigger than current offset
  } else if (query.offset > offset.value) {
    const data = await getAssociations(query);
    searchOptions.value.push(...data);
  }
  offset.value = query.offset;
  loading.value = false;
};

const handleSelectedAssociations = (items) => {
  emit('update:selectedItems', items);
};

const clearAssociationsSearch = () => {
  searchOptions.value = [];
  q.value = '';
  offset.value = 0;
  loading.value = false;
};
</script>

<style lang="scss" scoped>
</style>