<template>
  <div
    class="unsaved-changes-modal"
  >
    <div class="close-button-wrap">
      <IconButton
        class="btn"
        icon-name="close-line"
        :icon-color="isToggleButtonActive ? 'var(--colour-utility-white)' : 'var(--colour-utility-action)'"
        purpose="transparent"
        icon-only
        :icon-size="24"
        @click.stop="closeWidget"
      />
    </div>

    <p>{{ t('You have made changes to this enquiry widget.') }}</p>
    
    <CustomButton
      purpose="transparent"
      class="action"
      @click="updateWidget(props.computedWidgetData)"
    >
      <IconBase
        :width="24"
        :height="24"
        icon-name="edit"
        icon-color="var(--colour-utility-action)"
        class="icon"
      />
      <div class="info">
        <h2 class="heading">
          {{ t('Update existing widget') }}
        </h2>
        <p>{{ t('Updates the current widget with your most recent changes') }}</p>
      </div>  
    </CustomButton>

    <CustomButton
      purpose="transparent"
      class="action"
      @click="saveWidget"
    >
      <IconBase
        :width="24"
        :height="24"
        icon-name="plus"
        icon-color="var(--colour-utility-action)"
        class="icon"
      />
      <div class="info">
        <h2 class="heading">
          {{ t('Save as new widget') }}
        </h2>
        <p>{{ t('Create a new widget with your most recent changes') }}</p>
      </div>
    </CustomButton>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { useRoute, } from 'vue-router';
import { CustomButton, IconBase, IconButton } from '@sales-i/dsv3';
import { useDashboard } from '@/intelligence/components/Dashboard/composables/useDashboard';
import { t } from '@sales-i/utils';
import useNotification from '@/shared/composables/useNotification';

const emit = defineEmits(['saveAsNewWidget', 'closeWidgetModal', 'widgetUpdated']);

const store = useStore();
const vroute = useRoute();

const props = defineProps({
  query: {
    type: Object,
    default: () => ({}),
  },
  computedWidgetData: {
    type: Object,
    default: () => ({}),
  },
});

const {
  patchWidget,
} = useDashboard({ store });
const { sendSuccessAlert } = useNotification({ store });

async function updateWidget({ id, dashboard_id, name, description, width, height, settings }) {
  const url = vroute.fullPath;
  let response = await patchWidget({
    id,
    dashboard_id,
    name,
    description,
    width,
    height,
    settings: {
      ...settings,
      url
    },
  });
  if (response) {
    sendSuccessAlert(t('Successfully updated widget'));
    emit('widgetUpdated');
  }
}

function saveWidget() {
  emit('saveAsNewWidget');
}

function closeWidget() {
  emit('closeWidgetModal');
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.unsaved-changes-modal {
  box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-blur) var(--shadow-colour);
  border-radius: var(--border-radius-1);
  background-color: var(--colour-panel-g-0);
  padding-block-start: var(--spacing-1);
  padding-block-end: var(--spacing-2);
  position: fixed;
  bottom: calc(var(--spacing-10) - var(--spacing-1));
  right: 0;
  text-align: initial;
  max-width: 22rem;
  z-index: 100;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    position: absolute;
  }

  .close-button-wrap {
    display: flex;
    justify-content: flex-end;
    padding-inline: var(--spacing-1);
  }

  & > p {
    padding-inline: var(--spacing-2);
    padding-block-end: var(--spacing-1);
  }

  :deep(.button.action) {
    background-color: var(--colour-utility-white);
    border-radius: 0;
    cursor: pointer;
    padding: var(--spacing-1) var(--spacing-2);
    color: var(--colour-utility-black);
    text-align: left;

    &:hover {
      background-color: var(--colour-panel-g-8);
    }

    .button-container {
      gap: var(--spacing-2)
    }
  }

  .heading {
    color: var(--colour-utility-action);
    font-size: inherit;
    font-weight: var(--font-weight-regular);
  }

}

:deep(.icon-button > div) {
  margin-left: calc(var(--spacing-half) * -1);
}
</style>