
import { useStore } from 'vuex';
import { FETCH_LAST_INVOICE } from '@/intelligence/store/actionType';

export default function useLastInvoice({ store = useStore() } = {}) {
  const fetchLastInvoice = customer => store.dispatch(`intelligence/lastInvoice/${FETCH_LAST_INVOICE}`, customer);

  return {
    fetchLastInvoice
  };
}