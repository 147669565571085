<template>
  <TabsAndTagsLayout
    v-if="canRead"
    entity="prospect"
    :entity-id="prospectDetailsId || ''"
    :title="prospectDetails.name"
    :tabs="tabs"
    :tab-selected-index="tabIndex"
    :show-cta="canUpdate"
    :show-add-interaction="showAddInteraction"
    @tab-selected="handleTabSelected"
  >
    <template #cta>
      <CustomButton
        v-if="canDelete"
        small
        purpose="neutral"
        class="cta mt-1"
        @click="deleteProspect"
      >
        {{ t('Delete') }}
      </CustomButton>
      <CustomButton
        v-if="canUpdate"
        small
        purpose="action"
        class="cta mt-1"
        @click="editProspect"
      >
        {{ t('Edit') }}
      </CustomButton>
    </template>
    <template #subtitle>
      <RagChip
        v-if="false"
        class="mt-1"
        :value="percentComplete"
        suffix="%"
        :green="67"
        :amber="33"
        small
      />
      <h4
        v-if="prospectDetails.account_number"
        class="subtitle"
      >
        {{ prospectDetails.account_number }}
      </h4>
    </template>
    <template #content>
      <router-view
        :id="prospectDetailsId"
        :permissions="permissionsSet"
        :sections-titles="sectionsTitles"
        :entity-name="prospectDetails.name"
        :is-contact-view="isContactView"
        @edit-prospect="editProspect"
        @refresh-prospect="updateProspect"
        @toggle-custom-schema="toggleCustomSchema"
      />
    </template>
  </TabsAndTagsLayout>
</template>

<script>
import { RagChip } from '@sales-i/dsv3';
import TabsAndTagsLayout from '@/shared/components/Layout/TabsAndTagsLayout.vue';
import { CustomButton } from '@sales-i/dsv3';
import { mapState, mapActions, mapGetters } from 'vuex';
import { GET_CUSTOM_SCHEMA } from '@/admin/store/actionType';
import { root as adminArea } from '@/admin/router/urlBits';
import { baseUrl, prospectsArea as area, contactsSection, customSectionsSection, filesSection, postsSection, interactionsHistory, opportunitiesArea } from '@/crm/router/urlBits';

import { contacts, prospects, files, interactions, posts_comments, opportunities } from '@/shared/store/data/policies';

import { GET_BY_ID, CLEAR_BY_ID, SET_ROUTE, DELETE_BY_ID, SET_CONFIRMATION_MODAL, UPDATE_RECENTLY_VIEWED } from '@/shared/store/actionType';
import usePermissions from '@/shared/composables/usePermissions';
import { t } from '@sales-i/utils';
import { navigateToUrl } from 'single-spa';

export default {
  name: 'ProspectView',
  components: {
    CustomButton,
    RagChip,
    TabsAndTagsLayout,
  },
  inject: ['mq'],
  data() {
    return {
      childId: false,
      tabIndex: 0,
      entity: 'prospects', // for custom schema
      showAddInteraction: true,
      isContactView: false
    };
  },
  computed: {
    ...mapState({
      prospectDetails: state => state.crm.prospects.selected.data,
      platform: state => state.platform,
      permissions: state => state.pbac.permissionsGranted,
    }),
    ...mapGetters({
      getSchemaSections: `${adminArea}/schema/getSections`,
    }),
    sectionsTitles() {
      return this.getSchemaSections.customSections;
    },
    hasAccess() {
      const { hasAccess } = usePermissions();
      return hasAccess;
    },
    canRead() {
      return this.hasAccess(prospects, 'read');
    },
    canUpdate() {
      return this.hasAccess(prospects, 'update');
    },
    canDelete() {
      return this.hasAccess(prospects, 'delete');
    },
    permissionsSet() {
      return {
        entity: this.permissions[prospects] ? {
          ...this.permissions[prospects],
          canUpdateCustomSchema: this.permissions[prospects].update
        } : {},
        schema: this.permissions[prospects] || {},
      };
    },
    tabs() {
      const id = this.childId || this.prospectDetailsId;
      let arr = [
        {
          title: t('Details'),
          path: `${baseUrl}/${area}/${this.prospectDetailsId}`,
          key: 'details',
        },
      ];
      if (this.hasAccess(contacts, 'read')) {
        arr.push({
          title: t('Contacts'),
          path: `${baseUrl}/${area}/${this.prospectDetailsId}/${contactsSection}`,
          key: contactsSection,
        });
      }
      if (this.hasAccess(prospects, 'read')) {
        arr.push({
          title: t('Custom sections'),
          path: `${baseUrl}/${area}/${id}/${customSectionsSection}`,
          key: customSectionsSection,
        });
      }
      if (this.hasAccess(files, 'read')) {
        arr.push({
          title: t('Files'),
          path: `${baseUrl}/${area}/${this.prospectDetailsId}/${filesSection}`,
          key: filesSection,
        });
      }
      if (this.hasAccess(posts_comments, 'read')) {
        arr.push({
          title: t('Posts'),
          path: `${baseUrl}/${area}/${this.prospectDetailsId}/${postsSection}`,
          key: postsSection,
        });
      }
      if (this.hasAccess(interactions, 'read')) {
        arr.push({
          title: t('Interactions history'),
          path: `${baseUrl}/${area}/${this.prospectDetailsId}/${interactionsHistory}`,
          key: interactionsHistory,
        });
      }
      if (this.hasAccess(opportunities, 'read')) {
        arr.push({
          title: t('Opportunities'),
          path: `${baseUrl}/${area}/${this.prospectDetailsId}/${opportunitiesArea}`,
          key: opportunitiesArea,
        });
      }
      return arr;
    },
    percentComplete() {
      return this.prospectDetails.percent_complete;
    },
    prospectDetailsId() {
      return this.$route.params.id;
    },
  },
  watch: {
    $route(to, from) {
      this.setSelectedIndex(to.path);
      if (to.params.id && to.params.id !== from.params.id && to.path.includes(area)) {
        this.loadProspectDetails();
      }
    },
  },
  mounted() {
    const path = decodeURI(this.$route.path);
    this.setSelectedIndex(path);
    this.loadProspectDetails();
    this.loadSchema({ entity: this.entity });
    this.updatedRecentlyViewedProspect(this.prospectDetailsId);
  },
  unmounted() {
    this.clearById();
  },
  methods: {
    t,
    ...mapActions({
      getById: `crm/prospects/${GET_BY_ID}`,
      clearById: `crm/prospects/${CLEAR_BY_ID}`,
      deleteById: `crm/prospects/${DELETE_BY_ID}`,
      setRoute: `system/${SET_ROUTE}`,
      loadSchema: `admin/schema/${GET_CUSTOM_SCHEMA}`,
      showConfirmationModal: `confirmationModal/${SET_CONFIRMATION_MODAL}`,
      updatedRecentlyViewed: `searchBarResults/${UPDATE_RECENTLY_VIEWED}`
    }),
    updatedRecentlyViewedProspect(prospectId) {
      this.updatedRecentlyViewed({
        entity_type: 'prospect',
        entity_id: typeof prospectId === 'number' ? prospectId : parseInt(prospectId, 10),
      });
    },
    loadProspectDetails() {
      this.getById({
        id: this.prospectDetailsId,
      });
    },
    handleTabSelected(data) {
      const { index, item } = data;
      this.tabIndex = index;
      if (decodeURI(this.$route.path) !== item.path) {
        navigateToUrl(item.path);
      }
      this.isContactView = false;
    },
    setSelectedIndex(path) {
      this.tabIndex = this.tabs.findIndex(e => {
        const keys = path.split('/');
        return keys.includes(e.key);
      });
      if (this.tabIndex === -1) this.tabIndex = 0;
    },
    editProspect() {
      this.setRoute({
        success: this.$route.fullPath,
        cancel: this.$route.fullPath,
      });
      navigateToUrl(`${baseUrl}/${area}/${this.prospectDetailsId}/edit`);
    },
    async updateProspect() {
      await this.getById({
        id: this.prospectDetailsId,
      });
    },
    deleteProspect() {
      this.showConfirmationModal({
        message: t(`Do you really want to delete ${this.prospectDetails.name}?`, 'do_you_really_want_to_delete_variable', {
          interpolations: { variable: this.prospectDetails.name },
        }),
        updatedMessage: t(`You have deleted ${this.prospectDetails.name}`, 'you_have_deleted_variable', {
          interpolations: { variable: this.prospectDetails.name },
        }),
        updateFunction: async () => {
          try {
            await this.deleteById(this.prospectDetails.id);
            navigateToUrl(`${baseUrl}/${area}`);
            return true;
          } catch (e) {
            return false;
          }
        },
      });
    },
    addFile() {
      navigateToUrl(`${baseUrl}/${area}/${this.prospectDetailsId}/${filesSection}/upload`);
    },
    addContact() {
      navigateToUrl(`${baseUrl}/${area}/${this.prospectDetailsId}/${contactsSection}/add`);
    },
    addPost() {
      navigateToUrl(`${baseUrl}/${area}/${this.prospectDetailsId}/${postsSection}/add`);
    },
    toggleCustomSchema() {
      this.isContactView = !this.isContactView;
    }
  },
};
</script>