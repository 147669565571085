<template>
  <div class="show-gaps-container">
    <CustomCheckbox
      :label="t('Hide results with a 0 total')"
      :value="!requestParameters.showGaps"
      @input="handleShowGapsChange"
    />
  </div>
</template>

<script setup>
import { t } from '@sales-i/utils';
import { CustomCheckbox } from '@sales-i/dsv3';
import useShared from '@/intelligence/composables/useShared';
import useEnquiries from '@/intelligence/composables/useEnquiries';

const { requestParameters, refreshReport } = useShared();
const { setShowGaps } = useEnquiries();

const handleShowGapsChange = value => {
  setShowGaps(!value);
  refreshReport();
};
</script>

<style lang="scss" scoped>
.show-gaps-container {
  padding: var(--spacing-2);
}
</style>