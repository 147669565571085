<template>
  <span v-if="!isClickable">{{ title }}</span>
  <template v-else>
    <CustomButton
      v-if="props.item.allocated_users?.length"
      type="text"
      purpose="text"
      :label="title"
      @click="filterOpportunities"
    />
    <span v-else>{{ title }}</span>
  </template>
</template>
    
<script setup>
import { computed } from 'vue';
import { CustomButton } from '@sales-i/dsv3';
import { t } from '@sales-i/utils';
import { useStore } from 'vuex';
import { useUsers } from '@/shared/composables/useUsers';
import { navigateToUrl } from 'single-spa';
import { baseUrl, opportunitiesArea } from '@/crm/router/urlBits';
  
const store = useStore();
  
const { getUserById } = useUsers(store);
  
const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
  isClickable: {
    type: Boolean,
    default: false,
  },
});
    
const title = computed(() => {
  if (!Array.isArray(props.item.allocated_users) || !props.item.allocated_users.length) return t('Unassigned');
  const firstUser = getUserById(props.item.allocated_users[0].allocated_user_id);
  const firstUserName = firstUser.first_name && firstUser.last_name ? `${firstUser.first_name} ${firstUser.last_name}` : t('Unknown user');
  return firstUserName + (props.item.allocated_users.length === 1 ? '' : ` (+${props.item.allocated_users.length - 1})`);
});

const filterOpportunities = () => {
  const path = `${baseUrl}/${opportunitiesArea}`;
  const query = new URLSearchParams({ 
    status: 'open',
    searchLabel: title.value,
    filter: 'allocated_to',
    search: props.item.allocated_users[0].allocated_user_id,
  });
  navigateToUrl(`${path}?${query.toString()}`);
};
</script>
    