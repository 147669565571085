<template>
  <div class="container flow">
    <div class="intro-wrapper flow">
      <h2>
        {{
          t(`${actionText} product data`, 'action_product_data', {
            interpolations: {
              action: actionText,
            },
          })
        }}
      </h2>
      <p>{{ dateFilter }}</p>
    </div>
  </div>

  <FilterSummary />

  <div class="container flow">
    <div class="flex">
      <TabSwitch
        v-if="!isFirstProductSection"
        class="tab-switch"
        :items="clauseOptions"
        :selected-value="productDataClause"
        @switch-selected="handleSwitch($event)"
      />
      <h3>{{ t('Products these customers are buying / not buying') }}</h3>
    </div>

    <template
      v-for="(filter, index) in filters"
      :key="`${filter.id}_${index}`"
    >
      <div class="flex">
        <TabSwitch
          v-if="index !== 0"
          :key="`${filter.id}_${index}`"
          class="tab-switch"
          :items="clauseOptions"
          :selected-value="filter.operator.toUpperCase()"
          @switch-selected="handleProductSwitch($event, filter)"
        />
      </div>
      <div class="form-field-flex">
        <Field
          :id="`${filter.id}_product_type`"
          twoway
          type="select"
          :value="filter.level"
          :options="filteredProductTypeOptions(filter)"
          @input="setProductType($event, filter)"
        />

        <Field
          :id="`${filter.id}_buying_not_buying`"
          twoway
          type="select"
          :value="filter.status"
          :options="buyingNotBuyingOptions"
          @input="setBuyingNotBuying($event, filter)"
        />
      </div>

      <ProductDataCommunitiesSelection
        v-if="filter.level === 'communities'"
        :selected-items="selectedCommunities"
        @update:selected-items="handleSelectedCommunities($event, filter)"
      />

      <ProductDataAssociationsSelection
        v-if="filter.level === 'ai_generated_associations'"
        :selected-items="selectedAssociations"
        @update:selected-items="handleSelectedAssociations($event, filter)"
      />

      <ProductDataItemSelection
        v-if="filter.level === 'item_level'"
        :filter="filter"
        selected-value="item_level"
        @add-product="product => addProduct(product, filter)"
        @delete-product="product => removeProductFromQuery(product, filter)"
      />

      <ProductDataGroupSelection
        v-else-if="filter.level === 'product_group_level'"
        :filter="filter"
        selected-value="product_group_level"
        @add-product="product => addProduct(product, filter)"
        @set-product-group-level="level => setProductGroupLevel(level, filter)"
        @delete-product="product => removeProductFromQuery(product, filter)"
      />

      <div class="delete-icon">
        <IconButton
          v-if="filters.length > 1"
          icon-name="trash"
          purpose="transparent"
          icon-color="var(--colour-utility-action)"
          variant="round"
          :icon-size="28"
          @on-click="removeProductFilter(filter)"
        />
      </div>
    </template>

    <div class="add-button">
      <CustomDropdown
        icon-width="32"
        icon-height="32"
        icon-color="var(--colour-utility-white)"
        :toggle-icons="['plus', 'close-line']"
        close-on-selection
        :items="clauseOptions"
        @on-click="handleAddButtonClick($event.value)"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import { useStore } from 'vuex';
import { deepClone, t } from '@sales-i/utils';
import { TabSwitch, CustomDropdown, IconButton } from '@sales-i/dsv3';
import Field from '@/shared/components/Form/Field.vue';
import ProductDataItemSelection from '@/intelligence/components/AlertBuilder/AlertWizard/ProductDataItemSelection.vue';
import ProductDataGroupSelection from '@/intelligence/components/AlertBuilder/AlertWizard/ProductDataGroupSelection.vue';

import FilterSummary from '@/intelligence/components/AlertBuilder/AlertWizard/FilterSummary.vue';
import { AND_OR_OPTIONS as clauseOptions } from '@/intelligence/store/data/filters';
import { useAlertBuilder } from '@/intelligence/composables/useAlertBuilder';
import useEnquiries from '@/intelligence/composables/useEnquiries';
import ProductDataAssociationsSelection from './ProductDataAssociationsSelection.vue';
import ProductDataCommunitiesSelection from './ProductDataCommunitiesSelection.vue';
import { useCommunities } from '@/intelligence/composables/useCommunities';

const store = useStore();

const emit = defineEmits(['valid']);

const props = defineProps({
  actionText: {
    type: String,
    default: '',
  },
  dateFilter: {
    type: String,
    default: '',
  },
  isEditMode: {
    type: Boolean,
    default: false,
  },
  isFirstProductSection: {
    type: Boolean,
    default: false,
  },
});

const { productDataClause, updateProductDataClause } = useEnquiries({ store });
const { allProducts, setProductFiltersInQuery, replaceProductsInQuery, updateProductFilterInQuery } = useAlertBuilder({ store});
const { getCommunityById } = useCommunities({ store });

const selectedAssociations = ref([]);
const selectedCommunities = ref([]);

/** TODO: this should be store driven via llProducts and computed, 
 * all operations should be performed via action without deepClone,
 * below is diconnection between store and the component */
const filters = ref([
  {
    id: 1,
    operator: 'and',
    status: 'buying',
    items: [],
    level: 'item_level',
  },
]);

const productTypeOptions = computed(() => [
  {
    text: t('Item Level'),
    value: 'item_level',
    withItems: true,
  },
  {
    text: t('Product group level'),
    value: 'product_group_level',
    withItems: true,
  },
  {
    text: t('ai generated associations'),
    value: 'ai_generated_associations',
  },
  {
    text: t('Communities'),
    value: 'communities',
  },
]);

const filteredProductTypeOptions = (filter) => productTypeOptions.value.filter(o => !filter.items?.length || o.withItems);

const buyingNotBuyingOptions = computed(() => [
  {
    text: t('Buying'),
    value: 'buying',
  },
  {
    text: t('Not Buying'),
    value: 'not_buying',
  },
]);

const getNewId = () => (Math.max(0, ...filters.value.map(o => o.id)) + 1);

const handleSelectedCommunities = async (communities, filter) => {
  selectedCommunities.value = communities;
  for (const { id } of communities) {
    const community = await getCommunityById(id);
    const items = community.map(({ product_code, product_name }) => (
      { product_code, product_name, status: 'include' }
    ));
    filters.value.push({
      id: getNewId(),
      level: 'item_level',
      status: filter.status,
      operator: filter.operator,
      items,
    });
  }
  selectedCommunities.value = [];
  removeProductFilter(filter);
  setProductFiltersInQuery(deepClone(filters.value));
};

const handleSelectedAssociations = (items, filter) => {
  selectedAssociations.value = items;
  items.forEach(({ antecedent_code, antecedent_name, consequent_code, consequent_name }) => {
    filters.value.push({
      id: getNewId(),
      level: 'item_level',
      status: filter.status,
      operator: filter.operator,
      items: [
        { product_code: antecedent_code, product_name: antecedent_name, status: 'include' },
        { product_code: consequent_code, product_name: consequent_name, status: 'include' },
      ],
    });
  });
  selectedAssociations.value = [];
  removeProductFilter(filter);
  setProductFiltersInQuery(deepClone(filters.value));
};

const setProductGroupLevel = (level, filter) => {
  // Find the given sales filter
  const idx = filters.value.findIndex(o => o.id === filter.id);
  filters.value[idx].product_group_level = level;
  replaceProductsInQuery(deepClone(filters.value));
};

// Add product to the query
const addProduct = (product, filter) => {
  const idx = filters.value.findIndex(o => o.id === filter.id);
  filters.value[idx].items.push(product);
  setProductFiltersInQuery(deepClone(filters.value));
};

const removeProductFromQuery = (product, filter) => {
  const idx = filters.value.findIndex(o => o.id === filter.id);
  filters.value[idx].items = filters.value[idx].items?.filter(o => o.id !== product.id) || [];
  if (!props.isEditMode) {
    setProductFiltersInQuery(deepClone(filters.value));
  } else {
    replaceProductsInQuery({
      id: filter.id,
      items: filters.value[idx].items,
    });
  }
};

const removeProductFilter = filter => {
  filters.value = filters.value.filter(x => x.id !== filter.id);
  replaceProductsInQuery(deepClone(filters.value));
};

const handleProductSwitch = (event, filter) => {
  updateProductFilterInQuery({
    operator: event.value.toLowerCase(),
    filter,
  });
};

function handleSwitch(event) {
  updateProductDataClause(event.value);
}

function setProductType(level, filter) {
  // If it's a new filter, add it to the selected values
  const idx = filters.value.findIndex(o => o.id === filter.id);
  filters.value[idx].level = level;
  filters.value[idx].items = [];

  replaceProductsInQuery({
    id: filter.id,
    level,
    items: [],
  });
}

function setBuyingNotBuying(status, filter) {
  const idx = filters.value.findIndex(o => o.id === filter.id);
  filters.value[idx].status = status;
  replaceProductsInQuery(deepClone(filters.value));
}

function handleAddButtonClick(type) {
  filters.value.push({
    level: 'item_level',
    status: 'buying',
    operator: type.toLowerCase(),
    id: getNewId(),
    items: [],
  });
}

const isValid = computed(() => filters.value.every(o => o.items.length > 0));

watch(filters, () => {
  emit('valid', isValid.value);
});

onMounted(() => {
  if (allProducts.value.length > 0) {
    filters.value = deepClone(allProducts.value);
  }
});
</script>

<style lang="scss" scoped>
.intro-wrapper {
  margin-inline: auto;
  max-width: 28rem;
  text-align: center;
  margin-bottom: var(--spacing-5);
}

h4 {
  margin: var(--spacing-2) 0;
}

.add-button {
  background-color: var(--colour-utility-white);
  top: 170px;
  position: sticky;
  display: flex;
  align-items: center;
  z-index: 10;

  :deep(.icon-button) {
    font-size: var(--font-size-4);
    font-weight: var(--font-weight-medium);

    span {
      margin-left: var(--spacing-2);
    }
  }
}
</style>
