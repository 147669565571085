<template>
  <section class="footer-wrapper">
    <div
      class="footer-section"
      :class="{ overlap: areButtonsVisible && isSmallScreen }"
    >
      <div class="btn-container">
        <IconButton
          class="btn toggle-btn"
          :class="{ active: isToggleButtonActive }"
          icon-name="more"
          :icon-color="isToggleButtonActive ? 'var(--colour-utility-white)' : 'var(--colour-utility-action)'"
          purpose="transparent"
          icon-only
          :icon-size="32"
          @click.stop="toggleButtons"
        />
      </div>

      <template v-if="areButtonsVisible">
        <ButtonGroup class="btn-group-container">
          <!-- Export modal -->
          <ExportModal
            v-if="showExport"
            :title="t('Export enquiry')"
            :date-params="dateParams"
            :query="vroute.query"
            :report-type="props.reportType"
            :active-columns-data="activeColumnsData"
            :request-parameter-override="props.requestParameterOverride"
            @click="toggleExportModal"
          />
          <!-- Promotion Material -->
          <CustomButton
            v-if="getPromoLink()"
            :label="t('See Promo material')"
            :href="getPromoLink()"
            target="_blank"
            purpose="text"
          />
          <div class="buttons-centre">
            <!-- Add/close interaction -->
            <CustomButton
              v-if="isCustomerSnapshot && canCreateInteractions"
              small
              purpose="action"
              :label="interactionButtonLabel"
              class="footer-button"
              @click="handleInteraction"
            />

            <!-- Generate interaction button -->
            <CustomButton
              v-if="isCustomerName && canCreateInteractions"
              small
              purpose="action"
              :label="t('Generate interactions')"
              class="footer-button"
              @click="toggleGenerateInteractionsModal"
            />

            <!-- Add opportunity button -->
            <CustomButton
              v-if="canCreateOpportunities && reportType !== 'query-builder'"
              small
              purpose="action"
              :label="t('Add opportunity')"
              class="footer-button"
              @click="toggleOpportunityModal"
            />

            <!-- View customer map button -->
            <CustomButton
              v-if="showCustomerMap"
              small
              purpose="action"
              :label="t('View customer map')"
              class="footer-button"
              @click="toggleAddressModal"
            />
          </div>
        </ButtonGroup>

        <div
          class="dashboard-btn-container"
        >
          <!-- START -->
          <CustomButton
            class="dashboard-button"
            icon
            @click.stop="shareMenuHandler"
          >
            <div 
              v-if="isShareMenuOpen"
              class="share-menu-container"
            >
              <ul class="links">
                <li>
                  <IconButton
                    purpose="action"
                    icon-name="share"
                    icon-align="right"
                    icon-color="var(--colour-utility-white)"
                    @click.stop="shareClickHandler"
                  >
                    {{ t('Share') }}
                  </IconButton>
                </li>
                <li>
                  <IconButton
                    purpose="action"
                    icon-name="plus"
                    icon-align="right"
                    icon-color="var(--colour-utility-white)"
                    @click.stop="addWidget"
                  >
                    {{ t('Add to dashboard') }}
                  </IconButton>
                </li>
              </ul>
            </div>
            <IconBase
              icon-name="share"
              icon-color="var(--colour-utility-action)"
              :width="iconWidth"
              :height="iconHeight"
            />
          </CustomButton>
          <!-- END -->
        </div>
      </template>
    </div>
    <SaveChangesWarning
      :query="query"
      @save-as-new-widget="addWidget"
    />
  </section>
  <Teleport to="#modal-teleport-target">
    <!-- Generate interaction modal -->
    <GenerateInteractionsModal
      v-if="isGenerateInteractionsModalOpened"
      :report-type="props.reportType"
      @saved="toggleGenerateInteractionsModal"
      @close="toggleGenerateInteractionsModal"
    />
    <!-- Add opportunity modal -->
    <AddEditOpportunityModal
      v-if="isOpportunityModalOpened"
      @saved="toggleOpportunityModal"
      @close="toggleOpportunityModal"
    />
    <!-- Complete interaction modal -->
    <CompleteInteractionModal
      v-if="isCompleteInteractionModalOpened"
      @close-modal="toggleInteractionModal"
      @completed="onCompleteInteraction"
    />
    <AddEditWidget
      v-if="isWidgetModalOpened"
      :item="widgetToAdd"
      :dashboards="dashboards"
      @close="closeAddWidgetModal"
      @save="handleSaveWidget"
    />
    <!-- Customer map modal -->
    <AddressModal
      v-if="showCustomerMapModal"
      :report-type="props.reportType"
      @close="toggleAddressModal"
    />
    <!-- share modal -->
    <ShareModal
      v-if="isAssigneeMenuOpen"
      :enquiry-url="vroute.fullPath"
      @save="saveEnquiry"
      @close="shareClickHandler"
    />
  </Teleport>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRoute, } from 'vue-router';
import { useStore } from 'vuex';
import { useMq } from 'vue3-mq';
import { storage, t } from '@sales-i/utils';
import { CustomButton, IconBase, IconButton } from '@sales-i/dsv3';
import ExportModal from '@/intelligence/components/ReportFooter/ExportModal.vue';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import { baseUrl as crmUrl, interactionsArea } from '@/crm/router/urlBits';
import { customerScope } from '@/intelligence/router/urlBits';
import breakpoints from '@/shared/utils/breakpoints';
import AddEditOpportunityModal from '@/crm/components/Opportunity/AddEditOpportunityModal.vue';
import CompleteInteractionModal from '@/crm/views/Interactions/CompleteInteractionModal.vue';
import GenerateInteractionsModal from '@/crm/views/Interactions/GenerateInteractionsModal.vue';
import SaveChangesWarning from '@/intelligence/components/ReportFooter/SaveChangesWarning.vue';
import usePermissions from '@/shared/composables/usePermissions';
import { interactions, opportunities } from '@/shared/store/data/policies';
import { acceptedDimensions } from '@/intelligence/components/ReportFooter/acceptedDimensions';
import { CUSTOMER_FOR_INTERACTION } from '@/shared/constants/storageItems';
import { QUERY_BUILDER, FULL_PICTURE, MATRIX, SALES_VS_GAPS, VARIANCE, DASHBOARD_SUPPORTED_REPORT_TYPES, CUSTOMER_ACTIVITY, INTERACTION_SPEND_SUMMARY, TRENDS } from '@/intelligence/store/data/reportTypes';
import { useDashboard } from '@/intelligence/components/Dashboard/composables/useDashboard';
import AddEditWidget from '@/intelligence/components/Dashboard/AddEditWidget.vue';
import AddressModal from '@/intelligence/views/Communities/AddressModal.vue';
import { DEFAULT_DASHBOARD_CATEGORY, OTHER_DASHBOARD_CATEGORY } from '@/intelligence/components/Dashboard/constants';
import { navigateToUrl } from 'single-spa';
import ShareModal from './ShareModal.vue';
import { useInteraction } from '@/crm/composables/useInteraction';
import useContact from '@/crm/composables/useContact';
import useShared from '@/intelligence/composables/useShared';
import useNotification from '@/shared/composables/useNotification';

const mq = useMq();
const store = useStore();
const vroute = useRoute();
const { hasAccess } = usePermissions();
const { 
  dashboards, postWidget, getDashboardByCategory, 
  setUnsavedChangesModal, setQueryStringChanged } = useDashboard({ store });
const { 
  selected, getInteraction, setDate, setRelatedInteraction, 
  postInteraction } = useInteraction({ store });
const { getContact } = useContact({ store });
const { getInputData } = useShared({ store });
const { sendSuccessAlert } = useNotification({ store });

const props = defineProps({
  activeColumnsData: {
    default: () => [],
    type: Array,
  },
  dimensions: {
    default: () => [],
    type: [Array, String],
  },
  reportType: {
    type: String,
    default: '',
    required: true,
  },
  subTitle: {
    default: '',
    type: String,
  },
  interactionId: {
    type: Number,
    default: 0,
  },
  reportArea: {
    type: String,
    default: '',
  },
  reportDescription: {
    type: String,
    default: '',
  },
  requestParams: {
    type: Object,
    default: undefined,
  },
  requestParameterOverride: {
    type: String,
    default: '',
  },
  query: {
    type: Object,
    default: () => ({}),
  },
});

const showButtons = ref(false);
const isSavingWidget = ref(false);
const isOpportunityModalOpened = ref(false);
const isCompleteInteractionModalOpened = ref(false);
const isGenerateInteractionsModalOpened = ref(false);
const isExportModalOpened = ref(false);
const isToggleButtonActive = ref(false);
const showCustomerMapModal = ref(false);

const isWidgetModalOpened = ref(false);
const widgetToAdd = ref(null);

const isShareMenuOpen = ref(false);
const isAssigneeMenuOpen = ref(false);

const canCreateInteractions = computed(() => hasAccess(interactions, 'create'));
const canCreateOpportunities = computed(() => hasAccess(opportunities, 'create'));

// Show the export button only if the reportType is FULL_PICTURE or SALES_VS_GAPS
const showExport = computed(() => [QUERY_BUILDER, FULL_PICTURE, SALES_VS_GAPS, MATRIX, VARIANCE].includes(props.reportType));

// This is used to determine if the 'Generate interactions' button should be shown for customers.
// This prop identifies if the chosen dimension relates to the customer 'Name'.
// The first one is the actual dimension name that the user chooses. The others are used to identify the customer name in different reports.
const isCustomerName = computed(() => {
  let isAcceptedDimension = false;
  if (typeof props.dimensions === 'string') {
    const propsDims = props.dimensions.split('-').map(d => d.trim());
    acceptedDimensions.forEach(dimension => {
      if (propsDims.includes(dimension)) {
        isAcceptedDimension = true;
      }
    });
  }
  return (
    isAcceptedDimension ||
    acceptedDimensions.includes(props.dimensions) ||
    acceptedDimensions.includes(props.dimensions[0]) ||
    acceptedDimensions.includes(props.dimensions[1])
  );
});

// Show a 'View customer map' button if the report is QUERY_BUILDER
const showCustomerMap = computed(() => props.reportType === QUERY_BUILDER);

const inputData = computed(() => getInputData(props.reportType));
const areButtonsVisible = computed(() => (isSmallScreen.value ? showButtons.value : true));
const isSmallScreen = computed(() => breakpoints.smAndDown.includes(mq.current));
const interactionButtonLabel = computed(() => (props.interactionId ? t('Close Interaction') : t('Add Interaction')));
const isCustomerSnapshot = computed(() => vroute?.path?.includes(`/${customerScope}/snapshot`));
const customerData = computed(() => store.state.crm.customers.selected.data);

onMounted(async () => {
  if (props.interactionId) {
    await getInteraction({ id: props.interactionId });
  }
  if (customerData.value.id && customerData.value.default_contact_id) {
    getContact({ id: customerData.value.default_contact_id });
  }
});

function handleInteraction() {
  if (props.interactionId) isCompleteInteractionModalOpened.value = true;
  else {
    storage.set(CUSTOMER_FOR_INTERACTION, customerData.value);
    let path = `${crmUrl}/${interactionsArea}/add`;
    let query = new URLSearchParams({ clean: false });
    window.open(`${path}?${query.toString()}`, '_blank');
  }
}
function toggleExportModal() {
  isExportModalOpened.value = !isExportModalOpened.value;
}
function toggleGenerateInteractionsModal() {
  isGenerateInteractionsModalOpened.value = !isGenerateInteractionsModalOpened.value;
}
function toggleButtons() {
  showButtons.value = !showButtons.value;
  isToggleButtonActive.value = !isToggleButtonActive.value;
}
function toggleOpportunityModal() {
  isOpportunityModalOpened.value = !isOpportunityModalOpened.value;
}
function toggleInteractionModal() {
  isCompleteInteractionModalOpened.value = !isCompleteInteractionModalOpened.value;
}
function toggleAddressModal() {
  showCustomerMapModal.value = !showCustomerMapModal.value;
}

function getPromoLink() {
  const [, link] = props.subTitle.split('|~~|');
  if (link !== undefined && link.length > 0) {
    return link;
  }
  return false;
}

async function onCompleteInteraction(timescale) {
  // if timescale for follow-up selected, adjust currently editing for new follow-up
  if (timescale.date && props.interactionId) {
    await setDate(timescale);
    await setRelatedInteraction(props.interactionId);
    await postInteraction();
    const { data } = selected.value;
    navigateToUrl(`${crmUrl}/${interactionsArea}/${data.id}`);
  } else history.go(0);
}
function shareMenuHandler() {
  isShareMenuOpen.value = !isShareMenuOpen.value;
}
function shareClickHandler() {
  shareMenuHandler();
  isAssigneeMenuOpen.value = !isAssigneeMenuOpen.value;
}
function saveEnquiry() {
  isShareMenuOpen.value = false;
  isAssigneeMenuOpen.value = false;
}
function addWidget() {
  const widgetCategory = DASHBOARD_SUPPORTED_REPORT_TYPES.includes(props.reportType) 
    ? props.reportArea || inputData.value.area
    : OTHER_DASHBOARD_CATEGORY; 
    
  // get dashboard id for this area or for custom
  const dashboard_id = (
    getDashboardByCategory(widgetCategory) ||
    getDashboardByCategory(DEFAULT_DASHBOARD_CATEGORY)
  )?.id;

  widgetToAdd.value = {
    dashboard_id,
    reportType: props.reportType,
    name: inputData.value.title,
    description: props.reportDescription || inputData.value.description,
    requestParams: props.requestParams || { ...vroute.query },
    width: [CUSTOMER_ACTIVITY, INTERACTION_SPEND_SUMMARY, TRENDS].includes(props.reportType) ? 3 : 1,
    settings: { url: vroute.fullPath },
  };
  isWidgetModalOpened.value = true;
}
async function handleSaveWidget(item) {
  if (isSavingWidget.value) return;
  isSavingWidget.value = true;
  const response = await postWidget(item);
  if (response) {
    closeAddWidgetModal();
    setUnsavedChangesModal(false);
    setQueryStringChanged(false);
    sendSuccessAlert(t('Successfully saved as a new widget'));
  }
}

async function closeAddWidgetModal() {
  isWidgetModalOpened.value = false;
  widgetToAdd.value = null;
  isSavingWidget.value = false;
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.footer-wrapper {
  position: relative;
  border-radius: 0 0 var(--border-radius-1) var(--border-radius-1);
}

.footer-section {
  position: fixed;
  left: 0;
  bottom: 0;
  text-align: center;
  padding: var(--spacing-2);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  z-index: 101;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    position: relative;
    flex-wrap: nowrap;
    justify-content: center;
    width: 100%;
    box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-blur) var(--shadow-spread) var(--shadow-colour);
    background-color: var(--colour-panel-action);
    z-index: 100;
  }

  &.overlap {
    right: 0;
    justify-content: space-between;
    background: var(--colour-panel-action);

    .btn-container {
      order: 1;
    }

    .dashboard-btn-container {
      order: 2;
    }

    .btn-group-container {
      display: flex;
      flex-direction: column;
      margin: 0 0 var(--spacing-2);
      flex-basis: 100%;

      &:deep(.button) {
        margin-right: 0;
      }
      > * {
        width: 100%;
      }
    }
  }
}
.buttons-centre {
  display: flex;
  gap: var(--spacing-2);

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    flex: 1 1 auto;
    justify-content: center;
  }
}

// Targeting the export button only when it appears in the report footer.
.button-group :deep(.action.export-button) {
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    cursor: pointer;
    padding: 0;
    text-decoration: underline;
    background-color: transparent;
    color: var(--colour-utility-action);
  }

  &:hover {
    background-color: transparent;
  }
}

.button-group .footer-button:not(:last-child) {
  margin-right: 0;
}

.btn-group-container {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: var(--spacing-1);
  order: -1;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    margin-bottom: 0;
    order: 0;
  }
}

.dashboard-btn-container {
  display: flex;
  justify-content: flex-end;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    flex-basis: 12rem;
  }
}

.btn-container {
  flex-basis: 120px;
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    display: none;
  }

  &:deep(.toggle-btn) {
    &.active {
      background-color: var(--colour-utility-black);
    }
  }
}

.btn {
  box-shadow: 0 0 4px 1px var(--colour-panel-g-16);
  background: var(--colour-utility-white);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:deep(.button-container) {
    // temp/demo TODO: if no title for IconButton than margin should not be there. Solution: move margin from div to span in component
    margin-left: 0;
  }
}

.share-menu-container {
  position: absolute;
  top: -80px;
  right: 30px;
  background-color: var(--colour-utility-white);
  padding: var(--spacing-1) var(--spacing-2);
  border-radius: var(--border-radius-1);
}

.share-menu-container ul li {
  margin-bottom: var(--spacing-1);
  display: flex;
  justify-content: flex-end;
}
</style>