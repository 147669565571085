
import { computed } from 'vue';
import {
  FETCH_REPORT_DATA,
  CLEAR_REPORT_DATA,
  APPLY_DATE_RANGE,
  SET_LOADING,
  REFRESH_REPORT,
  APPLY_OFFSET,
  TOGGLE_DRILL,
  TOGGLE_SELECT_BUBBLES_POPUP,
  DISABLE_SELECT_BUBBLES_POPUP,
  APPLY_LIMIT,
  SET_ACTIVE_COLUMNS,
  SET_EXPANDING_REPORT,
} from '@/intelligence/store/actionType';
import { useStore } from 'vuex';

export default function useShared({ store = useStore() } = {}) {
  const expanded = computed(() => store.state.intelligence.shared.isReportExpanded);
  const reportRefreshCounter = computed(() => store.state.intelligence.shared.reportRefreshCounter);
  const isDrillActive = computed(() => store.state.intelligence.shared.isDrillActive);
  const dataLoading = computed(() => store.state.intelligence.shared.loading);
  const drillActiveRows = computed(() => store.state.intelligence.shared.drillActiveRows);
  const requestParameters = computed(() => store.state.intelligence.shared.requestParameters);
  const activeColumns = computed(() => store.state.intelligence.shared.activeColumns);
  const isSelectBubblesPopupActive = computed(() => store.state.intelligence.shared.isSelectBubblesPopupActive);  

  const getMonth = (params) => store.getters['intelligence/shared/getMonth'](params);
  const isSelectBubblesButtonActive = computed(() => store.getters['intelligence/shared/isSelectBubblesButtonActive']);
  const getDrillsQueryParams = computed(() => store.getters['intelligence/shared/getDrillsQueryParams']);
  const getResponseParameters = store.getters['intelligence/shared/getResponseParameters'];
  const getReportData = id => store.getters['intelligence/shared/getReportData'](id);
  const getInputData = reportType => store.getters['intelligence/shared/getInputData'](reportType);
  const getInputArea = inputArea => store.getters['intelligence/shared/getInputArea'](inputArea);
  const getDrillsLevel = (drillParam) => store.getters['intelligence/shared/getDrillsLevel'](drillParam);
  const getDrillingDimensionId = (drillLevel, dimension) => store.getters['intelligence/shared/getDrillingDimensionId'](drillLevel, dimension);
  const getDrillsLevelBasedOnQuery = dq => store.getters['intelligence/shared/getDrillsLevelBasedOnQuery'](dq);

  const fetchReportData = (params) => store.dispatch(`intelligence/shared/${FETCH_REPORT_DATA}`, params);
  const clearReportData = () => store.dispatch(`intelligence/shared/${CLEAR_REPORT_DATA}`);
  const refreshReport = () => store.dispatch(`intelligence/shared/${REFRESH_REPORT}`);
  const applyDate = (params) => store.dispatch(`intelligence/shared/${APPLY_DATE_RANGE}`, params);
  const setLoading = (params) => store.dispatch(`intelligence/shared/${SET_LOADING}`, params);
  const applyOffset = (params) => store.dispatch(`intelligence/shared/${APPLY_OFFSET}`, params);
  const applyLimit = value => store.dispatch(`intelligence/shared/${APPLY_LIMIT}`, value);
  const toggleDrillOption = (params) => store.dispatch(`intelligence/shared/${TOGGLE_DRILL}`, params);
  const toggleSelectBubblesPopup = (params) => store.dispatch(`intelligence/shared/${TOGGLE_SELECT_BUBBLES_POPUP}`, params);
  const disableSelectBubblesPopup = (params) => store.dispatch(`intelligence/shared/${DISABLE_SELECT_BUBBLES_POPUP}`, params);
  const setActiveColumns = (params) => store.dispatch(`intelligence/shared/${SET_ACTIVE_COLUMNS}`, params);
  
  const setDrillActiveRows = (params) => store.dispatch('intelligence/shared/setDrillActiveRows', params);
  const addDrillingDimensionRowValuesOnLevel = params => store.dispatch('intelligence/shared/addDrillingDimensionRowValuesOnLevel', params);
  const setDrillsDataByQuery = (params) => store.dispatch('intelligence/shared/setDrillsDataByQuery', params);
  const addDrillingDimensionOnLevel = (params) => store.dispatch('intelligence/shared/addDrillingDimensionOnLevel', params);
  const generateDrillActiveRows = (params) => store.dispatch('intelligence/shared/generateDrillActiveRows', params);
  const setExpanded = (params) => store.dispatch(`intelligence/shared/${SET_EXPANDING_REPORT}`, params);

  return {
    activeColumns,
    reportRefreshCounter,
    expanded,
    getMonth,
    isDrillActive,
    dataLoading,
    isSelectBubblesButtonActive,
    requestParameters,
    drillActiveRows,
    getDrillsQueryParams,
    isSelectBubblesPopupActive,
    getResponseParameters,
    applyOffset,
    getInputArea,
    getReportData,
    getInputData,
    fetchReportData,
    clearReportData,
    refreshReport,
    applyDate,
    setLoading,
    toggleDrillOption,
    toggleSelectBubblesPopup,
    disableSelectBubblesPopup,
    setDrillActiveRows,
    applyLimit,
    getDrillsLevel,
    getDrillingDimensionId,
    getDrillsLevelBasedOnQuery,
    setActiveColumns,
    addDrillingDimensionRowValuesOnLevel,
    setDrillsDataByQuery,
    addDrillingDimensionOnLevel,
    generateDrillActiveRows,
    setExpanded,
  };
}