<template>
  <div>
    <BufferImage
      v-if="loading"
      class="buffer my-4"
      color="var(--colour-utility-black)"
      float="center"
    />
    <div
      v-if="loaded && !loading"
      class="full-picture"
      :class="{ expanded: isExpanded, 'flex-space': !paginationNoResults }"
    >
      <component
        :is="components[reportToShow]"
        v-if="loaded && dataRows.length && enquiryData !== undefined"
        :loaded="loaded"
        :enquiry-data="enquiryData"
        :active-columns="activeColumns"
        sort-disabled
        is-snapshot
        is-alert-results
        @remove-alert-column="removeAlertColumn"
        @add-alert-column="addAlertColumn"
      />
      <NoResults v-if="loaded && !dataRows.length" />
      <ReportFooter
        v-if="loaded && dataRows.length > 0"
        :key="savedQuery.id"
        :dimensions="dimensions"
        :report-type="reportType"
        :sub-title="savedQuery.query_name"
        :request-parameter-override="requestParameterOverride"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { BufferImage } from '@sales-i/dsv3';
import NoResults from '@/intelligence/components/Shared/NoResults';
import VarianceTable from '@/intelligence/components/Variance/VarianceTable.vue';
import ReportFooter from '@/intelligence/components/ReportFooter/ReportFooter';
import useJwt from '@/shared/composables/useJwt';
import CommunitiesReportTable from '@/intelligence/components/Communities/CommunitiesReportTable.vue';
import { t } from '@sales-i/utils';
import { parseQuery } from '@/intelligence/utils/parseGraphQl';
import { useAlertBuilder } from '@/intelligence/composables/useAlertBuilder'; 
import useShared from '@/intelligence/composables/useShared';
import { QUERY_BUILDER, VARIANCE } from '@/intelligence/store/data/reportTypes';
import { SET_UNIQUE_CUSTOMERS } from '@/shared/store/actionType';
import { SET_LOADING } from '@/intelligence/store/actionType';

const props = defineProps({
  id: {
    type: Number,
    default: 0,
  },
});

const emit = defineEmits(['loaded', 'update:columns', 'date-ranges', 'is-variance']);

const store = useStore();
const vroute = useRoute();

const { applyFilters, clearEnquiryFilters, setFilters, getSavedAlertById, loading, goToReport, addQueryArgsToStore, clearAllFilters } = useAlertBuilder({ store });
const { applyDate, fetchReportData } = useShared({ store });

const { isNapa } = useJwt();

const isNapaVal = ref(false);
const loaded = ref(false);
const summaryData = ref({});
const rawData = ref({});
const columnHeadings = ref([]);
const dimensions = ref([]);
const dataRows = ref([]);
const customerNumbers = ref([]);
const isExpanded = ref(false);
const reportType = ref(QUERY_BUILDER);
const reportToShow = ref('full_picture');
const activeColumns = ref(['sales_value', 'quantity']);
const components = {
  variance: VarianceTable,
  full_picture: CommunitiesReportTable
};
const reportId = ref(1);

const savedQuery = computed(() => store.state.intelligence.savedQuery.data);
const offset = computed(() => store.state.intelligence.shared.requestParameters.offset);
const getReportData = computed(() => store.getters['intelligence/shared/getReportData']);
const requestParameterOverride = computed(() => activeResultSet.value.query_arguments?.split('||||')[0]);
const bubbleData = computed(() => store.state.intelligence.enquiry.bubbleData || []);

const setLoading = (value) => store.commit(`intelligence/shared/${SET_LOADING}`, value);

const uniqueCustomers = computed(() => {
  const arr = [];
  for (let n=0; n < dataRows.value?.length; n++) {
    const _ref = dataRows.value[n];
    if (arr.findIndex(o => o === _ref.dimensions[0].entity_id) === -1) {
      arr.push({
        id: _ref.dimensions[0].entity_id,
        title: _ref.dimensions[0].dimensions[0],
        account_number: _ref.dimensions[0].dimensions[1],
      });
    }
  }
  store.commit(`intelligence/savedQuery/${SET_UNIQUE_CUSTOMERS}`, arr);
  return arr;
});

const customersInReport = computed(() => uniqueCustomers.value.length);

const enquiryData = computed(() => {
  if (reportToShow.value === 'full_picture') {
    return rawData.value;
  }
  const reportData = getReportData.value(reportId.value);
  // application of the local lazy loading logic (don't need to re-fetch the data)
  if (reportToShow.value === 'variance' && reportData) {
    const reportDataShortened = {...reportData};
    reportDataShortened.rows = reportData.rows.slice(0,50 + offset.value);
    return reportDataShortened;
  }
  return reportData;
});

const paginationNoResults = computed(() => false);

const activeResultSet = computed(() => {
  const { resultId } = vroute.params;
  if (resultId === undefined) {
    return getMostRecentResultsSet();
  }
  return getResultSetById(resultId);
});

watch(() => enquiryData.value, () => {
  if (enquiryData.value) {
    dataRows.value = enquiryData.value.row_count > 0 ? [...enquiryData.value.rows] : [];
    columnHeadings.value = [...getDimensionHeadings(), ...enquiryData.value.headings.values];
    dimensions.value = [...enquiryData.value.headings.dimensions];
    summaryData.value = enquiryData.value.summary;
  }
});

watch(() => props.id, async (newId, oldId) => {
  if (newId !== oldId) {
    await getSavedAlertById(newId);
    if (savedQuery.value.results.length === 0) {
      return;
    }
    await generateReport(getMostRecentResultsSet());
  }
}, { immediate: false });

watch(() => customersInReport.value, (newVal) => {
  if (newVal === 0) {
    store.commit(`intelligence/savedQuery/${SET_UNIQUE_CUSTOMERS}`, []);
  }
});

onMounted(async () => {
  const { resultId } = vroute.params;
  isNapaVal.value = await isNapa();
  if (props.id === null) return;
  await getSavedAlertById(props.id);
  if (savedQuery.value?.results?.length === 0) return;
  await generateReport(resultId === undefined 
    ? getMostRecentResultsSet() 
    : getResultSetById(resultId)
  );
});

onBeforeUnmount(() => {
  loaded.value = false;
});

function getMostRecentResultsSet() {
  return 'results' in savedQuery.value ? savedQuery.value.results[savedQuery.value.results.length - 1] : [];
}

function getResultSetById(id) {
  const idx = savedQuery.value.results.findIndex(o => o.id.toString() === id);
  if (idx === -1) {
    return getMostRecentResultsSet();
  }
  return savedQuery.value.results[idx];
}

function getDimensionHeadings() {
  const clonedHeadings = [...enquiryData.value.headings.dimensions];
  clonedHeadings.splice(2, 0, t('Product Name'));
  return clonedHeadings;
}

async function generateReport(results) {
  setLoading(true);
  
  if (results.query_arguments === undefined) {
    return [];
  }

  const [queryArguments, queryFields, ] = results.query_arguments.split('||||');
  const fields = JSON.parse(queryFields.split('~~~~')[0]);

  if ('isUsingVarianceOutput' in fields && fields.isUsingVarianceOutput) reportToShow.value = 'variance';

  clearAllFilters();
  await addQueryArgsToStore();
  const abReportId = await goToReport({
    incrementRunCount: true,
  });

  rawData.value = getReportData.value(abReportId);

  customerNumbers.value = rawData.value.rows.map(o => o.dimensions[0].dimensions[1]);
  const parsedArguments = parseQuery(`{${queryArguments}}`);

  if (bubbleData.value) {
    // This is the account number bubble, it's better than hardcoded index due to different id's in other env
    const accountNumberBubble = bubbleData.value.find(item => item.title === 'Account Number'); 
    const accountId = accountNumberBubble ? accountNumberBubble.id : null;
    const dateRange = parsedArguments.enquiry.date_range;
    const dateRange2 = parsedArguments.enquiry.date_range_2;
    // Prepare parameters for setDateRange
    let params = {
      date_from: dateRange.from,
      date_to: dateRange.to,
    };
    // Add dateRange2 parameters if dateRange2 exists
    if (dateRange2) {
      params.date_from2 = dateRange2.from;
      params.date_to2 = dateRange2.to;
    }
    const dateRangesForSubtitles = {
      dateRange,
      dateRange2,
    };
    switch (reportToShow.value) {
    case VARIANCE:
      await clearEnquiryFilters();
      await applyDate(params);
      await setFilters(
        {
          title: 'Customer Numbers',
          id: accountId,
          selected_values: customerNumbers.value.map(f => ({ id: f })),
          filter_type: 'include',
          ignore_data_clauses: true,
        });
      await applyFilters();
      reportId.value = await fetchReportData({
        reportType: VARIANCE,
        completeRefresh: true,
      });
      emit('date-ranges', dateRangesForSubtitles);
      break;
    }
  }

  if (enquiryData.value !== undefined) {
    setLoading(false);
    loaded.value = true;
    emit('loaded', enquiryData.value.rows);
    emit('is-variance', reportToShow.value === 'variance');
  }
}

function addAlertColumn(value) {
  activeColumns.value.push(value);
}

function removeAlertColumn(newColumns) {
  activeColumns.value = activeColumns.value.filter(item => item !== newColumns);
}

</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.full-picture :deep(.button-group .action.export-button) {
  padding: var(--spacing-2);
}

:deep(.modal) {
  .content {
    padding: var(--spacing-3);

    header {
      h2 {
        font-size: var(--font-size-3);
      }
    }

    section {
      margin: 0 0 var(--spacing-2) 0;

      h3 {
        font-size: var(--font-size-4);
      }

      .flow {
        p {
          margin-top: 0;
        }
      }
    }
  }
}

.header-section {
  margin: var(--spacing-2) 0;
}

.icon-button.button {
  box-shadow: 0 0 4px 1px var(--colour-panel-g-16);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: var(--colour-utility-white);

  &.active {
    background: var(--colour-utility-black);
  }

  &.disabled {
    background: var(--colour-panel-g-32);
    cursor: not-allowed;
  }
}

.full-picture {
  border-radius: var(--border-radius-1);
  box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-blur) var(--shadow-spread) var(--shadow-colour);
  // display: flex;
  // flex-direction: column;
  overflow: hidden;
  margin-bottom: 58px;
  
  .header-section {
    padding: var(--spacing-2);
  }

  h3 {
    overflow-wrap: anywhere;
    width: 80%;
  }
  span {
    display: block;
    width: 80%;
  }

  &.flex-space {
    justify-content: space-between;
  }
}

.full-picture {
  &.expanded {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    height: 100%;
    justify-content: flex-start;
    overflow-y: auto;

    .expand-button {
      display: flex;

      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        right: var(--spacing-2);
      }
    }
  }

  .expand-button {
    position: absolute;
    top: var(--spacing-3);
    right: 80px;
    transform: rotate(45deg);
    display: flex;
    justify-content: center;
    height: 32px !important;
    width: 32px !important;
    background-color: var(--colour-panel-action);

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      display: none;
    }
  }
}

table {
  thead {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 15;
  }

  tbody {
    margin-top: 73px;
  }

  td,
  th,
  tr {
    vertical-align: middle;
    font-size: var(--font-size-body);
    color: var(--colour-utility-black);
    text-align: center;
    border: 0;
    position: relative;

    &.highlighted {
      background: var(--colour-utility-focus);
    }
  }

  td {
    font-size: var(--font-size-small);
    padding: 0 var(--spacing-2);

    div {
      padding: var(--spacing-2) 0;
    }
  }

  thead {
    th {
      min-width: 120px;
      padding: var(--spacing-3) var(--spacing-2) var(--spacing-3);
      background: var(--colour-panel-action);
      color: var(--colour-utility-action);
      border-bottom: 1px solid var(--colour-panel-g-16);
      position: relative;
      top: 0;
      z-index: 1;
      text-decoration: underline;

      @media #{map-get($display-breakpoints, 'sm-and-up')} {
        min-width: 128px;
      }

      .heading {
        overflow: visible;
        white-space: nowrap;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        height: 24px;
      }

      &.dimension {
        min-width: 120px;

        @media #{map-get($display-breakpoints, 'sm-and-up')} {
          min-width: 195px;
        }
      }

      &.sort {
        background-color: var(--colour-panel-g-2);
      }

      .svg-container {
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 10px;
        right: 15px;
      }

      &:not(.dimension):hover {
        cursor: pointer;
        background-color: var(--colour-data-deluge-light);
      }
    }
  }

  tbody {
    tr {
      .drill ~ .title {
        padding-left: var(--spacing-4);
      }
    }
  }

  tr {
    &:first-of-type {
      th,
      td {
        padding-top: var(--spacing-3);
      }

      .drill {
        align-items: end;
      }
    }

    &:nth-of-type(2n) {
      // This is a rendition of the global --colour-panel-g-2 style that allows the table shadow to be visible.
      background: rgba(200, 200, 200, 0.1);
    }

    td {
      &:first-of-type {
        text-align: left;
        padding-left: var(--spacing-2);
        cursor: help;
      }

      div {
        white-space: nowrap;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        max-width: 195px;
      }
    }
  }
}

.chart.burn-down {
  display: flex;
}

.chip {
  position: absolute;
  width: 100px;
  color: var(--colour-utility-black);
  background: var(--colour-panel-g-0);
  box-shadow: 0 0 var(--border-radius-half) var(--shadow-spread) var(--shadow-colour);
  font-size: var(--font-size-5);
  font-weight: var(--font-weight-medium);
  letter-spacing: 0;
  line-height: var(--spacing-3);
  padding: var(--spacing-1);
  border-radius: var(--spacing-6);
  margin: auto;
  margin-top: var(--spacing-1);
  left: 0;
  right: 0;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    width: 104px;
  }
}

.product-tooltip {
  position: absolute;
  opacity: 0;
  padding: var(--spacing-half) var(--spacing-1);
  border-radius: var(--spacing-2);
  text-align: center;
  color: var(--colour-utility-white);
  font-family: var(--font-family-primary);
  font-size: var(--font-size-small);
  letter-spacing: 0;
  line-height: var(--spacing-2);
  background-color: var(--colour-utility-black);
  width: 200px;
}

#communities-report-details-view {
  h3 {
    overflow-wrap: anywhere;
    margin: var(--spacing-3) 0;
  }

  p {
    text-align: center;
    padding: var(--spacing-2);
  }

  .checkbox {
    margin: var(--spacing-1);
  }

  .filters {
    width: 100%;

    tr {
      border-bottom: 1px solid #989898;
      width: 100%;

      &.expanded {
        border-bottom: none;
      }
    }

    td:first-child {
      width: 75%;
      text-wrap: wrap;
      word-break: break-all;
    }
  }
}

.container-wrapper {
  position: absolute;
  top: var(--spacing-3);
  right: var(--spacing-3);
}

.alert-actions {
  display: flex;
  flex-wrap: wrap;
  align-content: center;

  :deep(button) {
    margin-right: var(--spacing-2);
  }

  p {
    width: 100%;
    margin: var(--spacing-2) 0;
  }
}

</style>

<style lang="scss">
#communities-report-details-view {
  #community-report-details-view-community-select,
  #community-report-details-filter-selection {
    select {
      box-shadow: none;
    }
  }
}
</style>