<!-- eslint-disable vuejs-accessibility/no-static-element-interactions -->
<template>
  <div
    class="droppable"
    @dragover.prevent="handleDragOver"
    @dragleave="handleDragLeave"
    @drop="handleDrop"
  >
    <slot />
  </div>
</template>

<script setup>
const emit = defineEmits(['dragOver', 'dragLeave', 'drop']);

const handleDragOver = event => emit('dragOver', event);
const handleDragLeave = event => emit('dragLeave', event);
const handleDrop = event => emit('drop', event);
</script>

<style lang="scss" scoped>
.droppable {
  display: flex;
}
</style>