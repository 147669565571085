<template>
  <LeftHandNavigation
    :items="menu.data"
    :active-section="activeSection"
    :loading="menu.loading"
    :allow-click-on-active="true"
    @item-clicked="itemClicked"
    @active-section-clicked="setActiveSection"
  >
    <router-view />
  </LeftHandNavigation>
</template>

<script setup>
import LeftHandNavigation from '@/admin/components/Layout/LeftHandNavigation.vue';
import { ref, computed, onMounted, watch } from 'vue';
import { useRoute, } from 'vue-router';
import { useStore } from 'vuex';
import { GET_MENU_OPTIONS, } from '@/admin/store/actionType';
import { baseUrl, root, companyArea, permissionsSection } from '@/admin/router/urlBits';
import { navigateToUrl } from 'single-spa';
import useSystem from '@/shared/composables/useSystem';

const store = useStore();
const vroute = useRoute();

const { getTenant } = useSystem({ store });

const activeSection = ref(0);

const menu = computed(() => store.state.admin.pbac.menu);

const getMenuOptions = () => store.dispatch(`admin/pbac/${GET_MENU_OPTIONS}`);

watch(() => vroute.fullPath, (to, from) => {
  let toParts = to.split('/');
  let fromParts = from.split('/');
  if (fromParts.includes('roles') && toParts.includes('users') ||
  fromParts.includes('users') && toParts.includes('roles')) {
    setActiveSection();
  }
});

const itemClicked = (item) => {
  let route = item.name.toLowerCase().replace(/ /g, '-');
  let directTo = route !== permissionsSection ? route : '';
  navigateToUrl(`${baseUrl}/${companyArea}/${permissionsSection}/${directTo}`);
};

const setActiveSection = (index) => {
  if (index === undefined) {
    index = 0;
    let ignore = ['', root, companyArea, permissionsSection, 'add', 'edit', 'role', 'data-restrictions'];
    let parts = vroute.fullPath.split('/');
    parts.forEach(part => {
      if (!ignore.includes(part)) {
        let newIndex = menu.value.data.findIndex(item => item.name.toLowerCase().replace(/ /g, '-') === part);
        if (newIndex >= 0) {
          index = newIndex;
        }
      }
    });
  }
  activeSection.value = index;
};

onMounted(() => {
  getMenuOptions();
  getTenant();
  setActiveSection();
});
</script>

<style lang="scss" scoped></style>
