import { computed } from 'vue';
import { useStore } from 'vuex';
import {
  missed_potential_opportunities,
  interactions as interactionsPolicy,
  intelligence_products,
  intelligence_enquiries
} from '@/shared/store/data/policies';
import runIfPermittedOrReject from '@/shared/store/utils/runIfPermittedOrReject';

export default function usePermissions({ store = useStore() } = {}) {
  const permissions = computed(() => store.state.pbac.permissionsGranted);
  const featureFlags = computed(() => store.state.pbac.featureFlags);
  
  const hasAccessToPerformanceInsights = computed(() => store.getters['pbac/hasAccessToPerformanceInsights']);
  const hasAccessToProductInsights = computed(() => store.getters['pbac/hasAccessToProductInsights']);
  const hasAccessToCustomerInsights = computed(() => store.getters['pbac/hasAccessToCustomerInsights']);
  const hasAccessToSnapshots = computed(() => store.getters['pbac/hasAccessToSnapshots']);
  const hasAccessToAllInsights = computed(() => store.getters['pbac/hasAccessToAllInsights']);
  
  const getPermissions = (resource) => permissions.value[resource] || {};
  const hasAccess = (resource, action = 'read') => getPermissions(resource)[action];
  const hasAccessToTrends = computed(() => hasAccess(intelligence_enquiries));
  const hasAccessToProductGroupPurchaseAnalysis = computed(() => hasAccess(intelligence_products));
  const hasAccessToInteractions = computed(() => hasAccess(interactionsPolicy));
  const hasAccessToPotentialOpportunities = computed(() => hasAccess(missed_potential_opportunities));

  const hasActiveFeature = (feature) => {
    return featureFlags.value.findIndex(o => o === feature) !== -1;
  };

  return {
    featureFlags,
    permissions,
    hasAccessToPerformanceInsights,
    hasAccessToProductInsights,
    hasAccessToCustomerInsights,
    hasAccessToSnapshots,
    hasAccessToAllInsights,
    hasAccess,
    hasAccessToTrends,
    hasAccessToProductGroupPurchaseAnalysis,
    hasAccessToInteractions,
    hasAccessToPotentialOpportunities,
    hasActiveFeature,
    getPermissions,
    runIfPermittedOrReject: (policyName, callBack) => runIfPermittedOrReject(permissions.value, { name: policyName }, callBack),  
  };
}