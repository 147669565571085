import {
  FETCH_DATES,
  FETCH_DATES_WO_STORE,
  SAVE_DATES,
  SET_LOADING,
  FETCH_CALENDAR,
  PROCESS_REQUEST_PARAMETERS,
  CLEAR_DATES,
} from '@/intelligence/store/actionType';
import { httpRequest } from '@sales-i/utils';
import setRequestParam from '@/intelligence/store/utils/setRequestParam';
import { DateTime } from 'luxon';

// initial state
const state = () => ({
  fetchedDates: {},
  calendar: null,
  dateLoading: false,
});

// getters
const getters = {};

// mutations
const mutations = {
  [SET_LOADING]: (state, isLoading) => {
    state.dateLoading = isLoading;
  },
  [FETCH_CALENDAR]: (state, data) => {
    state.dateLoading = false;
    state.calendar = data;
  },
  [SAVE_DATES]: (state, data) => {
    state.fetchedDates = mapDates(data);
  },
  [CLEAR_DATES]: (state) => {
    state.fetchedDates = {};
    state.dateLoading = false;
  },
};

// actions
const actions = {
  [FETCH_CALENDAR]: async({ commit }) => {
    try {
      commit(SET_LOADING, true);
      const response = await httpRequest('get', 'calendar', {}, {
        version: 2,
        area: 'erp',
      });
      commit(FETCH_CALENDAR, response);
    } catch (e) {
      console.error(e);
      commit(SET_LOADING, false);
    }
  },
  [FETCH_DATES]: async ({ commit, }, dateObject) => {
    if (Object.values(dateObject).includes(undefined))
      return dateObject;

    if (!Object.values(dateObject).some(dateParam => dateParam.includes('today'))) {
      commit(SAVE_DATES, dateObject);
      return dateObject;
    }

    const requestParameters = [];
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    requestParameters.push({
      'date_from': dateObject.date_from,
      'date_to': dateObject.date_to,
      timezone,
    });

    if (Object.keys(dateObject).includes('date_from2') && Object.keys(dateObject).includes('date_to2')) {
      requestParameters.push({
        'date_from': dateObject.date_from2,
        'date_to': dateObject.date_to2,
        timezone,
      });
    }

    try {
      commit(SET_LOADING, true);
      const response = await httpRequest('post', 'rolldate', [...requestParameters], {
        version: 2,
        area: 'erp',
      });
      commit(SAVE_DATES, response);
      return mapDates(response);
    } catch (error) {
      // Set a default date range if the request fails
      const today = DateTime.now();
      const dateObject = {
        date_from: today.minus({months: 3}).toJSDate(),
        date_to: today.toJSDate(),
      };
      commit(SAVE_DATES, dateObject);
      console.error(error);
      return mapDates(dateObject);
    } finally {
      commit(SET_LOADING, false);
    }
  },
  [FETCH_DATES_WO_STORE]: async ({ commit, }, dateObject) => {
    if (Object.values(dateObject).includes(undefined))
      return dateObject;

    if (!Object.values(dateObject).some(dateParam => dateParam.includes('today'))) {
      return dateObject;
    }

    const requestParameters = [];
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    requestParameters.push({
      'date_from': dateObject.date_from,
      'date_to': dateObject.date_to,
      timezone,
    });

    if (Object.keys(dateObject).includes('date_from2') && Object.keys(dateObject).includes('date_to2')) {
      requestParameters.push({
        'date_from': dateObject.date_from2,
        'date_to': dateObject.date_to2,
        timezone,
      });
    }

    try {
      commit(SET_LOADING, true);
      const response = await httpRequest('post', 'rolldate', [...requestParameters], {
        version: 2,
        area: 'erp',
      });
      return mapDates(response);
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      commit(SET_LOADING, false);
    }
  },
  [PROCESS_REQUEST_PARAMETERS]: async ({ commit, state, dispatch }) => {
    if (!state.calendar) await dispatch(FETCH_CALENDAR);
    
    try {
      const { current_fiscal_year, previous_fiscal_year } = state.calendar;
      setRequestParam(commit, 'date_from', current_fiscal_year.start);
      setRequestParam(commit, 'date_to', current_fiscal_year.end);
      setRequestParam(commit, 'date_from2', previous_fiscal_year.start);
      setRequestParam(commit, 'date_to2', previous_fiscal_year.end);
      return {
        date_from: current_fiscal_year.start,
        date_to: current_fiscal_year.end,
        date_from2: previous_fiscal_year.start,
        date_to2: previous_fiscal_year.end,
      };
    } catch(e) {
      console.error(e);
    }
  },
  [CLEAR_DATES]: ({ commit }) => {
    commit(CLEAR_DATES);
  },
};

// HELPERS
const mapDates = (data) => {
  if (Array.isArray(data)) {
    const dates = {};

    dates.date_from = data[0].date_from;
    dates.date_to = data[0].date_to;

    if (data.length > 1) {
      dates.date_from2 = data[1].date_from;
      dates.date_to2 = data[1].date_to;
    }
    return dates;
  } else {
    return data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
