<template>
  <div>
    <div
      :id="uniqueId"
      ref="containerRef"
      class="chart-container"
    >
      <svg class="chart" />
      <div class="bar-tooltip tooltip" />
      <div class="axis-tooltip tooltip" />
    </div>
  </div>
</template>

<script setup>
import * as d3 from 'd3';
import { abbr } from '@sales-i/utils';
import { computed, onMounted, ref, watch } from 'vue';
import useCharts from '@/intelligence/composables/useCharts';

const customerTooltipRef = ref(null);
const barTooltipRef = ref(null);

const containerRef = ref(null);
const { uniqueId, handleChartResize } = useCharts({ containerRef, generateChart });

const props = defineProps({
  // [{name, columnKey}]
  chartData: {
    type: Array,
    default: () => [],
  },
  columnKey: {
    type: String,
    default: 'value'
  },
  colour: {
    type: String,
    default: 'var(--colour-data-de-york)'
  },
  formatFunc: {
    type: Function,
    default: (value) => value
  },
  svgWidth: {
    type: Number,
    default: 0
  },
  svgHeight: {
    type: Number,
    default: 410
  },
});

// dimensions and margins of the graph
const margin = {
  top: 0,
  right: 10,
  bottom: 100,
  left: 30,
};

const maxBandWidth = 100;
const svgWidthCalc = () => Math.min(
  props.svgWidth || containerRef.value?.clientWidth - 80, 
  props.chartData?.length * maxBandWidth, containerRef.value?.clientWidth - 80
);
const chartWidth = () => (svgWidthCalc() - margin.left - margin.right);
const chartHeight = computed(() => props.svgHeight - margin.top - margin.bottom);

watch(() => props.chartData, () => {
  handleChartResize();
});

onMounted(() => {
  customerTooltipRef.value = d3.select(`#${uniqueId} .axis-tooltip`);
  barTooltipRef.value = d3.select(`#${uniqueId} .bar-tooltip`);
});

// truncate too long names
function truncateLabel() {
  const self = d3.select(this);
  let text = self.text();
  if (text.length > 12) {
    text = text.slice(0, 10);
    self.text(text + '...');
  }
}

function generateChart() {
  const formatValue = props.formatFunc || (value => value);
  let maxNumericValue = Math.max(...props.chartData.map(a => a?.[props.columnKey]));
  let minNumericValue = Math.min(...props.chartData.map(a => a?.[props.columnKey]));
  if (minNumericValue == 0 && maxNumericValue == 0) {
    maxNumericValue = 1;  
  }

  // zero height value for displaying zeros
  let zeroHeightValue = Math.max(Math.abs(minNumericValue), Math.abs(maxNumericValue)) / 100; 
  
  const x = d3
    .scaleBand()
    .domain(props.chartData.map((d, i) => i))
    .range([0, chartWidth()]);

  const y = d3
    .scaleLinear()
    .domain([
      1.1 * (Math.min(0, minNumericValue) - zeroHeightValue / 10.0), 
      1.1 * Math.max(0, maxNumericValue)
    ])
    .range([chartHeight.value, 5]);

  const svg = d3
    .select(`#${uniqueId} svg`)
    .attr('width', svgWidthCalc())
    .attr('height', props.svgHeight)
    .append('g')
    .attr('transform', `translate(${margin.left + margin.right}, ${margin.top})`);

  // x-axis
  svg
    .append('g')
    .attr('class', 'chart-axis x')
    .attr('transform', `translate(0, ${y(0) || 0})`)
    .call(d3.axisBottom(x))
    .selectAll('text')
    .data(props.chartData)
    .text(d => d.name)
    .attr('transform', () => `translate(-10,${chartHeight.value - y(0)})rotate(-60)`)
    .attr('class', 'chart-label-x')
    .style('text-anchor', 'end')
    .each(truncateLabel)
    .on('mouseover', (event, d) => {
      const [xPosition, yPosition] = d3.pointer(event, d3.select(`#${uniqueId} svg`).node());
      const svgTopLeft = d3.select(`#${uniqueId} svg`).node().getBoundingClientRect();

      customerTooltipRef.value
        .style('display', 'block')
        .style('left', (svgTopLeft.left + xPosition) + 'px')
        .style('top', (svgTopLeft.top + yPosition - 30) + 'px')
        .style('opacity', 1)
        .html(d?.name);
    })
    .on('mouseout', () => {
      customerTooltipRef.value.style('display', 'none');
    });

  const axisY = d3.axisLeft(y).tickFormat(d => abbr.float(d));
  svg.append('g').attr('class', 'chart-axis y').call(axisY).selectAll('text').attr('class', 'chart-label-y');

  const axisColorValue = getComputedStyle(document.documentElement).getPropertyValue('--colour-panel-g-8');
  
  // Change color of axis line
  svg.selectAll('.chart-axis path')
    .attr('stroke', axisColorValue);
  svg.selectAll('.chart-axis line')
    .attr('stroke', 'transparent');

  const barWidth = 16;
  const offset = (x.bandwidth() - barWidth) / 2;

  svg
    .selectAll('mybar')
    .data(props.chartData || [])
    .join('rect')
    .attr('x', (d, i) => x(i) + offset)
    .attr('y', (d) => y(Math.max(0, d?.[props.columnKey] || zeroHeightValue / 2.0)))
    .attr('rx', '8')
    .attr('ry', '8')
    .attr('width', barWidth)
    .attr('height', d => Math.abs(y(0) - y(d?.[props.columnKey] || zeroHeightValue) ))
    .attr('fill', props.colour)
    .attr('class', 'bar')
    .style('cursor', 'pointer')
    .on('mouseover', (event, d) => {
      const [xPosition, yPosition] = d3.pointer(event, d3.select(`#${uniqueId} svg`).node());
      const svgTopLeft = d3.select(`#${uniqueId} svg`).node().getBoundingClientRect();

      barTooltipRef.value
        .style('display', 'block')
        .style('left', (svgTopLeft.left + xPosition) + 'px')
        .style('top', (svgTopLeft.top + yPosition - 30) + 'px')
        .style('opacity', 1)
        .html(d?.label || formatValue(d?.[props.columnKey]));
    })
    .on('mouseout', () => {
      barTooltipRef.value.style('display', 'none');
    });
}
</script>

<style lang="scss" scoped>
.chart-container {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  position: relative;
  
  :deep(.chart-label-x),
  :deep(.chart-label-y) {
    font-size: var(--font-size-small);
    font-family: var(--font-family-primary);
  }
  
  :deep(.chart-label-x) {
    font-weight: var(--font-weight-semibold);
  }
  
  :deep(.bar-tooltip) {
    width: 80px;
  }
  
  :deep(.axis-tooltip) {
    width: auto;
    z-index: 10;
  }
}
  
.tooltip {
  position: fixed;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s; 
  padding: var(--spacing-half) var(--spacing-1);
  border-radius: var(--spacing-2);
  text-align: center;
  color: var(--colour-utility-white);
  font-family: var(--font-family-primary);
  font-size: var(--font-size-small);
  letter-spacing: 0;
  line-height: var(--spacing-2);
  background-color: var(--colour-utility-black);
  font-weight: var(--font-weight-semibold);
  pointer-events: none;
}
</style>
