<template>
  <CustomModal
    title="Upload files"
    show-modal
    :show-unload-warning="isFormDirty"
    :max-width="600"
    class="file-upload"
    position="center"
    @close-modal="closeModal"
  >
    <div
      :class="`drop-area ${highlighted ? 'highlight' : ''}`"
      role="button"
      tabindex="-1"
      @dragenter.stop.prevent="onEnter"
      @dragover.stop.prevent="onOver"
      @dragleave.stop.prevent="onLeave"
      @drop.stop.prevent="onDrop"
    >
      <form class="fileform">
        <p>{{ t('Drag and drop files here') }}</p>
        <label
          class="button"
          for="upload"
          :title="t('Press Ctrl+U to open file dialog')"
        >
          <input
            id="upload"
            ref="upload"
            :key="`upload_btn_${filesRef}`"
            type="file"
            multiple
            accept="image/*"
            @change="handleFiles($event.target.files)"
          >
          {{ t('Add files') }}
        </label>
      </form>
    </div>
    <div
      v-if="filesToUpload.length"
      class="container p-3 pb-1"
    >
      <div class="row">
        <div class="col col-12 col-sm-12">
          {{ t('Change file name if needed and press') }}
          <strong>{{ t('Upload') }}</strong>. {{ t('Max file size is') }} {{ abbrBytes(maxSize) }}
        </div>
      </div>

      <div
        v-for="(item, index) in filesToUpload"
        :key="`${index} ${filesRef}`"
        class="row"
      >
        <div class="col col-12 col-sm-12 file-details my-1">
          <Field
            v-model="item.name"
            twoway
            class="mr-1 file-input"
            type="text"
            required
            :disabled="item.status != statuses.QUEUED"
            :error-message="t('File name is required field')"
            :icon="{
              name: 'trash',
              size: 32,
              align: 'right',
              disabled: isDisabled(item.status),
              color: 'var(--colour-utility-action)',
              func: () => deleteItem(index),
            }"
          />
          <CustomChip class="file-ext mr-1">
            {{ `.${getFileExtension(item.file.name)}` }}
          </CustomChip>
          <CustomChip class="file-upload-status mr-1">
            {{ abbrBytes(item.file.size) }}
          </CustomChip>
          <CustomChip
            class="file-upload-status"
            :class="getStatusClass(item)"
          >
            {{ item.status || statuses.QUEUED }}
          </CustomChip>
        </div>
      </div>
    </div>
    <template #footer>
      <ButtonGroup class="btn-group-bg">
        <CustomButton
          purpose="reversed"
          @click="closeModal"
        >
          {{ t('Close') }}
        </CustomButton>
        <CustomButton
          purpose="action"
          :disabled="!valid"
          @click="uploadFiles"
        >
          {{ t('Upload') }}
        </CustomButton>
      </ButtonGroup>
    </template>
  </CustomModal>
</template>

<script>
const MAX_SIZE = 10 * 1024 * 1024;
const STATUSES = {
  QUEUED: 'queued',
  UPLOADED: 'uploaded',
  UPLOADING: 'uploading...',
  FROM_API: 'error',
  TOO_BIG: 'wrong size',
  WRONG_TYPE: 'wrong type',
};

import { CustomChip, CustomModal, CustomButton } from '@sales-i/dsv3';
import Field from '@/shared/components/Form/Field.vue';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import { mapActions } from 'vuex';
import { UPLOAD_ENTITY_FILE } from '@/crm/store/actionType';
import { getFileExtension, getFileBaseName } from '@/shared/utils/file';
import { allowedFileExtensions } from '@/shared/constants/files/files';
import { abbr, isEqualObjects, t } from '@sales-i/utils';

export default {
  name: 'FileUpload',
  components: {
    CustomModal,
    CustomButton,
    ButtonGroup,
    Field,
    CustomChip,
  },
  props: {
    entityType: {
      type: String,
      required: true,
    },
    entityId: {
      type: [Number, String],
      required: true,
    },
  },
  emits: ['filesUploaded', 'closeModal'],
  data() {
    return {
      isUploading: false,
      highlighted: false,
      filesToUpload: [],
      filesUploadedCount: 0,
      filesRef: 0,
      errors: [STATUSES.FROM_API, STATUSES.TOO_BIG, STATUSES.WRONG_TYPE],
      statuses: STATUSES,
      maxSize: MAX_SIZE,
      initialFormValues: {},
    };
  },
  computed: {
    valid() {
      if (this.filesToUpload.find(x => !x.name)) return false;
      if (this.filesToUpload.find(x => x.status === STATUSES.QUEUED)) return true;
      return false;
    },
    isFormDirty() {      
      return !isEqualObjects(this.initialFormValues, this.filesToUpload);
    },
  },
  mounted() {
    window.addEventListener('keydown', this.onKeyDown);
    this.initialFormValues = this.filesToUpload;
  },
  unmounted() {
    window.removeEventListener('keydown', this.onKeyDown);
  },
  methods: {
    t,
    abbrBytes: abbr.bytes,
    getFileExtension,
    ...mapActions({
      upload: `crm/files/${UPLOAD_ENTITY_FILE}`,
    }),
    isDisabled(status) {
      return ![STATUSES.QUEUED, ...this.errors].includes(status);
    },
    onKeyDown(e) {
      if (e.ctrlKey && e.key === 'u') {
        e.preventDefault();
        this.$refs['upload'].click();
      }
    },
    getStatusClass(item) {
      return {
        'rag-red': this.errors.includes(item.status),
        'rag-green': item.status == STATUSES.UPLOADED,
      };
    },
    closeModal() {
      if (this.filesUploadedCount > 0) {
        this.$emit('filesUploaded', {
          uploaded: this.filesUploadedCount,
          files: this.filesToUpload,
        });
      }
      this.$emit('closeModal');
    },
    onEnter() {
      this.showHighlight(true);
    },
    onOver() {
      this.showHighlight(true);
    },
    onLeave() {
      this.showHighlight(false);
    },
    onDrop(event) {
      this.showHighlight(false);
      let dt = event.dataTransfer;
      let files = dt.files;
      this.handleFiles(files);
    },
    deleteItem(index) {
      this.filesToUpload.splice(index, 1);
      this.filesRef += 1;
    },
    handleFiles(fileList) {
      // filter and map files to add to the list
      const filesToAdd = Array.from(fileList).map(f => this.mapFile(f));

      // some files can be re-added after editing, we replace old versions with new
      const filteredFilesToUpload = this.filesToUpload.filter(
        x => filesToAdd.findIndex(nf => nf.file.name === x.file.name) < 0
      );

      this.filesToUpload = [...filteredFilesToUpload, ...filesToAdd];
    },
    mapFile(file) {
      const ext = getFileExtension(file.name);
      const fileType = file.type.split('/')[1];
      const allowed = allowedFileExtensions.includes(ext);
      const name = getFileBaseName(file.name);
      let status = STATUSES.QUEUED;
      if (!allowed) {
        status = STATUSES.WRONG_TYPE;
      } else if (file.size > MAX_SIZE) {
        status = STATUSES.TOO_BIG;
      }

      return { file, fileType, name, ext, status };
    },
    uploadFiles() {
      this.isUploading = true;
      this.filesToUpload.forEach(async (file, i) => await this.uploadFile(file, i));
    },
    async uploadFile(file, i) {
      if (file.status != STATUSES.QUEUED) return;
      let name = file.name + (file.ext ? `.${file.ext}` : '');
      this.filesToUpload[i].status = STATUSES.UPLOADING;
      let result = await this.upload({
        file,
        name,
        entity_type: this.entityType,
        entity_id: this.entityId,
      });
      this.filesUploadedCount += result ? 1 : 0;
      this.filesToUpload[i].status = result ? STATUSES.UPLOADED : STATUSES.FROM_API;
    },
    showHighlight(showHighlight) {
      this.highlighted = showHighlight;
    },
  },
};
</script>

<style lang="scss" scoped>
.file-upload {
  :deep(.form-group) {
    margin-bottom: 0;
    display: inline-flex;
    align-items: center;
    width: 100%;

    label {
      margin-bottom: 0;
      margin-right: var(--spacing-1);
    }

    .input-container {
      flex: 1;
    }
  }
  :deep(.form-group:has(.input-error)) {
    margin-bottom: var(--spacing-2);
  }

  .file-input {
    flex: 1;
  }
  .file-ext {
    min-width: 64px;
  }
  .btn-group-bg {
    padding-top: 0;
    padding-bottom: 0;

    .button {
      margin-top: 0;
    }
  }
  .drop-area {
    width: 100%;
    border: 2px dashed #bcbcbc;
    border-radius: var(--border-radius-1);
    font-family: sans-serif;
    margin: 24px auto 0;
    min-height: 225px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .drop-area.highlight {
    border-color: var(--colour-utility-action);
  }
  p {
    margin-top: 0;
  }
  .fileform {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .file-details {
    flex: 1;
    display: inline-flex;
    > input {
      border: 1px solid #bcbcbc;
      border-radius: 4px;
      padding: 16px;
      margin: 8px 32px 8px 0;
    }
    > span {
      font-size: 12px;
    }
  }

  label.button {
    margin-top: 16px;
    padding: var(--spacing-1) var(--spacing-2);
    color: var(--colour-utility-white);
    background-color: var(--colour-utility-action);
    cursor: pointer;
    border-radius: var(--border-radius-half);
    &:hover,
    &:focus {
      background-color: var(--colour-utility-focus);
    }
  }
  #upload {
    display: none;
  }

  .file-type {
    font-size: var(--font-size-small);
  }
}
</style>
