<template>
  <div class="chart-legend">
    <div
      v-for="(label, index) in labels"
      :key="index"
      class="legend-item"
    >
      <span
        class="legend-dot"
        :style="{ backgroundColor: colors[index] }"
      />
      <span class="legend-text">{{ label }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    labels: {
      type: Array,
      required: true
    },
    colors: {
      type: Array,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
  .chart-legend {
    display: flex;
    flex-flow: column;
    margin-top: var(--spacing-1);
    margin-bottom: var(--spacing-1);
  }
  .legend-item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }
  .legend-dot {
    height: var(--spacing-1);
    width: var(--spacing-1);
    border-radius: 50%;
    display: inline-block;
    margin-right: var(--spacing-1);
  }
  .legend-text {
    display: inline-block;
  }
</style>
