<template>
  <CustomModal
    title-as-text
    title="Your comment"
    show-modal
    :show-unload-warning="isFormDirty"
    :max-width="600"
    @close-modal="closeModal"
  >
    <Form
      v-if="fields.length > 0"
      class="minus-padding-top"
      :formdata="fields"
      :actions="actions"
      :saved="saved"
      :col-span="12"
      @show-unload-warning="handleIsFormDirty"
    >
      <template #savedContent>
        <div
          v-if="saved"
          :class="{
            complete: true,
          }"
        >
          <h2>{{ t('Saved') }}</h2>
        </div>
      </template>
    </Form>
    <BufferImage
      v-else
      color="var(--colour-utility-black)"
      float="center"
    />
  </CustomModal>
</template>

<script>
import { BufferImage, CustomModal } from '@sales-i/dsv3';
import Form from '@/shared/components/Form/Form.vue';
import { t } from '@sales-i/utils';
import { mapActions, mapState } from 'vuex';
import store from '@/shared/store';
import { SET_ITEMS, GET_FIELDS, CLEAR_FIELDS, GET_BY_ID, PUT_BY_ID, CLEAR_BY_ID } from '@/shared/store/actionType';
import { navigateToUrl } from 'single-spa';
import { baseUrl, postsSection } from '@/crm/router/urlBits';

export default {
  name: 'EditCommentView',
  components: {
    BufferImage,
    CustomModal,
    Form,
  },
  props: {
    postTitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      saved: false,
      isFormDirty: false,
    };
  },
  computed: {
    ...mapState({
      entity: state => state.system.entity,
      comment: state => state.comments.selected,
      fields: state => state.comments.fields,
    }),
    actions() {
      return [
        {
          label: 'Update comment',
          small: true,
          onClick: this.save,
          display: !this.saved,
          isSubmit: true,
        },
      ];
    },
  },
  mounted() {
    this[`comments/${CLEAR_BY_ID}`]();
    this[`comments/${GET_BY_ID}`]({
      id: this.$route.params.post_id,
      comment_id: this.$route.params.comment_id,
    });
    this.getFields();
  },
  unmounted() {
    this[`comments/${CLEAR_FIELDS}`]();
  },
  methods: {
    t,
    ...mapActions([
      `comments/${GET_FIELDS}`,
      `comments/${CLEAR_FIELDS}`,
      `comments/${GET_BY_ID}`,
      `comments/${PUT_BY_ID}`,
      `comments/${CLEAR_BY_ID}`,
    ]),
    getFields() {
      if (this.comment.data.id) {
        this[`comments/${GET_FIELDS}`]({
          comment: this.comment.data.comment,
        });
      } else {
        setTimeout(() => {
          this.getFields();
        }, 100);
      }
    },
    async save(payload, setSubmitting) {
      setSubmitting(true);
      try {
        await this[`comments/${PUT_BY_ID}`]({
          id: this.$route.params.post_id,
          comment_id: this.$route.params.comment_id,
          payload,
        });
        store.dispatch(`alerts/${SET_ITEMS}`, {
          type: 'success',
          message: `Comment updated for ${this.postTitle}`,
          selfDismiss: true,
        });
        setSubmitting(false);
        this.closeModal();
      } catch (e) {
        console.error(e);
        setSubmitting(false);
      }
    },
    closeModal() {
      let { id, post_id } = this.$route.params;
      navigateToUrl(`${baseUrl}/${this.entity}/${id}/${postsSection}/${post_id}`);
      this.isFormDirty = false;
    },
    handleIsFormDirty(value) {
      this.isFormDirty = value;
    }
  },
};
</script>

<style lang="scss">
.minus-padding-top .form-body {
  padding-top: 0;
}
</style>
