<template>
  <LineBarChart
    :chart-data="chartData"
    :elements-data="elementsData"
  />
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import LineBarChart from '@/intelligence/components/Dashboard/Elements/LineBarChart';
import { useCustomer } from '@/crm/composables/useCustomer';
import useShared from '@/intelligence/composables/useShared';

const props = defineProps({
  reportId: {
    type: Array,
    required: true,
  },
});

const store = useStore();
const { selectedCustomer } = useCustomer({ store });
const { getReportData } = useShared({ store });

const reportData = computed(() => getReportData(props.reportId));

const chartData = computed(() => reportData.value.map(row => ({
  line: row.average_customer_spend,
  bar: row.purchase_share,
  average: row.average_industry_spend,
  name: row.description,
})));

const isIndustryAvailable = computed(() => selectedCustomer.value.industry_type);

const elementsData = computed(() => [
  {
    key: 'bar',
    color: '--colour-brand-viking',
    axisPosition: 20,
    legendPosition: 33,
    legendPositionMobileX: 10,
    legendPositionMobileY: 0,
    title: 'This Customer',
  },
  {
    key: 'line',
    color: '--colour-data-puerto-rico-label',
    axisPosition: 60,
    legendPosition: 73,
    legendPositionMobileX: -110,
    legendPositionMobileY: 0,
    title: 'Average',
  },
  ...(isIndustryAvailable.value
    ? [
      {
        key: 'average',
        color: '--colour-brand-deluge',
        axisPosition: 100,
        legendPosition: 113,
        legendPositionMobileX: -110,
        legendPositionMobileY: 30,
        title: selectedCustomer.value.industry_type,
      },
    ]
    : []),
]);
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.line-bar-chart-container {
  position: relative;
  display: block;
  overflow-x: auto;

  .chart {
    display: block;
    margin: auto;
  }
}
</style>

<style lang="scss">
.line-bar-chart-container {
  .chart-label-x,
  .chart-label-values,
  .legend-text {
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-small);
    font-family: EuclidCircularA;
  }

  .legend-text {
    font-size: var(--font-size-small);
  }

  .chart-axis-x,
  .chart-axis-line,
  .chart-axis-average,
  .chart-axis-bar {
    line {
      display: none;
    }
  }
  .chart-axis-x line.vertical-line {
    display: block;
  }

  .chart-label-values,
  .legend-text {
    font-weight: var(--font-weight-semibold);
  }

  .average-text {
    font-weight: var(--font-weight-medium);
  }

  rect.chip-white {
    fill: var(--colour-panel-g-0);
    stroke: var(--colour-panel-g-16);
    stroke-width: 1;
  }

  path.domain {
    display: none;
  }
}
</style>
