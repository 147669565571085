<template>
  <div class="date-filter">
    <YearlyComparisonOption
      v-if="isTrendsReport"
      @hide-date-range="hideDateRange"
      @set-active-date="setActiveDate"
    />
    <template v-else>
      <CustomDateOption
        v-if="isCustomDateVisible"
        :date-range-number="inputData.dateRangeNumber"
        @hide-date-range="hideDateRange"
        @set-active-date="setActiveDate"
      />
      <template v-else>
        <div class="description">
          <IconBase
            icon-name="calendar"
            :height="48"
            :width="48"
          />
          <h3>{{ t('Select a date range') }}</h3>
          <p v-if="lastInvoiceDateFormatted">
            {{ t('Last invoice date') }}: {{ lastInvoiceDateFormatted }}
          </p>
        </div>
        <button
          v-for="option in dateFilterOptions"
          :key="option.text"
          :class="['option', {selected: option === activeDateOption}]"
          @click="setActiveDateField(option)"
        >
          {{ t(option.text) }}
        </button>
        <CustomCheckbox 
          :label="t('Complete periods')"
          :value="isCompletePeriods"
          @input="handleCompletePeriodsInput"
        />
      </template>
    </template>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { CustomCheckbox, IconBase } from '@sales-i/dsv3';
import { getDateTimeOptions, getCompletePeriods } from '@/intelligence/store/data/dateFilters';
import CustomDateOption from '@/intelligence/components/Date/CustomDateOption';
import YearlyComparisonOption from '@/intelligence/components/Date/YearlyComparisonOption';
import { SNAPSHOT_SUMMARY, TRENDS } from '@/intelligence/store/data/reportTypes';
import { dates, t } from '@sales-i/utils';
import { dashboardArea } from '@/crm/router/urlBits';
import useShared from '@/intelligence/composables/useShared';

const props = defineProps({
  reportType: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['setActiveDate']);

const store = useStore();
const vroute = useRoute();

const isCustomDateVisible = ref(false);
const isCompletePeriods = ref(false);
const activeDateOption = ref({});

const { getInputData } = useShared({ store });
const dateFilterOptions = computed(() => getDateTimeOptions({ isCompletePeriods: isCompletePeriods.value }));
const lastInvoiceDate = computed(() => store.state.intelligence.metadata.last_transaction_date);
const lastInvoiceDateFormatted = computed(() => dates.format(lastInvoiceDate.value));

const inputData = computed(() => {
  return props.reportType && props.reportType !== SNAPSHOT_SUMMARY 
    ? getInputData(props.reportType) 
    : { dateRangeNumber: 1 };
});

// TODO: move this check outside of the component
const isTrendsReport = computed(() => props.reportType === TRENDS && !vroute.path.includes(dashboardArea));

watch(
  () => vroute,
  () => {
    isCustomDateVisible.value = false;
  }
);

const setActiveDateField = (option) => {
  if (option.value === 'custom') {
    isCustomDateVisible.value = true;
    return;
  }

  activeDateOption.value = option;

  // if it is complete period calculate it according the setting.
  if (option.allowCompletePeriods && isCompletePeriods.value) {
    let dates = getCompletePeriods(option.value);
    setActiveDate(dates);
    return;
  }

  const { date_from, date_to, date_from2, date_to2 } = option;
  const dates = {
    date_from,
    date_to,
    date_from2: date_from2 || `${date_from}-1y`,
    date_to2: date_to2 || `${date_to}-1y`,
  };

  setActiveDate({ ...dates });
};

const setActiveDate = (datesObject) => {
  emit('setActiveDate', datesObject);
};

const hideDateRange = () => {
  isCustomDateVisible.value = false;
};

const handleCompletePeriodsInput = () => {
  isCompletePeriods.value = !isCompletePeriods.value;
};
</script>

<style lang="scss" scoped>
.date-filter {
  max-width: 360px;
  background: var(--colour-panel-g-0);
  box-shadow: 0 0 var(--shadow-blur) var(--shadow-spread) var(--colour-panel-g-16);
  border-radius: var(--border-radius-1);
  padding: var(--spacing-3) var(--spacing-2);
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0 auto var(--spacing-8);
  position: relative;
}

.description {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: var(--spacing-3);

  h3 {
    font-weight: var(--font-weight-semibold);
    flex-basis: 100%;
  }
}

.option {
  background: var(--colour-panel-action);
  color: var(--colour-data-deluge);
  font-size: var(--font-size-4);
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--spacing-2);

  &.selected {
    background-color: var(--colour-data-deluge-light);
  }
}
</style>