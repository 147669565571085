<template>
  <div>
    <EnquiryNav
      :active-area-prop="reportArea"
      :report-type="reportType"
      :is-date-filter-active="isDateFilterActive"
      :dates="dates"
      :disable-nav="disableNav"
      :nav-title="title"
      hide-filters
      hide-drill
      @toggle-date-filter="toggleDateFilter"
    />
    <DatePickerView
      v-show="isDateFilterActive"
      :report-type="reportType"
      @set-active-date="setDate"
    />
    <TrendsReport 
      v-if="isReportEnabled"
      v-show="!isDateFilterActive"
      :settings="settings"
      :request-params="dates"
    />
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, watch, computed } from 'vue';
import { useStore } from 'vuex';
import TrendsReport from '@/intelligence/components/Trends/TrendsReport';
import EnquiryNav from '@/intelligence/components/EnquiryReport/EnquiryNav/EnquiryNav';
import DatePickerView from '@/intelligence/components/Date/DatePickerView';
import { REPORT_AREA_ENQUIRY } from '@/intelligence/store/data/areas';
import { TRENDS, TRENDS_TITLE } from '@/intelligence/store/data/reportTypes';
import useCalendar from '@/intelligence/composables/useCalendar';
import useShared from '@/intelligence/composables/useShared';
import { useRoute, useRouter } from 'vue-router';

const props = defineProps({
  disableNav: {
    type: Boolean,
    default: false,
  },
  hideFooter: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: TRENDS_TITLE,
  },
});

const store = useStore();
const vrouter = useRouter();
const vroute = useRoute();

const reportType = TRENDS;
const reportArea = REPORT_AREA_ENQUIRY;
const isDateFilterActive = ref(false);
const dates = ref({});
const isReportEnabled = ref(false);

const { fetchCalendar, processRequestParams } = useCalendar({ store });
const { clearReportData } = useShared({ store });

const settings = computed(() => ({
  isChartShown: true,
  isTableShown: true,
  hideFooter: props.hideFooter,
}));

const setDate = async (dateObject) => {
  dates.value = dateObject;
  toggleDateFilter();
};

const toggleDateFilter = () => {
  isDateFilterActive.value = !isDateFilterActive.value;
};

onMounted(async () => {
  await fetchCalendar();
  dates.value = vroute.query.date_from ? vroute.query : await processRequestParams();
  isReportEnabled.value = true;
});

onBeforeUnmount(() => {
  clearReportData();
});

watch(() => dates,
  async () => await vrouter.replace({ query: { 
    ...vroute.query, 
    ...dates.value
  }}),
  { deep: true }
);
</script>