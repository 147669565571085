<template>
  <div class="enquiry-breadcrumbs">
    <CustomButton
      v-show="isResettable"
      class="reset-button"
      purpose="action"
      icon-name="close-line"
      icon-color="var(--colour-utility-white)"
      small
      round
      :icon-width="18"
      :icon-height="18"
      @click="resetReport"
    />
    <div
      v-for="(param, index, i) in breadcrumbs"
      :key="index"
    >
      <Breadcrumb
        :dim="param.id"
        :drill-values="param.drilledBy !== null ? breadcrumbs[param.drilledBy].values : null"
        :is-first-child="i === 0"
      >
        <CustomButton
          small
          purpose="text"
          :class="{
            text: true,
            'abandoned-drill': isDrillAbandoned(param.key),
          }"
          @click="goToSelectedDrill(param.key)"
        >
          {{ param.label }}
        </CustomButton>
        <span
          v-if="breadcrumbs[param.drilledBy]?.values?.length"
          class="drill-number"
        >
          ({{ breadcrumbs[param.drilledBy]?.values?.length }})
        </span>
      </Breadcrumb>

      <div
        v-if="getNextKeyValue(breadcrumbs, index) !== undefined && getNextKeyValue(breadcrumbs, index).type === 'dimension'"
        style="padding:0 10px;"
      >
        {{ '/' }}
      </div>
      <div
        v-if="getNextKeyValue(breadcrumbs, index) !== undefined && getNextKeyValue(breadcrumbs, index).type === 'drill'"
        style="padding:0 10px;"
      >
        {{ '>' }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { CustomButton } from '@sales-i/dsv3';
import Breadcrumb from '@/intelligence/components/EnquiryReport/EnquiryBreadcrumbs/Breadcrumb.vue';
import useEnquiries from '@/intelligence/composables/useEnquiries';

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
  abandonedDrillParams: {
    type: Array,
    default: () => [],
  },
  isResettable: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['resetReport', 'goToSelectedDrill']);

const { bubbleData } = useEnquiries();

const breadcrumbs = computed(() => {
  let result = {};

  Object.entries(props.params).forEach(([key, value]) => {
    const drillValuesKey = `${key}values`;

    switch (true) {
    case key === 'dim1' || key === 'dim2': {
      const dimensionNumber = key.replace('dim', '');
      result = {...result, ...parseData({id: value, dim: Number(dimensionNumber), type: 'dimension', key})};
      break;
    }
    case (key.includes('-dim') && !key.includes('values')): {
      const dimNumber = parseInt(key.split('dim')[1], 10);
      const values = parseDrillValues(props.params[drillValuesKey]);

      if (values?.length > 0) {
        let setLast = false;
        Object.entries(result).forEach(([bcKey, bcVal]) => {
          if (setLast) return;

          if (bcVal.dim === dimNumber && !bcVal.drilledBy) {
            result[bcKey] = {
              ...bcVal,
              drilledBy: getBubbleNameAsKey(value),
            };
            setLast = true;
          }
        });
      }
      result = {...result, ...parseData({id: value, dim: dimNumber, type: 'drill', values, key})};
      break;
    }
    }
  });
  return result;
});

function getDimLevel(dim) {
  return dim?.split('-dim')[0];
}

function getNextKeyValue(obj, currentKey) {
  const keys = Object.keys(obj);
  const currentIndex = keys.indexOf(currentKey);
  const nextIndex = currentIndex + 1;
  return obj[keys[nextIndex]];
}

function parseData({id, dim, type, values = [], key}) {
  const bubbleName = getBubbleName(id);
  if (!bubbleName) return;

  return {[getBubbleNameAsKey(id)]: {
    abandoned: false,
    id: id,
    dim: dim,
    label: bubbleName,
    type: type,
    values: values,
    drilledBy: null,
    key
  }};
}

function parseDrillValues(values) {
  if (!values) return;

  if (typeof values === 'string') {
    return [values.split(':')[0]];
  }

  return values.map(value => value.split(':')[0]);
}

function getBubbleName(bubbleId) {
  if (!bubbleData.value) return;
  let titleObj = bubbleData.value.find(bubble => bubble.id === bubbleId);
  return titleObj ? titleObj.title : '';
}

function getBubbleNameAsKey(bubbleId) {
  const bubble = getBubbleName(bubbleId);
  if (!bubble) return '';
  return `${bubble}_${bubbleId}`.replace(/ /g, '_').toLowerCase();
}

function resetReport() {
  emit('resetReport');
}

function goToSelectedDrill(key) {
  emit('goToSelectedDrill', key);
}

function isDrillAbandoned(drillLevel) {
  const currentDrill = Number(getDimLevel(props.params?.activeDim)) || 0;
  return currentDrill < Number(getDimLevel(drillLevel));
}
</script>

<style scoped lang="scss">
@import '@/shared/assets/scss/_variables';

.enquiry-breadcrumbs {
  display: flex;
  align-items: center;
  padding: var(--spacing-4);
  background: var(--colour-panel-alert-base);
  flex-wrap: nowrap;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding: var(--spacing-2);
    overflow-x: auto;
  }

  .reset-button {
    margin-right: var(--spacing-2);
    height: 24px;
    width: 24px;

    :deep(.svg-container) {
      margin: 0 !important;
    }
  }

  div {
    white-space: nowrap;
    display: inline-block;
  }

  .drillcrumbs {
    display: flex;
    align-items: center;
    flex-basis: 100%;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      flex-basis: auto;
    }
  }

  .abandoned-drill {
      opacity: 48%;
    }

  span {
    font-size: var(--font-size-small);
  }

  .text {
    display: inline-block;
    color: var(--colour-utility-action);
    text-decoration: underline;
    font-weight: var(--font-weight-semibold);
    cursor: pointer;
  }

  .divider {
    margin: 0 var(--spacing-1);
    display: inlnie-block;
  }

  .label {
    margin: 2px var(--spacing-1) 0;
  }

  .drill-number {
    margin-left: var(--spacing-half);
    color: var(--colour-utility-base);
  }
}
</style>