export const customerHeadings = [
  {
    text: 'Customer Name',
    value: 'links',
  },
  {
    text: 'Account Number',
    value: 'account_number',
  },
  {
    text: 'Address',
    value: 'address',
  },
  {
    text: 'Created Date',
    value: 'created_date',
  },
  {
    text: 'Tags',
    value: 'tags',
  },
];

export const prospectHeadings = [
  {
    text: 'Prospect Name',
    value: 'name',
  },
  {
    text: 'Created Date',
    value: 'created_date',
  },
  {
    text: 'Tags',
    value: 'tags',
  },
  {
    text: 'Links',
    value: 'links',
  },
];