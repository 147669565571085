<template>
  <div
    class="edit-widget"
  >
    <CustomModal
      :title="item?.id ? t('Edit widget') : t('Create widget')"
      show-modal
      :show-unload-warning="isFormDirty"
      :min-width="300"
      @close-modal="handleClose"
    >
      <template #default>
        <CustomInput
          :label="t('Title')"
          :value="item.name"
          :min-length="1"
          :max-length="100"
          :error="!validateTextField(item.name, 1, 100)"
          :error-message="t('Title length should be between 1 and 100 characters')"
          required
          @input="handleFieldInput($event, 'name')"
        />
        <CustomTextarea
          :label="t('Description')"
          :value="item.description"
          :min-length="3"
          :max-length="200"
          :error="!validateTextField(item.description, 3, 200)"
          :error-message="t('Description length should be between 3 and 200 characters')"
          required
          @input="handleFieldInput($event, 'description')"
        />
        <div class="flex align-center dashboard-form">
          <CustomSelect
            id="dashboard"
            :label="t('Dashboard')"
            :value="item.dashboard_id"
            :items="dashboardsOptions"
            :disabled="!!item?.id"
            key-field="id"
            label-field="title"
            required
            @input="handleFieldInput($event, 'dashboard_id', true)"
          />
          <CustomButton
            v-if="!item?.dashboard_id"
            class="add-btn"
            icon-name="plus"
            icon-color="var(--colour-utility-white)"
            icon-only
            :icon-height="24"
            :icon-width="24"
            @click="handleDashboardCreate"
          />
        </div>
        <AddEditDashboard
          v-if="isDashboardModal"
          :item="selectedDashboard"
          @close="handleCloseDashboardModal"
          @save="handleSaveDashboard"
        />
        <div
          v-if="item?.id"
          class="flex"
        >
          <CustomInput
            class="mr-2"
            :label="t('Width')"
            type="number"
            :min="1"
            :max="3"
            :value="item.width"
            @input="handleFieldInput($event, 'width', true)"
          />
          <CustomInput
            :label="t('Height')"
            type="number"
            :min="1"
            :max="10"
            :value="item.height"
            @input="handleFieldInput($event, 'height', true)"
          />
        </div>
        <CustomCheckbox
          v-if="false"
          class="mb-2"
          :label="t('Fixed')"
          :value="item.fixed"
          disabled
          @input="handleFieldInput($event, 'fixed')"
        />
        <CustomInput
          :label="t('URL')"
          twoway
          :value="computedUrl"
          disabled
        />
        <Field
          v-if="isCustomDateRange && isNotExcludedFromRollingDates"
          id="status"
          type="switch"
          :value="shouldRollDates"
          twoway
          :label="t('Roll dates')"
          @input="setRollDates"
        />
      </template>
      <template #footer>
        <CustomButton
          small
          class="mx-half"
          :disabled="!isSaveButtonEnabled"
          @click="postWidget"
        >
          {{ t('Save') }}
        </CustomButton>
        <CustomButton
          class="mx-half"
          small
          @on-click="emit('close')"
        >
          {{ t('Cancel') }}
        </CustomButton>
      </template>
    </CustomModal>
  </div>
</template>

<script setup>
// AddEditWidget
import { CustomModal, CustomButton, CustomInput, CustomTextarea, CustomSelect, CustomCheckbox } from '@sales-i/dsv3';
import { computed, onMounted, ref } from 'vue';
import { isEqualObjects, t } from '@sales-i/utils';
import { useDashboard } from '@/intelligence/components/Dashboard/composables/useDashboard';
import { useWidget } from '@/intelligence/components/Dashboard/composables/useWidget';
import { DEFAULT_WIDGET, DEFAULT_DASHBOARD_CATEGORY } from '@/intelligence/components/Dashboard/constants';
import AddEditDashboard from '@/intelligence/components/Dashboard/AddEditDashboard.vue';
import Field from '@/shared/components/Form/Field.vue';
import { useStore } from 'vuex';

const store = useStore();

const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
  dashboards: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['close', 'save']);
const isDashboardModal = ref(false);
const selectedDashboard = ref(null);
const shouldRollDates = ref(false);
const item = ref({
  ...DEFAULT_WIDGET,
  ...props.item,
});
const initialFormValues = ref({});

const { validateTextField, postDashboard, clearDashboard } = useDashboard({ store, emit });
const {
  stripWidgetUrlParams,
} = useWidget();

const isSaveButtonEnabled = computed(() => {
  const { name, description, dashboard_id, width, height } = item.value;
  return name?.trim() && description?.trim()?.length >= 3 && dashboard_id && width > 0 && height > 0;
});
const isCustomDateRange = computed(() => {
  const requestParams = props.item?.requestParams;
  return !requestParams?.date_from?.startsWith('today');
});
const isNotExcludedFromRollingDates = computed(() => 
  ![
    'customer-down-on-spend',
    'customer-with-zero-spend',
    'customer-top-number-of-accounts-down',
    'customer-who-spent-in-previous',
    'customer-dormant-ytd',
    'customer-last-complete-periods-but-not-bought',
    'customer-not-buying-top-products',
    'customer-decreasing-spend-period',
    'trends'
  ].includes(props.item.reportType)
);
const computedUrl = computed(() => {
  // Check that the url exists and is not empty
  if (!props.item.settings?.url) return '';
  
  if (props.item.settings?.url?.includes('&rolldates=true')) {
    return shouldRollDates.value ? stripWidgetUrlParams(props.item.settings.url) : stripWidgetUrlParams(props.item.settings.url).replace('&rolldates=true', '');
  } else {
    return shouldRollDates.value ? `${stripWidgetUrlParams(props.item.settings?.url)}&rolldates=true` : stripWidgetUrlParams(props.item.settings?.url);
  }
});

// "Sales Overview" Dashboard is hardcoded with id: -1; Other or created dashboards will have generated id gretaer than 0
const dashboardsOptions = computed(() => props.dashboards.filter(d => d.id > 0 ));
const isFormDirty = computed(() => !isEqualObjects(initialFormValues.value, item.value));

function handleClose() {
  emit('close');
}
function postWidget() {
  const widgetToPost = {
    ...item.value,
    settings: {
      ...item.value.settings,
      url: computedUrl.value,
    }
  };
  emit('save', widgetToPost);
}
function handleFieldInput(e, fieldName, numeric = false) {
  item.value[fieldName] = numeric ? +e : e;
}
function handleDashboardCreate() {
  selectedDashboard.value = { category: DEFAULT_DASHBOARD_CATEGORY };
  isDashboardModal.value = true;
}
function handleCloseDashboardModal() {
  isDashboardModal.value = false;
}
async function handleSaveDashboard(item) {
  let response = await postDashboard(item);
  if (response) {
    selectedDashboard.value = { id: item.id || response.id };
    if (!item.id) clearDashboard();
    handleCloseDashboardModal();
  }
}
const setRollDates = () => {
  shouldRollDates.value = !shouldRollDates.value;
};

onMounted(() => {
  const url = props.item?.settings?.url;
  if (url && url.includes('&rolldates=true')) {
    shouldRollDates.value = true;
  }
  initialFormValues.value = { ...item.value };
});
</script>

<style lang="scss" scoped>
.edit-widget {
  :deep(.content) {
    padding: var(--spacing-3);
  }
}

.dashboard-form {
  :deep(.form-group) {
    width: 100%;
  }
}
</style>
