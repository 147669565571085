<template>
  <div class="alert-results-wrapper">
    <TableWrapper
      v-if="props.loaded && dataRows.length > 0"
      :disable-shade="true"
      table-height="720px"
    >
      <thead>
        <tr>
          <th
            v-for="(heading, index) in columnHeadings"
            :key="index"
            :title="heading"
            :class="{
              sort: sortAttribute === translateHeadingLabelToKey(heading),
              dimension: sortDisabled || dimensions.includes(heading),
            }"
            @click="sortDisabled ? null : changeSorting(heading)"
          >
            <IconBase
              v-if="sortAttribute === translateHeadingLabelToKey(heading)"
              :width="16"
              :height="16"
              :icon-name="sortOrderIconName"
              icon-color="var(--colour-utility-black)"
            />
            <div>
              <IconButton
                v-if="getHeadingValue(heading) !== 'Name' && columnHeadings.length > 2"
                class="close-button"
                icon-name="close-line"
                purpose="transparent"
                icon-only
                :icon-size="16"
                @click.stop="removeColumn(translateHeadingLabelToKey(heading))"
              />
              <div class="heading">
                {{ getHeadingValue(heading) }}
              </div>
            </div>

            <div
              v-if="getChipValue(heading)"
              class="chip"
              :title="getChipValue(heading)"
            >
              {{ getChipValue(heading) }}
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(dataRow, index) in dataRows">
          <tr
            v-if="!selectedMapMarker || selectedMapMarker.id == dataRow.dimensions[0].entity_id"
            :id="dataRow.Id"
            :ref="`row_${dataRow.dimensions[0].entity_id}`"
            :key="index"
          >
            <td>
              <div>
                <a :href="getCustomerProfileLink(dataRow.dimensions[0].entity_id)">
                  {{ dataRow.dimensions[0].dimensions[0] }}
                </a>
              </div>
            </td>
            <td
              v-for="dim in getAdditionalDimensions(dataRow)"
              :key="dim"
            >
              {{ dim.dimensions[0] }}
            </td>

            <td
              v-for="value in getData(dataRow)"
              :key="value"
            >
              {{ value }}
            </td>
          </tr>
        </template>
      </tbody>
    </TableWrapper>

    <div
      v-if="showAddColumnControl"
      class="add-section"
    >
      <div class="tooltip-container">
        <CustomDropdown
          class="add-column-button"
          :items="unusedHeadings"
          menu-position="left"
          :toggle-icons="['plus', 'close-line']"
          :icon-height="18"
          :icon-width="18"
          icon-color="var(--colour-utility-white)"
          close-on-selection
          btn-style="width: 24px; height: 24px;"
          @click="addColumn"
        />
        <ToolTip :content="t('Add other columns')" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { abbr, t } from '@sales-i/utils';
import { baseUrl as crmBaseUrl, customersArea } from '@/crm/router/urlBits';
import { capitalize } from '@/shared/utils/strings';
import useCurrency from '@/shared/composables/useCurrency';
import TableWrapper from '@/shared/components/Tables/TableWrapper';
import { CustomDropdown, IconBase, IconButton, ToolTip } from '@sales-i/dsv3';

const { cPrice, cNumber } = useCurrency();

const emit = defineEmits(['changeSorting']);
const props = defineProps({
  loaded: {
    type: Boolean,
    default: true,
  },
  sortAttribute: {
    type: String,
    default: '',
  },
  enquiryData: {
    type: Object,
    default: () => ({}),
  },
  sortDisabled: {
    type: Boolean,
    default: false,
  }
});

const dataRows = ref([]);
const columnHeadings = ref([]);
const dimensions = ref([]);
const summaryData = ref({});
const selectedMapMarker = ref(undefined);

// Check the data in enquiryData.headings.values and product a list of any headings that don't match any of the current columnHeadings
const unusedHeadings = computed(() => {
  const usedHeadings = columnHeadings.value;
  const allHeadings = props.enquiryData.headings.values;

  return allHeadings
    .filter(heading => heading && !usedHeadings.includes(heading))
    .map(heading => {
      const value = translateHeadingLabelToKey(heading);
      return value ? { title: heading, value } : null;
    })
    .filter(Boolean);
});

const showAddColumnControl = computed(() => unusedHeadings.value.length > 0);

const abbrFloat = abbr.float;

const getCustomerProfileLink = (entity_id) => {
  return `${crmBaseUrl}/${customersArea}/${entity_id}`;
};

const translateHeadingLabelToKey = (label) => {
  if (!label) {
    return '';
  }

  if (label === 'Avg. Price') {
    return 'average_price';
  }

  if (label === 'Avg. Items Per Invoice') {
    return 'average_items_per_invoice';
  }

  return label.toLowerCase().replace(' ', '_');
};

const getHeadingValue = (heading) => {
  if (heading === 'Sales Value') {
    return t('Sales Value');
  }
  return capitalize(heading, { onlyFirstLetter: true });
};

const getAdditionalDimensions = (dataRow) => {
  return dataRow.dimensions.slice(1);
};

function getChipValue(heading) {
  const key = translateHeadingLabelToKey(heading);

  // Set up the different types of chip values that can be displayed
  const value = summaryData.value[key];
  const quantity = summaryData.value.quantity;
  const grossProfit = summaryData.value.gross_profit;
  
  const defaultChipValue = cPrice(value);

  // Set the exceptions for the different types of chips
  if (key === 'quantity' || key === 'count' || key === 'invoice_count'
  ) {
    return cNumber(quantity);
  }
  if (key === 'gross_profit') {
    return `${abbrFloat(grossProfit)}%`;
  }

  // All the other chip values use the default
  return defaultChipValue;
}
  
function getData(dataRow) {
  return columnHeadings.value.slice(1).map(heading => {
    const key = translateHeadingLabelToKey(heading);
    if (key === 'sales_value' || key === 'cost' || key === 'profit' || key === 'average_price') {
      return cPrice(dataRow[key]);
    }
    if (key === 'quantity' || key === 'average_items_per_invoice' || key === 'count') {
      return cNumber(dataRow[key]);
    }

    if (key === 'gross_profit') {
      return `${abbrFloat(dataRow[key])}%`;
    }
    return dataRow[key] !== undefined ? dataRow[key] : '';
  });
}

function addColumn({ title }) {
  columnHeadings.value.push(title);
}

function removeColumn(headingKey) {
  // Find the index of the column heading using the translated key
  const index = columnHeadings.value.findIndex(heading => translateHeadingLabelToKey(heading) === headingKey);
  if (index !== -1) {
    columnHeadings.value.splice(index, 1);
  }
}

const changeSorting = (heading) => {
  emit('changeSorting', heading);
};

onMounted(() => {
  if (props.enquiryData !== '' && props.enquiryData !== undefined && props.enquiryData.row_count > 0) {
    dataRows.value = [...props.enquiryData.rows];
  } else if (props.enquiryData !== '' && props.enquiryData !== undefined && props.enquiryData.row_count === 0) {
    dataRows.value = [];
  }
  columnHeadings.value = ['Name', 'Sales Value', 'Quantity'];

  dimensions.value = [...props.enquiryData.headings.dimensions];
  summaryData.value = props.enquiryData.summary;
});
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.alert-results-wrapper {
  display: flex;

  & > .table-container {
    overflow: auto;
  }
}

.add-section {
  background-color: var(--colour-panel-g-2);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: var(--spacing-3);

  .add-column-button {
    min-width: auto;
  }
}

.icon-button {
  margin-left: calc(var(--spacing-2) * -1);
}

    thead {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 15;
}

  td,
  th {
    border: 0;
  }

  th {
    padding: var(--spacing-3) var(--spacing-2) var(--spacing-3);
    background: var(--colour-panel-action);
    color: var(--colour-utility-action);
    font-size: var(--font-size-body);
    border-bottom: 1px solid var(--colour-panel-g-16);
    position: relative;
    text-decoration: underline;
    text-align: center;

    div {
      display: flex;
      justify-content: center;
    }

    .svg-container {
      display: block;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 10px;
      right: 15px;
    }

    &:not(.dimension):hover {
      cursor: pointer;
      background-color: var(--colour-data-deluge-light);
    }
  }

  td {
    font-size: var(--font-size-small);
    padding: 0 var(--spacing-2);
    text-align: center;

    &:first-of-type {
      text-align: left;
      padding-left: var(--spacing-2);
      cursor: help;
    }

    div {
      white-space: nowrap;
      overflow: hidden;
      padding: var(--spacing-2) 0;
      text-overflow: ellipsis;
      max-width: 195px;
    }
  }


  tr {
    &:first-of-type {
      th,
      td {
        padding-top: var(--spacing-3);
      }
    }

    &:nth-of-type(2n) {
      // This is a rendition of the global --colour-panel-g-2 style that allows the table shadow to be visible.
      background: rgba(200, 200, 200, 0.1);
    }

  }

.chip {
  position: absolute;
  width: 100px;
  color: var(--colour-utility-black);
  background: var(--colour-panel-g-0);
  box-shadow: 0 0 var(--border-radius-half) var(--shadow-spread) var(--shadow-colour);
  font-size: var(--font-size-5);
  font-weight: var(--font-weight-medium);
  letter-spacing: 0;
  line-height: var(--spacing-3);
  padding: var(--spacing-1);
  border-radius: var(--spacing-6);
  margin: auto;
  margin-top: var(--spacing-1);
  left: 0;
  right: 0;
  text-align: center;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    width: 104px;
  }
}

.tooltip-container {
  display: flex;
  flex-direction: column;
  place-content: center;
  gap: var(--spacing-1);
}
</style>