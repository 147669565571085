<template>
  <div class="trends-report">
    <BufferImage
      v-if="dataLoading || !reportId"
      float="center"
      class="loading-spinner"
    />
    <div v-else>
      <section
        v-if="settings.isChartShown"
        class="chart-section"
      >
        <MultilineChart
          :chart-data="chartData"
          :format-func="cPrice"
          :legend-labels="legendLabels"
        />
      </section>
      <section 
        v-if="settings.isTableShown"
        class="data-section"
      >
        <TrendsTable
          :report-id="reportId"
        />
      </section>
      <ReportFooter
        v-if="!settings.hideFooter"
        :report-type="TRENDS"
        :request-params="requestParams"
      />
    </div>
    <NoResults v-if="!reportId && !dataLoading" />
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useStore } from 'vuex';
import { BufferImage } from '@sales-i/dsv3';
import MultilineChart from '@/intelligence/components/Dashboard/Elements/MultilineChart';
import TrendsTable from '@/intelligence/components/Trends/TrendsTable';
import ReportFooter from '@/intelligence/components/ReportFooter/ReportFooter';
import NoResults from '@/intelligence/components/Shared/NoResults';
import { TRENDS } from '@/intelligence/store/data/reportTypes';
import useTrends from '@/intelligence/composables/useTrends';
import useCurrency from '@/shared/composables/useCurrency';

const props = defineProps({
  requestParams: {
    type: Object,
    default: () => ({}),
  },
  settings: {
    type: Object,
    default: () => ({
      isChartShown: true,
      isTableShown: true,
      hideFooter: false,
    }),
  },
});

const emit = defineEmits(['update:legendLabels']);
const store = useStore();
const { cPrice } = useCurrency({ store });
const reportId = ref(undefined);
const { dataLoading, chartData, legendLabels, loadData } = useTrends({ reportId, store });

watch(
  () => props.requestParams, 
  async () => {
    reportId.value = await loadData(props.requestParams);
    emit('update:legendLabels', legendLabels.value);
  },
  { immediate: true }
);
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.trends-report {
  padding-right: var(--spacing-2);
  padding-left: var(--spacing-2);
  position: relative;
  width: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    margin-bottom: 80px;
  }

  .chart-section {
    min-height: 200px;
  }

  > div:not(.loading-spinner) {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      padding: 0 var(--spacing-1);
    }
  }

  .loading-spinner {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    z-index: 1;
  }
}

:deep(.trends-chart-container) {
  max-width: 100%;
}

.data-section {
  border-radius: var(--border-radius-1);
  box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-blur) var(--shadow-spread) var(--shadow-colour);
  margin-bottom: var(--spacing-1);
}
</style>
