<template>
  <Field
    :id="id"
    required
    :error-message="t('Please choose an area')"
    type="select"
    :options="filterOptions"
    :value="value.startsWith('customsection') ? 'customsection' : value"
    :label="t('Filter by')"
    @input="$emit('input', $event)"
  />
</template>

<script setup>
import { computed } from 'vue';
import Field from '@/shared/components/Form/Field.vue';
import { t } from '@sales-i/utils';

defineProps({
  id: {
    type: String,
    default: ''
  },
  value: {
    type: String,
    default: ''
  },
});

defineEmits(['input']);

const filterOptions = computed(() => [
  {
    text: t('Interactions'),
    value: 'interaction',
  },
  {
    text: t('Contacts'),
    value: 'contact',
  },
  {
    text: t('Location'),
    value: 'address',
  },
  {
    text: t('Custom Schema'),
    value: 'customsection',
  },
].filter(Boolean));
</script>