<template>
  <h4>{{ subtitle }}</h4>
  <TrendsReport
    :request-params="item.requestParams"
    :settings="{
      hideFooter: true,
      ...settings
    }"
    @update:report-id="handleUpdateReportId"
  />
</template>

<script setup>
import { onMounted, ref, onBeforeUnmount, watch } from 'vue';
import TrendsReport from '@/intelligence/components/Trends/TrendsReport';
import { useStore } from 'vuex';
import { useWidget } from '@/intelligence/components/Dashboard/composables/useWidget';
import useShared from '@/intelligence/composables/useShared';
import useTrends from '@/intelligence/composables/useTrends';

const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
  settings: {
    type: Object,
    default: () => ({}),
  },
});

const store = useStore();
const emit = defineEmits(['update:menuOptions']);

const { chartAndTableMenuOptions } = useWidget({
  store,
  props,
  options: { chartTypes: [] },
});

const reportId = ref(0);
const { clearReportData } = useShared({ store });
const { subtitle } = useTrends({ reportId, store });

const handleUpdateReportId = (id) => {
  reportId.value = id;
};

onMounted(async () => {
  emit('update:menuOptions', chartAndTableMenuOptions.value);
});

onBeforeUnmount(() => {
  clearReportData();
});

watch(
  () => props.settings,
  () => emit('update:menuOptions', chartAndTableMenuOptions.value),
  { deep: true }
);
</script>

<style lang="scss" scoped>
h4,
h5 {
  text-align: center;
}

.report-table {
  margin-bottom: var(--spacing-1);
  max-height: 260px;
  /** todo: improve this later - it's temporary solution to not let chart be on top of the table */
  z-index: 1;
}
</style>
