<template>
  <div class="measure-filter">
    <MenuFilter
      :items="MEASURES"
      :selected-value="requestParameters.measure"
      menu-position="right"
      @on-change="changeMeasure"
    />
  </div>
</template>

<script setup>
import { MenuFilter } from '@sales-i/dsv3';
import { useStore } from 'vuex';
import { MEASURES } from '@/intelligence/store/data/kpis';
import { navigateToUrl } from 'single-spa';
import useSystem from '@/shared/composables/useSystem';
import useEnquiries from '@/intelligence/composables/useEnquiries';
import useShared from '@/intelligence/composables/useShared';
import { useRoute, useRouter } from 'vue-router';

const props = defineProps({
  isBubbleSelectionScreen: {
    type: Boolean,
    default: false,
  }});

const store = useStore();
const vroute = useRoute();
const vrouter = useRouter();

const { requestParameters, refreshReport } = useShared({ store });
const { iframe } = useSystem({ store });
const { applyMeasure } = useEnquiries({ store });

function changeMeasure(measure) {
  applyMeasure(measure.value);
  if (!props.isBubbleSelectionScreen) {
    if (!iframe.value) {
      let route = vrouter.resolve({ query: { ...vroute.query, measure: measure.value } });
      navigateToUrl(route.href);
    }
    refreshReport();
  }
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.measure-filter {
  left: 70px;
  top: 12px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    position: relative;
    left: auto;
    top: var(--spacing-1)
 }

  &:deep(.menu-filter .list) {
    top: auto;
    bottom: var(--spacing-6);
    right: 0;
    left: initial;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      top: var(--spacing-5);
      bottom: auto;
      right: initial;
      left: 0;
    }
  }

  &:deep(.menu-filter .filter-select__selections[data-v-24eb7e9e]::before) {
    width: 16px;
  }

  &:deep(.filter-select__selection) {
    white-space: break-spaces;
    max-width: 52px;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      white-space: nowrap;
      max-width: none;
    }
  }

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    position: relative;
    left: auto;
    top: var(--spacing-1);
  }
}
</style>
