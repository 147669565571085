<template>
  <div class="padded pt-3 px-3 o-grid">
    <TodayInteractions v-if="canAccessInteractions" />
    <NoDataCard v-if="canAccessInsights && !customerList?.length && hasAccessToAnyInsights" />
    <TodayNextInteraction
      v-if="canAccessEnquiries && customerList?.length"
      :is-chart-enabled="isAnalysisGraphVisible"
      :next-interaction="nextInteraction"
      :report-id="purchaseAnalysisId"
      :current-index="customerIndex"
      :total-interactions="customerList?.length"
      :has-current-analysis-data-empty="isAnalysisDataEmpty"
      @navigate="handleNavigation"
    />
    <TodayPerformance
      v-if="canAccessEnquiries"
    />
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount, watch } from 'vue';
import { useStore } from 'vuex';
import { interactions, intelligence_enquiries, intelligence_insights } from '@/shared/store/data/policies';
import { PRODUCT_GROUP_PURCHASE_ANALYSIS } from '@/intelligence/store/data/reportTypes';
import TodayInteractions from '@/crm/views/Main/TodayInteractions.vue';
import TodayNextInteraction from '@/crm/views/Main/TodayNextInteraction.vue';
import TodayPerformance from '@/crm/views/Main/TodayPerformance.vue';
import NoDataCard from '@/crm/views/Main/NoDataCard.vue';
import usePermissions from '@/shared/composables/usePermissions';
import useTodaysInteractions from '@/shared/composables/useTodaysInteractions';
import useShared from '@/intelligence/composables/useShared';
import useCalendar from '@/intelligence/composables/useCalendar';
import useTemplateReport from '@/intelligence/composables/useTemplateReport';

const store = useStore();

const isAnalysisDataEmpty = ref(false);
const isAnalysisGraphVisible = ref(false);
const purchaseAnalysisId = ref(null);
const customerIndex = ref(0);

const { hasAccessToCustomerInsights, hasAccessToPerformanceInsights, hasAccessToProductInsights, hasAccess } = usePermissions();
const { customerList, fetchReportData, clearReportData, fetchTodaysInteractions } = useTodaysInteractions();

const canAccessInteractions = computed(() => hasAccess(interactions));
const canAccessInsights = computed(() => hasAccess(intelligence_insights));
const canAccessEnquiries = computed(() => hasAccess(intelligence_enquiries));

// Create a variable which checks if the user has access to any the insights (defined by individual report access)
const hasAccessToAnyInsights = computed(
  () => hasAccessToPerformanceInsights.value || hasAccessToProductInsights.value || hasAccessToCustomerInsights.value
);

const { fetchBubbleIds, saveBubbleData } = useTemplateReport({ store });
const { fetchDates } = useCalendar({ store });
const { requestParameters, getReportData } = useShared({ store });
const nextInteraction = computed(() => isAnalysisGraphVisible.value ? customerList.value[customerIndex.value] : '');

onMounted(async () => {
  if (canAccessInteractions.value) {
    await fetchTodaysInteractions();
  }
  if (customerList.value?.length) {
    if (!requestParameters.value.snapshotBubbleId || !requestParameters.value.productBubbleId) {
      await fetchBubbleIds();
      saveBubbleData('customer');
    }
    await renderAnalysisChart();
  } else {
    isAnalysisDataEmpty.value = true;
  }
});

onBeforeUnmount(clearReportData);

watch(() => canAccessInteractions.value, async () => {
  await fetchTodaysInteractions();
  await renderAnalysisChart();
});

async function renderAnalysisChart(completeRefresh = false) {
  if (!customerList.value?.length) {
    isAnalysisDataEmpty.value = true;
    return;
  }
  const currentCustomer = customerList.value[customerIndex.value];
  const fetchedDates = await fetchDates({ 
    date_from: 'today/y',
    date_to: 'today'
  });
  purchaseAnalysisId.value = await fetchReportData({
    reportType: PRODUCT_GROUP_PURCHASE_ANALYSIS,
    completeRefresh,
    customParams: { 
      ...fetchedDates,
      snapshotBubbleValue: currentCustomer.name, 
      snapshotBubbleValueId: `"${currentCustomer.account_number}"`
    },
  });

  isAnalysisDataEmpty.value = !getReportData(purchaseAnalysisId.value)?.length;
  isAnalysisGraphVisible.value = true;
}

const handleNavigation = async (direction) => {
  isAnalysisGraphVisible.value = false;
  if (direction === 'next' && customerIndex.value < customerList.value?.length - 1) {
    customerIndex.value++;
  } else if (direction === 'prev' && customerIndex.value > 0) {
    customerIndex.value--;
  }
  await renderAnalysisChart(true);
};

</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.o-grid {
  display: grid;
  gap: var(--spacing-3);
  grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
  max-width: 1366px;
}

// Styles that affect all cards within the Today component
:deep(.c-card) {
  box-shadow: none;
  padding: var(--spacing-3) 0;
  justify-content: space-between;
}
:deep(.insights-wrapper) > .c-card--body {
  height: auto;
  overflow-y: auto;
}

:deep(header) {
  padding-right: var(--spacing-3);
  padding-left: var(--spacing-3);
  text-align: center;

  p {
    font-size: var(--font-size-4);
    line-height: 1.6;
  }

  a {
    font-weight: var(--font-weight-semibold);
  }
}

:deep(.c-card--footer) {
  margin: inherit;
}
</style>
