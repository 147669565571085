import { GET_BY_ID, } from '@/shared/store/actionType';
import { useStore } from 'vuex';

export default function useContact({ store = useStore() } = {}) {
  const getContact = params => store.dispatch(`crm/contacts/${GET_BY_ID}`, params);

  return {
    getContact
  };
}
