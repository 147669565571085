<template>
  <li
    role="button"
    tabindex="0"
    :class="{ highlighted: item.checked, disabled: item.disabled }"
    @click.self="handleCheckbox"
    @keydown.enter="handleCheckbox"
  >
    <div class="checkbox-container">
      <CustomCheckbox
        class="checkbox"
        :disabled="item.disabled"
        :value="item.checked"
        @input="handleCheckbox"
      />
    </div>
    <slot />
  </li>
</template>

<script setup>
import { CustomCheckbox } from '@sales-i/dsv3';
const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['onChange']);

const handleCheckbox = () => {
  if (props.item.disabled) return;
  emit('onChange', {
    value: props.item.value,
    id: props.item.id,
    checked: !props.item.checked,
  });
};
</script>

<style lang="scss" scoped>
li {
  display: grid;
  grid-template-columns: var(--spacing-6) 1fr;
  gap: var(--spacing-1);
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:nth-child(even) {
    background: var(--colour-panel-g-4);
  }

  &.highlighted {
    background: var(--colour-utility-black);
    color: var(--colour-utility-white);
  }

  &.disabled {
    background-color: var(--colour-utility-white);
    color: var(--colour-utility-disabled);
    .checkbox-container {
      background-color: var(--colour-utility-white);
      visibility: hidden;

      :deep(input[type='checkbox']:checked + .check) {
        background: var(--colour-utility-disabled)
        url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath style='fill:var(--colour-utility-disabled)' d='M 0.04038059,0.6267767 0.14644661,0.52071068 0.42928932,0.80355339 0.3232233,0.90961941 z M 0.21715729,0.80355339 0.85355339,0.16715729 0.95961941,0.2732233 0.3232233,0.90961941 z'%3E%3C/path%3E%3C/svg%3E")
        no-repeat center center;
        border-width: 4px;
        box-shadow: 0 0 0 4px transparent !important;
      }
    }
  }

  .checkbox-container {
    background: var(--colour-data-puerto-rico);
    padding-block: var(--spacing-half);
    display: flex;
    place-content: center;
  }
}
</style>
