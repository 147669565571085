<template>
  <Field
    v-model="selectedValue"
    type="select"
    :options="productSearchFilters"
    :twoway="true"
    :label="t('Level')"
    @input="setProductGroupLevel"
  />
  <ProductDataItemSelection
    v-if="selectedValue !== ''"
    :selected-value="selectedValue"
    :filter="filter"
    @add-product="addProduct"
    @delete-product="deleteProduct"
  />
</template>

<script setup>
import { t } from '@sales-i/utils';
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import {
  CLEAR_CRM_PRODUCTS,
  CLEAR_PRODUCT_GROUPS,
} from '@/admin/store/actionType';
import Field from '@/shared/components/Form/Field.vue';
import ProductDataItemSelection from '@/intelligence/components/AlertBuilder/AlertWizard/ProductDataItemSelection.vue';

const store = useStore();

const props = defineProps({
  filter: {
    type: Object,
    default: () => ({}),
  },
});

const selectedValue = ref('');
const productSearchFilters = [
  {
    text: 'Product Group Level 1 Description',
    value: 'prod_group_level_1_desc',
    path: 'prod_group_level_1_desc',
  },
  {
    text: 'Product Group Level 2 Description',
    value: 'prod_group_level_2_desc',
    path: 'prod_group_level_2_desc',
  },
  {
    text: 'Product Group Level 3 Description',
    value: 'prod_group_level_3_desc',
    path: 'prod_group_level_3_desc',
  },
  {
    text: 'Product Group Level 4 Description',
    value: 'prod_group_level_4_desc',
    path: 'prod_group_level_4_desc',
  },
  {
    text: 'Product Group Level 5 Description',
    value: 'prod_group_level_5_desc',
    path: 'prod_group_level_5_desc',
  },
];
const clearCrmProducts = () => store.dispatch(`admin/associations/${CLEAR_CRM_PRODUCTS}`);
const clearProductGroups = () => store.dispatch(`admin/associations/${CLEAR_PRODUCT_GROUPS}`);

onMounted(async () => {
  clearCrmProducts();
  clearProductGroups();
  selectedValue.value = props.filter.product_group_level || '';
});

const emit = defineEmits(['addProduct', 'deleteProduct', 'setProductGroupLevel']);

const setProductGroupLevel = (value) => {
  selectedValue.value = value;
  emit('setProductGroupLevel', value);
};

function addProduct(product) {
  emit('addProduct', product);
}

function deleteProduct(product) {
  emit('deleteProduct', product);
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.field {
  width: fit-content;
}
.search {
  display: block;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    display: grid;
    grid-template-columns: 6fr var(--spacing-6) 6fr;
    gap: var(--spacing-2);
  }
}

.buying,.not-buying {
  --flow-space: var(--spacing-1);
  padding: var(--spacing-1);
  overflow-y: hidden;
}
  .rendered-list {
    overflow: scroll;
  }
</style>
