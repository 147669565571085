<template>
  <div
    v-if="filterBySelectedDate.length == 0"
    class="interactions_table_no_event"
  >
    {{ t('There are no interactions planned for this date range.') }}
  </div>
  <div
    v-if="filterBySelectedDate.length > 0"
    class="interaction-search"
  >
    <CustomField
      id="interaction_search"
      :placeholder="t('Search...')"
      @input="updateSearchFilter"
    />
  </div>
  <div class="table-wrapper">
    <table v-if="filterBySelectedDate.length > 0">
      <thead>
        <tr>
          <th
            v-for="item in columns"
            :key="item.key"
            :class="{
              'sorting-header': currentSort === item.key,
            }"
            @click="sort(item.key)"
          >
            {{ t(item.name) }}
            <IconBase
              v-if="currentSort === item.key"
              class="sort-icon"
              :icon-name="currentSortDir === 'desc' ? 'arrow-down' : 'arrow-up'"
              :height="24"
              :width="24"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in filterBySearchTerm"
          :key="item.id"
        >
          <td class="first-column">
            <span v-if="item.is_personal">
              <a
                :href="`${baseUrl}/${interactionsArea}/${item.id}/personal`"
                @click.prevent="navigateToUrl(`${baseUrl}/${interactionsArea}/${item.id}/personal`)"
              >
                <strong>{{ t('Personal Interaction') }}</strong>
              </a>
            </span>
            <span v-if="!item.is_personal">
              <a
                v-for="customer in item.relationships.customers"
                :key="customer.id"
                :href="`${baseUrl}/${interactionsArea}/${item.id}`"
                @click.prevent="navigateToUrl(`${baseUrl}/${interactionsArea}/${item.id}`)"
              >
                <strong>{{ customer.name }}</strong>
              </a>
            </span>

            <span v-if="!item.is_personal">
              <a
                v-for="prospect in item.relationships.prospects"
                :key="prospect.id"
                :href="`${baseUrl}/${interactionsArea}/${item.id}`"
                @click.prevent="navigateToUrl(`${baseUrl}/${interactionsArea}/${item.id}`)"
              >
                <strong>{{ prospect.name }}</strong>
              </a>
            </span>
          </td>
          <td>
            <p>
              {{ dates.format(item.date_start) }} :
              {{ dates.format(item.date_start, 'timeSimple') }}
            </p>
          </td>
          <td>{{ item.contact }}</td>
          <td>{{ item.interaction_type }}</td>
          <td>{{ item.phone_number }}</td>
          <td v-if="item.outcome_id">
            {{ item.outcome_name }}
          </td>
          <td v-else-if="!item.is_personal">
            <CustomButton
              purpose="text"
              @on-click="completeInteraction(item.id)"
            >
              {{ t('Complete interaction') }}
            </CustomButton>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <Teleport to="#modal-teleport-target">
    <CompleteInteractionModal
      v-if="completeInteractionModal"
      :interaction-id="selectedInteractionId"
      @close-modal="closeModal"
      @completed="completed"
    />
  </Teleport>
</template>

<script setup>
// InteractionsTable
import { ref, computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { CustomButton, IconBase } from '@sales-i/dsv3';
import CustomField from '@/shared/components/Form/Field.vue';
import { dates } from '@sales-i/utils';
import { GET_BY_ENTITY, POST_BY_ENTITY } from '@/shared/store/actionType';
import { SET_DATE, SET_RELATED_TO } from '@/crm/store/actionType';
import { baseUrl, interactionsArea } from '@/crm/router/urlBits';
import CompleteInteractionModal from '@/crm/views/Interactions/CompleteInteractionModal.vue';
import { t } from '@sales-i/utils';
import { navigateToUrl } from 'single-spa';

const props = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  selectedDate: {
    type: Date,
    default: new Date(),
  },
  status: {
    type: String,
    default: 'open',
  },
});

const emit = defineEmits(['loadData', 'updateMonth']);

const store = useStore();
const searchFilter = ref('');
const currentSort = ref('date_start');
const currentSortDir = ref('asc');
const selectedInteractionId = ref(undefined);
const completeInteractionModal = ref(false);
const fields = computed(() => store.getters['admin/fields/getFieldsOptions']);
const selected = computed(() => store.state.crm.interactions.selected);

const columns = computed(() => {
  let cols = [
    {
      name: 'Customer/prospect',
      key: 'customer',
    },
    {
      name: 'Date/time',
      key: 'date_start',
    },
    {
      name: 'Contact',
      key: 'contact',
    },
    {
      name: 'Interaction type',
      key: 'interaction_type',
    },
    {
      name: 'Phone number',
      key: 'phone_number'
    },
    props.status === 'complete'
      ? {
        name: 'Outcome',
        key: 'outcome_name',
      } 
      : {
        name: '',
        key: 'complete_interaction',
      }
  ];
  return cols;
});

const editedInteractions = computed(() => {
  return props.items.map(item => mapInteraction(item));
});

const sortedInteractions = computed(() => {
  return [...editedInteractions.value].sort((a, b) => {
    let modifier = 1;
    if (currentSortDir.value === 'desc') modifier = -1;
    if (a[currentSort.value] < b[currentSort.value]) return -1 * modifier;
    if (a[currentSort.value] > b[currentSort.value]) return 1 * modifier;
    return 0;
  });
});

const filterBySelectedDate = computed(() => {
  
  return sortedInteractions.value.filter(item => {
    // Format the dates
    const formattedStartDate = dates.format(item.date_start);
    const formattedEndDate = dates.format(item.date_end);
    const formattedSelectedDate = dates.format(props.selectedDate);

    // Check if the selected date matches the start or end date
    const isMatchingDate = formattedStartDate === formattedSelectedDate || formattedEndDate === formattedSelectedDate;

    // Logic for filtering when status is 'missed'
    if (props.status === 'missed') {
      return isMatchingDate && item.interaction_type_name.toLowerCase() !== 'personal';
    }

    // Logic for filtering when status is 'completed'
    if (props.status === 'completed') {
      return isMatchingDate && item.interaction_type_name.toLowerCase() === 'completed';
    }

    // If status is not 'missed' or 'completed', return all matching dates
    return isMatchingDate;
  });
});

const filterBySearchTerm = computed(() => {
  if (searchFilter.value) {
    const searchTerm = searchFilter.value.toLowerCase();
    return filterBySelectedDate.value.filter(item => {
      const contactOrProspectName =
        (Array.isArray(item.customer) && item.customer[0].toLowerCase().includes(searchTerm)) ||
        (Array.isArray(item.prospect) && item.prospect[0].toLowerCase().includes(searchTerm));
      return (
        item.contact.toLowerCase().includes(searchTerm) || item.interaction_type_name.toLowerCase().includes(searchTerm) || contactOrProspectName
      );
    });
  }
  return filterBySelectedDate.value;
});

async function initialLoad() {
  await store.dispatch(`admin/fields/${GET_BY_ENTITY}`, { entity: 'interactions' });
}

function sort(s) {
  //if s == current sort, reverse
  if (s === currentSort.value) {
    currentSortDir.value = currentSortDir.value === 'asc' ? 'desc' : 'asc';
  }
  currentSort.value = s;
}

function updateSearchFilter(val) {
  searchFilter.value = val;
}

function mapInteraction(item) {
  const interactionTypeValue = item.interaction_type;
  const interactionOutcomevalue = item.outcome_id;
  const interactionTypeText = fields?.value?.type?.find(item => item.value === interactionTypeValue) || {};
  const interactionOutcomeText = fields?.value?.outcome?.find(item => item.value === interactionOutcomevalue) || {};
  const phoneNumber = (item.relationships?.contacts?.[0]?.mobile_phone === 'null' ? null : item.relationships?.contacts?.[0]?.mobile_phone) || t('No phone number');

  return item.is_personal
    ? {
      ...item,
      contact: '',
      customer: 'Personal Interaction',
      prospect: '',
      start: item.start || 'No date',
      interaction_type: interactionTypeText.text,
      outcome_name: interactionOutcomeText.text
    }
    : {
      ...item,
      contact: item.relationships.contacts.length > 0 ? item.relationships.contacts[0].full_name : 'No contact name',
      customer: item.relationships.customers.length > 0 ? item.relationships.customers.map(customer => customer.name) : 'No customer name',
      prospect: item.relationships.prospects.length > 0 ? item.relationships.prospects.map(prospect => prospect.name) : 'No prospect name',
      start: item.start || 'No date',
      interaction_type: interactionTypeText.text,
      phone_number : phoneNumber,
      outcome_name: interactionOutcomeText.text
    };
}

function completeInteraction(id) {
  selectedInteractionId.value = id;
  completeInteractionModal.value = true;
}

async function completed(timescale) {
  const intId = selectedInteractionId.value;
  if (timescale.date && intId) {
    await store.dispatch(`crm/interactions/${SET_DATE}`, timescale);
    await store.dispatch(`crm/interactions/${SET_RELATED_TO}`, intId);
    await store.dispatch(`crm/interactions/${POST_BY_ENTITY}`);
    const { data } = selected.value;
    navigateToUrl(`${baseUrl}/${interactionsArea}/${data.id}`);
  }
}

function closeModal(completed = false) {
  if (completed) {
    emit('loadData');
    emit('updateMonth');
  }
  selectedInteractionId.value = undefined;
  completeInteractionModal.value = false;
}

watch(
  () => props.selectedDate,
  () => {
    searchFilter.value = '';
  }
);

onMounted(() => {
  initialLoad();
});
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.table-wrapper {
  background: linear-gradient(to right, var(--colour-utility-white) 0%, rgba(255, 255, 255, 0.2)),
    linear-gradient(to right, rgba(255, 255, 255, 0.2), var(--colour-utility-white) 100%) 0 100%,
    linear-gradient(to right, rgba(0, 0, 0, 0.2), transparent), linear-gradient(to right, transparent, rgba(0, 0, 0, 0.2)) 0 100%;

  background-repeat: no-repeat;
  background-color: var(--colour-utility-white);
  background-size: 250px 100%, 250px 100%, 16px 100%, 16px 100%;
  background-position: 0 0, 100%, 0 0, 100%;
  background-attachment: local, local, scroll, scroll;
  overflow: auto;
  transition: all 0.2s;
  position: relative;
  // scroll-snap-type: x mandatory;
}

table {
  position: relative;
  width: 100%;

  th,
  td {
    font-size: inherit;
  }
}

tbody tr:first-child td {
  padding-top: var(--spacing-4);
}

tr {
  &:nth-child(even) {
    // I have to use a version of the colour-panel-g-2 variable with opacity to allow for the gradient at the end of a scrollable table to show through.
    background: rgba(210, 210, 210, 0.1);
  }
}

th,
td {
  color: var(--colour-utility-black);
  padding: var(--spacing-2);
  border: none;
}

th {
  background: var(--colour-panel-action);
  color: var(--colour-utility-action);
  padding: var(--spacing-3) var(--spacing-2);
  vertical-align: middle;
  text-decoration: underline;
  cursor: pointer;

  &.contains-chip-header {
    padding-bottom: var(--spacing-3);
  }

  &.sorting-header {
    background: var(--colour-panel-g-2);
    color: var(--colour-utility-black);
    font-weight: var(--font-weight-medium);
    text-decoration: none;
  }
}

td {
  padding: var(--spacing-1) var(--spacing-2);
  line-height: var(--spacing-2);

  &.first-column {
    background: rgba(210, 210, 210, 0.1);
    border-right: 1px solid var(--colour-panel-g-8);
    text-align: left;
  }
}

.sort-icon {
  display: inline-block;
  position: absolute;
}

.interaction-search {
  padding: var(--spacing-1);
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    // class targeted in CustomField
    .field {
      width: 200px;
    }
  }
  :deep(.form-group) {
    margin-bottom: 0;
  }
}
</style>
