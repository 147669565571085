<template>
  <div
    ref="container"
    class="trends-chart-container"
  >
    <svg class="chart" />
  </div>
</template>

<script>
import * as d3 from 'd3';
import { abbr, debounce } from '@sales-i/utils';
import { mapGetters, mapState } from 'vuex';

export default {
  props: {
    headings: {
      type: Object,
      default() {
        return {
          1: {
            title: 'Wiper Blades',
            subTitle: '10001-ABV',
          },
          2: {
            title: 'Wiper Blades',
            subTitle: '10001-ABC',
          },
          3: {
            title: 'BOTH',
          },
        };
      },
    },
  },
  data() {
    return {
      currentParams: '',
      greenChipClass: 'chip-green',
      blueChipClass: 'chip-blue',
      redChipClass: 'chip-red',
      greenThreshold: 0,
      yellowThreshold: 0,
    };
  },
  computed: {
    ...mapState({
      fetchedCalendar: state => state.intelligence.calendar.calendar,
    }),
    ...mapGetters({
      getMonth: 'intelligence/shared/getMonth',
      getReportData: 'intelligence/shared/getReportData',
    }),
    tableData() {
      return this.getReportData();
    },
    chartWidth() {
      return this.currentParams.svgWidth - this.currentParams.margin.left - this.currentParams.margin.right;
    },
    chartHeight() {
      return this.currentParams.svgHeight - this.currentParams.margin.top - this.currentParams.margin.bottom;
    },
    containerResizeObserver() {
      return new ResizeObserver(this.debounceChartResize);
    },
    chartData() {
      if (!this.tableData || this.tableData.rows === undefined) {
        return [];
      }

      const { periods_current_fiscal_year } = this.fetchedCalendar;
      // Infer when their fiscal year begins
      let start_period = parseInt(periods_current_fiscal_year[0].start.split('-')[1]) - 1;

      const periods = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(() => {
        start_period++;
        let period = start_period;

        if (period > 12) {
          start_period = 1;
          period = 1;
        }
        return { start: period };
      });

      return this.tableData.rows.map(row => {
        const fiscalPeriod = periods[parseInt(row.period) - 1];
        if (fiscalPeriod === undefined) {
          return '';
        }
        const { start } = fiscalPeriod;

        return {
          value1: row.product_1,
          value2: row.product_2,
          value3: row.product_both,
          name: this.getMonth(parseInt(start)).value,
        };
      });
    },
    debounceChartResize() {
      return debounce(this.handleChartResize, 250);
    },
  },
  mounted() {
    this.setThresholds();
    this.containerResizeObserver.observe(this.$refs.container);
  },
  unmounted() {
    this.containerResizeObserver.disconnect();
    this.removeChart();
  },
  methods: {
    getChartVersion() {
      if (this.$refs.container === undefined) {
        return 'mobile';
      }

      return this.$refs.container?.clientWidth <= 680 ? 'mobile' : 'desktop';
    },
    getExtremeValue(extreme) {
      if (['min', 'max'].indexOf(extreme) === -1) return;
      return Math[extreme](
        ...this.chartData.map(el => el.value1),
        ...this.chartData.map(el => el.value2),
        ...this.chartData.map(el => el.value3)
      );
    },
    setThresholds() {
      this.greenThreshold = this.getExtremeValue('max') * 0.667;
      this.yellowThreshold = this.getExtremeValue('max') * 0.333;
    },
    handleChartResize() {
      this.removeChart();
      this.generateChart();
    },
    removeChart() {
      d3.select('.trends-chart-container svg.chart g').remove();
    },
    processChartParams(type) {
      // dimensions and margins of the chart (mobile and desktop)
      this.currentParams =
        type === 'mobile'
          ? {
            svgWidth: this.$refs.container?.clientWidth,
            svgHeight: 451,
            margin: {
              top: 10,
              right: -10,
              bottom: 190,
              left: 20,
            },
          }
          : type === 'desktop'
            ? {
              svgWidth: this.$refs.container?.clientWidth,
              svgHeight: 510,
              margin: {
                top: 10,
                right: 0,
                bottom: 200,
                left: 135,
              },
            }
            : '';
    },
    generateChart() {
      const type = this.getChartVersion();
      this.processChartParams(type);

      // set parameters to container svg
      const svg = d3
        .select('.trends-chart-container svg.chart')
        .attr('width', this.currentParams.svgWidth)
        .attr('height', this.currentParams.svgHeight)
        .append('g')
        .attr('transform', `translate(${this.currentParams.margin.left},${this.currentParams.margin.top})`);

      //
      // DATA
      //
      const graphOffsetFromEdge = 20,
        xValuePosition = this.chartWidth / this.chartData.length / 2,
        bgLinesColor = '--colour-panel-g-8',
        linesData = [
          {
            id: 3,
            key: 'value3',
            color: '--colour-brand-mandy',
            strokeWidth: 4,
            strokeDasharray: '',
            axisPosition: 10,
            legendPosition: 23,
            legendPositionMobile: -100,
          },
          {
            id: 2,
            key: 'value2',
            color: '--colour-brand-viking',
            strokeWidth: 4,
            strokeDasharray: '',
            axisPosition: 60,
            legendPosition: 73,
            legendPositionMobile: 0,
          },
          {
            id: 1,
            key: 'value1',
            color: '--colour-data-viking-label',
            strokeWidth: 2,
            strokeDasharray: '5,5',
            axisPosition: 110,
            legendPosition: 123,
            legendPositionMobile: 100,
          },
        ];

      //
      // UTILS
      //
      const internal = this;

      function setChipWidthPosition() {
        let textLength = d3.select(this.parentNode).select('text').node().getComputedTextLength() + 16;
        if (type === 'mobile') textLength -= 8;
        d3.select(this)
          .attr('width', textLength)
          .attr('transform', `translate(-${textLength / 2},1)`);
      }

      //
      //  DRAWING
      //
      // X axis (with month names)
      const x = d3
        .scaleBand()
        .domain(this.chartData.map((d, i) => i))
        .range([0, this.chartWidth]);
      const bottomOffsetMonths = type === 'mobile' ? 25 : 32;
      svg
        .append('g')
        .attr('class', 'chart-axis')
        .attr('transform', `translate(0, ${this.chartHeight})`)
        .call(d3.axisBottom(x))
        .selectAll('text')
        .data(this.chartData)
        .text(d => d.name)
        .attr(
          'transform',
          `translate(-${xValuePosition + 16},${this.currentParams.margin.bottom - bottomOffsetMonths})rotate(-90)`
        )
        .attr('dominant-baseline', 'middle')
        .attr('class', 'chart-label-x')
        .style('text-anchor', 'middle');

      // Y axis
      const y = d3
        .scaleLinear()
        .domain([this.getExtremeValue('min'), this.getExtremeValue('max')])
        .range([this.chartHeight - graphOffsetFromEdge, graphOffsetFromEdge]);
      const axisY = d3.axisLeft(y);
      svg.append('g').attr('class', 'chart-axis').call(axisY).selectAll('text').attr('class', 'chart-label-y');

      // Vertical lines
      this.chartData.forEach((e, i) => {
        svg
          .append('line')
          .attr('x1', x(i))
          .attr('y1', 0)
          .attr('x2', x(i))
          .attr('y2', this.chartHeight + this.currentParams.margin.bottom - bottomOffsetMonths - 20)
          .style('stroke-width', 1)
          .style('stroke', `var(${bgLinesColor})`)
          .style('fill', 'none');
      });

      // Horizontal line indicating zero
      if (this.getExtremeValue('min') <= 0) {
        svg
          .append('line')
          .attr('x1', x(0))
          .attr('y1', y(0))
          .attr('x2', x(this.chartData.length - 1))
          .attr('y2', y(0))
          .style('stroke-width', 1)
          .style('stroke', `var(${bgLinesColor})`)
          .style('fill', 'none');
      }

      linesData.forEach(line => {
        // X axis with value 1 and value 2
        let axisTranslate = `translate(-${xValuePosition}, ${this.chartHeight + line.axisPosition})`,
          axisTextTranslate = 'translate(0,0)',
          axisTextAnchor = 'middle',
          axisTextDominant = '';

        if (type === 'mobile') {
          axisTranslate = `translate(-${xValuePosition + -10}, ${this.chartHeight + line.axisPosition + 60})`;
          axisTextTranslate = 'translate(0,0)rotate(-90)';
          axisTextAnchor = 'start';
          axisTextDominant = 'middle';
        }

        svg
          .append('g')
          .attr('class', `chart-axis chart-axis-${line.key}`)
          .attr('transform', axisTranslate)
          .call(d3.axisBottom(x))
          .selectAll('text')
          .data(this.chartData)
          .text(d => abbr.int(d[line.key]))
          .attr('transform', axisTextTranslate)
          .attr('class', 'chart-label-values')
          .attr('dominant-baseline', axisTextDominant)
          .style('text-anchor', axisTextAnchor);

        // chips for X values
        const chipsSvg = svg
          .selectAll(`g.chart-axis-value${line.id} g.tick`)
          .data(this.chartData)
          .insert('rect', 'text');
        if (type === 'desktop') {
          chipsSvg.attr('ry', 12).attr('rx', 12).attr('height', 24).each(setChipWidthPosition);
        } else if (type === 'mobile') {
          chipsSvg
            .attr('height', 12)
            // .attr('class', 'chip-white')
            .each(setChipWidthPosition)
            .attr('transform', `translate(${xValuePosition / 2}, 3)rotate(-90)`);
        }

        // lines
        const linePath = d3
          .line()
          .curve(d3.curveMonotoneX)
          .x((d, i) => x(i))
          .y(d => y(d[line.key]));
        svg
          .append('path')
          .datum(this.chartData)
          .attr('fill', 'none')
          .attr('stroke', `var(${line.color})`)
          .attr('stroke-width', line.strokeWidth)
          .attr('stroke-dasharray', line.strokeDasharray)
          .attr('d', linePath);

        // legend
        if (type === 'desktop') {
          svg
            .append('line')
            .attr('x1', 25 - this.currentParams.margin.left)
            .attr('y1', this.chartHeight + line.legendPosition)
            .attr('x2', -30)
            .attr('y2', this.chartHeight + line.legendPosition)
            .attr('stroke', `var(${line.color})`)
            .attr('stroke-width', line.strokeWidth)
            .attr('stroke-dasharray', line.strokeDasharray)
            .style('fill', 'none');
          svg
            .append('text')
            .attr('x', 25 - this.currentParams.margin.left)
            .attr('y', this.chartHeight + line.legendPosition - 10)
            .attr('class', 'legend-text')
            .text(internal.headings?.[line.id]?.title)
            .attr('alignment-baseline', 'middle');
        } else if (type === 'mobile') {
          const xLegendPosition = this.chartWidth / 2 + line.legendPositionMobile,
            xLegendOffset = 70,
            yLegendOffset = 30;

          svg
            .append('line')
            .attr('x1', -10)
            .attr('y1', this.chartHeight + line.axisPosition + 5)
            .attr('x2', -10)
            .attr('y2', this.chartHeight + line.axisPosition + 30)
            .attr('stroke', `var(${line.color})`)
            .attr('stroke-width', line.strokeWidth)
            .attr('stroke-dasharray', line.strokeDasharray)
            .style('fill', 'none');

          svg
            .append('line')
            .attr('x1', xLegendPosition - xLegendOffset)
            .attr('y1', this.currentParams.svgHeight - yLegendOffset)
            .attr('x2', xLegendPosition - xLegendOffset + yLegendOffset)
            .attr('y2', this.currentParams.svgHeight - yLegendOffset)
            .attr('stroke', `var(${line.color})`)
            .attr('stroke-width', line.strokeWidth)
            .attr('stroke-dasharray', line.strokeDasharray)
            .style('fill', 'none');
          svg
            .append('text')
            .attr('x', xLegendPosition)
            .attr('y', this.currentParams.svgHeight - yLegendOffset)
            .attr('class', 'legend-text')
            .text(internal.headings?.[line.id]?.title)
            .attr('alignment-baseline', 'middle')
            .style('text-anchor', 'middle');
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.trends-chart-container {
  width: 100%;
  position: relative;
  display: block;
  overflow-x: auto;
  margin-bottom: var(--spacing-6);

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    margin-bottom: var(--spacing-2);
  }

  .chart {
    display: block;
    margin: auto;
  }
}

:deep(g.tick line),
:deep(g.chart-axis .domain) {
  display: none;
}

.trends-chart-container {
  g.tick line,
  .chart-label-y,
  .chart-axis line,
  .chart-axis path {
    display: none;
  }

  .chart-label-x,
  .chart-label-values,
  .legend-text {
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-small);
    font-family: EuclidCircularA;
  }

  .chart-label-values,
  .legend-text {
    font-weight: var(--font-weight-semibold);
  }

  .chart-axis-value1 text,
  .chart-axis-value2 text,
  .chart-axis-value3 text {
    color: white;
  }

  .chart-axis-value1 rect {
    fill: var(--colour-data-viking-label);
  }

  .chart-axis-value2 rect {
    fill: var(--colour-brand-viking);
  }

  .chart-axis-value3 rect {
    fill: var(--colour-brand-mandy);
  }

  rect.chip-white {
    fill: var(--colour-panel-g-0);
  }
}
</style>
