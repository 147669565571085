<template>
  <div class="filter">
    <div
      v-if="filter.type === 'input'"
      class="range-filter"
    >
      <label for="input">
        <span>{{ filter.label }}</span>
        <input
          type="number"
          name="input"
          min="0"
          :value="currentFilterValue"
          @change="applyFilter($event.target.value, filter.queryParamKey)"
        >
      </label>
    </div>
    <MenuFilter
      v-else
      :items="filter.values"
      :selected-value="currentFilterValue"
      menu-position="right"
      @on-change="applyFilter($event.value, filter.queryParamKey)"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { MenuFilter } from '@sales-i/dsv3';
import { navigateToUrl } from 'single-spa';
import useShared from '@/intelligence/composables/useShared';
import useTemplateReport from '@/intelligence/composables/useTemplateReport';

const props = defineProps({
  filter: {
    type: Object,
    default: () => ({}),
  },
});

const vroute = useRoute();
const vrouter = useRouter();

const { refreshReport } = useShared();
const { applyNewFilter } = useTemplateReport();

const currentFilterValue = computed(() => {
  const param = vroute.query[props.filter.queryParamKey] || props.filter.default;
  return props.filter.isNumber ? parseInt(param) : param;
});

const applyFilter = async (newFilter, queryParamKey) => {
  if (newFilter === currentFilterValue.value) return;

  const { href } = vrouter.resolve({ 
    query: { 
      ...vroute.query, 
      [queryParamKey]: newFilter 
    } 
  });
  navigateToUrl(href);
  await applyNewFilter([queryParamKey, newFilter]);
  refreshReport();
};
</script>

<style lang="scss" scoped>
.header-section > .filters > .filter {
  margin-left: var(--spacing-2);
  width: fit-content;
}

.range-filter {
  width: 100%;
  display: flex;
  flex-direction: row;

  &.ytd {
    display: none;
  }
}

label {
  padding: var(--spacing-1) 0;

  &:first-of-type {
    padding-left: 0;
    padding-right: var(--spacing-half);
  }
  &:nth-of-type(2) {
    padding-left: var(--spacing-half);
    padding-right: 0;
  }
}

span {
  color: var(--colour-utility-black);
  font-size: var(--font-size-5);
  letter-spacing: 0;
}

input[type='number'] {
  margin-top: var(--spacing-half);
  padding: var(--spacing-1) var(--spacing-half) var(--spacing-1) var(--spacing-1);
  color: var(--colour-utility-black);
  font-size: var(--font-size-5);
  font-weight: var(--font-weight-regular);
  letter-spacing: 0;

  &:disabled {
    background-color: var(--colour-panel-g-8);
    color: var(--colour-panel-g-48);
    cursor: not-allowed;
  }
}
</style>
