import { computed } from 'vue';
import { useStore } from 'vuex';
import { CLEAR_SEARCH_BAR_RESULTS, GET_SEARCH_BAR_RESULTS } from '@/shared/store/actionType';

export default function useSearchInput({ url, type, emit, mapResult, getUrl } = {}) {
  const store = useStore();

  const searchBarResults = computed(() => store.state.searchBarResults);
  const loading = computed(() => store.state.searchBarResults.loading);

  const getSearchBarResults = params => store.dispatch(`searchBarResults/${GET_SEARCH_BAR_RESULTS}`, params);
  const clearSearchBarResults = () => store.dispatch(`searchBarResults/${CLEAR_SEARCH_BAR_RESULTS}`);
  
  const mapResultFunc = mapResult ? mapResult : x => ({...x, value: x.id, text: x.header});
  const getSearchResults = async(q, limit) => {
    let parameters = {
      limit,
      q
    };
    let urlToUse = getUrlFunc();
    await clearSearchBarResults();
    await getSearchBarResults({
      parameters,
      url: urlToUse,
      type: type.value,
    });
    return searchBarResults.value.data.map(x => mapResultFunc(x)) || [];
  };

  const getUrlFunc = getUrl ? getUrl : () => url.value;

  const handleInput = async(value) => {
    emit('input', value);
    await clearSearchBarResults();
  };
  const handleSearch = value => { emit('search', value); };
  const handleBlur = value => { emit('blur', value); };
  const handleFocus = value => { emit('focus', value); };
  const handleValid = value => { emit('valid', value); };

  return {
    loading,
    searchBarResults,
    getSearchResults,
    getSearchBarResults,
    clearSearchBarResults,
    handleInput,
    handleSearch,
    handleBlur,
    handleFocus,
    handleValid,
  };
}
