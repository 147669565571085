import TargetsView from '@/admin/views/Company/TargetsSettingsView.vue';
import AddEditTarget from '@/intelligence/components/Targets/AddEditTarget/AddEditTarget.vue';
import TargetsReport from '@/intelligence/components/Targets/TargetsReport.vue';
import { targetsSection as section } from '@/admin/router/urlBits';

export default [
  {
    path: section,
    component: TargetsView,
    children: [
      {
        path: '',
        component: TargetsReport,
        children: [
          {
            path: 'add',
            component: AddEditTarget,
            props: route => ({
              year: route.query.year,
              isEdit: false,
            }),
          },
          {
            path: ':id(\\d+)/edit',
            component: AddEditTarget,
            props: route => ({
              targetId: route.params.id,
              year: route.query.year,
              isEdit: true,
            }),
          },
        ]
      },
    ],
  }
];
