import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import {
  ADD_SALES_FILTER_TO_QUERY,
  ADD_CUSTOMER_FILTER_TO_QUERY,
  ADD_PRODUCT_TO_QUERY,
  REMOVE_ALL_FILTERS_FROM_QUERY,
  UPDATE_SALES_FILTER_IN_QUERY,
  REMOVE_SALES_FILTER_FROM_QUERY,
  GET_SALES_FILTER_OPTIONS,
  SET_PRODUCT_FILTERS_IN_QUERY,
  REPLACE_PRODUCTS_IN_QUERY,
  UPDATE_PRODUCT_FILTER_IN_QUERY,
  SET_LARGE_LIMIT,
  SAVE_REPORT_DATA,
} from '@/intelligence/store/actionType';
import { QUERY_BUILDER } from '@/intelligence/store/data/reportTypes';
import { pluralise, dates } from '@sales-i/utils';
import { parseQuery } from '@/intelligence/utils/parseGraphQl';
import { alertBuilderScope, alertsScope, baseUrl as intelligenceBaseUrl } from '@/intelligence/router/urlBits';
import useEnquiries from '@/intelligence/composables/useEnquiries';
import useShared from '@/intelligence/composables/useShared';
import useSystem from '@/shared/composables/useSystem';
import useSavedQueries from '@/intelligence/composables/useSavedQueries';

export const useAlertBuilder = ({ store = useStore(), vroute = useRoute() } = {}) => {
  const { applyDate, getDrillsQueryParams, setDrillsDataByQuery, fetchReportData } = useShared({ store });

  const isEditMode = computed(() => !!vroute.params.queryId);
  const { iframe, isDemo } = useSystem({ store });
  const {
    clearEnquiryFilters, updateSalesDataClause, 
    updateCrmDataClause, updateProductDataClause,
    applyFiltersToQuery, fetchBubbleData,
    applyCrmFilters, applySalesFilters, setCrmFilter, setSalesFilter, setFilters,
  } = useEnquiries({ store });
  const { savedQueryData, getSavedAlertById, getAllSavedAlerts } = useSavedQueries({ store });
  const loading = computed(() => store.state.intelligence.shared.loading);
  const setReportData = (data) => store.commit(`intelligence/shared/${SAVE_REPORT_DATA}`, data);


  const alertBuilderBaseUrl = computed(() => iframe.value ? '/iframe/overview' : intelligenceBaseUrl);
  const alertBuilderModalBaseUrl = computed(() => isEditMode.value 
    ? `${alertBuilderBaseUrl.value}/${alertsScope}/${vroute.params.queryId}/${alertBuilderScope}`
    : `${alertBuilderBaseUrl.value}/${alertsScope}/${alertBuilderScope}`
  );
  
  const customerBubble = ref({});
  const abandonedDrills = ref([]);
  const reportEnabled = ref(false);
  const showReport = ref(false);
  const runCount = ref(1);

  const bubbles = computed(() => store.getters['intelligence/queryBuilder/getBubbles']);
  const crmFilters = computed(() => store.getters['intelligence/queryBuilder/getCrmFilters']);
  const salesFilters = computed(() => store.getters['intelligence/queryBuilder/getSalesFilters']);
  const allProducts = computed(() => store.getters['intelligence/queryBuilder/getAllProducts']);
  const getSalesFilterOptionsFromAlertBuilder = computed(() => store.getters['intelligence/queryBuilder/getSalesFilterOptions']);
 
  const isVarianceOutput = computed(() => store.state.intelligence.queryBuilder.varianceOutput);
  // Product data
  const products = computed(() => store.state.intelligence.queryBuilder.products);
  const buyingProduct = computed(() => products.value.filter((product) => product.status === 'buying' && product.level === 'item_level'));
  const notBuyingProduct = computed(() => products.value.filter((product) => product.status === 'not_buying' && product.level === 'item_level'));
  const buyingProductGroup = computed(() => products.value.filter((product) => product.status === 'buying' && product.level === 'product_group_level'));
  const notBuyingProductGroup = computed(() => products.value.filter((product) => product.status === 'not_buying' && product.level === 'product_group_level'));
  
  const buyingProductCount = computed(() => buyingProduct.value.length === 0 ? 0 :
    buyingProduct.value[0].items.length
  );
  const notBuyingProductCount = computed(() => notBuyingProduct.value.length === 0 ? 0 :
    notBuyingProduct.value[0].items.length
  );
  const  buyingProductGroupCount = computed(() => buyingProductGroup.value.length === 0 ? 0 :
    buyingProductGroup.value[0].items.length
  );
  const notBuyingProductGroupCount = computed(() => notBuyingProductGroup.value.length === 0 ? 0 :
    notBuyingProductGroup.value[0].items.length
  );

  const setShowReport = (value) => {
    showReport.value = value;
  };
  
  const productFilterData = computed(() => products.value
    .map((product) => ({
      id: product.id,
      level: `${pluralise.number(product.items.length, product.level === 'item_level' ? 'product' : 'product group', { includeNumber: true })}`,
      status: product.status === 'buying' ? 'buying' : 'not buying',
      items: product.items,
      operator: product.operator.toUpperCase(),
    }))
  );

  
  // Filters
  const addSalesFilterToQuery = (params) => store.dispatch(`intelligence/queryBuilder/${ADD_SALES_FILTER_TO_QUERY}`, params);
  const addCustomerFilterToQuery = (params) => store.dispatch(`intelligence/queryBuilder/${ADD_CUSTOMER_FILTER_TO_QUERY}`, params);
  const addProductToQuery = (params) => store.dispatch(`intelligence/queryBuilder/${ADD_PRODUCT_TO_QUERY}`, params);
  const clearAllFilters = () => store.dispatch(`intelligence/queryBuilder/${REMOVE_ALL_FILTERS_FROM_QUERY}`);
  // Update/remove the filters
  const updateSalesFilterInQuery = (params) => store.dispatch(`intelligence/queryBuilder/${UPDATE_SALES_FILTER_IN_QUERY}`, params);
  const removeSalesFilterFromQuery = (params) => store.dispatch(`intelligence/queryBuilder/${REMOVE_SALES_FILTER_FROM_QUERY}`, params);
  const setProductFiltersInQuery = (params) => store.dispatch(`intelligence/queryBuilder/${SET_PRODUCT_FILTERS_IN_QUERY}`, params);
  const replaceProductsInQuery = (params) => store.dispatch(`intelligence/queryBuilder/${REPLACE_PRODUCTS_IN_QUERY}`, params);
  const updateProductFilterInQuery = (params) => store.dispatch(`intelligence/queryBuilder/${UPDATE_PRODUCT_FILTER_IN_QUERY}`, params);
  const getSalesFilterOptions = () => store.dispatch(`intelligence/queryBuilder/${GET_SALES_FILTER_OPTIONS}`);
  const setLargeLimit = (params) => store.dispatch(`intelligence/queryBuilder/${SET_LARGE_LIMIT}`, params);

  const setCustomerBubble = (bubble) => {
    customerBubble.value = bubble;
  };

  const drillParams = computed(() => {
    const params = [];
    Object.keys(getDrillsQueryParams.value).forEach(key => {
      const drillId = getDrillsQueryParams.value[key];
      if (!key.includes('values') && !key.includes('1-dim')) {
        const paramIndex = Number(key.charAt(0));
        params[paramIndex] ? params[paramIndex].push(drillId) : (params[paramIndex] = [drillId]);
      }
    });
    return params.filter(el => el);
  });
  
  const query = computed(() => {
    const drillsDataQuery = {
      '1-dim1': customerBubble.value.id,
    };
    let index = 2;
    // Take all the items from the query string and append them
    for (let n in vroute.query) {
      if (n.indexOf('values') !== -1) {
        drillsDataQuery[n] = vroute.query[n];
      }
    }
    if (bubbles.value.length > 0) {
      let n = 0;
      for (n; n < bubbles.value.length; n++) {
        if (bubbles.value[n].id !== customerBubble.value.id) {
          drillsDataQuery[`1-dim${index}`] = bubbles.value[n].id;
        }
        index++;
      }
    }
    let x = 0;
    index = 1;
    let len = drillParams.value.flat();
    for (x; x < len.length; x++) {
      drillsDataQuery[`2-dim${index}`] = drillParams.value.flat()[x];
      index++;
    }
    len = abandonedDrills.value.flat();
    for (x; x < len.length; x++) {
      drillsDataQuery[`2-dim${index}`] = abandonedDrills.value.flat()[x];
      index++;
    }
    return drillsDataQuery;
  });

  const getProdGroupLevelIds = (item) => ([
    item.prod_group_level_1 ? item.prod_group_level_1 : '',
    item.prod_group_level_2 ? `-${item.prod_group_level_2}` : '',
    item.prod_group_level_3 ? `-${item.prod_group_level_3}` : '',
    item.prod_group_level_4 ? `-${item.prod_group_level_4}` : '',
    item.prod_group_level_5 ? `-${item.prod_group_level_5}` : '',
  ].join(''));
  
  function addItemsToFilter(allProducts) {
    let filterCount = 1;
    let x = 0;
    for (x; x < allProducts.value.length; x++) {
      const _ref = allProducts.value[x];
      const productCodes = _ref.items.map(o => {
        if (_ref.level === 'item_level') {
          return {
            id: o.product_code,
          };
        } else {
          return {
            id: getProdGroupLevelIds(o),
          };
        }
      });
      const filter = {
        id: filterCount,
        type: _ref.level === 'item_level' ? 'product' : 'product_group',
        filter_type: _ref.status === 'buying' ? 'INCLUDE' : 'EXCLUDE',
        clause: _ref.operator.toUpperCase(),
        selected_values: productCodes,
      };
      // Set the filter
      setFilters(filter);
      // Increment the filter count for the next filter
      filterCount++;
    }
  }

  const goToReport = async (options = {}) => {
    if (Object.keys(customerBubble.value).length === 0) {
      return;
    }
    clearEnquiryFilters();
    if (isDemo.value) {
      setLargeLimit(2000);
    } else {
      setLargeLimit(20000);
    }
    reportEnabled.value = false;
    // If there are any product items, run the addProductItemsToFilterInclude function
    if (allProducts.value.length > 0) {
      addItemsToFilter(allProducts);
    }
    if (Object.keys(crmFilters.value).length > 0) {
      setCrmFilter(crmFilters.value);
    }
    applyCrmFilters();
    if (Object.keys(salesFilters.value).length > 0) {
      setSalesFilter(salesFilters.value);
    }
    // Only apply the sales filter if all of the values are set
    if (salesFilters.value.every(filter => filter.field && filter.operator && filter.value !== '')) {
      applySalesFilters();
    }
    // Check the bubble query and apply all
    const drillsDataQuery = query.value;
    setDrillsDataByQuery(drillsDataQuery);
    applyFiltersToQuery();
    showReport.value = true;
    // Refresh the data

    const reportId = await fetchReportData({
      reportType: QUERY_BUILDER,
    });

    if ('incrementRunCount' in options && options?.incrementRunCount !== false) {
      runCount.value = runCount.value + 1;
    }

    return reportId;
  };

  function getNextId(section) {
    const existingIds = section.map(filter => filter.id);
    if (existingIds.length === 0) {
      return 1;
    }
    const maxId = Math.max(...existingIds);
    return maxId + 1;
  }

  const queryArgFilters = computed(() => {
    // Get the query_arguments and split the string at the |||| and ~~~~ delimiters to get the raw query as well as the filters and dataClauses
    if (savedQueryData.value.results?.length > 0) {
      // Get the first result from the savedQuery data (there should only be one result in the array)
      // Split the query_arguments string at the |||| delimiter
      const parts = savedQueryData.value?.results[0].query_arguments.split('||||');
      // The second part contains filters and data clauses
      const filtersAndData = parts[1].split('~~~~');
      
      // The first object is the filters
      const filters = filtersAndData[0];
  
      // The second object is the data clauses
      const dataClauses = filtersAndData[1];

      // Fetch the dates from the query
      const query = parseQuery(`{${parts[0]}}`);

      const dates = query?.enquiry?.date_range;

      const drills = query?.enquiry?.drills;
  
      // Return the filters and dataClauses as an object
      return {
        drills,
        dates,
        filters,
        dataClauses,
      };
    } else {
      // Handle the case where results is undefined or empty
      return {
        dates: {
          from: null,
          to: null,
        },
        drills: [],
        filters: '',
        dataClauses: '',
      };
    }
  });

  async function addQueryArgsToStore() {
    // Get each filter array into one object. Then, push these arrays to the store
    const dates = queryArgFilters.value.dates;
    const queryArgs = JSON.parse(queryArgFilters.value.filters);
    const drills = queryArgFilters.value.drills;
    const dataClauses = JSON.parse(queryArgFilters.value.dataClauses);

    setCustomerBubble({
      id: drills[0].dimensions[0]?.id,
    });

    // Add the filters to the store
    await applyDate({
      from: dates.from,
      to: dates.to,
    });
    
    queryArgs.salesFilters.forEach(filter => addSalesFilterToQuery(filter));
    queryArgs.crmFilters.forEach(filter => addCustomerFilterToQuery(filter));
    queryArgs.productFilters.forEach(filter => addProductToQuery(filter));

    // Add the data clauses to the store even though they're not objects
    updateSalesDataClause(dataClauses.salesDataClause);
    updateCrmDataClause(dataClauses.crmDataClause);
    updateProductDataClause(dataClauses.productDataClause);

    return Promise.resolve(queryArgs);
  }

  const formatDateForAlertBuilder = (date) => date ? dates.getDateTime(date)?.toISODate()+'T00:00:00.000+0000' : '';
  
  return {
    alertBuilderBaseUrl,
    alertBuilderModalBaseUrl,
    setCustomerBubble,
    abandonedDrills,
    reportEnabled,
    showReport,
    bubbles,
    getDrillsQueryParams,
    crmFilters,
    salesFilters,
    allProducts,
    isEditMode,
    isVarianceOutput,
    clearEnquiryFilters,
    fetchBubbleData,
    setDrillsDataByQuery,
    applyFilters: applyFiltersToQuery,
    applyCrmFilters,
    applySalesFilters,
    setCrmFilter,
    setSalesFilter,
    clearAllFilters,
    setFilters,
    getSavedAlertById,
    getAllSavedAlerts,
    drillParams,
    goToReport,
    getNextId,
    addQueryArgsToStore,
    runCount,
    buyingProductCount,
    notBuyingProductCount,
    buyingProductGroupCount,
    notBuyingProductGroupCount,
    productFilterData,
    updateSalesFilterInQuery,
    removeSalesFilterFromQuery,
    addSalesFilterToQuery,
    addCustomerFilterToQuery,
    addProductToQuery,
    setShowReport,
    query,
    getSalesFilterOptions,
    formatDateForAlertBuilder,
    updateProductDataClause,
    setProductFiltersInQuery,
    replaceProductsInQuery,
    updateProductFilterInQuery,
    getSalesFilterOptionsFromAlertBuilder,
    loading,
    setReportData,
  };
};
