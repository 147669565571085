<template>
  <div
    class="variance"
    :class="{ 'iframe-height': iframe }"
  >
    <BufferImage
      v-if="!isReportEnabled"
      color="black"
      float="center"
      class="loading-spinner"
    />
    <NoResults v-if="!rowCount && isReportEnabled" />
    <section
      v-else
      class="data-section"
    >
      <VarianceChart
        v-if="isReportEnabled && isChartEnabled"
        :key="enquiryData.rows.length"
        :chart-data="enquiryData"
        :depth="featureTreemapDepth"
        class="my-4"
      />
      <VarianceTable
        v-if="isReportEnabled"
        :table-parameters="tableParameters"
        :enquiry-data="enquiryData"
        :active-columns="activeColumns"
        :is-percent-value-active="isPercentValueActive"
        :is-initial-load="isInitialLoad"
        @handle-enquiry-drill="handleEnquiryDrill"
        @load-table="loadTable"
        @load-more-data="loadMoreData"
        @update:columns="emit('update:columns', $event)"
      />
    </section>
    <ReportFooter
      v-if="isReportFooterVisible"
      :dimensions="dimensions"
      :report-type="reportType"
      :sub-title="title"
      :active-columns-data="activeColumnsData"
      :query="query"
    />
  </div>
</template>

<script setup>
import { ref, computed, watch, onBeforeMount, onMounted } from 'vue';
import { useStore } from 'vuex';
import { BufferImage } from '@sales-i/dsv3';
import ReportFooter from '@/intelligence/components/ReportFooter/ReportFooter';
import VarianceTable from '@/intelligence/components/Variance/VarianceTable';
import VarianceChart from '@/intelligence/components/Variance/VarianceChart';
import NoResults from '@/intelligence/components/Shared/NoResults';
import { VARIANCE } from '@/intelligence/store/data/reportTypes';
import { intelligence_enquiries } from '@/shared/store/data/policies';
import runIfPermittedOrReject from '@/shared/store/utils/runIfPermittedOrReject';
import isRolldate from '@/intelligence/store/utils/isRolldate';
import useFeatures from '@/shared/composables/useFeatures';
import { REQUEST_ENTITY_VARIANCE_INITIAL } from '@/intelligence/store/data/apiInput';
import useSystem from '@/shared/composables/useSystem';
import usePermissions from '@/shared/composables/usePermissions';
import useCalendar from '@/intelligence/composables/useCalendar';
import useShared from '@/intelligence/composables/useShared';
import useEnquiries from '@/intelligence/composables/useEnquiries';
import { useRoute } from 'vue-router';

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  isReportEnabled: {
    type: Boolean,
    default: false,
  },
  isChartEnabled: {
    type: Boolean,
    default: false,
  },
  dateParams: {
    type: Object,
    required: true,
  },
  querySubstitute: {
    type: Object,
    default: () => ({}),
  },
  activeColumns: {
    type: Array,
    default: () => ([]),
  },
  isPercentValueActive: {
    type: Boolean,
    default: false,
  }
});

const emit = defineEmits(['handleEnquiryDrill', 'loaded', 'loading', 'update:columns']);

const store = useStore();
const vroute = useRoute();

const tableParameters = ref(null);
const reportType = ref(VARIANCE);
const lazyLoadingData = ref([]);
const pageSize = ref(100);
const varianceId = ref(0);
const activeColumnsData = ref([]);
const isInitialLoad = ref(true);

const { permissions } = usePermissions({ store });
const { iframe } = useSystem({ store });
const { fetchedDates, fetchDates } = useCalendar({ store });
const { getResponseParameters, getReportData, getInputData, 
  applyLimit, applyOffset, fetchReportData, applyDate } = useShared({ store });
const { setSortingData } = useEnquiries({ store });

const query = computed(() => iframe.value ? props.querySubstitute : vroute.query);

const inputData = computed(() => getInputData(reportType.value));
const rowCount = computed(() => enquiryData.value && enquiryData.value.row_count);
const dimensions = computed(() => enquiryData.value && enquiryData.value.headings.dimensions);

const enquiryData = computed(() => {
  if (!varianceId.value) return {};
  const reportData = { ...getReportData(varianceId.value) } || {};
  if (lazyLoadingData.value.length && reportData) {
    for (const iteration of lazyLoadingData.value) {
      const { row_count, rows } = iteration;
      reportData.row_count += row_count;
      reportData.rows = reportData.rows.concat(rows);
    }
  }
  return reportData;
});

const isReportFooterVisible = computed(() => props.isReportEnabled && Object.keys(enquiryData.value).length && enquiryData.value.rows.length && !iframe.value);
const featureTreemapDepth = computed(() => parseInt(useFeatures().getFeatureValue('feat_treemap_depth') || '2', 10));

watch(() => props.activeColumns, (newColumns) => {
  activeColumnsData.value = (newColumns || []).map(column => column.toUpperCase());
}, { immediate: true });

onBeforeMount(async () => {
  applyLimit(pageSize.value);
  const { defaultSortHeader, defaultSortOption } = inputData.value;
  const { sort } = query.value;
  await loadTable({
    sortHeader: sort ? sort.split(':')[0] : defaultSortHeader,
    sortOption: sort ? sort.split(':')[1] : defaultSortOption,
    activeColumns: []
  }, false);
});

onMounted(() => {
  isInitialLoad.value = false;
});

const loadTable = async (payload, completeRefresh = false) => {
  await runIfPermittedOrReject(permissions.value, { name: intelligence_enquiries }, async () => {
    emit('loading');
    const sortingData = [payload?.sortHeader, payload?.sortOption];

    if (Array.isArray(query.value.activeColumns) || query.value.sort) {
      isInitialLoad.value = false;
    }

    const customResponse = isInitialLoad.value ? getResponseParameters(REQUEST_ENTITY_VARIANCE_INITIAL) : null;
    setSortingData(sortingData);
    await setDateParams();
    lazyLoadingData.value = [];
    varianceId.value = await fetchReportData({ reportType: reportType.value, completeRefresh, customResponse });
    tableParameters.value = payload;
    emit('loaded');
  });
};

const loadMoreData = async () => {
  const lazyLoadId = await fetchReportData({ reportType: reportType.value });
  lazyLoadingData.value.push(getReportData(lazyLoadId));
};

const handleEnquiryDrill = (payload) => {
  emit('handleEnquiryDrill', payload);
};

const setDateParams = async () => {
  const { date_from, date_to, date_from2, date_to2 } = props.dateParams;

  if (isRolldate(props.dateParams)) {
    await fetchDates({ date_from, date_to, date_from2, date_to2 });
    applyDate(fetchedDates.value);
  } else {
    applyDate({ date_from, date_to, date_from2, date_to2 });
  }

  applyOffset(0);
  lazyLoadingData.value = [];
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.variance {
  width: 100%;
  margin: 0 auto auto;
  border-radius: var(--border-radius-1);
  position: relative;
  background: var(--colour-utility-white);
  box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-blur) var(--shadow-spread) var(--shadow-colour);
  display: flex;
  flex-direction: column;
  min-height: 580px;

  &.flex-space {
    justify-content: space-between;

    .data-section {
      flex: auto;
      display: block;
    }
  }

  .data-section {
    flex: 1;
    display: table;
    position: static;
    overflow: hidden;
    margin-bottom: var(--spacing-10);

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      position: relative;
      margin-bottom: 0;
    }
  }

  &.iframe-height {
    min-height: 510px;
  }
}

.loading-spinner {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
}

.sort-button {
  position: absolute;
  right: var(--spacing-half);
  top: calc(100% + var(--spacing-half));

  &.asc {
    transform: rotate(180deg);
  }
}
</style>
