import CustomerView from '@/crm/views/Customers/Customer.vue';
import CustomerDetailsRoute from './CustomerDetails';
import CustomerContactsRoute from './CustomerContacts';
import CustomerOverviewRoute from './CustomerOverview';
import CustomerCustomSectionsRoute from './CustomerCustomSections';
import FilesRoute from '@/crm/router/Files';
import CustomerPostsRoute from './CustomerPosts';
import CustomerInsightsRoute from './CustomerInsights';
import CustomersDashboardRoute from './CustomersDashboard';
import CustomerProductGroupGaps from './CustomerProductGroupGaps';
import CustomerInteractionsHistory from './CustomerInteractionsHistory';
import CustomerSavedAlerts from './CustomerSavedAlerts';
import CustomerOpportunities from './CustomerOpportunities';
import { baseUrl, customersArea as area } from '@/crm/router/urlBits';

export default [
  CustomersDashboardRoute,
  {
    path: `${baseUrl}/${area}/:id(\\d+)`,
    component: CustomerView,
    children: [
      CustomerDetailsRoute,
      CustomerContactsRoute,
      CustomerCustomSectionsRoute,
      CustomerPostsRoute,
      CustomerOverviewRoute,
      CustomerInsightsRoute,
      CustomerProductGroupGaps,
      CustomerInteractionsHistory,
      CustomerSavedAlerts,
      CustomerOpportunities,
      ...FilesRoute,
    ],
  }
];
