<template>
  <form
    @submit.prevent="handleSubmit"
  >
    <label :for="fieldId">
      {{ label }}
    </label>
    <div class="product-search">
      <IconBase
        icon-name="search"
        icon-color="var(--colour-utility-black)"
        class="icon"
        height="32"
        width="32"
        @click="focus"
      />

      <input
        :id="fieldId"
        ref="searchinput"
        v-model="searchInputRaw"
        name="search"
        type="text"
        class="search-input"
        :aria-label="t('Search for products or product groups')"
        :placeholder="placeholder"
        @keyup.enter="handleKeyPress"
      >
      
      <IconButton
        icon-name="close-line"
        purpose="reversed"
        class="close"
        tabindex="0"
        variant="round"
        @click.prevent="clearSearch"
      />
    </div>
  </form>

  <BufferImage
    v-show="(crmProducts.loading || loading) && searchInputRaw.length"
    :size="40"
    float="center"
  />

  <template v-if="localProductData.length">
    <div class="product-listing">
      <ul
        ref="listContainer"
        class="rendered-list"
      >
        <ListItem
          v-for="item in localProductData"
          :key="item.product_code"
          :item="item"
          :status="status"
          :product-description="productDescription"
          @on-change="toggleSelection(item)"
        >
          {{ productDescription[localProductData.indexOf(item)] }}
        </ListItem> 
      </ul>
      <div class="actions">
        <CustomButton
          :disabled="!selectedProducts.length"
          purpose="action"
          small
          @click="saveSelection"
        >
          {{ t('Continue') }}
        </CustomButton>
      </div>
    </div>
  </template>

  <ProductDataResults
    :products="productList"
    :status="status"
    @delete-product="deleteProductFromStore"
  />
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { debounce, t } from '@sales-i/utils';
import { BufferImage } from '@sales-i/dsv3';
import { useStore } from 'vuex';
import {
  CLEAR_PRODUCT_GROUPS,
  GET_CRM_PRODUCTS,
  GET_PRODUCT_GROUPS
} from '@/admin/store/actionType';
import { CustomButton, IconBase, IconButton } from '@sales-i/dsv3';
import ListItem from '@/intelligence/components/AlertBuilder/ListItem.vue';
import ProductDataResults from '@/intelligence/components/AlertBuilder/AlertWizard/ProductDataResults.vue';

// Constants
const searchInputRaw = ref('');
const localProductData = ref([]);
const selectedProducts = ref([]);
const listContainer = ref(null);
const limit = 50;
const searchMode = ref(props.selectedValue);

const props = defineProps({
  fieldId: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  status: {
    type: String,
    default: '',
  },
  selectedValue: {
    type: String,
    default: '',
  },
  filter: {
    type: Object,
    default: () => ({}),
  },
});

// Store
const store = useStore();
const crmProducts = computed(() => store.state.admin.associations.crmProducts);
const loading = computed(() => store.state.admin.associations.products.loading);
const productGroups = computed(() => store.state.admin.associations.productGroups);
const getAllProducts = computed(() => store.getters['intelligence/queryBuilder/getAllProducts']);

// Create a variable called productList. It will look at the selectedValue prop and if it's 'product_name' return getBuyingProducts and getNotBuyingProducts
const productList = computed(() => props.filter.items);

// Get data
const getProducts = (params) => store.dispatch(`admin/associations/${GET_CRM_PRODUCTS}`, params);
const getProductGroups = (params) => store.dispatch(`admin/associations/${GET_PRODUCT_GROUPS}`, params);

// Clear data
const clearProductGroups = () => store.dispatch(`admin/associations/${CLEAR_PRODUCT_GROUPS}`);

const clearSearch = () => {
  selectedProducts.value = [];
  localProductData.value = [];  // Clear the local data
  searchInputRaw.value = '';    // Clear the search input
};

// Set the placeholder for the search input
const placeholder = computed(() => {
  if (props.selectedValue === 'product_name' || props.selectedValue === 'item_level') {
    return t('Search products');
  } else {
    return t('Search product groups');
  }
});

const handleSubmit = () => {
  // Handle form submission here
  handleSearch();
};

const handleKeyPress = (event) => {
  if (event.key === 'Enter') {
    handleSubmit();
  }
};

// Create an array of with the all values found in the 'items' array across all objects in getAllProducts
const allSelectedProducts = computed(() => getAllProducts.value.reduce((acc, item) => {
  return [...acc, ...item.items];
}, []));

// Handle search
const handleSearch = async() => {
  // If the user submits the form with an empty search input, return all products. Make sure that if the user clicks the close button that the search input is cleared
  if (searchInputRaw.value === '') {
    // Return ALL products if the search input is empty
    localProductData.value = getAllProducts.value.map((product) => ({
      ...product,
      checked: doesProductExistInList(product.id) !== -1,
      disabled: allSelectedProducts.value.some((p) => p.id === product.id), 
    }));
  }
  
  localProductData.value = [];
  clearProductGroups();

  if (props.selectedValue === 'item_level') {
    searchMode.value = 'product_name';
  }

  switch (searchMode.value) {
  case 'product_name':
  case 'product_code':
    await getProducts({ q: `${searchMode.value}:${searchInputRaw.value}`, });
    // Add a checked value to localProductData
    localProductData.value = crmProducts.value.data.map((product) => ({
      ...product,
      checked: doesProductExistInList(product.id) !== -1,
      disabled: allSelectedProducts.value.some((p) => p.id === product.id),
    }));
    break;
  default:
    await getProductGroups({ q: `${props.selectedValue}:${searchInputRaw.value}`, });
    localProductData.value = productGroups.value.data.map((product) => ({
      ...product,
      checked: doesProductExistInList(product.id) !== -1,
      // If an item exists already in one list then set it to disabled
      disabled: allSelectedProducts.value.some((p) => p.id === product.id),
    }));
  }
};

const doesProductExistInList = (id) => {
  let idx = -1;
  let x = 0;
  let xx = 0;
  for (x; x < getAllProducts.value.length; x++) {
    const _ref = getAllProducts.value[x];
    for (xx; xx < _ref.items.length; xx++) {
      if (_ref.items[xx].id === id) {
        idx = x;
      }
    }
  }
  return idx;
};

const getProdGroupLevelIds = (item) => {
  return [
    'pg-',
    item.prod_group_level_1 ? item.prod_group_level_1 : '',
    item.prod_group_level_2 ? `-${item.prod_group_level_2}` : '',
    item.prod_group_level_3 ? `-${item.prod_group_level_3}` : '',
    item.prod_group_level_4 ? `-${item.prod_group_level_4}` : '',
    item.prod_group_level_5 ? `-${item.prod_group_level_5}` : '',
  ].join('');
};

const getProdGroupDescription = (item) => {
  return [
    item.prod_group_level_1_desc ? item.prod_group_level_1_desc : '',
    item.prod_group_level_2_desc ? `-${item.prod_group_level_2_desc}` : '',
    item.prod_group_level_3_desc ? `-${item.prod_group_level_3_desc}` : '',
    item.prod_group_level_4_desc ? `-${item.prod_group_level_4_desc}` : '',
    item.prod_group_level_5_desc ? `-${item.prod_group_level_5_desc}` : '',
  ].join('');
};

// Create a computed property to loop through each item in localProductData and build the product description
const productDescription = computed(() => localProductData.value.map((item) => 
  (props.selectedValue === 'product_name' || props.selectedValue === 'item_level') 
    ? `${item.product_code} - ${item.product_name}`
    : `${getProdGroupLevelIds(item).replace('pg-', '')} - ${getProdGroupDescription(item)}`
));

const toggleSelection = (product) => {
  const productIndex = selectedProducts.value.findIndex((p) => p.id === product.id);
  // Toggle the checked value in localProductData
  const index = localProductData.value.findIndex((p) => p.id === product.id);
  localProductData.value[index].checked = !localProductData.value[index].checked;
  if (productIndex !== -1) {
    selectedProducts.value.splice(productIndex, 1);
  } else {
    selectedProducts.value.push(product);
  }
};

const emit = defineEmits(['addProduct', 'deleteProduct']);

function saveSelection() {
  selectedProducts.value.forEach((product) => {
    product.status = props.status;
    emit('addProduct', product);
  });

  // Clear local data and search input
  selectedProducts.value = [];
  searchInputRaw.value = '';
}

function deleteProductFromStore(product) {
  emit('deleteProduct', product);
}

watch(
  () => listContainer.value,
  () => {
    if (listContainer.value) {
      listContainer.value.addEventListener('scroll', handleScroll);
    }
  }
);

watch(
  () => searchInputRaw.value,
  debounce(function(newVal) {
    // If searchInputRaw is empty then do nothing. Otherwise call handleSearch
    if (newVal) {
      handleSearch();
    } else {
      clearSearch();
    }
  })
);

watch(
  () => props.selectedValue,
  () => {
    handleSearch();
  },
);

const handleScroll = debounce(function() {
  const container = listContainer.value;
  if (container.scrollHeight - container.scrollTop - container.clientHeight < limit) {
    // Load the next 50 results from the API
    getMoreProducts();
  }
}, 200);

const getMoreProducts = async () => {
  if (props.selectedValue === 'item_level') {
    searchMode.value = 'product_name';
  }
  try {
    const params = {
      q: `${searchMode.value}:${searchInputRaw.value}`,
      limit: 50,
      offset: localProductData.value.length
    };
    await getProducts(params);
    const dataSource = props.selectedValue === 'item_level' ? crmProducts.value : productGroups.value;
    appendFetchedProducts(dataSource);
  } catch (error) {
    console.error('Error fetching more products:', error);
  }
};

const appendFetchedProducts = (dataSource) => {
  if (!dataSource || !dataSource.data) {
    return;
  }
  localProductData.value = [
    ...localProductData.value, 
    ...dataSource.data.map((product) => ({
      ...product,
      checked: doesProductExistInList(product.id) !== -1,
      disabled: productList.value.some((p) => p.id === product.id),
    }))];
};

</script>

<style lang="scss" scoped>
label {
  font-size: var(--font-size-4);
  font-weight: var(--font-weight-semibold);
  margin-block-end: 0;
}

.product-search {
  align-items: center;
  background-color: var(--colour-panel-g-0);
  border-radius: 900px;
  border: 2px solid transparent;
  box-shadow: inset 0 0 0 1px var(--border-utility-base);
  display: inline-flex;
  flex-flow: row nowrap;
  font-weight: var(--font-weight-medium);
  min-width: calc(var(--spacing-12) * 2);
  padding: var(--spacing-half) var(--spacing-1);
  position: relative;
  overflow: hidden;
  width: 100%;

  &:focus-within:not([disabled]) {
    border: 2px solid var(--colour-utility-checked);
    box-shadow: 0px 0px 0px var(--spacing-half) var(--colour-utility-focus);
    outline: 2px solid transparent;
  }
  .close {
    cursor: pointer;
  }
}
.close {
  height: 40px;
  width: 40px;
  position: absolute;
  top: 0;
  right: var(--spacing-1);
  padding: 0;
  margin: 0;
  &.wide {
    right: 245px;
  }
}

.search-input,
.search-input:focus {
  background-color: transparent;
  border: none;
  box-shadow: none;
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  outline: none;
  padding-left: var(--spacing-1);
}

.product-listing {
  display: flex;
  flex-direction: column;
  max-height: 320px;
}

  .rendered-list {
    overflow-y: scroll;
    flex: 10;
  }

  .actions {
    background-color: var(--colour-panel-action);
    display: flex;
    flex: 1;
    justify-content: center;
    padding: var(--spacing-2);
    width: 100%;
  }
</style>