import { computed } from 'vue';
import { useStore } from 'vuex';
import { GET_ALL, GET_BY_ID } from '@/shared/store/actionType';

export const useCommunities = ({ store = useStore() } = {}) => {
  const communities = computed(() => store.state.intelligence.communities.all.data);
  const communitiesOptions = computed(() => store.state.intelligence.communities.all.data
    .map(c => ({ id: c.community_code, name: `${c.community_name} (${c.community_item_count})`}))
  );
  const selectedCommunity = computed(() => store.state.intelligence.communities.selected.data);
  const getCommunities = (params) => store.dispatch(`intelligence/communities/${GET_ALL}`, params);
  const getCommunityById = (params) => store.dispatch(`intelligence/communities/${GET_BY_ID}`, params);
  
  return {
    communities,
    communitiesOptions,
    selectedCommunity,
    getCommunities,
    getCommunityById,
  };
};