<template>
  <div
    v-if="inputData && inputData.tableComponent" 
    class="snapshot-report"
    :class="[{ expanded }, expandedDataView, inputData.tableComponent.toLowerCase()]"
  >
    <BufferImage
      v-if="!isReportLoaded"
      color="black"
      float="center"
      class="loading-spinner"
    />
    <NoResults v-if="!isReportDataAvailable && isReportLoaded" />
    <template v-if="isReportDataAvailable">
      <section class="header-section">
        <h3>{{ inputData.title }}</h3>
        <DateSubHeader 
          :request-parameters="requestParameters"
          :date-range-number="inputData.dateRangeNumber"
        />
        <div class="container-wrapper">
          <FullScreenButton
            enable-switch
            :visible-data-type="expandedDataView"
            @full-screen-data-type-change="switchDataView"
          />
        </div>
      </section>
      <section class="chart-section">
        <component
          :is="componentsMap[inputData.chartComponent]"
          :report-id="reportId"
          :report-type="reportType"
        />
      </section>
      <section class="data-section">
        <component
          :is="componentsMap[inputData.tableComponent]"
          v-if="isReportLoaded"
          :report-id="reportId"
          :report-type="reportType"
          :enquiry-data="enquiryData"
          :is-quantity-type="isQuantityReport"
          is-snapshot
          @load-more-data="loadMoreData"
          @save-parameters="saveReportData"
          @apply-new-sort="applyNewSort"
        />
      </section>
      <ReportFooter
        :report-type="reportType"
        report-description="Example description for each snapshot report"
        :report-area="snapshotType"
        :interaction-id="customerObject.interactionId"
      />
    </template>
  </div>
</template>

<script setup>
// TODO - Remove global styles from this component
import { ref, computed, onMounted } from 'vue';
import useShared from '@/intelligence/composables/useShared';
import useTemplateReport from '@/intelligence/composables/useTemplateReport';
import { BufferImage } from '@sales-i/dsv3';
import DateSubHeader from '@/intelligence/components/SnapshotReport/DateSubHeader';
import {
  MONTHLY_PURCHASES_BY_QUANTITY,
  MONTHLY_PURCHASES_BY_VALUE,
  PURCHASES_VS_GAPS_BY_PURCHASE_QUANTITY,
  PURCHASES_VS_GAPS_BY_PURCHASE_VALUE,
  SNAPSHOT_REPORT_LIST,
  VARIANCE_ENQUIRY_BY_PURCHASE_VALUE,
  VARIANCE_ENQUIRY_BY_QUANTITY,
  PRODUCT_GROUP_PURCHASE_ANALYSIS
} from '@/intelligence/store/data/reportTypes';
import { REPORTS_SORT_ASCENDING_KEY, REPORTS_SORT_DESCENDING_KEY } from '@/intelligence/store/data/apiInput';
import TemplateReportTable from '@/intelligence/components/TemplateReport/TemplateReportTable';
import TrendsTable from '@/intelligence/components/Trends/TrendsTable';
import VarianceTable from '@/intelligence/components/Variance/VarianceTable';
import MatrixTable from '@/intelligence/components/Matrix/MatrixTable';
import PieChart from '@/intelligence/components/SnapshotReport/Charts/PieChart';
import MultiBarChart from '@/intelligence/components/SnapshotReport/Charts/MultiBarChart';
import LineBarChart from '@/intelligence/components/SnapshotReport/Charts/LineBarChart';

import FullScreenButton from '@/intelligence/components/Shared/FullScreenButton';
import NoResults from '@/intelligence/components/Shared/NoResults';
import ReportFooter from '@/intelligence/components/ReportFooter/ReportFooter';

const props = defineProps({
  reportType: {
    type: String,
    default: '',
    validator: value => SNAPSHOT_REPORT_LIST.includes(value),
  },
  customerObject: {
    type: Object,
    default: () => ({}),
  },
  snapshotType: {
    type: String,
    default: 'customer',
  },
});

const { expanded, requestParameters, getReportData, getInputData, fetchReportData } = useShared();
const { fetchBubbleIds, saveBubbleData } = useTemplateReport();
const componentsMap = {
  TemplateReportTable,
  TrendsTable,
  VarianceTable,
  MatrixTable,
  PieChart,
  MultiBarChart,
  LineBarChart,
};

const expandedDataView = ref('data');
const isReportLoaded = ref(false);
const reportId = ref(1);
const lazyLoadingData = ref([]);
const currentSortHeader = ref('');
const currentSortOption = ref(REPORTS_SORT_ASCENDING_KEY);

const inputData = computed(() => getInputData(props.reportType));

const enquiryData = computed(() => {
  const reportData = { ...getReportData(reportId.value) } || null;
  if (lazyLoadingData.value.length && reportData && isVarianceBasedReport.value) {
    for (const iteration of lazyLoadingData.value) {
      const { row_count, rows } = iteration;
      reportData.row_count += row_count;
      reportData.rows = reportData.rows.concat(rows);
    }
  }
  return reportData;
});

const isReportDataAvailable = computed(() => {
  const reportData = getReportData(reportId.value) || null;
  switch (props.reportType) {
  case MONTHLY_PURCHASES_BY_VALUE:
  case MONTHLY_PURCHASES_BY_QUANTITY:
    return reportData?.rows?.some(row => row.value_1 !== 0 || row.value_2 !== 0 || row.value_variance !== 0);
  case VARIANCE_ENQUIRY_BY_QUANTITY:
  case VARIANCE_ENQUIRY_BY_PURCHASE_VALUE:
    return reportData?.row_count;
  case PURCHASES_VS_GAPS_BY_PURCHASE_VALUE:
  case PURCHASES_VS_GAPS_BY_PURCHASE_QUANTITY:
    return reportData?.rows?.length;
  default:
    return reportData?.length;
  }
});

const isVarianceBasedReport = computed(() => 
  [VARIANCE_ENQUIRY_BY_QUANTITY, VARIANCE_ENQUIRY_BY_PURCHASE_VALUE].includes(props.reportType));
const isQuantityReport = computed(() => 
  [VARIANCE_ENQUIRY_BY_QUANTITY, MONTHLY_PURCHASES_BY_QUANTITY, PURCHASES_VS_GAPS_BY_PURCHASE_QUANTITY].includes(props.reportType));

const switchDataView = (dataType) => {
  expandedDataView.value = dataType;
};

const loadReport = async (customParams = {}) => {
  if (props.reportType === PRODUCT_GROUP_PURCHASE_ANALYSIS) {
    reportId.value = await fetchReportData({
      reportType: props.reportType,
      customParams: {
        ...customParams,
        snapshotBubbleValueId: [`"${props.customerObject.account_number}"`]
      }
    });
  } else if (props.reportType === MONTHLY_PURCHASES_BY_VALUE) {
    reportId.value = await fetchReportData({
      reportType: props.reportType,
      customParams: {
        ...customParams,
        snapshotBubbleValue: [`"${props.customerObject.name}"`]
      }
    });
  } else {
    reportId.value = await fetchReportData({
      reportType: props.reportType,
      customParams: {
        ...customParams,
      },
    });
  }
};

const loadMoreData = async () => {
  const lazyLoadId = await fetchReportData({ reportType: props.reportType });
  lazyLoadingData.value.push(getReportData(lazyLoadId));
};

const applyNewSort = async (headerKey) => {
  if (currentSortHeader.value === headerKey) {
    currentSortOption.value = (currentSortOption.value === REPORTS_SORT_DESCENDING_KEY ? REPORTS_SORT_ASCENDING_KEY : REPORTS_SORT_DESCENDING_KEY);
  } else {
    currentSortHeader.value = headerKey;
    currentSortOption.value = REPORTS_SORT_DESCENDING_KEY;
  }
  isReportLoaded.value = false;
  reportId.value = await loadReport({
    currentSortHeader: headerKey,
    currentSortOption: currentSortOption.value,
  });
  isReportLoaded.value = true;
};

onMounted(async () => {
  if (!requestParameters.value.productBubbleId || !requestParameters.value.transactionBubbleId) 
    await fetchBubbleIds();
  saveBubbleData(props.snapshotType);
  await loadReport();
  isReportLoaded.value = true;
});
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.snapshot-report {
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  border-radius: var(--border-radius-1);
  position: relative;
  box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-blur) var(--shadow-spread) var(--shadow-colour);
  display: flex;
  flex-direction: column;
  height: fit-content;
  min-height: 350px;

  &.variancetable {
    .data-section {
      display: table;
    }
  }

  .data-section {
    &.matrixtable {
      display: flex;
      overflow: hidden;
      flex: auto;
    }

    &.trendstable,
    &.templatereporttable {
      display: block;
      overflow: auto;
    }
  }
}

.header-section {
  position: relative;
  margin-bottom: var(--spacing-5);

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    margin-bottom: var(--spacing-3);
    margin-top: var(--spacing-8);
  }

  h3 {
    text-align: center;
    padding: var(--spacing-4) var(--spacing-6);
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-3);
    max-width: 560px;
    margin: auto;
    padding-bottom: 0;

    @media #{map-get($display-breakpoints, 'md-and-down')} {
      font-size: var(--font-size-3);
      padding: var(--spacing-4);
    }

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      font-size: var(--font-size-4);
      padding: var(--spacing-2);
      padding-bottom: 0;
      max-width: 400px;
    }
  }
}

.export-dropdown-wrapper {
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    top: -48px;
  }
}

.loading-spinner {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
}

.container-wrapper {
  position: absolute;
  display: flex;
  gap: var(--spacing-1);
  top: var(--spacing-5);
  right: var(--spacing-2);

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    top: var(--spacing-3);
    right: var(--spacing-3);
  }
}
</style>
<style lang="scss">
@import '@/shared/assets/scss/_variables';
.snapshot-report {
  .filters {
    display: flex;
    margin-bottom: var(--spacing-3);
  }

  &.expanded {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: white;
    max-width: 100%;
    height: 100%;

    &.data {
      .chart-section {
        display: none;
      }
    }

    &.graph {
      .data-section {
        display: none;
      }
      .chart-section {
        min-height: unset;
        height: calc(100vh - 176px);
        overflow: auto;
        margin: 0;
      }
    }

    .data-section {
      height: calc(100% - 162px);
      overflow: hidden;
    }

    .table-wrapper {
      max-height: 100%;
    }

    &.variancetable {
      .header-section {
        margin-bottom: var(--spacing-1);
      }

      .data-section,
      .table-wrapper {
        height: calc(100vh - 178px);
      }

      .table-wrapper {
        .rows {
          height: 100%;
          max-height: unset;
        }
      }
    }

    &.matrixtable {
      .data-section {
        height: calc(100% - 162px);
        overflow: hidden;
      }
    }

    .export-dropdown-wrapper {
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        display: none;
      }
    }
  }
}
</style>
