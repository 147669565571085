<!-- eslint-disable vuejs-accessibility/no-static-element-interactions -->
<template>
  <div
    :class="['breadcrumb', { 'not-first': !isFirstChild }]"
    @mouseenter="toggleTooltip(props.dim)"
    @mouseleave="toggleTooltip(props.dim, false)"
    @focusin="toggleTooltip(props.dim)"
    @focusout="toggleTooltip(props.dim, false)"
  >
    <slot />
  </div>
  <div
    v-if="props.drillValues"
    ref="tooltip"
    :class="{
      tooltip: true,
      first: isFirstChild
    }"
  >
    <p
      v-for="value in props.drillValues"
      :key="value"
    >
      {{ value }}
    </p>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import breakpoints from '@/shared/utils/breakpoints';
import { useMq } from 'vue3-mq';

const mq = useMq();

const props = defineProps({
  dim: {
    type: String,
    default: '',
  },
  drillValues: {
    type: Array,
    default: () => [],
  },
  isFirstChild: {
    type: Boolean,
    default: false,
  }
});

const tooltip = ref(null);

const isSmallScreen = computed(() => breakpoints.smAndDown.includes(mq.current));

function toggleTooltip(param, visibility = true) {
  const tooltipElement = tooltip.value;
  if (!param || isSmallScreen.value || !tooltipElement) return;
  tooltipElement.style.display = visibility ? 'block' : 'none';
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.breadcrumb {
  position: relative;
}
.tooltip {
  display: none;
  position: absolute;
  color: var(--colour-utility-white);
  background: var(--colour-utility-black);
  padding: 12px;
  font-size: var(--font-size-small);
  border-radius: var(--border-radius-1);
  z-index: 20;
  top: calc(var(--spacing-16) + var(--spacing-3));

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    top: calc(var(--spacing-16) + var(--spacing-5));
  }
  transform: translate(-50%, var(--spacing-half));
}
.tooltip.first {
  transform: translate(-25%, var(--spacing-half));
}
</style>