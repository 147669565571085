<template>
  <CustomModal
    show-modal
    @close-modal="close"
  >
    <div class="container modal-content">
      <Map
        :markers="markers"
        :zoom="5"
        :func-info="getMapInfoHtml"
        @marker-click="handleMarkerClick"
        @marker-reset="handleMarkerReset"
      />
    </div>
  </CustomModal>
</template>

<script setup>
import { computed, ref } from 'vue';
import { baseUrl as crmBaseUrl, customersArea } from '@/crm/router/urlBits';
import { address as addr, t } from '@sales-i/utils';
import { useStore } from 'vuex';
import { QUERY_BUILDER } from '@/intelligence/store/data/reportTypes';

import Map from '@/shared/components/Map/Map.vue';
import CustomModal from '@sales-i/dsv3/src/components/CustomModal/CustomModal.vue';

const store = useStore();

const props = defineProps({
  reportType: {
    type: String,
    default: '',
  },
  customers: {
    type: Array,
    default: () => []
  }
});

const emit = defineEmits(['close']);

const addresses = computed(() => store.state.crm.customers.addresses.data);
const dataRows = computed(() => store.state.intelligence.shared.fetchedData);

const processedReportCustomers = computed(() => {
  if (Object.keys(dataRows.value).length === 0) return [];

  const list = {};

  let n;
  for (n in dataRows.value) {
    const _ref = dataRows.value[n];
    if (!_ref.data || !_ref.data.rows) continue;

    let p = 0;
    for (p; p <_ref.data.rows.length; p++) {
      if ('dimensions' in _ref.data.rows[p] && _ref.data.rows[p].dimensions.length > 0) {
        list[parseInt(_ref.data.rows[p].dimensions[0].entity_id, 10)] = {
          id: parseInt(_ref.data.rows[p].dimensions[0].entity_id, 10),
          title: _ref.data.rows[p].dimensions[0].dimensions[0],
          account_number: _ref.data.rows[p].dimensions[0].dimensions[1],
        };
      }
    }
  }
  return Object.values(list);
});

const markers = computed(() => {
  if (props.reportType !== QUERY_BUILDER) {
    return props.customers.map(customer => {
      const address = addresses.value.find(a => a.customer_id == customer.customer_id);
      if (!address || !address.longitude || !address.latitude) return null;
      return {
        id: customer.customer_id,
        title: customer.customer_name,
        position: { lng: address.longitude, lat: address.latitude },
        address,
      };
    }).filter(c => !!c);
  }

  if (!addresses.value || processedReportCustomers.value.length === 0) return [];

  return processedReportCustomers.value
    .map(c => {
      const address = addresses.value.find(a => a.customer_id == c.id);
      if (!address) return undefined;
      const { longitude: lng, latitude: lat } = address;
      if (!lat && !lng) return undefined;
      return {
        ...c,
        position: { lng, lat },
        address,
      };
    })
    .filter(c => !!c);
});

const selectedMapMarker = ref(undefined);

const getMapInfoHtml = (marker) => {
  const { address, account_number, title } = marker;
  return `
  <h5 style="font-size: var(--font-size-6)">${title} ${account_number ? `(${account_number})` : ''}</h5>
  <p>${addr.asString(address)}</p>
  <a href="${crmBaseUrl}/${customersArea}/${marker.id}" target="_blank">${t('View customer')}</a>
  `;
};

const handleMarkerClick = (marker) => {
  selectedMapMarker.value = marker;
};

const handleMarkerReset = () => {
  selectedMapMarker.value = undefined;
};

const close = () => {
  emit('close');
};
</script>