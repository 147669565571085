<template>
  <div
    :key="refreshKey"
    class="template-report-charts"
  >
    <Carousel
      v-if="isCarousel"
      style="height: 450px; overflow-y: auto"
      :slides-amount="chartInputs.length"
      :active-slide-index="activeSlideIndex"
      @update:active-slide-index="activeSlideIndex = $event"
    >
      <CarouselSlide
        v-for="(chart, index) in charts"
        :key="index"
        :is-active="index === activeSlideIndex"
      >
        <MultiChart v-bind="chart">
          <template #header>
            <h4 class="fw-sbld mb-1">
              {{ chartInputs[index].name }}
            </h4>
          </template>
        </MultiChart>
      </CarouselSlide>
    </Carousel>
    <div
      v-else 
      class="no-carousel"
    >
      <MultiChart 
        v-for="(chart, index) in charts"
        :key="index"
        v-bind="chart"  
      >
        <template #header>
          <h4 class="fw-sbld mb-1">
            {{ chartInputs[index].name }}
          </h4>
        </template>
      </MultiChart>
    </div>
  </div>
</template>

<script setup>
import MultiChart from '@/intelligence/components/Dashboard/Elements/MultiChart.vue';
import Carousel from '@/shared/components/Carousel/Carousel.vue';
import CarouselSlide from '@/shared/components/Carousel/CarouselSlide.vue';
import { useWidget } from '@/intelligence/components/Dashboard/composables/useWidget';
import { computed, ref, watch } from 'vue';
import { TEMPLATE_REPORT_CHART_COLOR_SETS } from '@/intelligence/store/data/charts';
import { abbr } from '@sales-i/utils';

const props = defineProps({
  chartInputs: {
    type: Array,
    default: () => [],
  },
  reportData: {
    type: Array,
    default: () => [],
  },
  settings: {
    type: Object,
    default: () => ({}),
  },
  sortOption: {
    type: String,
    default: '',
  },
  isCarousel: {
    type: Boolean,
    default: true
  }
});

const { getSortedAndSlicedData } = useWidget();
const refreshKey = ref(1);
const activeSlideIndex = ref(0);

watch(() => props.reportData, () => { 
  refreshKey.value += 1; 
});

function getSortedData(chartInput) {
  const result = getSortedAndSlicedData({
    arr: props.reportData, 
    sort: props.sortOption, 
    limit: props.settings.limit, 
    key: chartInput.key 
  }).map(x => ({
    name: `${x.name}${x.code ? ` (${x.code})`: ''}`,
    value: x[chartInput.key],
  }));
  return result;
}

const getColour = (colorSet) => {
  const [colorStandard] = TEMPLATE_REPORT_CHART_COLOR_SETS[colorSet ?? 1];
  return `var(${colorStandard})`;
}; 

const charts = computed(() => {
  return props.chartInputs.map((ci, ciIndex) => ({
    chartData: getSortedData(ci),
    chartType: props.settings.chartTypes?.[ciIndex],
    showValues: props.settings.showValues,
    colour: getColour(ci.colorSet),
    formatFunc: (value) => abbr.float(value)
  }));
});
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.template-report-charts {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  height: fit-content;
  min-height: 200px;

  .no-carousel {
    display: flex;
    flex-flow: row nowrap;
    > div {
      flex: 1;
    }
  }
}

.charts {
  display: block;
  .slider {
    z-index: 0;
  }
}

.data-section {
  flex: 1;
}

.template-report-charts {
  .slider-btn {
    height: 40px !important;
    width: 40px !important;
    background: var(--colour-data-deluge-light) !important;
    border-radius: 50%;
    &:hover {
      background: var(--colour-data-deluge) !important;
      .slider-icon {
        border-color: var(--colour-data-deluge-light) !important;
      }
    }
  }
  .slider-btn-left {
    position: absolute !important;
    left: 0 !important;
    top: calc(50% - 40px) !important;
  }
  .slider-btn-right {
    position: absolute !important;
    right: 0 !important;
    top: calc(50% - 40px) !important;
  }
  .slider-icon-left {
    margin: var(--spacing-half) calc(-1 * var(--spacing-half)) 0 0;
  }
  .slider-icon-right {
    margin: var(--spacing-half) 0 0 calc(-1 * var(--spacing-half));
  }
  .slider-icon {
    border-color: var(--colour-data-deluge) !important;
    width: 12px !important;
    height: 12px !important;
  }
  .slider-indicator-active {
    background-color: var(--colour-data-deluge) !important;
  }

  :deep(.template-report-chart-container h3) {
    font-size: var(--font-size-5);
  }
}
</style>
