<template>
  <div
    :id="uniqueId"
    ref="containerRef"
    class="chart-container"
  >
    <svg class="chart" />
    <div class="bar-tooltip tooltip" />
  </div>
</template>

<script setup>
import useCharts from '@/intelligence/composables/useCharts';
import * as d3 from 'd3';
import { computed, onMounted, ref } from 'vue';

const containerRef = ref(null);
const { uniqueId } = useCharts({ containerRef, generateChart });

const props = defineProps({
  chartData: {
    type: Array,
    default: () => [],
  },
  columnKey: {
    type: Array,
    default: () => [],
  },
  colour: {
    type: Array,
    default: () => ['var(--colour-data-de-york)', 'var(--colour-data-mandy)'],
  },
  formatFunc: {
    type: Function,
    default: value => value,
  },
  svgWidth: {
    type: Number,
    default: 0,
  },
  svgHeight: {
    type: Number,
    default: 410,
  },
  barWidth: {
    type: Number,
    default: 14,
  },
  barGap: {
    type: Number,
    default: 6,
  },
});

const margin = {
  top: 0,
  right: 10,
  bottom: 10,
  left: 30,
};

const maxBandWidth = 100;
const svgWidthCalc = () =>
  Math.min(
    props.svgWidth || containerRef.value?.clientWidth + 80,
    props.chartData?.length * maxBandWidth,
    containerRef.value?.clientWidth + 80
  );
const chartWidth = () => svgWidthCalc() - margin.left - margin.right;
const chartHeight = computed(() => props.svgHeight - margin.top - margin.bottom);

const barTooltip = ref(null);

onMounted(() => {
  barTooltip.value = d3.select(`#${uniqueId} .bar-tooltip`);
});

function generateChart() {
  const formatValue = props.formatFunc || (value => value);
  let maxNumericValue = Math.max(
    ...props.chartData.map(entry => Math.max(...props.columnKey.map(key => entry?.[key])))
  );
  let minNumericValue = Math.min(
    ...props.chartData.map(entry => Math.min(...props.columnKey.map(key => entry?.[key])))
  );

  const barGap = props.barGap / 100;

  const x = d3
    .scaleBand()
    .domain(props.chartData.map((_, i) => i))
    .range([0, chartWidth()])
    .padding(barGap);

  const y = d3
    .scaleLinear()
    .domain([1.1 * Math.min(0, minNumericValue), 1.1 * Math.max(0, maxNumericValue)])
    .range([chartHeight.value, 5]);

  const svg = d3
    .select(`#${uniqueId} svg`)
    .attr('width', svgWidthCalc())
    .attr('height', props.svgHeight)
    .append('g')
    .attr('transform', `translate(${margin.left}, ${margin.top})`); // Translate by left margin only

  const barWidth = props.barWidth;
  const rx = barWidth / 2;
  const ry = barWidth / 2;

  props.columnKey.forEach((key, columnIndex) => {
    svg
      .selectAll(`rect.${key}`)
      .data(props.chartData || [])
      .join('rect')
      .attr('x', (d, i) => {
        // Calculate x position based on the column index and gap
        return x(i) + (columnIndex * (barWidth + props.barGap));
      })
      .attr('y', d => y(Math.max(0, d?.[key])))
      .attr('rx', rx)
      .attr('ry', ry)
      .attr('width', barWidth)
      .attr('height', d => Math.abs(y(0) - y(d?.[key])))
      .attr('fill', props.colour[columnIndex])
      .attr('class', `bar ${key}`)
      .style('cursor', 'pointer')
      .on('mouseover', (event, d) => {
        const [xPosition, yPosition] = d3.pointer(event, d3.select(`#${uniqueId} svg`).node());
        const svgTopLeft = d3.select(`#${uniqueId} svg`).node().getBoundingClientRect();

        barTooltip.value
          .style('display', 'block')
          .style('left', svgTopLeft.left + xPosition + 'px')
          .style('top', svgTopLeft.top + yPosition - 30 + 'px')
          .style('opacity', 1)
          .html(d?.label || formatValue(d?.[key], key));
      })
      .on('mouseout', () => {
        barTooltip.value.style('display', 'none');
      });
  });
}
</script>

<style lang="scss" scoped>
.chart-container {
  position: relative;
  display: flex;
  margin: 0 auto;
  flex-flow: column;
  align-items: center;
}

.bar-tooltip {
  width: 80px;
}

.tooltip {
  position: fixed;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s;
  padding: var(--spacing-half) var(--spacing-1);
  border-radius: var(--spacing-2);
  text-align: center;
  color: var(--colour-utility-white);
  font-family: var(--font-family-primary);
  font-size: var(--font-size-small);
  letter-spacing: 0;
  line-height: var(--spacing-2);
  background-color: var(--colour-utility-black);
  font-weight: var(--font-weight-semibold);
  pointer-events: none;
}
</style>
