<template>
  <CheckboxFilter
    v-if="filterEnabled"
    :title="t('Post categories')"
    :options="categoriesOptions"
    :model-value="categoriesFilter"
    @update:model-value="handleCategoriesFilterInput"
  />
  <EntityList
    v-if="permissions.read"
    :is-button="true"
    :page-limit="limit"
    :page-offset="offset"
    :records-count="recordsCount"
    :show-pagination="!!recordsCount"
    :loading="posts.loading"
    @page-changed="handlePageChanged"
  >
    <Posts
      v-bind="posts"
      :id="id"
      @add-post="addPost"
      @item-clicked="itemClicked"
      @edit-post="editPost"
      @deleted-post="getPosts"
    >
      <template #addtitle>
        {{ addTitle }}
      </template>
      <template #addsubtitle>
        {{ addSubtitle }}
      </template>
    </Posts>
    <router-view />
  </EntityList>
</template>

<script setup>
// PostsView
import { computed, onMounted, watch, ref } from 'vue';
import { useRoute, } from 'vue-router';
import { useStore } from 'vuex';

import EntityList from '@/crm/components/EntityList/EntityList.vue';
import Posts from '@/shared/components/Posts/Posts.vue';

import usePermissions from '@/shared/composables/usePermissions';
import { posts_comments } from '@/shared/store/data/policies';

import { GET_ALL, CLEAR_BY_ID, CLEAR_UPDATED_FLAG } from '@/shared/store/actionType';
import { baseUrl, postsSection } from '@/crm/router/urlBits';
import { capitalize } from '@/shared/utils/strings';
import CheckboxFilter from '@/shared/components/Form/CheckboxFilter.vue';
import { usePagination } from '@/shared/composables/usePagination';
import { isEqualObjects, t } from '@sales-i/utils';
import { navigateToUrl } from 'single-spa';
import useSystem from '@/shared/composables/useSystem';

const vroute = useRoute();
const store = useStore();

const { entity } = useSystem({ store });

const categoriesOptions = computed(() => [
  { id: 'customer', label: t('Customer profile'), default: true },
  { id: 'opportunity', label: t('Opportunities') },
  { id: 'interaction', label: t('Interactions') },
]);

const emit = defineEmits(['pageChanged']);
const { limit, offset, handlePageChanged } = usePagination(props, getPosts, emit);

const { getPermissions } = usePermissions();
const permissions = getPermissions(posts_comments);

const props = defineProps({
  id: {
    type: [String, Number],
    default: '',
  },
  data: {
    type: Object,
    default: () => ({}),
  },
  isCategoryFilterEnabled: {
    type: Boolean,
    default: false,
  },
});

const singularEntity = computed(() => store.getters['system/singularEntity']);
const categoriesFilter = ref([singularEntity.value]);
const posts = computed(() => store.state.posts.all);
const post = computed(() => store.state.posts.selected);
const recordsCount = computed(() => posts.value.data.length || 0);
const addTitle = computed(() => {
  return capitalize(t(`${t(singularEntity.value)} posts`), 'entity_posts', {
    interpolations: { entity: t(singularEntity.value) },
  });
});
const addSubtitle = computed(() =>
  t(`Use Posts to capture and share information on your ${t(entity.value)}.`, 'use_posts_to_capture_and_share_informationon_your_entity', {
    interpolations: { entity: t(entity.value) },
  })
);
const filterEnabled = computed(() => props.isCategoryFilterEnabled || singularEntity.value == 'customer');

watch(
  () => [props.id, ...categoriesFilter.value],
  () => getPosts()
);
watch(
  () => vroute.fullPath,
  () => {
    if (post.value.updated) {
      clearUpdatedFlag();
      clearById();
      getPosts();
    }
  }
);

onMounted(() => {
  getPosts();
});

const clearUpdatedFlag = () => store.dispatch(`posts/${CLEAR_UPDATED_FLAG}`);
const clearById = () => store.dispatch(`posts/${CLEAR_BY_ID}`);
const getAll = params => store.dispatch(`posts/${GET_ALL}`, params);
const queryParams = computed(() => {
  let apiQuery = {
    entity: entity.value,
    id: props.id,
    params: {
      sort: 'created_date:desc',
      limit: limit.value,
      offset: offset.value,
    },
  };
  if (!['interaction', 'opportunity'].includes(singularEntity.value)) {
    apiQuery.params.related_entity_type = categoriesFilter.value.filter(f => f != 'all').join(',');
  }
  return apiQuery;
});

function itemClicked(post_id) {
  if (!permissions.read) return;
  let { id } = vroute.params;
  navigateToUrl(`${baseUrl}/${entity.value}/${id}/${postsSection}/${post_id}`);
}
function editPost(post_id) {
  if (!permissions.update) return;
  let { id } = vroute.params;
  navigateToUrl(`${baseUrl}/${entity.value}/${id}/${postsSection}/${post_id}/edit`);
}
function addPost() {
  if (!permissions.create) return;
  let { id } = vroute.params;
  navigateToUrl(`${baseUrl}/${entity.value}/${id}/${postsSection}/add`);
}
function getPosts() {
  getAll(queryParams.value);
}

function handleCategoriesFilterInput(values) {
  if (!isEqualObjects(categoriesFilter.value, values)) categoriesFilter.value = values;
}
</script>

<style lang="scss"></style>
