import { computed } from 'vue';
import { useStore } from 'vuex';
import { GET_PRODUCTS } from '@/admin/store/actionType';

export const useAssociations = ({ store = useStore() } = {}) => {

  const associations = computed(() => store.state.admin.associations.products);
  const getAssociations = (params) => store.dispatch(`admin/associations/${GET_PRODUCTS}`, params);
  
  return {
    associations,
    getAssociations,
  };
};