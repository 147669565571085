import { getAllCommunitiesActions, getAllCommunitiesMutations } from './getAll';
import { getByIdCommunitiesActions, getByIdCommunitiesMutations } from './getById';

const state = () => ({
  all: {
    data: [],
    loading: false,
    loaded: false,
    error: null,
  },
  selected: {
    data: [],
    loading: false,
    loaded: false,
    error: null,
  },
});

const actions = {
  ...getAllCommunitiesActions,
  ...getByIdCommunitiesActions,
};

const mutations = {
  ...getAllCommunitiesMutations,
  ...getByIdCommunitiesMutations,
};

const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
