<template>
  <div>
    <div
      :id="uniqueId"
      ref="containerRef"
      class="multilinechart-container"
    >
      <svg class="chart" />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import * as d3 from 'd3';
import { abbr } from '@sales-i/utils';
import useCharts from '@/intelligence/composables/useCharts';

const props = defineProps({
  chartData: {
    type: Object,
    default: () => ({}),
  },
  formatFunc: {
    type: Function,
    default: v => v,
  },
  legendLabels: {
    type: Array,
    default: () => ['Value 1', 'Value 2'],
  }
});

const containerRef = ref(null);

const { uniqueId, getChartVersion, handleChartResize } = useCharts({ containerRef, generateChart });

const greenChipClass = 'chip-green';
const yellowChipClass = 'chip-yellow';
const redChipClass = 'chip-red';
const greenThreshold = ref(0);
const yellowThreshold = ref(0);
const currentParams = ref('');

const chartWidth = computed(() => 
  currentParams.value.svgWidth - currentParams.value.margin.left - currentParams.value.margin.right
);
const chartHeight = computed(() => 
  currentParams.value.svgHeight - currentParams.value.margin.top - currentParams.value.margin.bottom
);

watch(() => props.chartData, () => {
  handleChartResize();
});

function getExtremeValue(extreme) {
  if (['min', 'max'].indexOf(extreme) === -1 || !props.chartData) return;
  return Math[extreme](
    ...props.chartData.map(el => el.value1), 
    ...props.chartData.map(el => el.value2)
  );
}

function setThresholds() {
  greenThreshold.value = getExtremeValue('max') * 0.667;
  yellowThreshold.value = getExtremeValue('max') * 0.333;
}

function processChartParams(type) {
  currentParams.value =
    type === 'mobile'
      ? {
        svgWidth: containerRef.value?.clientWidth,
        svgHeight: 450,
        margin: {
          top: 10,
          right: -10,
          bottom: 200,
          left: 40,
        },
      }
      : {
        svgWidth: containerRef.value?.clientWidth,
        svgHeight: 510,
        margin: {
          top: 10,
          right: 0,
          bottom: 150,
          left: 135,
        },
      };
}

function generateChart() {
  if (!props.chartData?.length) return;
  setThresholds();
  const type = getChartVersion();
  processChartParams(type);

  const scopedAttr = containerRef.value.getAttributeNames().find(attr => attr.startsWith('data-v-'));
  
  const svg = d3
    .select(`#${uniqueId} svg.chart`)
    .attr('width', currentParams.value.svgWidth)
    .attr('height', currentParams.value.svgHeight)
    .append('g')
    .attr('transform', `translate(${currentParams.value.margin.left},${currentParams.value.margin.top})`)
    .attr(scopedAttr, '');

  const graphOffsetFromEdge = 20,
    xValuePosition = chartWidth.value / props.chartData.length / 2,
    bgLinesColor = '--colour-panel-g-8',
    linesData = [
      {
        id: 1,
        key: 'value1',
        color: '--colour-brand-viking',
        strokeWidth: 4,
        strokeDasharray: '',
        axisPosition: 10,
        legendPosition: 23,
        legendPositionMobile: -75,
        title: props.legendLabels?.[0] || '',
      },
      {
        id: 2,
        key: 'value2',
        color: '--colour-data-viking-label',
        strokeWidth: 2,
        strokeDasharray: '5,5',
        axisPosition: type === 'mobile' ? 78 : 60,
        legendPosition: type === 'mobile' ? 91 : 73,
        legendPositionMobile: 75,
        title: props.legendLabels?.[1] || '',
      },
    ];

  const vue = {
    greenChipClass,
    yellowChipClass,
    redChipClass,
    generateChipClass,
  };

  function assignChipClass() {
    const valueType = this.parentNode.parentNode.className.baseVal.includes('value1') ? 'value1' : 'value2';
    d3.select(this)
      .classed(vue.greenChipClass, d => vue.generateChipClass(d[valueType], vue.greenChipClass))
      .classed(vue.yellowChipClass, d => vue.generateChipClass(d[valueType], vue.yellowChipClass))
      .classed(vue.redChipClass, d => vue.generateChipClass(d[valueType], vue.redChipClass));
  }

  function setChipWidthPosition() {
    let textLength = d3.select(this.parentNode).select('text').node().getComputedTextLength() + 16;
    if (type === 'mobile') textLength -= 8;
    d3.select(this)
      .attr('width', textLength)
      .attr('transform', `translate(-${textLength / 2},1)`);
  }

  const x = d3
    .scalePoint()
    .domain(props.chartData.map((d, i) => i))
    .range([0, chartWidth.value - currentParams.value.margin.left]);
  const bottomOffsetMonths = type === 'mobile' ? 55 : 22;
  svg
    .append('g')
    .attr('class', 'chart-axis')
    .attr('transform', `translate(0, ${chartHeight.value})`)
    .call(d3.axisBottom(x))
    .selectAll('text')
    .data(props.chartData)
    .text(d => d.name)
    .attr('transform', `translate(0, ${currentParams.value.margin.bottom - bottomOffsetMonths - 16})`)
    .attr('dominant-baseline', 'middle')
    .attr('class', 'chart-label-x')
    .style('text-anchor', 'middle')
    .attr(scopedAttr, '');

  const y = d3
    .scaleLinear()
    .domain([getExtremeValue('min'), getExtremeValue('max')])
    .range([chartHeight.value - graphOffsetFromEdge, graphOffsetFromEdge]);
  const axisY = d3.axisLeft(y);
  svg
    .append('g')
    .attr('class', 'chart-axis')
    .call(axisY)
    .selectAll('text')
    .attr('class', 'chart-label-y')
    .style('display', 'block')
    .text(d => abbr.float(d))
    .attr(scopedAttr, '');

  props.chartData.forEach((e, i) => {
    svg
      .append('line')
      .attr('x1', x(i))
      .attr('y1', 0)
      .attr('x2', x(i))
      .attr('y2', chartHeight.value + currentParams.value.margin.bottom - bottomOffsetMonths - 20)
      .style('stroke-width', 1)
      .style('stroke', `var(${bgLinesColor})`)
      .style('fill', 'none')
      .attr(scopedAttr, '');
  });

  svg
    .append('line')
    .attr('x1', x(0))
    .attr('y1', 0)
    .attr('x2', x(0))
    .attr('y2', y(0))
    .style('stroke-width', 1)
    .style('stroke', 'var(--colour-panel-g-32)')
    .style('fill', 'none')
    .attr(scopedAttr, '');

  if (getExtremeValue('min') <= 0) {
    svg
      .append('line')
      .attr('x1', x(0))
      .attr('y1', y(0))
      .attr('x2', x(props.chartData.length - 1))
      .attr('y2', y(0))
      .style('stroke-width', 1)
      .style('stroke', 'var(--colour-panel-g-32)')
      .style('fill', 'none')
      .attr(scopedAttr, '');
  }

  linesData.forEach(line => {
    let axisTranslate = `translate(0, ${chartHeight.value + line.axisPosition})`,
      axisTextTranslate = 'translate(0,0)',
      axisTextAnchor = 'middle',
      axisTextDominant = '';

    if (type === 'mobile') {
      axisTranslate = `translate(-16, ${chartHeight.value + line.axisPosition + 35})`;
      axisTextTranslate = 'translate(0,0)rotate(-90)';
      axisTextAnchor = 'start';
      axisTextDominant = 'middle';
    }

    svg
      .append('g')
      .attr('class', `chart-axis chart-axis-${line.key}`)
      .attr('transform', axisTranslate)
      .call(d3.axisBottom(x))
      .selectAll('text')
      .data(props.chartData)
      .text(d => props.formatFunc?.(d[line.key]) || d[line.key])
      .attr('transform', axisTextTranslate)
      .attr('class', 'chart-label-values')
      .attr('dominant-baseline', axisTextDominant)
      .each(assignChipClass)
      .style('text-anchor', axisTextAnchor)
      .attr(scopedAttr, '');

    const chipsSvg = svg
      .selectAll(`g.chart-axis-value${line.id} g.tick`)
      .data(props.chartData)
      .insert('rect', 'text')
      .attr(scopedAttr, '');

    if (type === 'desktop') {
      chipsSvg
        .attr('ry', 12)
        .attr('rx', 12)
        .attr('height', 24)
        .each(assignChipClass)
        .each(setChipWidthPosition)
        .attr(scopedAttr, '');
    } else if (type === 'mobile') {
      chipsSvg
        .attr('height', 12)
        .attr('class', 'chip-white')
        .each(setChipWidthPosition)
        .attr('transform', `translate(${xValuePosition / 2}, 3)rotate(-90)`)
        .attr(scopedAttr, '');
    }

    const linePath = d3
      .line()
      .curve(d3.curveMonotoneX)
      .x((d, i) => x(i))
      .y(d => y(d[line.key]));
    svg
      .append('path')
      .datum(props.chartData)
      .attr('fill', 'none')
      .attr('stroke', `var(${line.color})`)
      .attr('stroke-width', line.strokeWidth)
      .attr('stroke-dasharray', line.strokeDasharray)
      .attr('d', linePath)
      .attr(scopedAttr, '');

    if (type === 'desktop') {
      svg
        .append('line')
        .attr('x1', 25 - currentParams.value.margin.left)
        .attr('y1', chartHeight.value + line.legendPosition)
        .attr('x2', -30)
        .attr('y2', chartHeight.value + line.legendPosition)
        .attr('stroke', `var(${line.color})`)
        .attr('stroke-width', line.strokeWidth)
        .attr('stroke-dasharray', line.strokeDasharray)
        .style('fill', 'none')
        .attr(scopedAttr, '');
      svg
        .append('text')
        .attr('x', 25 - currentParams.value.margin.left)
        .attr('y', chartHeight.value + line.legendPosition - 10)
        .attr('class', 'legend-text')
        .text(line.title)
        .attr('alignment-baseline', 'middle')
        .attr(scopedAttr, '');
    } else if (type === 'mobile') {
      const xLegendPosition = chartWidth.value / 2 + line.legendPositionMobile,
        xLegendOffset = 70,
        yLegendOffset = 30;

      svg
        .append('line')
        .attr('x1', -12)
        .attr('y1', chartHeight.value + line.axisPosition + 5)
        .attr('x2', -12)
        .attr('y2', chartHeight.value + line.axisPosition + 30)
        .attr('stroke', `var(${line.color})`)
        .attr('stroke-width', line.strokeWidth)
        .attr('stroke-dasharray', line.strokeDasharray)
        .style('fill', 'none')
        .attr(scopedAttr, '');

      svg
        .append('line')
        .attr('x1', xLegendPosition - xLegendOffset)
        .attr('y1', currentParams.value.svgHeight - yLegendOffset)
        .attr('x2', xLegendPosition - xLegendOffset + yLegendOffset)
        .attr('y2', currentParams.value.svgHeight - yLegendOffset)
        .attr('stroke', `var(${line.color})`)
        .attr('stroke-width', line.strokeWidth)
        .attr('stroke-dasharray', line.strokeDasharray)
        .style('fill', 'none')
        .attr(scopedAttr, '');
      svg
        .append('text')
        .attr('x', xLegendPosition)
        .attr('y', currentParams.value.svgHeight - yLegendOffset)
        .attr('class', 'legend-text')
        .text(line.title)
        .attr('alignment-baseline', 'middle')
        .style('text-anchor', 'middle')
        .attr(scopedAttr, '');
    }
  });
}

function generateChipClass(value, chipName) {
  return (value > greenThreshold.value 
    ? greenChipClass 
    : value > yellowThreshold.value 
      ? yellowChipClass 
      : redChipClass) === chipName;
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.multilinechart-container {
  position: relative;
  display: block;
  overflow-x: hidden;

  .chart {
    display: block;
    margin: auto;
  }

  :deep(g.tick line),
  :deep(g.chart-axis .domain) {
    display: none;
  }

  .chart-label-x,
  .chart-label-values,
  .chart-label-y,
  .legend-text {
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-small);
    font-family: EuclidCircularA;
  }

  .chart-label-y {
    color: var(--colour-utility-base);
  }

  .chart-label-values,
  .legend-text {
    font-weight: var(--font-weight-semibold);
  }

  text.chip-green {
    color: var(--colour-data-de-york-label);
  }

  text.chip-yellow {
    color: var(--colour-data-barberry-label);
  }

  text.chip-red {
    color: var(--colour-data-mandy-label);
  }

  rect.chip-green {
    fill: var(--colour-data-de-york-background);
  }

  rect.chip-yellow {
    fill: var(--colour-data-barberry-background);
  }

  rect.chip-red {
    fill: var(--colour-data-mandy-background);
  }

  rect.chip-white {
    fill: var(--colour-panel-g-0);
  }
}
</style>
