<template>
  <div class="table-wrapper">
    <table>
      <thead>
        <tr>
          <th>
            <CustomButton
              icon-only
              icon-name="search"
              icon-color="var(--colour-utility-action)"
              tabindex="0"
              purpose="neutral"
              @click="openSearch(true)"
            />
            <SearchField
              v-show="searchOpen"
              ref="searchTable"
              class="search-in-table"
              icon-color="var(--colour-utility-action)"
              @search="searchUsers"
              @close="closeSearch"
            />
          </th>
          <th
            v-for="item in columns"
            :key="item.key"
            :class="{
              'sorting-header': currentSort === item.key,
            }"
            @click="sortUsers(item.key)"
          >
            {{ t(item.name) }}
            <IconBase
              v-if="currentSort === item.key"
              class="sort-icon"
              :icon-name="currentSortDir === 'desc' ? 'arrow-down' : 'arrow-up'"
              :height="24"
              :width="24"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="user in users"
          :key="user.id"
        >
          <td class="first-column">
            <CustomCheckbox
              @input="includeOnDeleteUserList($event, user)"
            />
          </td>
          <td>
            <CustomButton
              class="user-name"
              purpose="text"
              @on-click="goToUser(user.id)"
            >
              {{ user.first_name }}
              {{ user.last_name }}
            </CustomButton>
          </td>
          <td class="email-cell">
            {{ user.email }}
          </td>
          <td>
            {{ user.roles[0] && user.roles[0].name }}
            {{ user.roles.length > 1 ? `+${user.roles.length - 1}` : '' }}
          </td>
          <td v-if="fullPbac">
            {{ user.team }}
          </td>
          <td v-if="permissions.update || permissions.delete">
            <div class="actions">
              <CustomButton
                v-if="permissions.update"
                :disabled="deleteUserList.length"
                purpose="text"
                @on-click="editUser(user.id)"
              >
                {{ t('Edit') }}
              </CustomButton>
              <CustomButton
                v-if="permissions.delete"
                :disabled="deleteUserList.length"
                purpose="text"
                @on-click="deleteUser(user)"
              >
                {{ t('Delete') }}
              </CustomButton>
            </div>
          </td>
          <td>
            <template v-if="permissions.update && user.editable">
              <MenuFilter
                :selected-value="user.status"
                :items="[{
                  value: 'ACTIVE',
                  text: t('Active')
                }, {
                  value: 'INACTIVE',
                  text: t('Inactive')
                }]"
                @on-change="changeUserStatusFromDropdown($event, user)"
              />
            </template>
            <template v-else>
              {{ user.status.substr(0,1) }}{{ user.status.substr(1).toLowerCase() }}
            </template>
          </td>
          <td>
            <template v-if="user.last_login_date">
              {{ dates.format(user.last_login_date) }}
            </template>
            <template v-else>
              {{ t('Never logged in') }}
            </template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <NumberedPagination
    v-show="!searchOpen"
    :total="totalUsers"
    :page-size="currentLimit"
    @paging="getUsersAndUpdatePaging"
  />
  <router-view />
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { dates, t } from '@sales-i/utils';
import { CustomButton, CustomCheckbox, IconBase, MenuFilter, } from '@sales-i/dsv3';
import { SET_USER_STATUS, } from '@/admin/store/actionType';
import { GET_ALL, DELETE_BY_ID, PUT_BY_ID, SET_CONFIRMATION_MODAL } from '@/shared/store/actionType';
import NumberedPagination from '@/admin/components/Layout/NumberedPagination.vue';
import SearchField from '@/admin/components/Company/Permissions/SearchField.vue';
import { baseUrl, companyArea, permissionsSection, usersSection } from '@/admin/router/urlBits';
import useFeatures from '@/shared/composables/useFeatures';
import { user_management } from '@/shared/store/data/policies';
import usePermissions from '@/shared/composables/usePermissions';
import { navigateToUrl } from 'single-spa';

const props = defineProps({
  deleteUserKey: {
    type: Number,
    default: 0
  }
});
const emit = defineEmits(['deleteUserList']);

const store = useStore();
const { fullPbac } = useFeatures();

const totalUsers = computed(() => store.getters['admin/users/totalCount']);
const users = computed(() => store.state.admin.users.data);
const getUsers = params => store.dispatch(`admin/users/${GET_ALL}`, params);
const deleteUsers = params => store.dispatch(`admin/users/${DELETE_BY_ID}`, params);
const setUserStatus = params => store.dispatch(`admin/users/${SET_USER_STATUS}`, params);
const updateUser = params => store.dispatch(`admin/users/${PUT_BY_ID}`, params);
const showConfirmationModal = params => store.dispatch(`confirmationModal/${SET_CONFIRMATION_MODAL}`, params);

const searchTable = ref(null);
const searchOpen = ref(false);
const currentSearchInput = ref('');
const currentSort = ref('first_name');
const currentSortDir = ref('asc');
const currentOffset = ref(0);
const currentLimit = ref(20);
const deleteUserList = ref([]);

watch(() => props.deleteUserKey, () => {
  getUsersWithParams();
});

const columns = ref([
  {
    key: 'first_name',
    name: t('Name')
  },
  {
    key: 'email',
    name: t('Login')
  },
  {
    key: 'role',
    name: t('Role')
  },
  // other columns added in onMounted
]);

const { getPermissions } = usePermissions();

const permissions = computed(() => getPermissions(user_management));

const goToUser = id => {
  navigateToUrl(`${baseUrl}/${companyArea}/${permissionsSection}/${usersSection}/${id}`);
};

const editUser = id => {
  navigateToUrl(`${baseUrl}/${companyArea}/${permissionsSection}/${usersSection}/${id}/edit`);
};

const getUsersWithParams = () => {
  getUsers({
    offset: currentOffset.value,
    limit: currentLimit.value,
    sort: `${currentSort.value}:${currentSortDir.value}`
  });
};

const updateUserStatus = async(status, user) => {
  setUserStatus(status);
  await updateUser({ id: user.id, user });
  getUsersWithParams();
};

const changeUserStatusFromDropdown = async (e, user) => {
  const status = e.value;
  if (status === 'ACTIVE') {
    updateUserStatus(status, user);
    handleSearchAndClose();
  } else {
    showConfirmationModal({
      message: t("By making this user 'in-active' they will be disabled from logging into sales-i. You will still be charged for this user."),
      cancelLabel: t('Cancel'),
      saveLabel: t('OK'),
      closeOnUpdate: true,
      closeOnCancel: false,
      updateFunction: async() => {
        try {
          updateUserStatus(status, user);
          handleSearchAndClose();
        } catch (e) {
          return false;
        }
        return true;
      },
      closeFunction: () => true,
      cancelFunction: () => true,
    });
  }
};

function handleSearchAndClose() {
  searchTable.value.clearSearch();
  closeSearch();
}

const openSearch = (open = true) => {
  searchOpen.value = open;
  if (open) {
    setTimeout(() => {
      searchTable.value.focus();
    }, 100);
  }
};

const closeSearch = () => {
  setTimeout(() => openSearch(false), 100);
  searchUsers('');
};

onMounted(() => {
  getUsersWithParams();
  if (fullPbac.value) {
    columns.value.push({
      key: 'team',
      name: t('Team')
    });
  }
  if (permissions.value.update || permissions.value.delete) {
    columns.value.push({
      key: 'actions',
      name: t('Actions')
    });
  }
  columns.value.push({
    key: 'status',
    name: t('Status')
  });
  columns.value.push({
    key: 'last_login_date',
    name: t('Last Log In')
  });
});

const includeOnDeleteUserList = (checked, user) => {
  deleteUserList.value = deleteUserList.value.filter(item => item.id !== user.id);
  if (checked) {
    deleteUserList.value.push(user);
  }
  emit('deleteUserList', deleteUserList.value);
};

const searchUsers = input => {
  currentSearchInput.value = input;
  getUsers({
    offset: input === '' ? currentOffset.value : 0,
    limit: currentLimit.value,
    sort: `${currentSort.value}:${currentSortDir.value}`,
    q: input,
  });
};

const getUsersAndUpdatePaging = ({ offset, limit }) => {
  currentOffset.value = offset;
  currentLimit.value = limit;
  getUsersWithParams();
};

const sortUsers = input => {
  let newSort = input;
  if (['actions', 'status', 'team', 'role'].includes(newSort)) return;
  if (currentSort.value === newSort) {
    currentSortDir.value = currentSortDir.value === 'asc' ? 'desc' : 'asc';
  } else {
    currentSortDir.value = 'asc';
  }
  currentSort.value = newSort;
  getUsers({
    offset: currentOffset.value,
    limit: currentLimit.value,
    sort: `${currentSort.value}:${currentSortDir.value}`,
    q: currentSearchInput.value,
  });
};

const deleteUser = user => {
  const userName = user.display_name;
  showConfirmationModal({
    message: t(
      `Do you really want to delete ${userName}?`,
      'do_you_really_want_to_delete_variable',
      { interpolations: { variable: `${userName}` }, }
    ),
    updatedMessage: t(
      `You have deleted ${userName}`,
      'you_have_deleted_variable',
      { interpolations: { variable: userName }, }
    ),
    updateFunction: async () => {
      try {
        await deleteUsers([user.id]);
        return true;
      } catch (e) {
        return false;
      }
    },
    finishFunction: () => {
      return true;
    },
    closeFunction: () => {
      return true;
    },
  });
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.table-wrapper {
  background-repeat: no-repeat;
  background-size: 250px 100%, 250px 100%, 16px 100%, 16px 100%;
  background-position: 0 0, 100%, 0 0, 100%;
  background-attachment: local, local, scroll, scroll;
  overflow: auto;
  transition: all 0.2s;
  position: relative;
}

table {
  position: relative;
  width: 100%;

  th,
  td {
    font-size: inherit;
  }

  thead th {
    min-width: auto;
    background-color: transparent;
    border-bottom: 1px solid black;
  }
}

tr {
  &:nth-child(even) {
    // I have to use a version of the colour-panel-g-2 variable with opacity to allow for the gradient at the end of a scrollable table to show through.
    background: rgba(210, 210, 210, 0.1);
  }
}

th,
td {
  color: var(--colour-utility-black);
  padding: var(--spacing-1);
  border: none;
}

th {
  vertical-align: middle;
  cursor: pointer;

  &.sorting-header {
    color: var(--colour-utility-black);
    font-weight: var(--font-weight-medium);
    text-decoration: none;
  }
}

td {
  vertical-align: middle;
  line-height: var(--spacing-2);
}
td.email-cell {
  max-width: 275px;
  overflow-wrap: break-word;
}

.sort-icon {
  display: inline-block;
  position: absolute;
}

.user-name :deep(> div) {
  text-align: left;
}

.actions {
  display: flex;
  gap: var(--spacing-3);
}

.search-in-table {
  position: absolute;
  top: 6px;
  z-index: 1;
  :deep(input) {
    padding: 0;
  }
}
</style>
