<template>
  <div class="association-inline">
    {{ item?.antecedent_code }} - {{ item.antecedent_name }} 
    <RagChip
      green="0.7"
      amber="0.5"
      :disabled="disabled"
      :value="item?.confidence"
      class="mx-1"
    /> 
    {{ item.consequent_code }} - {{ item.consequent_name }}
  </div>
</template>

<script setup>
import { RagChip } from '@sales-i/dsv3';

defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

</script>

<style lang="scss" scoped>
</style>