<template>
  <div>
    <CustomButton
      class="export-button"
      purpose="action"
      small
      @click="modalTrigger"
    >
      {{ title }}
    </CustomButton>
    <CustomModal
      id="export-modal"
      :title="title"
      :show-modal="isModalVisible"
      @close-modal="closeModal"
    >
      <p class="subtitle">
        {{ t('Export as') }}
      </p>
      <CustomSelect
        :value="selectedFileFormat"
        :items="fileFormats"
        @on-change="onFileFormatChange"
      />
      <div>
        <CustomButton
          v-if="!downloading"
          purpose="action"
          @click="exportHandler"
        >
          {{ t('Export') }}
        </CustomButton>
        <BufferImage
          v-else
          color="black"
          float="center"
          class="loading-spinner"
        />
      </div>
    </CustomModal>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { t, address as addr } from '@sales-i/utils';
import { CustomModal, CustomButton, CustomSelect, BufferImage } from '@sales-i/dsv3';
import { useCustomer } from '@/crm/composables/useCustomer';
import useCurrency from '@/shared/composables/useCurrency';
import useExport from '@/intelligence/composables/useExport';

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  dateParams: {
    type: Object,
    default: () => ({}),
  },
  customerObject: {
    type: Object,
    default: () => ({}),
  },
  exportComponentKey: {
    type: [String, Number],
    default: '',
  },
  isSnapShot: {
    type: Boolean,
    default: false,
  }
});

const store = useStore();

const { cs } = useCurrency();
const { selectedCustomer, getCustomerById } = useCustomer({ store });
const { exportTemplateReport } = useExport({ store });
  
const selectedFileFormat = ref('PDF');
const isModalVisible = ref(false);
const downloading = ref(false);
const customerAddress = ref('');
const fileFormats = [
  {
    text: 'PDF',
    value: 'PDF',
  },
  {
    text: 'PPTX',
    value: 'PPTX',
  },
];

const userDetails = computed(() => store.state.userDetails);
const platform = computed(() => store.state.platform);
  
watch(
  () => props.exportComponentKey, 
  () => { 
    if(props.isSnapShot) getCustomerAddress(); 
  },
  { immediate: true }
);


function modalTrigger() {
  isModalVisible.value = true;
}
    
function closeModal() {
  isModalVisible.value = false;
}

async function exportHandler() {
  const reporter = `${userDetails.value.data.first_name} ${userDetails.value.data.last_name}`;
  const { account_number, name } = props.customerObject;
  const address = props.isSnapShot ? customerAddress.value : addr.asString(selectedCustomer.value.addresses[0]);
  const { date_from, date_to, date_from2, date_to2 } = props.dateParams;

  if (platform.value.data === 'mobile') {
    const mobileData = {
      reporter,
      account_number,
      address,
      date_from,
      date_to,
      date_from2,
      date_to2,
      fileExt: selectedFileFormat.value,
      customerName: name?.trim(),
      completePeriodsOnly: false,
      currency: cs,
    };
    try {
      window.webkit.messageHandlers.exportSnapshotCallbackHandler.postMessage(JSON.stringify(mobileData));
    } catch (err) {
      // onErr
    }
  } else {
    downloading.value = true;
    try {
      await exportTemplateReport({
        account_number,
        reporter,
        address,
        date_from,
        date_to,
        date_from2,
        date_to2,
        name: name?.trim(),
        fileExt: selectedFileFormat.value,
        currency: cs,
      });
      closeModal();
    } catch (err) {
      console.error('Export failed or restricted tenant user', err);
    } finally {
      downloading.value = false;
    }
  }
}

function onFileFormatChange(format) {
  selectedFileFormat.value = format;
}

async function getCustomerAddress() {
  let addressString = '';
  await getCustomerById({ id: props.customerObject.id });
  if (selectedCustomer.value?.addresses?.length) {
    return customerAddress.value = addr.asString(selectedCustomer.value.addresses[0]);
  }
  // regex removes last comma, from string, if exists
  return (customerAddress.value = addressString.replace(/,\s*$/, ''));
}
</script>

<style lang="scss">
@import '@/shared/assets/scss/_variables';

#export-modal {
  .subtitle {
    margin-bottom: var(--spacing-1);
    color: var(--colour-utility-base);
  }

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    min-width: initial;
    max-width: 360px;
  }

  .content-wrapper .content {
    padding: 0 var(--spacing-3);
  }
}

.export-button {
  width: 100%;
}
</style>
