import { convertToUserFriendlyStr } from '@/shared/utils/strings';
import urlify from '@/shared/utils/urlify';
import { uuid, } from '@sales-i/utils';

const genIdentifier = () => {
  return uuid();
};

export function updateRulesAndClausesArray(rulesAndClauses = {}) {
  const {
    rulesArr = [],
    clausesArr = [],
    existingRules = [],
  } = rulesAndClauses;

  let rules = [];

  // if there are already rules, they need to be added to, otherwise return standard rules
  if (existingRules.length) {
    const firstClause = clausesArr.shift();
    const addNewLeadingClause = firstClause !== existingRules[0].clause;
    rules = existingRules.length === 1 ? existingRules[0] : [...existingRules];
    const { newRules, existingIds } = mapRulesAndClauses(rulesArr, clausesArr);
    const id = genIdentifier(existingIds);
    const newLeadingClause = {
      findId: id,
      clause: firstClause
    };
    // the newLeadingClause compares all existing rules against any newly added ones,
    // if newLeadingClause is identical to existing leadingClause, we can push to existing rules
    // if not, need to make old rules and new rules two separate rulesets
    if (addNewLeadingClause && newRules.length === 1) {
      rules = [newLeadingClause, rules, ...newRules];
    } else if (addNewLeadingClause && newRules.length > 1) {
      rules = [newLeadingClause, rules, newRules];
    }
    if (!addNewLeadingClause && newRules.length === 1) {
      rules.push(...newRules);
    } else if (!addNewLeadingClause && newRules.length > 1) {
      rules.push(newRules);
    }    
  } else {
    const { newRules } = mapRulesAndClauses(rulesArr, clausesArr);
    rules = newRules;
  }

  return rules;
}

export function mapRulesAndClauses(rulesArr, clausesArr) {
  let arr = [];
  let topLevelArr = [];
  let existingIds = [];
  let pointer = arr;
  topLevelArr.push(pointer);
  let topLevelClause = '';
  let pushObject = false;


  function processRuleset(rules, clauses) {
    let clauseLength = 1; // defaulted to 1 as used to loop the rulesets in next step
    for(let i = 0, len = clauses.length; i < len; i++) {
      // break out of the loop when clause changes as ruleset will need to nest
      if (i !== 0 && clauses[i - 1] !== clauses[i]) {
        topLevelClause = clauses[i];
        break;
      }
      // if first loop can push clause
      // otherwise just increase clauseLength
      if (clauses[i] && i == 0) {
        const id = genIdentifier(existingIds);
        existingIds.push(id);
        pointer.push({
          findId: id,
          clause: clauses[i]
        });
      }
      clauseLength += 1;
    }
    for(let i = 0; i < clauseLength; i++) {
      // loop through rules
      const id = genIdentifier(existingIds);
      existingIds.push(id);
      pointer.push({
        findId: id,
        ...rules[i]
      });
    }
    // pushObject is set in next step
    // used for last item in an array if remainingRules will equal 0
    if (pushObject) {
      topLevelArr.push(...pointer);
    }
    // create new rule and clause arrays for next level of loop
    const remainingRules = rules.slice(clauseLength);
    const remainingClauses = clauses.slice(clauseLength);
    if (remainingRules.length > 0) {
      // set the top level clause if not set
      if (topLevelArr.length && topLevelArr[0].clause !== topLevelClause) {
        const id = genIdentifier(existingIds);
        existingIds.push(id);
        topLevelArr.splice(0,0, {
          findId: id,
          clause: topLevelClause
        });
      }
      if (!remainingClauses.length) {
        pushObject = true;
      }
      const newRuleset = [];
      pointer = newRuleset;
      if (!pushObject) {
        topLevelArr.push(pointer);
      }
      processRuleset(remainingRules, remainingClauses);
    }
  }

  processRuleset(rulesArr, clausesArr);

  const newRules = topLevelArr.length === 1 ? topLevelArr[0] : topLevelArr;
  return {
    existingIds,
    newRules
  };
}

export function arrayFromRuleset(rulesetObj = {}) {
  let arr = [];
  let pointer = arr;
  const existingIds = [];

  function processRuleset(ruleset, previous) {
    const prev = previous;
    if (ruleset && ruleset?.rules) {
      const id = genIdentifier(existingIds);
      existingIds.push(id);
      // checks for condition first as this is the first object in any ruleset array with multiple rules
      if (ruleset.condition) {
        const id = genIdentifier(existingIds);
        existingIds.push(id);
        pointer.push({
          findId: id,
          clause: ruleset.condition
        });
      }
      const { rules } = ruleset;
      // map rules
      for (let i = 0, len = rules.length; i < len; i++) {
        const id = genIdentifier(existingIds);
        existingIds.push(id);
        // if ruleset has own condition and rules, nest into new ruleset array
        // if not, push existing rule into current ruleset
        if (rules[i].condition && rules[i].rules) {
          const newRestrictionArr = [];
          pointer.push(newRestrictionArr);
          pointer = newRestrictionArr;
          processRuleset(rules[i], prev);
        } else {
          pointer.push({
            findId: id,
            ...rules[i],
            name: convertToUserFriendlyStr(rules[i].name)
          });
        }
        // if at end of loop reset pointer to parent
        if (i === len - 1) {
          pointer = prev;
        }
      }
    }
  }

  processRuleset(rulesetObj, pointer);

  return arr;
}

export function rulesetFromArray(rulesArray = []) {
  let expression = {};
  let pointer = expression;
  let previousPointer = expression;

  function processRulesArray(arr, previous) {
    const prev = previous;
    for (let i = 0, len = arr.length; i < len; i++) {
      // check for clause, if present set condition
      if (arr[i].clause) {
        // if condition exists, new rule in ruleset, go down a level
        // if not, just set existing expression condition
        if (pointer.condition) {
          const newExpression = {};
          newExpression.condition = arr[i].clause;
          if (!pointer?.rules) {
            pointer.rules = [];
          }
          pointer.rules.push(newExpression);
          pointer = newExpression;
        } else {
          pointer.condition = arr[i].clause;
        }
      }
      
      // if an array, go down a level,
      // if not and has no clause, then is rule, add to ruleset
      if (Array.isArray(arr[i])) {
        processRulesArray(arr[i], prev);
      } else if (arr[i] && !arr[i].clause) {
        const { type } = arr[i];
        let rule;

        if (type === 'crm') {
          const { name, operator, value, field_id, entity } = arr[i];
          rule = {
            field_id,
            type,
            entity,
            name: urlify(name, '_'),
            operator,
            value,
          };
        } else if (type === 'analysis') {
          const { name, operator, field_id, entity, field_lookup_id, code, data_type } = arr[i];
          rule = {
            field_id,
            type,
            entity,
            name: urlify(name, '_'),
            operator,
            field_lookup_id,
            code,
            data_type
          };
        }

        if (!pointer?.rules) {
          pointer.rules = [];
        }
        pointer.rules.push(rule);
      }
      // if at end of loop reset pointer to parent
      if (i === len - 1) {
        pointer = prev;
      }
    }
  }

  processRulesArray(rulesArray, previousPointer);

  return { expression };
}
export function stringRulesFromArray(rulesArray = []) {
  let str = '';

  function processRulesArray(arr) {
    let initialCondition = '';
    for (let i = 0, len = arr.length; i < len; i++) {
      // if object in array has clause, set condition for that level
      if (arr[i].clause) {
        initialCondition = arr[i].clause.toUpperCase();
      }
      // ruleset struct is [clause, rule, rule]
      // so only rule index >= 2 needs clause preceding it
      if (i >= 2) {
        str = `${str} ${initialCondition} `;
      }
      // if array, add brackets, go down a level
      // else add rule to str
      if (Array.isArray(arr[i])) {
        str += '(';
        processRulesArray(arr[i]);
        str += ')';
      } else if (arr[i] && !arr[i].clause) {
        const { name, operator, value } = arr[i];
        const useOperator = operator === 'not_equals' ? 'does not equal' : operator;
        str += `${name} ${useOperator} '${value}'`;
      }
    }
  }

  processRulesArray(rulesArray);

  return str;
}

export function findAndUpdateRule(rules, findId, value) {
  // cannot mutate store, so reconstruct entire array to update a value
  let arr = [];
  let pointer = arr;

  function updateRule(outerRules, id, val, previous) {
    const prev = previous;
    for (let i = 0, len = outerRules.length; i < len; i++) {
      let innerRules = outerRules[i];
      // if current rule is array, go down a level to new array
      // if not push existing value and update rule if findId matches
      if (Array.isArray(innerRules)) {
        const newArr = [];
        pointer.push(newArr);
        pointer = newArr;
        updateRule(innerRules, findId, val, prev);
      } else {
        let newValue = {
          ...innerRules
        };
        if (newValue.findId === id) {
          newValue = val;
          newValue.findId = id;
        }
        pointer.push(newValue);
      }
      // if at end of loop reset pointer to parent
      if (i === len - 1) {
        pointer = prev;
      }
    }
  }
  
  updateRule(rules, findId, value, pointer);
  return arr;
}

export function findAndUpdateClause(rules, findId, value) {
  // cannot mutate store, so reconstruct entire array to update a value
  let arr = [];
  let pointer = arr;

  function updateClause(outerRules, id, val, previous) {
    const prev = previous;
    for (let i = 0, len = outerRules.length; i < len; i++) {
      let innerRules = outerRules[i];
      // if current rule is array, go down a level to new array
      // if not push existing value and update clause if findId matches
      if (Array.isArray(innerRules)) {
        const newArr = [];
        pointer.push(newArr);
        pointer = newArr;
        updateClause(innerRules, findId, val, prev);
      } else {
        let newValue = {
          ...innerRules
        };
        if (newValue.findId === id) {
          newValue.clause = val;
        }
        pointer.push(newValue);
      }
      // if at end of loop reset pointer to parent
      if (i === len - 1) {
        pointer = prev;
      }
    }
  }
  
  updateClause(rules, findId, value, pointer);
  return arr;
}

function cleanUpClauses(arr) {
  const processArray = (items) => {
    for (const [i, item] of items.entries()) {
      if (Array.isArray(item)) {
        processArray(item);
        continue;
      }
      
      if (item?.clause && items.length === 2) {
        items.splice(i, 1);
        break; // Exit loop since we've modified the array
      }
    }
  };

  processArray(arr);
}


export function findAndDeleteRule(rules, findId) {
  // cannot mutate store, so reconstruct entire array to update a value
  let arr = [];
  let pointer = arr;

  function deleteRule(outerRules, id, previous) {
    const prev = previous;
    for (let i = 0, len = outerRules.length; i < len; i++) {
      let innerRules = outerRules[i];
      // if current rule is array, filter rules, check if only one rule left
      // if yes, push and continue, if not go down level to new array
      // if not array push existing value if findId does not match
      if (Array.isArray(innerRules)) {
        const filteredArray = innerRules.filter(rule => rule.findId !== id);
        if (filteredArray.length !== innerRules.length) {
          if (filteredArray.length === 2) {
            pointer.push(filteredArray[1]);
          } else {
            const newArr = [];
            pointer.push(newArr);
            pointer = newArr;
            deleteRule(filteredArray, findId, prev);
          }
        } else {
          const newArr = [];
          pointer.push(newArr);
          pointer = newArr;
          deleteRule(innerRules, findId, prev);
        }
      } else {
        let newValue = {
          ...innerRules
        };
        if (newValue.findId !== id) {
          pointer.push(newValue);
        }
      }
      // if at end of loop reset pointer to parent
      if (i === len - 1) {
        pointer = prev;
      }
    }
  }

  deleteRule(rules, findId, pointer);
  cleanUpClauses(arr);
  return arr;
}

export function findAndDeleteRuleset(rules, ruleset) {
  // cannot mutate store, so reconstruct entire array to update a value
  let arr = [];
  let pointer = arr;

  function deleteRule(outerRules, set, previous) {
    const prev = previous;
    for (let i = 0, len = outerRules.length; i < len; i++) {
      let innerRules = outerRules[i];
      // if current rule is array, filter rules, check if only one rule left
      // if yes, push and continue, if not go down level to new array
      // if not array push existing value if findId does not match
      if (Array.isArray(innerRules)) {
        if (innerRules[0].findId !== set[0].findId) {
          const newArr = [];
          pointer.push(newArr);
          pointer = newArr;
          deleteRule(innerRules, set, prev);  
        }
      } else {
        let newValue = {
          ...innerRules
        };
        pointer.push(newValue);
      }
      // if at end of loop reset pointer to parent
      if (i === len - 1) {
        pointer = prev;
      }
    }
  }

  deleteRule(rules, ruleset, pointer);
  cleanUpClauses(arr);
  return arr;
}