<template>
  <div>
    <EnquiryNav
      v-if="!iframe"
      :active-area-prop="reportArea"
      :report-type="reportType"
      :alert-builder-dates="subtitles"
      :is-alert-builder-variance="isAlertBuilderVariance"
      hide-enquiry-buttons
    />
    <div class="container">
      <div class="description">
        <i>{{ inputData.description }}</i>
      </div>

      <router-view
        :report-type="reportType"
        @close-modal="closeModal"
        @refresh-count="refreshCount"
        @saved-alert-success="handleSavedAlertSuccess"
      />

      <TwoColumnLayout
        class="columns-container"
        sidebar-width="400px"
      >
        <template #sidebar>
          <AlertMenu
            :queries="queries"
            :selected-query-id="selectedQueryId"
            @query-change="handleQueryChange"
            @clause-change="handleClauseChange"
          />

          <div>
            <p>
              <CustomButton
                v-if="isQuery"
                purpose="text"
                :label="t('Delete')"
                @click="handleDeleteAlert"
              />
            </p>
          </div>
        </template>

        <template #content>
          <div
            id="results-table-wrapper"
            class="results-table-wrapper"
          >
            <FullPictureTable
              v-if="showReport && !isQuery"
              :drill-enabled="false"
              :search-mode="mode"
              :is-report-enabled="reportEnabled"
              :hide-value-fields="mode === 'prospect'"
              :title="t('Alert Builder')"
              :report-type="QUERY_BUILDER"
              :date-params="dateParams"
              @loaded="enableReport"
            />

            <SavedQueryTable
              v-if="showReport && isQuery"
              :id="selectedQueryId"
              :key="`${selectedQueryId}_${runCount}`"
              :report-type="QUERY_BUILDER"
              @loaded="enableMap"
              @date-ranges="handleDatesRanges"
              @is-variance="isVarianceHandler"
            />
          </div>
        </template>
      </TwoColumnLayout>
    </div>
  </div>
</template>

<script setup>
import { CustomButton } from '@sales-i/dsv3';
import AlertMenu from '@/intelligence/views/Communities/AlertMenu.vue';

// Constants
import { computed, ref, onMounted, watch, onUnmounted } from 'vue';
import { useMq } from 'vue3-mq';
import { REPORT_AREA_ALERTS } from '@/intelligence/store/data/areas';
import {
  RESET_ALL_DATA_CLAUSES,
  FETCH_BUBBLE_DATA,
  CLEAR_ENQUIRY_FILTERS,
  CLEAR_PRODUCTS,
} from '@/intelligence/store/actionType';
import { GET_ADDRESSES } from '@/crm/store/actionType';
import { PRODUCT_COMMUNITY_INSIGHTS, QUERY_BUILDER } from '@/intelligence/store/data/reportTypes';
import { useStore } from 'vuex';
import { onBeforeRouteLeave, useRoute } from 'vue-router';
import { t, dates } from '@sales-i/utils';
import { SET_CONFIRMATION_MODAL, GET_BY_ENTITY, DELETE_BY_ID } from '@/shared/store/actionType';
import { alertsScope } from '@/intelligence/router/urlBits';
import { useAlertBuilder } from '@/intelligence/composables/useAlertBuilder';
import breakpoints from '@/shared/utils/breakpoints';

// Components
import TwoColumnLayout from '@/shared/components/Layout/TwoColumnLayout';
import EnquiryNav from '@/intelligence/components/EnquiryReport/EnquiryNav/EnquiryNav';
import FullPictureTable from '@/intelligence/components/FullPicture/FullPictureTable.vue';
import SavedQueryTable from '@/intelligence/views/Communities/SavedQueryTable.vue';
import { navigateToUrl } from 'single-spa';
import { NEW_ALERT_IFRAME_ROUTE, NEW_ALERT_ROUTE } from '@/intelligence/router/routeNames';
import useSystem from '@/shared/composables/useSystem';

const mq = useMq();

// Computed
const store = useStore();
const vroute = useRoute();
const {
  alertBuilderBaseUrl,
  clearAllFilters,
  fetchBubbleData,
  goToReport,
  getAllSavedAlerts,
  getSavedAlertById,
  setShowReport,
  setCustomerBubble,
  showReport,
  runCount,
} = useAlertBuilder({ store, vroute });
const { iframe } = useSystem({ store });

const inputData = computed(() => {
  if (selectedQueryId.value !== null && isQuery.value) {
    let x = 0;
    let description = '';

    for (x; x < queries.value.length; x++) {
      if (queries.value[x].id === parseInt(selectedQueryId.value, 10)) {
        description = queries.value[x].query_name;
      }
    }

    let [title, promoLink] = description.split('|~~|');

    if (title.length > 0) {
      return {
        description: title,
        link: promoLink,
      };
    }
  }
  return getInputData(reportType);
});
const isQuery = computed(() => queries.value?.length > 0);
const getInputData = reportType => store.getters['intelligence/shared/getInputData'](reportType);
const isMobile = computed(() => breakpoints.smAndDown.includes(mq.current));

const subtitles = computed(() => {
  const format = isMobile.value ? dates.format : dates.formatWithOrdinals;
  const { date_from, date_to, date_from2, date_to2 } = dateRangesObject.value;

  return [
    `${format(date_from)} - ${format(date_to)}`,
    `${format(date_from2)} - ${format(date_to2)}`
  ];
});

const reportArea = REPORT_AREA_ALERTS;
const reportType = PRODUCT_COMMUNITY_INSIGHTS;
const queries = computed(() => store.state.intelligence.savedQuery.queries);

// Filters
const clearEnquiryFilters = () => store.dispatch(`intelligence/enquiry/${CLEAR_ENQUIRY_FILTERS}`);
const fetchBubbleIds = params => store.dispatch(`intelligence/templateReport/${FETCH_BUBBLE_DATA}`, params);
const getAddresses = params => store.dispatch(`crm/customers/${GET_ADDRESSES}`, params);
const showConfirmationModal = params => store.dispatch(`confirmationModal/${SET_CONFIRMATION_MODAL}`, params);

// selectedQueryId should be a number ref
const selectedQueryId = computed(() => Number(vroute.params.queryId) || null);
const mode = ref('customer');
const reportEnabled = ref(false);
const isAlertBuilderVariance = ref(false);
const dateParams = ref({
  date_from: 'today-3m',
  date_to: 'today',
  date_from2: 'today-3m-3m',
  date_to2: 'today-3m',
});

const dateRangesObject = ref({
  date_from: '',
  date_to: '',
  date_from2: '',
  date_to2: '',
});

onMounted(async () => {
  await getAllSavedAlerts();
  await fetchBubbleData();
  await fetchBubbleIds([
    'field',
    'name',
    bubbles => {
      setCustomerBubble(bubbles[0]);
    },
  ]);
  await store.dispatch(`admin/fields/${GET_BY_ENTITY}`, {
    entity: 'interactions',
  });

  if ([NEW_ALERT_ROUTE, NEW_ALERT_IFRAME_ROUTE].includes(vroute.name) &&
    selectedQueryId.value == null &&
    queries.value.length > 0) {
    handleQueryChange(queries.value[0].id);
    return;
  }

  if (selectedQueryId.value) {
    await loadAlertById(selectedQueryId.value);
  } else if (queries.value.length === 0) {
    // We have no saved alerts, so go to a fresh report
    goToReport({ incrementRunCount: true });
  }
});

onUnmounted(() => {
  clearAll();
});

function refreshCount() {
  goToReport();
}

// If the route changes to the alert builder page, open the modal
watch(
  () => vroute.path,
  async () => {
    // redirect to the first alert if the user is on the alert builder page
    if ([NEW_ALERT_ROUTE, NEW_ALERT_IFRAME_ROUTE].includes(vroute.name) &&
    selectedQueryId.value == null &&
    queries.value.length > 0) {
      handleQueryChange(queries.value[0].id);
      return;
    }
  }
);

const handleClauseChange = () => {
  runCount.value = runCount.value + 1;
};

const enableMap = async customers => {
  if (customers === undefined) {
    return;
  }
  await getAddresses(
    customers.map(c => ({
      id: parseInt(c.dimensions[0].entity_id, 10),
      account_number: c.dimensions[0].dimensions[1],
      title: c.dimensions[0].dimensions[0],
    }))
  );
};

const enableReport = async () => {
  await enableMap();
  reportEnabled.value = true;
};

async function loadAlertById(id) {
  await clearAll();
  await getSavedAlertById(id);

  setShowReport(true);
}

async function handleQueryChange(id) {
  await loadAlertById(id);
  navigateToUrl(`${alertBuilderBaseUrl.value}/${alertsScope}/${id}`);
}

async function handleSavedAlertSuccess() {
  getAllSavedAlerts();
}

// Closing the modal and clearing the data
const clearProducts = () => store.dispatch(`intelligence/queryBuilder/${CLEAR_PRODUCTS}`);
const resetAllDataClauses = () => store.dispatch(`intelligence/enquiry/${RESET_ALL_DATA_CLAUSES}`);

async function closeModalActions() {
  navigateToUrl(`${alertBuilderBaseUrl.value}/${alertsScope}/${vroute.params.queryId || ''}`);
}

function closeModal(showConfirmation) {
  if (showConfirmation) {
    showConfirmationModal({
      heading: t("If you close this window you'll lose your changes"),
      cancelLabel: t('No, keep changes'),
      saveLabel: t('Close'),
      closeOnUpdate: true,
      closeOnCancel: false,
      updateFunction: () => {
        closeModalActions();
        return true;
      },
    });
  } else {
    closeModalActions();
  }
}

async function clearAll() {
  clearAllFilters();
  clearEnquiryFilters();
  clearProducts();
  resetAllDataClauses();
}

function handleDeleteAlert() {
  showConfirmationModal({
    heading: t('Delete Alert'),
    message: t('This will permanently delete this alert, are you sure?'),
    cancelLabel: t('Cancel'),
    saveLabel: t('Delete'),
    closeOnUpdate: true,
    closeOnCancel: false,
    updateFunction: async () => {
      await store.dispatch(`intelligence/savedQuery/${DELETE_BY_ID}`, selectedQueryId.value);
      await getAllSavedAlerts();
      navigateToUrl(`${alertBuilderBaseUrl.value}/${alertsScope}`);
      return true;
    },
  });
}

async function handleDatesRanges(dates) {
  const { dateRange, dateRange2 } = dates || {};
  
  if (dateRange?.from && dateRange?.to && dateRange2?.from && dateRange2?.to) {
    dateRangesObject.value = {
      date_from: dateRange.from,
      date_to: dateRange.to,
      date_from2: dateRange2.from,
      date_to2: dateRange2.to,
    };
  } else {
    console.error('Invalid dates object received:', dates);
  }
}

function isVarianceHandler(isVariance) {
  isAlertBuilderVariance.value = isVariance;
}

// clear filters while navigating between tabs in iframe
onBeforeRouteLeave(() => {
  if (iframe.value) clearAll();
});
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.delete-modal {
  h3 {
    text-align: center;
    font-size: 1.5rem;
    margin: 0 0 var(--spacing-2) 0;
  }

  p,
  label {
    font-size: 1rem;
    margin: var(--spacing-2) 0;
  }

  .modal-content {
    p {
      margin: auto;
      text-align: center;
    }
  }

  div.svg-container {
    margin: auto;
  }
}

.container {
  padding: 0 0 var(--spacing-2);
  position: relative;
  background-image: linear-gradient(
    var(--colour-utility-white) 0 var(--spacing-3),
    var(--colour-panel-customer) var(--spacing-3) 100%
  );
  min-height: 250px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding: 0 var(--spacing-2) var(--spacing-2);
  }
}

.description {
  border-radius: var(--border-radius-1);
  display: grid;
  font-weight: var(--font-weight-semibold);
  place-content: center;
  padding: calc(var(--spacing-1) * 1.5);
  color: var(--colour-data-turmeric-label);
  background: var(--colour-data-turmeric-light);
  margin: 0 var(--spacing-2) var(--spacing-2);

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    margin: 0 0 var(--spacing-2);
  }
}

.columns-container {
  :deep(.container.flow) {
    padding: 0;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      padding: var(--spacing-2);
    }
  }
}

:deep(.main) {
  padding-inline: var(--spacing-2);

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding: var(--spacing-2);
  }
}

.filters-titles {
  h3 {
    font-weight: var(--font-weight-semibold);
  }

  p {
    margin-bottom: var(--spacing-2);
  }
}

h4 {
  margin: var(--spacing-2) 0;
}

.results-table-wrapper {
  position: relative;
}

.add-button {
  background-color: var(--colour-utility-white);
  top: 170px;
  position: sticky;
  display: flex;
  align-items: center;
  z-index: 10;

  :deep(button) {
    margin: var(--spacing-2) var(--spacing-2) var(--spacing-2) 0;
  }

  :deep(.icon-button) {
    font-size: var(--font-size-4);
    font-weight: var(--font-weight-medium);

    span {
      margin-left: var(--spacing-2);
    }
  }
}

.alert-issue {
  color: white;
  background-color: var(--colour-brand-mandy);
  padding: var(--spacing-1) var(--spacing-2);
  border-radius: var(--border-radius-1);
}

.alert-management {
  display: flex;
  margin: var(--spacing-2) 0;

  :deep(button) {
    margin-right: var(--spacing-2);
  }
}

.bubbles {
  background: var(--colour-utility-white);
  z-index: 100;
  box-shadow: 0 0 var(--spacing-half) 1px var(--colour-panel-g-16);
  border-radius: var(--border-radius-1);
  padding: 0 var(--spacing-2);
  margin: 0 auto;
  overflow: auto;
  position: relative;
  max-width: calc(100% - 32px);

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    position: absolute;
    top: 0;
    bottom: var(--spacing-2);
    right: 0;
    left: 30%;
  }

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    left: 24%;
  }
}
</style>
