<template>
  <p class="customers-count-wrapper">
    {{ t('Customers in Report:') }} 
    <template v-if="loading">
      <BufferImage
        v-show="loading"
        :size="18"
      />
    </template>
    <template v-else>
      <slot>{{ latestCount }}</slot>
    </template>
  </p>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { t } from '@sales-i/utils';
import { BufferImage } from '@sales-i/dsv3';

const store = useStore();

const report = computed(() => store.state.intelligence.shared.fetchedData);
const loading = computed(() => store.state.intelligence.shared.loading);
const latestCount = computed(() => {
  const i = Object.keys(report.value);
  if (i.length < 1) {
    return 0;
  }
  return report.value[parseInt(i[i.length - 1], 10)].data?.summary.customer_count;
});

</script>

<style lang="scss" scoped>
.customers-count-wrapper {
  display: flex;
  gap: var(--spacing-half);
  align-items: center;
}
</style>