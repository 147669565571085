<template>
  <div
    class="dashboard-layout"
    :style="gridStyles"
  >
    <DraggableItem
      v-for="item in items"
      :key="item.id"
      class="grid-item"
      :style="getWidgetStyle(item)"
      :transfer-data="item"
      :disabled="!!item.fixed"
      @drag-started="onDragStarted"
      @drag-ended="onDragEnded"
    >
      <DroppableItem
        @drag-over="onDragOver(item)"
        @drag-leave="onDragLeave(item)"
        @drop="onDrop(item)"
      >
        <slot
          name="item"
          :item="item"
          :is-drop="isDroppableItemActive"
        />
      </DroppableItem>
    </DraggableItem>
    <div
      :style="{ gridColumns: `span ${columns}` }"
      class="p-2"
    >
      <slot
        v-if="!items?.length"
        name="empty"
      />
    </div>
  </div>
</template>

<script setup>
import DraggableItem from '@/shared/components/DragAndDrop/DraggableItem.vue';
import DroppableItem from '@/shared/components/DragAndDrop/DroppableItem.vue';
import { ref, computed } from 'vue';

const props = defineProps({
  columns: {
    type: Number,
    default: 3,
  },
  items: {
    type: Array,
    default: () => [],
  },
  expandedId: {
    type: [String, Number],
    default: undefined,
  },
});
const emit = defineEmits(['reorder', 'drag-over', 'drag-leave']);
const draggedItem = ref(null);
const isDroppableItemActive = ref(false);

const gridStyles = computed(() => ({
  gridTemplateColumns: `repeat(${props.columns}, minmax(0, 1fr))`,
}));

const getWidgetStyle = item => {
  return {
    gridColumn: `span ${props.expandedId === item.id ? props.columns : Math.min(props.columns, item.width || 1)}`,
    gridRow: `span ${item.height || 1}`,
    justifySelf: 'stretch',
  };
};

const onDragOver = item => { 
  isDroppableItemActive.value = true; 
  emit('drag-over', item);
};
const onDragLeave = item => { 
  isDroppableItemActive.value = false; 
  emit('drag-leave', item);
};
const onDrop = item => {
  if (item.fixed) return;
  const fromIndex = props.items.findIndex(i => i === draggedItem.value);
  const toIndex = props.items.findIndex(i => i === item);
  if (fromIndex !== toIndex) {
    const reorderedItems = [...props.items];
    const movedItem = reorderedItems.splice(fromIndex, 1)[0];
    reorderedItems.splice(toIndex, 0, movedItem);
    emit('reorder', reorderedItems);
  }
};

const onDragStarted = item => {
  draggedItem.value = item;
};

const onDragEnded = () => {
  draggedItem.value = null;
  isDroppableItemActive.value = false;
  emit('drag-leave');
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.dashboard-layout {
  padding: var(--spacing-2);
  display: grid;
  gap: var(--spacing-2);
  width: 100%;
  background-color: var(--colour-panel-g-2);

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding-left: 0;
    padding-right: 0;
  }
}
.not-draggable {
  cursor: no-drop;
}
.draggable,
.droppable {
  flex: 1;
  max-width: 100%;
}
.droppable {
  height: 100%;
}
.expanded {
  min-width: 100%;
}
</style>
