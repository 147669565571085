<template>
  <div>
    <EnquiryNav
      :active-area-prop="REPORT_AREA_PERFORMANCE"
      :report-type="ACTIVITY_REPORT"
      :dates="dateFilter"
      hide-enquiry-buttons
    />
    <img
      src="@/intelligence/views/ActivityReport/NAPA_Activity_Report.png"
      alt=""
      style="max-width: 100%;margin-top:-16px;"
    >
  </div>
</template>

<script setup>
import { ref } from 'vue';
import EnquiryNav from '@/intelligence/components/EnquiryReport/EnquiryNav/EnquiryNav';
import { REPORT_AREA_PERFORMANCE } from '@/intelligence/store/data/areas';
import { ACTIVITY_REPORT } from '@/intelligence/store/data/reportTypes';

const dateFilter = ref({
  date_from: '',
  date_to: '',
});

</script>

<style lang="scss" scoped>
@import "@/shared/assets/scss/_variables";
.sales-opportunity-report {
  padding-right: var(--spacing-4);
  padding-left: var(--spacing-4);
  width: 100%;
  margin: auto;
  position: relative;
  background: var(--colour-panel-g-0);

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    min-width: 750px;
  }
  
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding: var(--spacing-2);
  }
}
.sidebar.hide {
  .date-filter {
    display: none;
    opacity: 0;
  }
}

:deep(.area.performance .container) {
  padding-bottom: 100px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding-bottom: var(--spacing-2);
  }
}
</style>