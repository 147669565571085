<template>
  <div class="customer-overview-chart-container">
    <h3 v-if="chartInput.name">
      {{ chartInput.name }}
    </h3>
    <BarChart
      :chart-data="reportData"
      :colour="chartColour"
      :column-key="chartInput.key"
      :format-func="chartInput?.formatFunc"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import BarChart from '@/intelligence/components/Dashboard/Elements/BarChart.vue';
import useShared from '@/intelligence/composables/useShared';
import { TEMPLATE_REPORT_CHART_COLOR_SETS } from '@/intelligence/store/data/charts';

const props = defineProps({
  chartInput: {
    type: Object,
    default: () => ({}),
  },
  reportId: {
    type: Number,
    default: null,
  }
});

const { getReportData } = useShared();
const reportData = computed(() => getReportData(props.reportId));
const chartColour = computed(() => {
  const [colorStandard] = TEMPLATE_REPORT_CHART_COLOR_SETS[props.chartInput.colorSet ?? 1];
  return `var(${colorStandard})`;
});
</script>

<style lang="scss" scoped>
h3 {
  font-weight: var(--font-weight-semibold);
  text-align: center;
}
</style>
