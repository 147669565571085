<template>
  <div class="search">
    <div class="buying flow">
      <ProductDataSearch
        :filter="filter"
        status="include"
        field-id="buying"
        :selected-value="selectedValue"
        @add-product="addProduct"
        @delete-product="deleteProduct"
      />
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import {
  CLEAR_CRM_PRODUCTS,
  CLEAR_PRODUCT_GROUPS,
} from '@/admin/store/actionType';
import ProductDataSearch from '@/intelligence/components/AlertBuilder/AlertWizard/ProductDataSearch.vue';

const store = useStore();

const clearCrmProducts = () => store.dispatch(`admin/associations/${CLEAR_CRM_PRODUCTS}`);
const clearProductGroups = () => store.dispatch(`admin/associations/${CLEAR_PRODUCT_GROUPS}`);

defineProps({
  selectedValue: {
    type: String,
    default: '',
  },
  filter: {
    type: Object,
    default: () => ({}),
  }
});

onMounted(async () => {
  clearCrmProducts();
  clearProductGroups();
});

const emit = defineEmits(['addProduct', 'deleteProduct']);

function addProduct(product) {
  emit('addProduct', product);
}

function deleteProduct(product) {
  emit('deleteProduct', product);
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.search {
  display: block;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    display: grid;
    grid-template-columns: 6fr var(--spacing-6) 6fr;
    gap: var(--spacing-2);
  }
}

.buying,.not-buying {
  --flow-space: var(--spacing-1);
  padding: var(--spacing-1);
  overflow-y: hidden;
}

.operator {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-size: var(--font-size-4);
  font-weight: var(--font-weight-semibold);
  margin-top: var(--spacing-3);

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    margin-top: var(--spacing-6);
  }

}
  .rendered-list {
    overflow: scroll;
  }
</style>
