/* eslint-disable no-unused-vars */
import {
  GET_ALL,
  GET_ALL_LOADING,
  GET_ALL_ERROR,
  CLEAR_ALL
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';
import querystring from 'querystring';

// mutations
export const getAllMutations = {
  [GET_ALL]: (state, data) => {
    state.all.data = data;
    state.all.loaded = true;
    state.all.loading = false;
    state.all.error = undefined;
  },
  [GET_ALL_LOADING]: (state) => {
    state.all.loaded = false;
    state.all.loading = true;
    state.all.error = undefined;
  },
  [GET_ALL_ERROR]: (state, error) => {
    state.all.loaded = false;
    state.all.loading = false;
    state.all.error = error;
  },
  [CLEAR_ALL]: (state) => {
    state.all.data = [];
    state.all.loaded = false;
    state.all.loading = false;
    state.all.error = undefined;
  },
};

// actions
/**
 * parameters:
 *  allocated_to
 *  customer
 *  prospect
 *  offset
 *  limit
 *  sort
 */
export const getAllActions = {
  [GET_ALL]: async ({ commit, }, parameters) => {
    try {
      const params = querystring.stringify(parameters);
      commit(GET_ALL_LOADING);
      const response = await httpRequest('get', `opportunities/with-products?${params}`, {}, {version: '2',});
      commit(GET_ALL, response);
      return true;
    } catch (error) {
      commit(GET_ALL_ERROR, error);
      return false;
    }
  },
  [CLEAR_ALL]: ({ commit, }) => {
    commit(CLEAR_ALL);
  },
};
