<template>
  <div class="address-field">
    {{ userAddress }}
  </div>
</template>

<script setup>
import { address as addr } from '@sales-i/utils';
import { computed } from 'vue';

const props = defineProps({
  addresses: {
    type: Array,
    default: () => [],
  },
});

const userAddress = computed(() => {
  const address = props.addresses;
  return addr.asString(address);
});
</script>

<style lang="scss" scoped>
.address-field {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
