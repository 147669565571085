<template>
  <MenuFilter
    class="customer-filter"
    :items="processedCustomerList"
    :selected-value="selectedCustomer"
    menu-position="right"
    @on-change="changeCustomer"
  />
</template>

<script setup>
import { computed } from 'vue';
import { MenuFilter } from '@sales-i/dsv3';
import useShared from '@/intelligence/composables/useShared';

const props = defineProps({
  customerList: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['changeCustomer']);

/** TODO: move this to the parent, make it pure component */
const { requestParameters } = useShared();
const customer = computed(() => requestParameters.value.snapshotBubbleValue);
/** -- */

const selectedCustomer = computed(() => customer.value.replace(/\s/g, ''));

const processedCustomerList = computed(() => {
  return props.customerList.map(customer => ({ ...customer, text: customer.name }));
});

const changeCustomer = (customerObject) => {
  emit('changeCustomer', customerObject.text);
};
</script>