<template>
  <div class="filter-list-view">
    <h2>{{ t('Filters') }}</h2>
    <div class="filter-list-content">
      <CRMCard
        v-for="(filter, i) in filters"
        :key="i"
        class="filter-card"
        :title="filter.title"
        :links="getCardLinks(filter)"
        @delete="$emit('deleteFilter', $event.filter)"
        @edit="$emit('editFilter', $event.filter)"
      >
        <template #info>
          <div class="filter-text">
            <span class="fw-sbld">
              {{ filter.description }}
            </span>
            <span v-if="![INTERACTION_OUTCOMES, INTERACTION_SPEND_SUMMARY].includes(inputData.urlCode)">
              &nbsp;{{ t(filter.filter_type === 'include' ? 'includes' : 'excludes').toLowerCase() }}&nbsp;
            </span>
            <ExpandableList :items="filter?.selected_values" />
          </div>
        </template>
      </CRMCard>
      <div class="add-filter-container">
        <CustomButton
          small
          :label="t('Add another filter')"
          @on-click="$emit('addFilter')"
        />
      </div>
    </div>
    <ShowGapsCheckbox v-if="inputData.urlCode === SALES_VS_GAPS" />
    <div class="filters-footer">
      <CustomButton
        small
        :label="t('Return to data')"
        @on-click="$emit('closeModal')"
      />
    </div>
  </div>
</template>

<script setup>
import { CRMCard, CustomButton } from '@sales-i/dsv3';
import { INTERACTION_OUTCOMES, INTERACTION_SPEND_SUMMARY, SALES_VS_GAPS } from '@/intelligence/store/data/reportTypes';
import { t } from '@sales-i/utils';
import ExpandableList from '@/shared/components/List/ExpandableList.vue';
import ShowGapsCheckbox from '@/intelligence/components/EnquiryReport/EnquiryFilter/ShowGapsCheckbox.vue';

defineProps({
  filters: {
    type: Object,
    required: true,
  },
  inputData: {
    type: Object,
    default: () => ({}),
  },
});

defineEmits(['deleteFilter', 'editFilter', 'addFilter', 'closeModal']);

const getCardLinks = (filter) => {
  const cardLinks = [
    {
      text: t('Delete'),
      emit: 'delete',
      filter,
    },
  ];

  cardLinks.push({
    text: t('Edit'),
    emit: 'edit',
    filter,
  });

  return cardLinks;
};
</script>

<style lang="scss" scoped>
.filter-list-view {
  display: flex;
  flex-direction: column;
  padding: 0;

  h2 {
    padding: var(--spacing-3) var(--spacing-2) var(--spacing-2);
  }

  .filter-list-content {
    height: 365px;
    overflow: auto;
    max-height: calc(100vh - 300px);
    overflow: auto;

    .filter-card {
      border-radius: unset;
      box-shadow: none;
      border-bottom: 1px solid var(--shadow-colour);
      height: fit-content;
      width: 100%;
    }
  }

  .filters-footer,
  .add-filter-container {
    padding: var(--spacing-2);
    display: flex;
    justify-content: center;
  }
  .filters-footer {
    background-color: var(--colour-panel-action);
  }
}

.filter-text {
  display: inline;
}
</style>
