<template>
  <SearchList
    :selected-items="selectedItems"
    :items="searchOptions"
    :loading="loading"
    @search="loadCommunities({ q: $event, offset: 0 })"
    @load-page="loadCommunities({ q, offset: $event})"
    @clear-search="clearCommunitiesSearch"
    @update:selected-items="handleSelectedCommunities"
  />
</template>

<script setup>
import { computed, ref } from 'vue';
import SearchList from '@/shared/components/SearchList/SearchList.vue';
import { useCommunities } from '@/intelligence/composables/useCommunities';

const emit = defineEmits(['update:selectedItems']);

const { communitiesOptions, getCommunities } = useCommunities();

defineProps({
  selectedItems: {
    type: Array,
    default: () => [],
  },
});

const q = ref('');
const offset = ref(0);
const loading = ref(false);

const searchOptions = computed(() => communitiesOptions.value.filter(c => c.name.includes(q.value) || q.value.trim() === ''));

const loadCommunities = async (query) => {
  // communities does not have paging abilities
  if (offset.value > 0) return;
  loading.value = true;
  q.value = query.q;
  // we do not make call to api in case it is the last page, when offsetValue == offset.value and > 0
  await getCommunities(query);
  offset.value = query.offset;
  loading.value = false;
};

const handleSelectedCommunities = (items) => {
  emit('update:selectedItems', items);
};

const clearCommunitiesSearch = () => {
  q.value = '';
  offset.value = 0;
  loading.value = false;
};
</script>

<style lang="scss" scoped>
</style>