<template>
  <CustomModal
    id="reports-modal"
    class="reports-modal"
    :show-modal="isModalVisible"
    position="top"
    @close-modal="closeModal"
  >
    <Tabs
      twoway
      :items="navigationTabs"
      :selected-index="activeAreaIndex"
      @nav-clicked="navClicked"
    />
    <div
      role="list"
      class="list list-card"
    >
      <ReportCard
        v-for="(item, index) in itemsFilteredByType"
        :key="index"
        :title="item.title"
        :links="getLinks"
        :on-click="() => goToReport(item)"
        @open="goToReport(item)"
      >
        <template #info>
          {{ item.description }}
        </template>
      </ReportCard>
    </div>
  </CustomModal>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import Tabs from '@/shared/components/Tabs/Tabs';
import { CRMCard as ReportCard, CustomModal } from '@sales-i/dsv3';
import { SET_ACTIVE_AREA, CLEAR_VISIBLE } from '@/shared/store/actionType';
import { REFRESH_REPORT } from '@/intelligence/store/actionType';
import {
  REPORT_AREAS,
  REPORT_AREA_PERFORMANCE,
  REPORT_AREA_PRODUCT,
  REPORT_AREA_CUSTOMER,
} from '@/intelligence/store/data/areas';
import { baseUrl as intelligenceUrl } from '@/intelligence/router/urlBits';
import { navigateToUrl } from 'single-spa';
import { capitalize } from '@/shared/utils/strings';

export default {
  components: {
    CustomModal,
    Tabs,
    ReportCard,
  },
  props: {
    activeArea: {
      type: [String, Boolean],
      default: false,
    },
  },
  emits: ['closeModal'],
  computed: {
    ...mapState({
      isModalVisible: state => state.reportsModal.visible,
    }),
    ...mapGetters({
      getInputArea: 'intelligence/shared/getInputArea',
    }),
    itemsFilteredByType() {
      return this.getInputArea(this.activeArea);
    },
    getLinks() {
      return [{ text: 'Open', emit: 'open' }];
    },
    navigationTabs() {
      return REPORT_AREAS.map(area => ({ title: area }));
    },
    activeAreaIndex() {
      return REPORT_AREAS.indexOf(this.activeArea);
    },
  },
  mounted() {
    this.setActiveArea();
  },
  methods: {
    ...mapActions({
      setArea: `reportsModal/${SET_ACTIVE_AREA}`,
      hideReportsModal: `reportsModal/${CLEAR_VISIBLE}`,
      refreshReport: `intelligence/shared/${REFRESH_REPORT}`,
    }),
    closeModal() {
      this.hideReportsModal();
      this.$emit('closeModal');
    },
    navClicked(data) {
      this.setArea(data.item.title);
    },
    goToReport(item) {
      this.closeModal();
      const route = this.$router.resolve({ name: item.urlCode });
      navigateToUrl(route.href);
      this.refreshReport();
    },
    setActiveArea() {
      if (!this.activeArea) {
        const reportsViews = [REPORT_AREA_PERFORMANCE, REPORT_AREA_PRODUCT, REPORT_AREA_CUSTOMER];
        const routePath = this.$route.path;
        let areaUrl = routePath.split('/'); // LOOK INTO THIS MORE?
        if (routePath === '/' || areaUrl.length <= 2 || areaUrl[1] !== intelligenceUrl) {
          this.setArea(REPORT_AREA_PRODUCT);
          return;
        }
        areaUrl = areaUrl[2];
        areaUrl = capitalize(areaUrl, { onlyFirstLetter: true });
        this.setArea(reportsViews.includes(areaUrl) ? areaUrl : REPORT_AREA_PRODUCT);
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/shared/assets/scss/_variables';

#reports-modal {
  @media screen and (max-height: 500px) {
    min-height: 96vh;
  }

  .content {
    padding: 0 var(--spacing-4);
  }

  .header {
    padding: var(--spacing-2);

    .close-button {
      position: absolute;
      top: var(--spacing-3);
      right: var(--spacing-4);
      z-index: 1;
    }
  }

  .crm-card {
    position: relative;

    .card-info {
      margin-bottom: var(--spacing-4);
    }

    .links {
      position: absolute;
      bottom: var(--spacing-2);
      right: var(--spacing-2);
    }
  }

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    .content-wrapper {
      padding-bottom: 0;
      overflow-y: unset;
    }
    .content {
      max-height: unset;
      width: 100%;
      height: auto;
      overflow-y: unset;
      padding: 0;
      display: flex;
      flex-direction: column-reverse;

      .list.list-card {
        padding: var(--spacing-2);
        overflow-y: auto;
        max-height: 450px;
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
        grid-auto-rows: min-content;
        grid-gap: var(--spacing-1);
        margin-bottom: 0;
      }

      .crm-card {
        width: 100%;
        max-width: none;
        min-height: 100px;
        height: 100%;
        margin-bottom: var(--spacing-1);
      }

      .tabs.sm,
      .tabs.xs {
        position: static;
      }
    }

    .slide-group__wrapper {
      height: auto;
      padding-top: 0;
      padding-right: 0;

      .slide-group__content {
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
        padding-top: var(--spacing-1);
        padding-bottom: var(--spacing-1);

        .tab {
          margin-top: var(--spacing-half);
          margin-bottom: var(--spacing-half);

          &:first-of-type {
            margin-left: var(--spacing-half);
          }
        }
      }
    }

    .header {
      padding: var(--spacing-4);
    }
  }
}
</style>

<style lang="scss" scoped>
.crm-card {
  max-width: 360px;
}
</style>
