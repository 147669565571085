<template>
  <div>
    <header v-if="!hiddenHeader">
      <h2>{{ t('Artificial Intelligence') }}</h2>
      <p>{{ t('sales-ai is monitoring your sales data to help you sell smarter') }}</p>
    </header>

    <div class="container">
      <MenuFilter
        v-if="breakpoints.smAndDown.includes(mq.current)"
        :items="[
          {
            text: t('Missed Opportunities'),
            value: 'missed',
          },
          {
            text: t('Potential Opportunities'),
            value: 'potential',
          },
          {
            text: t('Product Group Gaps'),
            value: 'product-group-gaps',
          },
        ]"
        :selected-value="opportunity"
        @on-change="menuClicked"
      />

      <InlineTabs
        v-if="breakpoints.mdAndUp.includes(mq.current)"
        :items="navigation"
        :selected-index="selectedIndex"
        class="tabs-navigation"
        @nav-clicked="navClicked"
      />

      <ProductGroupGaps
        v-if="navigation[selectedIndex].key === 'product-group-gaps'"
        :entity-id="customerId"
      />

      <OpportunitiesView
        v-if="navigation[selectedIndex].key !== 'product-group-gaps'"
        :type="navigation[selectedIndex].key"
        :reload="reloadData"
        :customer-id="customerId"
        :type-filter="typeFilter"
        :is-rolled-up="isRolledUp"
        @dismiss="addFeedback"
        @opportunities-loaded="opportunitiesLoaded"
        @change-opportunity-level="changeOpportunityLevel"
      />
    </div>

    <DismissModal
      :dismiss-modal-open="dismissModalOpen"
      :close-dismiss-modal="closeDismissModal"
      :dismiss-options="dismissOptions"
      object-type="opportunity"
      @post-feedback="handlePostFeedback"
    />
  </div>
</template>

<script>
import breakpoints from '@/shared/utils/breakpoints';
import { MenuFilter } from '@sales-i/dsv3';
import { mapActions } from 'vuex';
import { DELETE_BY_ID, SET_ITEMS } from '@/shared/store/actionType';
import InlineTabs from '@/shared/components/Tabs/InlineTabs.vue';
import DismissModal from '@/admin/components/AiFeedback/DismissModal.vue';
import OpportunitiesView from '@/intelligence/views/Opportunities/OpportunitiesView.vue';
import ProductGroupGaps from '@/crm/components/ProductGroups/Gaps.vue';
import store from '@/shared/store';
import { baseUrl } from '@/intelligence/router/urlBits';
import { opportunitiesSection } from '@/admin/router/urlBits';
import { t } from '@sales-i/utils';

export default {
  name: 'Opportunities',
  components: {
    DismissModal,
    InlineTabs,
    MenuFilter,
    OpportunitiesView,
    ProductGroupGaps,
  },
  inject: ['mq'],
  props: {
    hiddenHeader: {
      type: Boolean,
      default: false,
    },
    customerId: {
      type: [String, Number],
      default: '',
    },
    isRolledUp: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      breakpoints,
      reloadData: false,
      dismissModalOpen: false,
      dismissOptions: {},
      navigation: [
        {
          title: t('Missed Opportunities'),
          path: `${baseUrl}/${opportunitiesSection}/missed`,
          key: 'missed',
        },
        {
          title: t('Potential Opportunities'),
          path: `${baseUrl}/${opportunitiesSection}/potential`,
          key: 'potential',
        },
        {
          title: t('Product Group Gaps'),
          path: `${baseUrl}/${opportunitiesSection}/product-group-gaps`,
          key: 'product-group-gaps',
        },
      ],
      opportunity: 'missed',
      selectedIndex: 0,
      typeFilter: 'group_level',
    };
  },
  watch: {
    selectedIndex() {
      this.reloadData = true;
    },
  },
  mounted() {
    this.setSelectedIndex(this.$route.fullPath);
  },
  methods: {
    t,
    ...mapActions({
      deleteOpportunity: `crm/opportunities/${DELETE_BY_ID}`,
      deleteOpportunityProducts: `crm/opportunityProducts/${DELETE_BY_ID}`,
    }),
    // When the user clicks an item in menuFilter, set the selectedIndex to the index of the item clicked
    menuClicked(item) {
      this.opportunity = item.value;
      this.selectedIndex = this.navigation.findIndex((nav) => nav.key === item.value);
      this.changeOpportunityLevel('group_level');
    },
    navClicked(data) {
      const { index } = data;
      this.selectedIndex = index;
      this.changeOpportunityLevel('group_level');
    },
    setSelectedIndex(path) {
      const index = this.navigation.findIndex((e) => {
        const keys = path.split('/');
        return keys.includes(e.key);
      });

      if (index === -1) {
        this.selectedIndex = 0;
        return;
      }
      this.selectedIndex = index;
    },
    closeDismissModal() {
      this.dismissModalOpen = false;
    },
    addFeedback(feedbackData) {
      const { products, opportunity } = feedbackData;
      const productCodes = Object.keys(products);
      if (productCodes.length > 0) {
        // Build a list of dismiss options for each product
        const feedbackProducts = productCodes
          .map((prod) => {
            if (products[prod]) {
              return {
                object: 'opportunity_product',
                object_id: prod,
                opportunity_id: opportunity.id.toString(),
              };
            }
          })
          .filter((o) => o !== undefined);

        if (feedbackProducts.length) {
          this.dismissOptions = feedbackProducts;
        } else {
          this.dismissOptions = {
            object: 'opportunity',
            object_id: opportunity.id.toString(),
          };
        }
      } else {
        this.dismissOptions = {
          object: 'opportunity',
          object_id: opportunity.id.toString(),
        };
      }

      this.dismissModalOpen = true;
    },
    async handlePostFeedback() {
      try {
        if (this.dismissOptions.constructor === Array) {
          // Delete the products
          const payload = {
            id: this.dismissOptions[0].opportunity_id,
            products: this.dismissOptions.map((prod) => ({
              product_code: prod.object_id,
            })),
          };
          await this.deleteOpportunityProducts(payload);
        } else {
          await this.deleteOpportunity(this.dismissOptions.object_id);
        }
        store.dispatch(`alerts/${SET_ITEMS}`, {
          type: 'success',
          message: t('Successfully deleted'),
          selfDismiss: true,
        });

        // Close all modals, and re-load the oppo data
        this.closeDismissModal();
        this.reloadData = true;
      } catch (e) {
        store.dispatch(`alerts/${SET_ITEMS}`, {
          type: 'error',
          message: t('An error occurred deleting opportunity'),
          selfDismiss: true,
        });
      }
    },
    opportunitiesLoaded() {
      this.reloadData = false;
    },
    changeOpportunityLevel(level) {
      this.typeFilter = level;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.tabs-navigation {
  margin-bottom: var(--spacing-4);
}

.period-commencing-menu {
  display: flex;
  align-items: center;
  gap: var(--spacing-1);
  font-size: 18px;
  text-align: left;
  margin-bottom: var(--spacing-3);

  :deep(.filter-select__selections) {
    display: flex;
  }
}

header {
  margin-bottom: var(--spacing-3);
  display: flex;
  flex-direction: column;
  text-align: center;

  h2 {
    display: flex;
    align-self: center;

    .logo {
      margin-right: var(--spacing-1);
    }
  }
}

.logo {
  display: inline;
}

.container {
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    max-width: 75%;
  }
  margin: auto;
}
</style>
