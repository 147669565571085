<template>
  <button
    v-if="isQueryStringChanged && vroute.query.id"
    class="save-enquiry"
    @click="openUnsavedChangesModal"
  >
    <IconBase
      :width="iconSize"
      :height="iconSize"
      icon-name="warning"
      icon-color="var(--colour-brand-mandy)"
    />
    <IconButton
      icon-name="chevron-down"
      label="Unsaved changes"
      purpose="transparent"
      icon-color="var(--colour-brand-mandy)"
      text-color="var(--colour-brand-mandy)"
      :icon-size="24"
    />
  </button>

  <SaveChangesDialog
    v-if="showUnsavedChangesModal"
    :query="query"
    :computed-widget-data="computedWidgetData"
    @save-as-new-widget="addWidget"
    @widget-updated="handleWidgetUpdated"
    @close-widget-modal="closeWidgetModal"
  />
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useMq } from 'vue3-mq';
import breakpoints from '@/shared/utils/breakpoints';
import { useDashboard } from '@/intelligence/components/Dashboard/composables/useDashboard';
import { useWidget } from '@/intelligence/components/Dashboard/composables/useWidget';
import SaveChangesDialog from '@/intelligence/components/ReportFooter/SaveChangesDialog.vue';
import { IconBase, IconButton } from '@sales-i/dsv3';

defineProps({
  query: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['saveAsNewWidget']);

const mq = useMq();
const vroute = useRoute();
const store = useStore();
const widgetData = ref(null);

const {
  getWidgetById,
  showUnsavedChangesModal,
  isQueryStringChanged,
  setUnsavedChangesModal,
  setQueryStringChanged
} = useDashboard({ store });
const { stripWidgetUrlParams } = useWidget();

const computedWidgetData = computed(() => {
  if (!widgetData.value) return {};
  const parsedWidgetData = {
    ...widgetData.value,
    request_params: JSON.parse(widgetData.value.request_params),
    settings: JSON.parse(widgetData.value.settings),
  };
  return parsedWidgetData;
});

const isMediumScreen = computed(() => breakpoints.mdAndDown.includes(mq.current));
const iconSize = computed(() => isMediumScreen.value ? 48 : 32);

function openUnsavedChangesModal() {
  setUnsavedChangesModal(true);
}

function handleWidgetUpdated() {
  setUnsavedChangesModal(false);
  setQueryStringChanged(false);
}

function addWidget() {
  emit('saveAsNewWidget');
}

function closeWidgetModal() {
  setUnsavedChangesModal(false);
}

onMounted(async () => {
  if (!vroute.query.id) return;
  // Get the widget data only if there is an id in the query
  if (vroute.query.id) {
    await getWidget();
  }
  const currentUrl = stripWidgetUrlParams(vroute.fullPath);
  const widgetUrl = stripWidgetUrlParams(computedWidgetData.value.settings?.url);
  if (widgetUrl && currentUrl !== widgetUrl) {
    setQueryStringChanged(true);
  }
});

watch(
  // watch for changes in the route query
  () => vroute.query,
  () => {
    setQueryStringChanged(true);
  },
  { deep: true }
);


async function getWidget() {
  widgetData.value = await getWidgetById({
    id: vroute.query.dashboard_id,
    widgetId: vroute.query.id
  });
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.save-enquiry {
  color: var(--colour-brand-mandy);
  display: flex;
  align-items: center;
  gap: var(--spacing-1);
  text-wrap: nowrap;
  position: fixed;
  z-index: 100;
  right: 112px;
  bottom: 12px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    position: absolute;
    right: var(--spacing-10);
    top: 20%;
    bottom: var(--spacing-2);
  }
}

.save-enquiry :deep(.icon-button) {
  display: none;
  
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    display: flex;
  }
}
</style>