<template>
  <CustomModal
    show-modal
    :show-unload-warning="isFormDirty"
    :max-width="800"
    :title="t('Generate interactions')"
    class="generate-interactions-modal"
    @close-modal="close"
  >
    <div class="rendered-list-column">
      <div class="search-item-container">
        <div class="search-item">
          <input
            v-model="searchQuery"
            class="input"
            type="text"
            :aria-label="t('Search customers')"
            :placeholder="t('Search customers')"
            @input="debounceSearchEvent"
          >
          <CustomButton
            class="search-button"
            purpose="transparent"
            icon-name="search"
            icon-only
            :icon-width="22"
            :icon-height="22"
          />
        </div>
      </div>
      <ul class="rendered-list">
        <BufferImage
          v-if="dataLoading"
          color="var(--colour-utility-black)"
          float="center"
          class="loading-spinner"
        />
        <template v-if="!dataLoading && !isFiltering">
          <GenerateInteractionsForm
            :customer-list="renderedList"
            @customer-selection="customerSelection"
          />
        </template>
      </ul>
    </div>

    <template #footer>
      <ButtonGroup>
        <CustomButton
          :label="t('Generate')"
          purpose="action"
          small
          :disabled="!isValid"
          :class="{ save: true, disabled: !isValid }"
          @on-click="generateInteractions"
        />
      </ButtonGroup>
    </template>
  </CustomModal>
</template>

<script>
import GenerateInteractionsForm from '@/crm/components/Calendar/GenerateInteraction/GenerateInteractionsForm.vue';
import { BufferImage, CustomModal, CustomButton } from '@sales-i/dsv3';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import { mapActions, mapState, mapGetters } from 'vuex';
import { acceptedDimensions, ACCOUNT_NUMBER } from '@/intelligence/components/ReportFooter/acceptedDimensions';

import { SET_ENTITY_ID } from '@/crm/store/actionType';
import { SET_ROUTE } from '@/shared/store/actionType';
import { baseUrl, interactionsArea } from '@/crm/router/urlBits.js';
import { baseUrl as intelligenceBaseUrl, customerScope, insightsScope, alertsScope } from '@/intelligence/router/urlBits.js';
import { FULL_PICTURE, VARIANCE, QUERY_BUILDER } from '@/intelligence/store/data/reportTypes';
import { debounce, t } from '@sales-i/utils';
import { navigateToUrl } from 'single-spa';

export default {
  name: 'GenerateInteractionsModal',
  components: {
    BufferImage,
    ButtonGroup,
    CustomModal,
    CustomButton,
    GenerateInteractionsForm,
  },
  props: {
    entityType: {
      type: String,
      default: 'customer',
    },
    reportType: {
      type: [String, Boolean],
      default: false,
    },
  },
  emits: ['close', 'input'],
  data() {
    return {
      filterOptions: [],
      renderedList: [],
      entityIDs: [],
      isValid: false,
      searchQuery: '',
      isFiltering: false,
    };
  },
  computed: {
    ...mapState({
      bubbleValues: state => state.intelligence.enquiry.bubbleValues,
      dataLoading: state => state.intelligence.enquiry.bubblesLoading,
      fetchedData: state => state.intelligence.shared.fetchedData,
      searchBarResults: state => state.intelligence.shared.searchBarResults,
    }),
    ...mapGetters({
      getSavedAlertsCustomers: 'intelligence/savedQuery/uniqueCustomers'
    }),
    debounceSearchEvent() {
      if (this.searchQuery.length) {
        this.filterList();
      } else {
        this.resetFilterList();
      }

      return debounce(this.filterList, 500);
    },
    customerList() {
      let list = [];

      // this applies to Saved Alerts
      if (this.$route.path.includes(`${alertsScope}`)) {
        if (this.getSavedAlertsCustomers) {
          list = this.getSavedAlertsCustomers.map(({ id, title }) => ({
            id,
            text: title,
            checked: false,
          }));
        }

        return list;
      }
      
      const selectedData = Object.values(this.fetchedData)
        .filter(item => item.reportType === this.reportType)
        .map(item => item.data)
        .flat();

      const selectedRows = Object.values(this.fetchedData)
        .filter(item => item.reportType === this.reportType)
        .map(item => item.data.rows)
        .flat();

      // this applies to Matrix / SVG queries
      if (selectedData.length > 0 && 'axis' in selectedData[0] && selectedData[0].axis) {
        let headingIndex = 0;

        const customerIDs = selectedRows.map((row) => {
          return {
            id: row.dimension_values[0].id,
            customer_no: row.dimension_values[0].values[1],
          };
        });

        // if Acc No flip headingIndex to get Customer Name
        if (selectedData[0].axis.y.entity === ACCOUNT_NUMBER) {
          headingIndex = 1;
        }
        
        list = selectedData
          .map(item => item.axis.y[0].headings)
          .flat()
          .map(item => {
            const customer = item[headingIndex];
            return {
              text: item[headingIndex],
              customer_no: customer[1],
              checked: false,
            };
          });

        // Merge customerIDs and customerNames into one array of objects with the customer name and id.
        list = list.map((item, index) => {
          return {
            ...item,
            ...customerIDs[index],
          };
        });

        return list;
      }

      // This applies to all reports in the customer insights section
      if (selectedData.length && this.$route.path.includes(`${intelligenceBaseUrl}/${insightsScope}/${customerScope}`)) {
        // Check if the selectedData item has a customers key, as different reports have different data structures(inside customerScope).
        const dataToMap = selectedData[0].customers || selectedData;

        list = dataToMap.map(({ id, customer, name }) => ({
          id,
          text: customer || name,
          checked: false,
        }));
      }

      // this applies to Full Picture / Variance
      if ([FULL_PICTURE, VARIANCE, QUERY_BUILDER].includes(this.reportType) && selectedRows.length) {
        
        let lookupIndex = 1;
        let headingIndex = 0;

        if (
          selectedData[0].headings.dimensions[1] &&
          acceptedDimensions.includes(selectedData[0].headings.dimensions[1]) &&
          selectedData[0].headings.dimensions[1] === ACCOUNT_NUMBER
        ) {
          headingIndex = 1;
        }
        if (acceptedDimensions.includes(selectedData[0].headings.dimensions[0])) {
          lookupIndex = 0;
          headingIndex = 0;
          if (selectedData[0].headings.dimensions[0] === ACCOUNT_NUMBER) {
            headingIndex = 1;
          }
        }
        list = selectedRows.map(item => {
          return {
            text: item.dimensions[lookupIndex].dimensions[headingIndex],
            id: item.dimensions[lookupIndex].entity_id,
            checked: false,
          };
        });

        let arrIds = [];
        list = list.filter(item => {
          let shouldReturn = false;
          if (!arrIds.includes(item.id)) shouldReturn = true;
          arrIds.push(item.id);
          return shouldReturn;
        });

        return list;
      }

      return list;
    },
    items() {
      return this.searchBarResults;
    },
    isFormDirty() {
      return this.entityIDs.length > 0;
    },
  },
  mounted() {
    this.renderedList = this.customerList;
  },
  methods: {
    t,
    ...mapActions({
      setEntityIDs: `crm/interactions/${SET_ENTITY_ID}`,
      setRoute: `system/${SET_ROUTE}`,
    }),
    async getCustomers() {
      this.filterList([this.searchQuery]);
      this.resetFilterList();
    },
    async filterList() {
      this.isFiltering = true;
      await new Promise((resolve) => {
        this.renderedList = this.customerList.filter(option => (
          option.text.toLowerCase().includes(this.searchQuery.toLowerCase())
        ));
        resolve();
      });
      this.isFiltering = false;
    },
    resetFilterList() {
      this.renderedList = this.customerList;
    },
    close() {
      this.$emit('close');
    },
    customerSelection(value) {
      this.entityIDs = value;
      this.isValid = value.length > 0 && value.every(item => item !== null);
    },
    async generateInteractions() {
      // Convert the entityIDs strings into numbers.
      const generatedEntityIDs = this.entityIDs.map(item => Number(item));
      await this.setEntityIDs(generatedEntityIDs);
      this.setRoute({
        success: this.$route.fullPath,
        cancel: this.$route.fullPath,
      });
      navigateToUrl(`${baseUrl}/${interactionsArea}/generate-customer-interaction`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
:deep(.content-wrapper) {
  padding-bottom: 0;
}

.generate-interactions-modal {
  .form-grid {
    padding: 0 var(--spacing-2);

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      padding: 0 var(--spacing-3);
    }

    & > :deep(.row) {
      margin-right: 0;
      margin-left: 0;
    }
  }
}

.modal-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  min-width: 300px;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.1) center center no-repeat;
  display: flex;
}

.search-item-container {
  padding: var(--spacing-2) var(--spacing-3) var(--spacing-1);
  background: var(--colour-panel-action);

  .search-item {
    position: relative;
    margin-bottom: var(--spacing-1);
  }
}

.input {
  background: var(--colour-panel-g-0);
  box-shadow: 0 0 var(--spacing-half) var(--shadow-spread) var(--colour-panel-g-16);
  border-radius: var(--spacing-4);
  font-size: var(--font-size-small);
  line-height: var(--spacing-2);
  font-weight: var(--font-weight-regular);
  padding-left: var(--spacing-5);
}

.search-button {
  position: absolute;
  top: 50%;
  left: var(--spacing-1);
  transform: translateY(-50%);
}

.rendered-list {
  overflow-y: auto;
  height: 330px;
  max-height: calc(100vh - 400px);

  .loading-spinner {
    position: absolute;
    top: calc(50% + 50px);
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1;
  }
}
</style>
