import {
  ENABLE_TARGETS,
  FETCH_MEASUREMENTS,
  FETCH_REPORT_DATA,
  FETCH_TARGET_PERIODS,
  SAVE_MEASUREMENTS,
  SAVE_REPORT_DATA,
  SAVE_TARGET_PERIODS,
  TOGGLE_TARGETS_SWITCH,
  SAVE_CURRENT_VALUES,
  SAVE_CURRENT_DIMENSIONS,
  CLEAR_CURRENT_TARGET,
  SAVE_WHOLE_DIM
} from '@/intelligence/store/actionType';
import { getRequestParameters } from '@/intelligence/store/modules/targets/getRequestParameters';
import { getResponseParameters } from '@/intelligence/store/modules/targets/getResponseParameters';
import { GET_BY_ID } from '@/shared/store/actionType';
import { gqlRequest } from '@sales-i/utils';
import { gql } from 'graphql-request';

const targetsEndpoint = `${process.env.VUE_APP_API_V2_ENDPOINT}/targets/v2/query`;

// initial state
const state = () => ({
  targetsData: {},
  periodsData: [],
  measurementsData: [],
  isTargetsSwitchActive: false,
  areTargetsEnabled: false,
  currentTargetValues: []
});

// getters
const getters = {
  getRequestParameters,
  getResponseParameters,
};

// mutations
const mutations = {
  [SAVE_REPORT_DATA]: (state, targetsData) => {
    state.targetsData = targetsData;
  },
  [SAVE_TARGET_PERIODS]: (state, periodsData) => {
    state.periodsData = periodsData;
  },
  [SAVE_MEASUREMENTS]: (state, measurementsData) => {
    state.measurementsData = measurementsData;
  },
  [TOGGLE_TARGETS_SWITCH]: (state) => {
    state.isTargetsSwitchActive = !state.isTargetsSwitchActive;
  },
  [ENABLE_TARGETS]: (state, value) => {
    state.areTargetsEnabled = value;
  },
  [SAVE_CURRENT_VALUES]: (state, payload) => {
    const { bubbleId, dimIndex, selectedBubble } = payload;
    if (state.currentTargetValues && state.currentTargetValues[dimIndex].id == bubbleId) {
      const { id, value } = selectedBubble;
      if (value && !state.currentTargetValues[dimIndex].values.includes(id)) {
        state.currentTargetValues[dimIndex].values.push(id);
      } else {
        if (state.currentTargetValues[dimIndex].checkedAll) state.currentTargetValues[dimIndex].checkedAll = false;
        state.currentTargetValues[dimIndex].values = state.currentTargetValues[dimIndex].values.filter(existingId => existingId !== id);
      }
    }
  },
  [SAVE_CURRENT_DIMENSIONS]: (state, newValues) => {
    if(state.currentTargetValues.length !== newValues.length) {
      state.currentTargetValues = newValues;
    } else {
      newValues.forEach((newValue, index) => {
        if (!state.currentTargetValues.some(existingValue => existingValue.id === newValue.id)) {
          state.currentTargetValues[index] = newValue;
        }
      });
    }
  },
  [SAVE_WHOLE_DIM]: (state, payload) => {
    const { checked, dimIndex, bubbleId } = payload;
    if (state.currentTargetValues && state.currentTargetValues[dimIndex].id == bubbleId) {
      state.currentTargetValues[dimIndex].values = [];
      state.currentTargetValues[dimIndex].checkedAll = !!checked;
    }
  },
  [CLEAR_CURRENT_TARGET]: (state) => {
    state.currentTargetValues = [];
  },
};

// actions
const actions = {
  [FETCH_REPORT_DATA]: async ({ commit, getters }, { type = 'get', params } = {}) => {
    const reportTypes = ['get', 'delete', 'update', 'add', 'deleteTargetsByBubbleId'];
    if (!reportTypes.includes(type)) return;
    try {
      let requestEntity = `${type === 'add' ? 'add' : type}Target${type === 'get' ? 's' : ''}`;
      if (type === 'deleteTargetsByBubbleId') {
        requestEntity = type;
      }
      const { getRequestParameters, getResponseParameters } = getters;
      let query = gql`${type === 'get' ? '' : 'mutation'} { ${requestEntity}(${getRequestParameters([type, params])}) { ${getResponseParameters(type)} }}`;
      if (type === 'deleteTargetsByBubbleId') {
        query = gql`${type === 'get' ? '' : 'mutation'} { ${requestEntity}(${getRequestParameters([type, params])}) }`;
      }
      const response = await gqlRequest(query, {}, { debug: true }, targetsEndpoint);
      
      const targetsData = response[requestEntity];
      commit(SAVE_REPORT_DATA, targetsData);
      return targetsData;
    } catch (error) {
      console.error(error);
    }
  },
  // eslint-disable-next-line no-empty-pattern
  [GET_BY_ID]: async ({}, id) => {
    if (!id) return;
    try {
      const
        requestEntity = 'getTarget',
        query = gql`{ ${requestEntity}(id: ${id}) { 
          id
          name
          description
          dimensions {
            id
            values
          }
          measurement {
              id
              metric
              unit
          }
          periodValues {
              id
              interval_start
              interval_end
              value
          }
          interval {
            interval
            Description
          }
        }}`,
        response = await gqlRequest(query, {}, { debug: true }, targetsEndpoint);
      
      return response[requestEntity];
    } catch (error) {
      console.error(error);
    }
  },
  [FETCH_TARGET_PERIODS]: async ({ commit }) => {
    try {
      const
        query = gql`{ 
          getTargetPeriods {
            year
            interval
            description
          }
        }`,
        response = await gqlRequest(query, {}, { debug: true }, targetsEndpoint);
      
      const periodsData = response['getTargetPeriods'];
      commit(SAVE_TARGET_PERIODS, periodsData);
    } catch (error) {
      console.error(error);
    }
  },
  [FETCH_MEASUREMENTS]: async ({ commit }) => {
    try {
      const
        query = gql`{ 
          getMeasurements {
            id
            metric
            unit
          }
        }`,
        response = await gqlRequest(query, {}, { debug: true }, targetsEndpoint);
      
      const measurementsData = response['getMeasurements'];
      commit(SAVE_MEASUREMENTS, measurementsData);
    } catch (error) {
      console.error(error);
    }
  },
  [TOGGLE_TARGETS_SWITCH]: ({ commit }) => {
    commit(TOGGLE_TARGETS_SWITCH);
  },
  [ENABLE_TARGETS]: ({ commit }, value) => {
    commit(ENABLE_TARGETS, value);
  },
  [SAVE_CURRENT_VALUES]: ({commit}, values ) => {
    commit(SAVE_CURRENT_VALUES, values);
  },
  [SAVE_CURRENT_DIMENSIONS]: ({commit}, dimensions ) => {
    commit(SAVE_CURRENT_DIMENSIONS, dimensions);
  },
  [SAVE_WHOLE_DIM]: ({commit}, dimension ) => {
    commit(SAVE_WHOLE_DIM, dimension);
  },
  [CLEAR_CURRENT_TARGET]: ({commit}) => {
    commit(CLEAR_CURRENT_TARGET);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
