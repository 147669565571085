<template>
  <CRMCard
    :title="title"
    :currency="currency"
    :chips="getChips(item)"
    class="opportunity-card"
  >
    <template #info>
      <slot name="info" />
    </template>
  </CRMCard>
</template>

<script setup>
import { CRMCard } from '@sales-i/dsv3';
import useCurrency from '@/shared/composables/useCurrency';

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  item: {
    type: Object,
    default: () => ({}),
  },
});

const { currency } = useCurrency();

const getRolledUpValue = (item) => {
  let value = 0;
  const productsLength = item.products?.length;

  for (let x = 0; x < productsLength; x++) {
    value += item.products[x].value;
  }

  return value;
};

const getHighestProbability = (item) => {
  let value = 0;

  item.products?.forEach(_ref => {
    if (_ref.confidence > value) {
      value = _ref.confidence;
    }
  });

  return value;
};

const getChips = (item) => [
  {
    value: getRolledUpValue(item),
    prefix: props.valueSymbol,
    abbr: true,
    green: 20,
    amber: 10,
  },
  {
    value: getHighestProbability(item),
    suffix: '%',
    green: 67,
    amber: 33,
  },
];
</script>