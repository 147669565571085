<template>
  <span
    class="products-label"
    :class="{ expand: extendedOpportunityItem.expanded }"
  >
    <strong class="short">
      {{ shortProductCodes.join(', ') }}
      <CustomButton
        v-if="productCodes.length > productsLabelLimit"
        purpose="text"
        tabindex="0"
        @click="expand"
        @keydown.enter="expand"
      >
        + {{ productCodes.length - productsLabelLimit }} {{ t('more') }}
      </CustomButton>
    </strong>
    <strong class="long">
      {{ productCodes.join(', ') }}
    </strong>
  </span>
</template>

<script setup>
/** TODO: rewrite using computed and avoiding refs */
import { ref, computed, onMounted } from 'vue';
import { CustomButton } from '@sales-i/dsv3';
import { t } from '@sales-i/utils';

const props = defineProps({
  opportunityItem: {
    type: Object,
    default: () => ({}),
  },
  productsLabelLimit: {
    type: Number,
    default: 3,
  },
  opportunityType: {
    type: String,
    default: '',
  },
  enableProductCode: {
    type: Boolean,
    default: false,
  },
});

const extendedOpportunityItem = ref({ ...props.opportunityItem, expanded: false });
const productCodes = ref([]);

const products = computed(() => extendedOpportunityItem.value.products || []);

const getProductCodes = (isShort = false) => {
  const items = [];
  for (let i = 0; i < products.value.length; i++) {
    if (isShort && items.length === props.productsLabelLimit) break;
    if (products.value[i].prod_category === props.opportunityType) {
      items.push(`${products.value[i].product_name} ${props.enableProductCode ? `(${products.value[i].prod_code})` : ''}`);
    }
  }
  return items;
};

const shortProductCodes = computed(() => getProductCodes(true));

const expand = () => {
  extendedOpportunityItem.value.expanded = true;
};

onMounted(() => {
  productCodes.value = getProductCodes();
});
</script>

<style lang="scss" scoped>
.products-label {
  .long {
    display: none;
  }

  &.expand {
    .short {
      display: none;
    }
    .long {
      display: inline;
    }
  }

  a {
    cursor: pointer;
  }
}
</style>