<template>
  <div class="communities container">
    <LeftHandNavigation
      :loading="menu.loading"
      :items="menu.items"
      :sub-section="activeSubSection"
      :active-section="activeSection"
      add-type="community"
      @item-clicked="navigateToUrl($event.to)"
      @active-section-clicked="activeSection = $event"
      @sub-section-clicked="setSubSection"
      @create-entity="createEntity"
    >
      <router-view
        :key="$route.fullPath"
        :data-changed="dataChanged"
        @open-item="openItem"
        @community-updated="loadNavigation"
        @dismiss-community="addFeedback"
      />
    </LeftHandNavigation>

    <DismissModal
      :dismiss-modal-open="dismissModalOpen"
      :close-dismiss-modal="closeDismissModal"
      :dismiss-options="dismissOptions"
      :object-type="dismissOptions.constructor === Array ? 'products' : 'community'"
      @post-feedback="deleteCommunity"
    />
  </div>
</template>

<script setup>
import { GET_ALL, DELETE_BY_ID, SET_ITEMS, SET_ROUTE } from '@/shared/store/actionType';
import { DELETE_PRODUCTS } from '@/admin/store/actionType';
import LeftHandNavigation from '@/admin/components/Layout/LeftHandNavigation.vue';
import DismissModal from '@/admin/components/AiFeedback/DismissModal.vue';
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute, } from 'vue-router';
import { t } from '@sales-i/utils';
import usePermissions from '@/shared/composables/usePermissions';
import { product_communities_admin } from '@/shared/store/data/policies';
import { baseUrl, intelligenceArea, communitiesSection } from '@/admin/router/urlBits';
import { navigateToUrl } from 'single-spa';

const store = useStore();
const vroute = useRoute();
const { hasAccess } = usePermissions();

const activeSection = ref(0);
const activeSubSection = ref(9999);
const dismissModalOpen = ref(false);
const dismissOptions = ref({});
const dataChanged = ref(false);

const menu = ref({
  loading: true,
  items: [
    {
      id: 1,
      canCreateEntities: false,
      name: t('Communities'),
      to: { name: 'Admin/Intelligence/CommunitiesListView' },
      sections: [],
    },
  ],
});

const getAllCommunities = () => store.dispatch(`admin/communities/${GET_ALL}`);
const delCommunity = communityCode => store.dispatch(`admin/communities/${DELETE_BY_ID}`, communityCode);
const delCommunityProducts = productObject => store.dispatch(`admin/communities/${DELETE_PRODUCTS}`, productObject);
const setRoute = routeObject => store.dispatch(`system/${SET_ROUTE}`, routeObject);

const communities = computed(() => store.state.admin.communities);

watch(vroute, loadNavigation, { immediate: true });

async function loadNavigation() {
  await getAllCommunities();
  const navMenu = menu.value.items[0];
  navMenu.canCreateEntities = hasAccess(product_communities_admin, 'create');

  navMenu.sections = communities.value.data
    .filter(o => o.community_code !== '')
    .map((item, index) => {
      return {
        id: index,
        name: item.community_name,
        code: item.community_code,
        icons: {
          postLink:
            item.community_item_count === 1
              ? {
                icon: 'warning',
                content: t('This community only has 1 product'),
              }
              : false,
        },
        to: {
          path: `${baseUrl}/${intelligenceArea}/${communitiesSection}/${item.community_code}`,
        },
      };
    });

  // If we are in a deeper route, set the sub section here
  if (Object.keys(vroute.params).length > 0) {
    // Set the sub section
    const subItem = navMenu.sections.find(menuItem => menuItem.code === vroute.params.community_code);
    if (subItem === undefined) {
      makeSubSectionActive(9999);
    } else {
      makeSubSectionActive(subItem.id);
    }  
  } else {
    makeSubSectionActive(9999);
  }

  menu.value.loading = false;
}

function openItem(item) {
  const navMenu = menu.value.items[0];
  const subItem = navMenu.sections.find(menuItem => menuItem.code === item.community_code);

  makeSubSectionActive(subItem.id);

  navigateToUrl(`${baseUrl}/${intelligenceArea}/${communitiesSection}/${item.community_code}`);
}

function addFeedback(items, productCodes) {
  if (productCodes.length > 0) {
    // Build a list of dismiss options for each product
    const feedbackProducts = productCodes.map(prod => {
      return {
        object: 'product_community',
        object_id: prod,
        community_code: items[0].community_code,
      };
    });

    if (feedbackProducts.length) {
      dismissOptions.value = feedbackProducts;
    } else {
      dismissOptions.value = {
        object: 'product_community',
        object_id: items[0].community_code,
      };
    }
  } else {
    dismissOptions.value = {
      object: 'product_community',
      object_id: items[0].community_code,
    };
  }
  dismissModalOpen.value = true;
}

async function deleteCommunity() {
  const communityCode = communities.value.currentlyLoaded[0].community_code;
  switch (dismissOptions.value.constructor) {
  case Array:
    await delCommunityProducts({
      communityCode: communityCode,
      products: dismissOptions.value.map(item => {
        return {
          product_code: item.object_id,
          product_name: item.object_id,
          community_code: item.community_code,
          community_name: item.community_code,
        };
      }),
    });
    break;
  case Object:
    await delCommunity(communityCode);
    break;
  }

  if (communities.value.error === null) {
    closeDismissModal();
    const dismissType = dismissOptions.value.constructor !== Array ? 'community' : 'products';
    store.dispatch(`alerts/${SET_ITEMS}`, {
      type: 'success',
      message: t(`Successfully dismissed ${dismissType}`, 'successfully_dismissed_variable', {
        interpolations: {
          variable: dismissOptions.value.constructor !== Array ? 'community' : 'products',
        },
      }),
      selfDismiss: true,
    });
    if (dismissOptions.value.constructor !== Array) {
      navigateToUrl(`${baseUrl}/${intelligenceArea}/${communitiesSection}`);
    } else {
      dataChanged.value = true;

      setTimeout(() => (dataChanged.value = false), 500);
    }
  }
}

function closeDismissModal() {
  dismissModalOpen.value = false;
}

function setSubSection(item) {
  const navMenu = menu.value.items[0];
  navigateToUrl(navMenu.sections[item].to.path);

  makeSubSectionActive(item);
}

function makeSubSectionActive(item) {
  activeSubSection.value = item;
}

function createEntity() {
  setRoute({
    cancel: vroute.fullPath,
  });
  navigateToUrl(`${baseUrl}/${intelligenceArea}/${communitiesSection}/add`);
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.communities {
  margin-bottom: var(--spacing-3);
  margin-top: 0;
}
</style>
