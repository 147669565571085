<template>
  <div
    class="edit-dashboard"
  >
    <CustomModal
      :title="item.id ? t('Edit dashboard') : t('Create dashboard')"
      show-modal
      :show-unload-warning="isFormDirty"
      :min-width="300"
      @close-modal="closeModal"
    >
      <template #default>
        <CustomInput
          :label="t('Title')"
          :value="item.title"
          :min-length="1"
          :max-length="100"
          :error="!validateTextField(item.title, 1, 100)"
          :error-message="t('Title length should be between 1 and 100 characters')"
          required
          @input="handleFieldInput($event, 'title')"
        />
        <CustomTextarea
          :label="t('Description')"
          :value="item.description"
          :min-length="3"
          :max-length="200"
          required
          @input="handleFieldInput($event, 'description')"
        />
        <CustomSelect
          id="category"
          :label="t('Category')"
          :value="item.category"
          :items="categories.filter(c => c.value != OTHER_DASHBOARD_CATEGORY)"
          @input="handleFieldInput($event, 'category')"
        />
      </template>
      <template #footer>
        <CustomButton
          small
          :disabled="!isSaveButtonEnabled"
          class="mx-1"
          @click="save"
        >
          {{ t('Save') }}
        </CustomButton>
        <CustomButton
          small
          class="mx-1"
          @click="closeModal"
        >
          {{ t('Cancel') }}
        </CustomButton>
      </template>
    </CustomModal>
  </div>
</template>

<script setup>
// AddEditDahboard
import { CustomModal, CustomButton, CustomInput, CustomTextarea, CustomSelect } from '@sales-i/dsv3';
import { computed, onMounted, ref } from 'vue';
import { isEqualObjects, t } from '@sales-i/utils';
import { useDashboard } from '@/intelligence/components/Dashboard/composables/useDashboard';
import { OTHER_DASHBOARD_CATEGORY } from '@/intelligence/components/Dashboard/constants';

const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['close', 'save']);
const { categories, validateTextField } = useDashboard();

const initialFormValues = ref({});
// TODO: Refactor to use reactive object and ensure it doesn't use the same reference as the item prop
const item = ref({ ...props.item });
const isFormDirty = computed(() => !isEqualObjects(initialFormValues.value, item.value));

const isSaveButtonEnabled = computed(() => {
  return item.value.title?.trim() && item.value.description?.trim()?.length >= 3 && item.value.category;
});

onMounted(() => {
  initialFormValues.value = { ...item.value };
});

function closeModal() {
  emit('close');
}
function save() {
  emit('save', item.value);
}
function handleFieldInput(e, fieldName) {
  item.value[fieldName] = e;
}
</script>

<style lang="scss" scoped>
.edit-dashboard {
  :deep(.content) {
    padding: var(--spacing-3);
  }
}
</style>
