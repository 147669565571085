import {
  GET_BY_ENTITY,
  GET_BY_ENTITY_LOADING,
  GET_BY_ENTITY_ERROR,
  CLEAR_BY_ENTITY,
} from '@/shared/store/actionType';
import { getKeyByValue, httpRequest, t } from '@sales-i/utils';
import namesakes from '@/admin/store/data/interactionFieldNamesakes';


// getters
export const getByEntityGetters = {
  getFieldsOptions: (state) => Object.keys(state.data)
    .reduce((result, key) => (
      {
        ...result,
        [key]: state?.data[key]?.map(f => ({
          text: t(f.value),
          value: f.id
        }))
      }), {}),
  getInteractionOptionsFields: (state, getters, rootState) => {
    let { currentlyEditing: { furtherDetails } } = rootState.crm.interactions;
    
    let fields = [];
    let listnames = {
      nextAction: 'Next action',
      purpose: 'Interaction purpose',
    };

    let keys = Object.keys(listnames);

    keys.forEach(key => {
      let field = {
        label: listnames[key],
        id: key,
        type: 'select',
        options: [
        ],
      };
      if (key === 'nextAction') {
        field.required = false;
      }
      if (key === 'purpose') {
        field.valid = false;
        field.required = true;
      }
      if (furtherDetails[key]) {
        field.value = furtherDetails[key];
      }
      if (key === 'idealOutcome' && state.data.outcome) {
        state.data.outcome.forEach(option => {
          let simplifiedOption = {
            text: option.value,
            value: option.id, // this is what the Interactions API is looking for as the value,
            id: option.id,
          };
          field.options.push(simplifiedOption);
        });
      }
      if (state.data[key]) {
        state.data[key].forEach(option => {
          let simplifiedOption = {
            text: option.value,
            value: option.id, // this is what the Interactions API is looking for as the value,
            id: option.id,
          };
          field.options.push(simplifiedOption);
        });
      }
      fields.push(field);
    });
    return fields;
  }
};

// mutations
export const getByEntityMutations = {
  [GET_BY_ENTITY]: (state, { lists, list_type }) => {
    state.data = {
      ...state.data,
      ...lists,
    };
    state.loaded = true;
    state.loading = false;
    for (let i = 0, len = list_type.length; i < len; i++) {
      let listname = getKeyByValue(namesakes, list_type[i]);
      state.managers[listname].loading = false;
    }
  },
  [GET_BY_ENTITY_LOADING]: (state, list_type) => {
    for (let i = 0, len = list_type.length; i < len; i++) {
      let listname = getKeyByValue(namesakes, list_type[i]);
      state.managers[listname].loading = true;
    }
    state.loaded = false;
    state.loading = true;
  },
  [GET_BY_ENTITY_ERROR]: (state, data) => {
    state.data = [];
    state.loaded = false;
    state.loading = false;
    state.errors = data;
  },
  [CLEAR_BY_ENTITY]: (state) => {
    state.data = {};
    state.loaded = false;
    state.loading = true;
  },
};

// actions
export const getByEntityActions = {
  [GET_BY_ENTITY]: async ({ commit, }, { entity, list_type }) => {
    const lists = {};
    try {
      if (!list_type) {
        list_type = [
          'interaction_type',
          'purpose',
          'outcome',
          'next_action'
        ];
      }
      commit(GET_BY_ENTITY_LOADING, list_type);
      for (let i = 0, len = list_type.length; i < len; i++) {
        const now = new Date().getTime();
        let listname = getKeyByValue(namesakes, list_type[i]);
        let listvalue = await httpRequest('get', `config/schema/${entity}/lists/${list_type[i]}?now=${now}`);
        lists[listname] = listvalue;
      }
      commit(GET_BY_ENTITY, { lists, list_type });
    } catch(e) {
      commit(GET_BY_ENTITY_ERROR, []);
    }

  },
};
