<template>
  <div>
    <SignPost
      v-if="mySalesAiLinks.length"
      :heading="t('My sales-ai')"
      :description="t('Configure your settings to sell smarter')"
      color="var(--colour-data-mandy-label)"
      background-color="var(--colour-data-mandy-light)"
      :links="mySalesAiLinks"
    />
    <SignPost
      v-if="crmLinks.length"
      :heading="t('CRM Settings')"
      :description="t('Manage your custom schema sections and fields')"
      color="var(--colour-data-deluge-label)"
      background-color="var(--colour-data-deluge-light)"
      :links="crmLinks"
    />
    <SignPost
      v-if="intelligenceLinks.length"
      :heading="t('Intelligence')"
      :description="t('Take control of enquiries, associations, and communities')"
      color="var(--colour-data-puerto-rico-label)"
      background-color="var(--colour-data-puerto-rico-light)"
      :links="intelligenceLinks"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { t } from '@sales-i/utils';
import {
  root as adminBase,
  crmArea, intelligenceArea, companyArea,
  tagsSection, tagsReportSection, tagConsolidationSection,
  emailSyncSection,
  storageSection,
  permissionsSection, usersSection, rolesSection,
  targetsSection,
  customersPropspectsSection, contactsSection, opportunitiesSection, interactionsSection,
  customSectionsPath, stagesPath,
  associationsSection, communitiesSection,
  blacklistPath,
  logoSection,
} from '@/admin/router/urlBits.js';
import SignPost from './SignPost.vue';
import usePermissions from '@/shared/composables/usePermissions';
import {
  product_associations_admin,
  product_blacklist,
  product_communities_admin,
  crm_admin,
  targets_admin,
  iam_update,
  user_management,
  intelligence_admin,
} from '@/shared/store/data/policies';

const {
  permissions: permissionsComputed,
} = usePermissions();

const type = 'a';

const mySalesAiLinks = computed(() => {
  const permissions = permissionsComputed.value;
  const aiArr = [];
  if (permissions[crm_admin]?.read) {
    aiArr.push({
      title: t('Tags'),
      description: t('View reports and consolidate similar tags'),
      href: `/${adminBase}/${companyArea}`,
      sectionLinks: [{
        type,
        text: t('Reporting'),
        href: `/${adminBase}/${companyArea}/${tagsSection}/${tagsReportSection}`,
      },{
        type,
        text: t('Consolidation'),
        href: `/${adminBase}/${companyArea}/${tagsSection}/${tagConsolidationSection}`,
      }]
    });
  }
  aiArr.push({
    title: t('Email Sync'),
    description: t('Connect your inbox with sales-i'),
    href: `/${adminBase}/${companyArea}/${emailSyncSection}`,
  });
  if (permissions[iam_update]?.read) {
    aiArr.push({
      title: t('Storage'),
      description: t('View used storage details'),
      href: `/${adminBase}/${companyArea}/${storageSection}`,
    });
  }
  if (permissions[user_management]?.update) {
    aiArr.push({
      title: t('Permissions'),
      description: t('Create and manage users and roles'),
      href: `/${adminBase}/${companyArea}/${permissionsSection}/${usersSection}`,
      sectionLinks: [{
        type,
        text: t('Users'),
        href: `/${adminBase}/${companyArea}/${permissionsSection}/${usersSection}`,
      },{
        type,
        text: t('Roles'),
        href: `/${adminBase}/${companyArea}/${permissionsSection}/${rolesSection}`,
      }]
    });
  }
  if (permissions[targets_admin]?.update) {
    aiArr.push({
      title: t('Targets'),
      description: t('Create and view current targets'),
      href: `${adminBase}/${companyArea}/${targetsSection}`
    });
  }
  if (permissions[targets_admin]?.update) {
    aiArr.push({
      title: t('Logo'),
      description: t('Manage your logo across desktop and mobile'),
      href: `${adminBase}/${companyArea}/${logoSection}`
    });
  }
  return aiArr;
});

const crmLinks = computed(() => {
  const crmArr = [];
  const permissions = permissionsComputed.value;

  if (!permissions[crm_admin]?.read) return [];

  crmArr.push({
    title: t('Customer/Prospect'),
    description: t('Custom schema settings for Customer/Prospect'),
    href: `/${adminBase}/${crmArea}/${customersPropspectsSection}`,
    sectionLinks: [{
      type,
      text: t('Default sections'),
      href: `/${adminBase}/${crmArea}/${customersPropspectsSection}`,
    },{
      type,
      text: t('Custom sections'),
      href: `/${adminBase}/${crmArea}/${customersPropspectsSection}/${customSectionsPath}`,
    }]
  });
  crmArr.push({
    title: t('Contacts'),
    description: t('Custom schema settings for Contacts'),
    href: `/${adminBase}/${crmArea}/${contactsSection}`,
    sectionLinks: [{
      type,
      text: t('Default sections'),
      href: `/${adminBase}/${crmArea}/${contactsSection}`,
    },{
      type,
      text: t('Custom sections'),
      href: `/${adminBase}/${crmArea}/${contactsSection}/${customSectionsPath}`,
    }]
  });
  crmArr.push({
    title: t('Opportunities'),
    description: t('Custom schema settings for Opportunities'),
    href: `/${adminBase}/${crmArea}/${opportunitiesSection}`,
    sectionLinks: [{
      type,
      text: t('Default sections'),
      href: `/${adminBase}/${crmArea}/${opportunitiesSection}`,
    },{
      type,
      text: t('Custom sections'),
      href: `/${adminBase}/${crmArea}/${opportunitiesSection}/${customSectionsPath}`,
    },{
      type,
      text: t('Stages'),
      href: `/${adminBase}/${crmArea}/${opportunitiesSection}/${stagesPath}`,
    }]
  });
  crmArr.push({
    title: t('Interactions'),
    description: t('Custom schema settings for Interactions'),
    href: `/${adminBase}/${crmArea}/${interactionsSection}`,
    sectionLinks: [{
      type,
      text: t('Fields'),
      href: `/${adminBase}/${crmArea}/${interactionsSection}`,
    }]
  });
  return crmArr;
});

const intelligenceLinks = computed(() => {
  const intelligenceArr = [];
  const permissions = permissionsComputed.value;

  if (!permissions[intelligence_admin]?.read) return [];

  intelligenceArr.push({
    title: t('Enquiries'),
    description: t('Manage Customer viewing mode'),
    href: `/${adminBase}/${intelligenceArea}`
  });
  if (permissions[product_associations_admin]?.update) {
    const assObj = {
      title: t('Associations'),
      description: t('Manage product associations and exceptions'),
      href: `/${adminBase}/${intelligenceArea}/${associationsSection}`,
      sectionLinks: [{
        type,
        text: t('Products'),
        href: `/${adminBase}/${intelligenceArea}/${associationsSection}`,
      }]
    };
    if (permissions[product_blacklist]?.read) {
      assObj.sectionLinks.push({
        type,
        text: t('Exceptions'),
        href: `/${adminBase}/${intelligenceArea}/${associationsSection}/${blacklistPath}`,
      });
    }
    intelligenceArr.push(assObj);
  }
  if (permissions[product_communities_admin]?.update) {
    intelligenceArr.push({
      title: t('Communities'),
      description: t('Manage and setup product communities'),
      href: `/${adminBase}/${intelligenceArea}/${communitiesSection}`,
    });
  }
  return intelligenceArr;
});
</script>

<style lang="scss" scoped>
</style>
