<template>
  <CustomCard
    purpose="reversed"
    class="flow set-permissions"
  >
    <div
      v-if="showHeading"
      class="heading-and-controls"
    >
      <h3 class="fw-sbld">
        {{
          t(
            `${tenantName || ''} Roles (${totalRolesCount})`,
            'tenant_item_count',
            {
              interpolations: {
                tenant: tenantName,
                item: t('Roles'),
                count: totalRolesCount,
              }
            }
          )
        }}
      </h3>
      <CustomButton
        v-if="fullPbac"
        purpose="action"
        small
        @on-click="addRole"
      >
        {{ t('Add a role') }}
      </CustomButton>
    </div>
    <router-view />
  </CustomCard>
</template>

<script setup>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { t } from '@sales-i/utils';
import { CustomCard, CustomButton, } from '@sales-i/dsv3';
import { useRoute, } from 'vue-router';
import { GET_SUMMARY } from '@/shared/store/actionType';
import { baseUrl, companyArea, permissionsSection, rolesSection } from '@/admin/router/urlBits';
import useFeatures from '@/shared/composables/useFeatures';
import { navigateToUrl } from 'single-spa';
import useSystem from '@/shared/composables/useSystem';

const store = useStore();
const vroute = useRoute();
const { fullPbac } = useFeatures();
const { tenant } = useSystem({ store });

const totalRolesCount = computed(() => store.getters['admin/pbac/totalCount']);
const tenantName = computed(() => tenant.value.data.name);
const getRolesSummary = params => store.dispatch(`admin/pbac/${GET_SUMMARY}`, params);

const showHeading = computed(() => {
  return !(vroute.path.includes('add') || vroute.path.includes('edit'));
});

function addRole() {
  navigateToUrl(`${baseUrl}/${companyArea}/${permissionsSection}/${rolesSection}/add`);
}

onMounted(() => {
  getRolesSummary();
});
</script>

<style lang="scss" scoped>
.set-permissions {
  margin: var(--spacing-3);

  :deep(.c-card--footer) {
    padding: 0;
  }

  .heading-and-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
