<template>
  <div>
    <EnquiryNav
      :report-type="reportType"
      :dates="dateFilter"
      :active-area-prop="defultReportArea"
      :is-date-filter-active="isDateFilterActive"
      :is-period-filter-active="isPeriodFilterActive"
      is-bubble-selection-screen
      :is-meta-date-stamp-active="false"
      @toggle-date-filter="toggleDateFilter"
      @toggle-period-filter="togglePeriodFilter"
      @change-report="changeReport"
    />
    <div class="content">
      <div class="container">
        <DatePickerView
          v-if="isDateFilterActive"
          :report-type="reportType"
          @set-active-date="setActiveDate"
        />
        <PeriodFilter
          v-else-if="isPeriodFilterActive"
          @on-set-active-period="setActivePeriod"
        />
        <template v-else>
          <BubbleList
            v-if="allowRenderList"
            :key="bubbleListRefresher"
            :date-filter="dateFilter"
            :report-type="reportType"
            :hidden-bubble-areas="hiddenBubbleAreas"
            @change-step="changeStep"
            @go-to-report="goToReport"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import BubbleList from '@/intelligence/components/Bubble/BubbleList';
import EnquiryNav from '@/intelligence/components/EnquiryReport/EnquiryNav/EnquiryNav';
import DatePickerView from '@/intelligence/components/Date/DatePickerView';
import PeriodFilter from '@/intelligence/components/Filters/PeriodFilter';
import { REPORT_AREA_ENQUIRY } from '@/intelligence/store/data/areas';
import { DATES_ITEM } from '@/shared/constants/storageItems';
import { storage } from '@sales-i/utils';
import useShared from '@/intelligence/composables/useShared';
import useEnquiries from '@/intelligence/composables/useEnquiries';
import useSystem from '@/shared/composables/useSystem';

const props = defineProps({
  reportType: {
    type: String,
    default: '',
  },
  hiddenBubbleAreas: {
    type: Array,
    default: () => [],
  },
  querySubstitute: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['goToReport', 'changeReport']);

const store = useStore();
const vroute = useRoute();

const { iframe } = useSystem({ store });
const { getInputData } = useShared({ store });
const { setDimensionsNumber } = useEnquiries({ store });

const dateFilter = ref({
  date_from: '',
  date_to: '',
});
const bubbleListRefresher = ref(0);
const allowRenderList = ref(false);
const isDateFilterActive = ref(false);
const isPeriodFilterActive = ref(false);
const step = ref('start');
const defultReportArea = ref(REPORT_AREA_ENQUIRY);

const inputData = computed(() => getInputData(props.reportType));
const query = computed(() => iframe.value ? props.querySubstitute : vroute.query);

watch(() => props.reportType, () => {
  setDimensionsNumber(inputData.value.urlCode);
  bubbleListRefresher.value++;
  isPeriodFilterActive.value = false;
});

onMounted(() => {
  setDimensionsNumber(inputData.value.urlCode);
  allowRenderList.value = true;
  initializeDates();
});

function changeStep(newStep) {
  step.value = newStep;
}

function setActiveDate(dateInfo) {
  isDateFilterActive.value = false;
  dateFilter.value = dateInfo;
  // TODO: do we keep storage params?
  storage.set(DATES_ITEM, dateInfo);
}

function toggleDateFilter() {
  isDateFilterActive.value = !isDateFilterActive.value;
  isPeriodFilterActive.value = false;
}

function setActivePeriod() {
  isPeriodFilterActive.value = false;
}

function togglePeriodFilter() {
  isPeriodFilterActive.value = !isPeriodFilterActive.value;
  isDateFilterActive.value = false;
}

function initializeDates() {
  const sessionStorageDates = storage.get(DATES_ITEM);
  let dates = {
    date_from: 'today/y',
    date_to: 'today',
    date_from2: 'today/y-1y',
    date_to2: 'today-1y',
  };
  if (query.value.date_from && query.value.date_to) {
    dates = query.value;
    storage.set(DATES_ITEM, dates);
  } else if (!iframe.value && sessionStorageDates && sessionStorageDates.date_from && sessionStorageDates.date_to) {
    dates = sessionStorageDates;
  }
  dateFilter.value = { ...dates };
}

function goToReport(enquiryParams) {
  emit('goToReport', enquiryParams);
}

function changeReport(reportType) {
  emit('changeReport', { reportType, query: dateFilter.value });
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

h3 {
  font-weight: var(--font-weight-semibold);
  text-align: center;
}

.content {
  .container {
    background: var(--colour-panel-g-2);
    padding: var(--spacing-3) 0;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      padding: var(--spacing-3) var(--spacing-5) var(--spacing-3);
    }
  }

  .description {
    padding: var(--spacing-2);
    box-shadow: 0 0 var(--spacing-half) 1px var(--colour-panel-g-16);
    border-radius: var(--border-radius-1) var(--border-radius-1) 0 0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--background-color);

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      padding: var(--spacing-3);
    }

    &::after {
      content: ' ';
      position: absolute;
      left: 0;
      right: 0;
      background: var(--background-color);
      bottom: calc(-1 * var(--spacing-half));
      height: var(--spacing-half);
    }

    p,
    a {
      font-size: var(--font-size-small);
      font-weight: var(--font-weight-semibold);
    }

    p {
      color: var(--colour-panel-g-32);
    }

    a {
      color: var(--colour-brand-deluge);
    }
  }
}
</style>
