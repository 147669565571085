<template>
  <CRMCard
    :id="id"
    :title="computedTitle"
    :chips="getChips(item)"
    :links="getLinks(item)"
    :currency="currency"
    :on-click="openItem"
    @open-item="openItem"
    @pursue-item="pursueItem"
  >
    <template #info>
      {{ t(`${capitalize(item.type, { onlyFirstLetter: true })} Opportunity`) }}
      <div
        v-if="['missed', 'missedgrp'].indexOf(item.type.toLowerCase()) !== -1"
      >
        {{ t('Invoice date') }}: {{ dates.format(item.invoice_date) }}
      </div>

      <div
        v-if="['missed', 'missedgrp'].indexOf(item.type.toLowerCase()) !== -1"
        class="opportunity-details"
      >
        <p>
          {{ t('You sold ') }} <strong>{{ getProductCodes(item, 'Antecedent') }}</strong>
        </p>
        <p>
          {{ t(' but did not sell ') }}<strong>{{ getProductCodes(item, 'Consequent') }}</strong>
        </p>
        <p v-if="getProductCount(item, 'Consequent', 1) !== 0">
          and
          <CustomButton
            purpose="text"
            class="num-items-link"
            @on-click="openItem"
          >
            {{ getProductCount(item, 'Consequent', 1) }} more items
          </CustomButton>
        </p>
      </div>
      <div
        v-if="['potential', 'potentialgrp'].indexOf(item.type.toLowerCase()) !== -1"
        class="opportunity-details"
      >
        <p>
          {{ t('You could sell ') }} <strong>{{ getProductCodes(item, 'POTENTIAL') }}</strong>
        </p>
        <p v-if="getProductCount(item, 'POTENTIAL', 1) !== 0">
          and
          <CustomButton
            purpose="text"
            class="num-items-link"
            @click="openItem"
          >
            {{ getProductCount(item, 'POTENTIAL', 1) }} more items
          </CustomButton>
        </p>
      </div>
    </template>
  </CRMCard>
</template>

<script setup>
import { computed } from 'vue';
import { GET_BY_ID, SET_ENTITY, CLEAR_BY_ID, POST_BY_ENTITY } from '@/shared/store/actionType';
import { SET_OPPORTUNITY, SET_FURTHER_DETAILS } from '@/crm/store/actionType';
import { CRMCard, CustomButton } from '@sales-i/dsv3';
import { useStore } from 'vuex';
import { replaceSpaceWithNBSP } from '@/shared/utils/replaceSpaceWithNBSP';
import usePermissions from '@/shared/composables/usePermissions';
import { interactions, missed_potential_opportunities } from '@/shared/store/data/policies';
import { baseUrl, interactionsArea } from '@/crm/router/urlBits';
import useCurrency from '@/shared/composables/useCurrency';
import { capitalize } from '@/shared/utils/strings';
import { dates, t } from '@sales-i/utils';
import { navigateToUrl } from 'single-spa';
import useSystem from '@/shared/composables/useSystem';

const store = useStore();
const { getPermissions } = usePermissions();
const interactionPerm = getPermissions(interactions);
const aimlPerm = getPermissions(missed_potential_opportunities);
const { cs, currency } = useCurrency();
const { iframe } = useSystem({ store });

const props = defineProps({
  id: {
    type: String,
    default: '',
  },
  item: {
    type: Object,
    default: () => ({}),
  },
  clickable: {
    type: Boolean,
    default: true,
  },
});


const emit = defineEmits(['pursueItem', 'openItem']);
const customer = computed(() => store.state.crm.customers.selected);
const createdOpportunity = computed(() => store.state.crm.opportunities.selected.data);
const computedTitle = computed(() => replaceSpaceWithNBSP(props.item.entity_name));

const getCustomer = params => store.dispatch(`crm/customers/${GET_BY_ID}`, params);
const clearById = params => store.dispatch(`crm/interactions/${CLEAR_BY_ID}`, params);
const setOpportunity = params => store.dispatch(`crm/interactions/${SET_OPPORTUNITY}`, params);
const createOpportunity = params => store.dispatch(`crm/opportunities/${POST_BY_ENTITY}`, params);
const setEntity = params => store.dispatch(`crm/interactions/${SET_ENTITY}`, params);
const setFurtherDetails = params => store.dispatch(`crm/interactions/${SET_FURTHER_DETAILS}`, params);

function getProductCount(item, type, offset) {
  if ('products' in item && item.products.length) {
    return getUnabridgedProducts(item.products, type).length - offset;
  }
  return 0;
}

function openItem() {
  emit('openItem', props.item);
}

async function pursueItem(item) {
  emit('pursueItem', item);
  // Make sure we are working with a clean slate
  clearById();
  const opportunity = { ...props.item };

  await getCustomer({ id: opportunity.entity_id });
  setEntity({
    entityType: opportunity.entity_type,
    entity: {
      ...customer.value.data,
      header: customer.value.data.name,
      subHeader: customer.value.data.account_number,
    },
  });

  // Generate the interaction note
  let interactionNote = '';
  if (opportunity.type.toLowerCase() !== 'potential') {
    interactionNote += 'Because this client purchased: ';
    const antecedents = getUnabridgedProducts(opportunity.products, 'Antecedent');
    const consequents = getUnabridgedProducts(opportunity.products, 'Consequent');

    antecedents.forEach(ante => {
      interactionNote += `${ante.product_name} (${ante.prod_code}) `;
    });
    interactionNote += '\r\n'; // eslint-disable-line
    interactionNote += 'We recommend they also purchase: ';
    consequents.forEach(cons => {
      interactionNote += `${cons.product_name} (${cons.prod_code}) `;
    });
  } else {
    const potentials = getUnabridgedProducts(opportunity.products, 'POTENTIAL');
    interactionNote += 'We recommend they purchase: ';
    potentials.forEach(cons => {
      interactionNote += `${cons.product_name} (${cons.prod_code}) `;
    });
  }

  if (opportunity.id === null) {
    await createOpportunity(opportunity);
    setOpportunity({ opportunity: createdOpportunity.value });
  } else {
    setOpportunity({ opportunity });
  }

  setFurtherDetails({ interactionNote });

  // Push them to the interactions
  navigateToUrl(`${baseUrl}/${interactionsArea}/add?clean=false`);
}

function getProductCodes(item, type) {
  const items = [];
  let extraCount = 0;
  let appendEllipsis = false;

  if ('products' in item) {
    const { products } = item;
    for (let i = 0; i < products.length; i++) {
      if (items.length > 0 && products[i].prod_category === type) {
        extraCount++;
        appendEllipsis = true;
        continue;
      }
      if (products[i].prod_category === type) {
        items.push(products[i].product_name);
      }
    }
  }
  return items.join(', ') + (appendEllipsis ? `, +${extraCount} more` : '');
}

function getUnabridgedProducts(products, type) {
  const items = [];
  products.forEach(prod => {
    if (prod.prod_category === type) {
      items.push(prod);
    }
  });
  return items;
}

function getRolledUpValue(item) {
  let value = 0;
  if ('products' in item) {
    const productsLength = item.products.length;

    for (let x = 0; x < productsLength; x++) {
      value += item.products[x].value;
    }
  }

  return value;
}

function getHighestProbability(item) {
  let value = 0;

  if ('products' in item) {
    item.products.forEach(_ref => {
      if (_ref.confidence > value) {
        value = _ref.confidence;
      }
    });
  }

  return value;
}

function getChips(item) {
  const chips = [
    {
      value: getRolledUpValue(item),
      prefix: cs,
      abbr: true,
      green: 20,
      amber: 10,
    },
  ];

  if (item.type.toLowerCase() !== 'potential') {
    chips.push({
      value: getHighestProbability(item),
      suffix: '%',
      green: 67,
      amber: 33,
    });
  }

  return chips;
}

function getLinks(item) {
  let pursueLink =
    interactionPerm.create && !iframe.value
      ? {
        text: t('Pursue as Interaction'),
        emit: 'pursue-item',
        id: item.id,
      }
      : undefined;

  let openLink = aimlPerm.read
    ? {
      text: t('View'),
      emit: 'open-item',
      id: item.id,
    }
    : undefined;
  return [pursueLink, openLink].filter(x => !!x);
}
</script>

<style lang="scss" scoped>
:deep(span.card-title) {
  overflow: hidden;
  max-width: 55%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

:deep(div.title-chip-container) {
  justify-content: space-between;
}

.opportunity-details {
  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.num-items-link {
  display: inline;
}
</style>
