<template>
  <div>
    <div
      :id="uniqueId"
      ref="container"
      class="chart-container"
    >
      <Legend
        v-if="legendPosition === 'top'"
        :labels="legendLabels || columnKey"
        :colors="colour"
      />
      <svg class="chart" />
      <div class="bar-tooltip tooltip" />
      <div class="axis-tooltip tooltip" />
      <Legend
        v-if="legendPosition === 'bottom'"
        :labels="legendLabels || columnKey"
        :colors="colour"
      />
    </div>
  </div>
</template>

<script setup>
// MultiBarChart
import * as d3 from 'd3';
import { abbr, uuid, debounce } from '@sales-i/utils';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import Legend from '@/intelligence/components/Dashboard/Elements/Legend.vue';

const uniqueId = uuid('chart');
const container = ref(null);

const props = defineProps({
  chartData: {
    type: Array,
    default: () => [],
  },
  columnKey: {
    type: Array,
    default: () => [],
  },
  colour: {
    type: Array,
    default: () => ['var(--colour-data-de-york)', 'var(--colour-data-mandy)'],
  },
  formatFunc: {
    type: Function,
    default: value => value,
  },
  svgWidth: {
    type: Number,
    default: 0,
  },
  svgHeight: {
    type: Number,
    default: 410,
  },
  legendPosition: {
    type: String,
    default: 'top',
    validator: value => ['top', 'bottom'].includes(value),
  },
  legendLabels: {
    type: Array,
    default: undefined,
  },
});

const margin = {
  top: 0,
  right: 10,
  bottom: 100,
  left: 30,
};

const formatValue = props.formatFunc || (value => value);
const maxBandWidth = 100;
const svgWidthCalc = () =>
  Math.min(
    props.svgWidth || container.value?.clientWidth + 80,
    props.chartData?.length * maxBandWidth,
    container.value?.clientWidth + 80
  );
const chartWidth = () => svgWidthCalc() - margin.left - margin.right;
const chartHeight = computed(() => props.svgHeight - margin.top - margin.bottom);

const customerTooltip = ref(null);
const barTooltip = ref(null);

const debounceChartResize = debounce(handleChartResize, 250);
const containerResizeObserver = new ResizeObserver(debounceChartResize);

onMounted(() => {
  containerResizeObserver.observe(container.value);
});

onUnmounted(() => {
  containerResizeObserver.disconnect();
});

function handleChartResize() {
  generateChart();
}

onMounted(() => {
  customerTooltip.value = d3.select(`#${uniqueId} .axis-tooltip`);
  barTooltip.value = d3.select(`#${uniqueId} .bar-tooltip`);
  handleChartResize();
});

function truncateLabel() {
  const self = d3.select(this);
  let text = self.text();

  if (text.length > 12) {
    text = text.slice(0, 10);
    self.text(text + '...');
  }
}

function generateChart() {
  d3.select(`#${uniqueId} svg g`).remove();

  let maxNumericValue = Math.max(
    ...props.chartData.map(entry => Math.max(...props.columnKey.map(key => entry?.[key])))
  );
  let minNumericValue = Math.min(
    ...props.chartData.map(entry => Math.min(...props.columnKey.map(key => entry?.[key])))
  );

  const x = d3
    .scaleBand()
    .domain(props.chartData.map((_, i) => i))
    .range([0, chartWidth()]);

  const y = d3
    .scaleLinear()
    .domain([1.1 * Math.min(0, minNumericValue), 1.1 * Math.max(0, maxNumericValue)])
    .range([chartHeight.value, 5]);

  const svg = d3
    .select(`#${uniqueId} svg`)
    .attr('width', svgWidthCalc())
    .attr('height', props.svgHeight)
    .append('g')
    .attr('transform', `translate(${margin.left + margin.right}, ${margin.top})`);

  svg
    .append('g')
    .attr('class', 'chart-axis x')    
    .attr('transform', `translate(0, ${y(0)})`)
    .call(d3.axisBottom(x))
    .selectAll('text')
    .data(props.chartData)
    .text(d => d.name)    
    .attr('transform', () => `translate(-10,${chartHeight.value - y(0)})rotate(-60)`)
    .attr('class', 'chart-label-x')
    .style('text-anchor', 'end')
    .each(truncateLabel)
    .on('mouseover', (event, d) => {
      const [xPosition, yPosition] = d3.pointer(event, d3.select(`#${uniqueId} svg`).node());
      const svgTopLeft = d3.select(`#${uniqueId} svg`).node().getBoundingClientRect();

      customerTooltip.value
        .style('display', 'block')
        .style('left', svgTopLeft.left + xPosition + 'px')
        .style('top', svgTopLeft.top + yPosition - 30 + 'px')
        .style('opacity', 1)
        .html(d?.name);
    })
    .on('mouseout', () => {
      customerTooltip.value.style('display', 'none');
    });

  const axisY = d3.axisLeft(y).tickFormat(d => abbr.float(d));
  svg.append('g').attr('class', 'chart-axis y').call(axisY).selectAll('text').attr('class', 'chart-label-y');

  const colorValue = getComputedStyle(document.documentElement).getPropertyValue('--colour-panel-g-8');
  
  // Change color of axis line
  svg.selectAll('.chart-axis path')
    .attr('stroke', colorValue);
  svg.selectAll('.chart-axis line')
    .attr('stroke', colorValue);

  const barWidth = 8;
  const offset = (x.bandwidth() - barWidth * props.columnKey.length) / 2;

  props.columnKey.forEach((key, index) => {
    svg
      .selectAll(`mybar-${key}`)
      .data(props.chartData || [])
      .join('rect')
      .attr('x', (d, i) => x(i) + offset + index * barWidth)
      .attr('y', d => y(Math.max(0, d?.[key])))
      .attr('rx', '4')
      .attr('ry', '4')
      .attr('width', barWidth)
      .attr('height', d => Math.abs(y(0) - y(d?.[key])))
      .attr('fill', props.colour[index])
      .attr('class', `bar ${key}`)
      .style('cursor', 'pointer')
      .on('mouseover', (event, d) => {
        const [xPosition, yPosition] = d3.pointer(event, d3.select(`#${uniqueId} svg`).node());
        const svgTopLeft = d3.select(`#${uniqueId} svg`).node().getBoundingClientRect();

        barTooltip.value
          .style('display', 'block')
          .style('left', svgTopLeft.left + xPosition + 'px')
          .style('top', svgTopLeft.top + yPosition - 30 + 'px')
          .style('opacity', 1)
          .html(d?.label || formatValue(d?.[key], key));
      })
      .on('mouseout', () => {
        barTooltip.value.style('display', 'none');
      });
  });
}
</script>

<style lang="scss" scoped>
.chart-container {
  position: relative;
  display: flex;
  margin: 0 auto;
  flex-flow: column;
  align-items: center;
}

.chart-axis {

  line,
  path {
    display: none;
  }
}

.chart-label-x,
.chart-label-y {
  font-size: var(--font-size-small);
  font-family: var(--font-family-primary);
}

.chart-label-x {
  font-weight: var(--font-weight-semibold);
}

.bar-tooltip {
  width: 80px;
}

.axis-tooltip {
  width: auto;
  z-index: 10;
}

.tooltip {
  position: fixed;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s;
  padding: var(--spacing-half) var(--spacing-1);
  border-radius: var(--spacing-2);
  text-align: center;
  color: var(--colour-utility-white);
  font-family: var(--font-family-primary);
  font-size: var(--font-size-small);
  letter-spacing: 0;
  line-height: var(--spacing-2);
  background-color: var(--colour-utility-black);
  font-weight: var(--font-weight-semibold);
  pointer-events: none;
}
</style>
