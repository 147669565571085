<template>
  <div class="multi-chart">
    <slot name="header" />
    <div class="chart">
      <PieChart
        v-if="chartType === PIE_CHART"
        v-bind="props"
      />
      <MultiBarChart
        v-else-if="chartType === MULTIBAR_CHART"
        v-bind="props"
      />
      <LineChart
        v-else-if="chartType === LINE_CHART"
        v-bind="props"
      />
      <BarChart
        v-else
        v-bind="props"
      />
    </div>
  </div>
</template>

<script setup>
import BarChart from '@/intelligence/components/Dashboard/Elements/BarChart.vue';
import PieChart from '@/intelligence/components/Dashboard/Elements/PieChart.vue';
import LineChart from '@/intelligence/components/Dashboard/Elements/LineChart.vue';
import MultiBarChart from '@/intelligence/components/Dashboard/Elements/MultiBarChart.vue';
import { PIE_CHART, MULTIBAR_CHART, LINE_CHART } from '@/intelligence/components/Dashboard/constants';

const props = defineProps({
  chartType: {
    type: String,
    default: 'bar',
  },
  chartData: {
    type: Array,
    default: () => [],
  },
  columnKey: {
    type: [String, Array],
    default: undefined
  },
  colour: {
    type: [String, Array],
    default: undefined
  },
  formatFunc: {
    type: Function,
    default: (value) => value
  },
  legendPosition: {
    type: String,
    default: 'top',
    validator: value => ['top', 'bottom'].includes(value),
  },
  legendLabels: {
    type: Array,
    default: undefined,
  },
  showValues: {
    type: Boolean,
    default: false,
  },
  svgWidth: {
    type: Number,
    default: undefined
  },
});
</script>

<style lang="scss" scoped>
.multi-chart {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  .chart {
    width: 100%;
  }
}
</style>
