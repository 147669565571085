<template>
  <CustomInput
    icon-color="var(--colour-utility-action)"
    icon-name="search"
    :icon-size="iconSize"
    :label="props.label"
    :placeholder="props.placeholder"
    :value="props.value"
    :disabled="props.disabled"
    @input="handleSearch"
  />
</template>

<script setup>
// SearchInput
import { CustomInput } from '@sales-i/dsv3';
import { debounce } from '@sales-i/utils';

const emit = defineEmits(['search']);

const props = defineProps({
  label: {
    type: String,
    default: undefined,
  },
  placeholder: {
    type: String,
    default: undefined,
  },
  value: {
    type: String,
    default: undefined,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  iconSize: {
    type: Number,
    default: undefined,
  },
});

// Create a function that will be called when the user types in the search input
const handleSearch = debounce(value => {
  emit('search', value);
}, 500);
</script>
