<template>
  <div
    class="svg"
    :class="{ 'flex-space': !paginationNoResults, 'iframe-height': iframe }"
  >
    <BufferImage
      v-if="dataLoading || !isReportEnabled"
      color="black"
      float="center"
      class="loading-spinner"
    />
    <section class="data-section">
      <SVGTable
        v-show="isReportEnabled && dataRowsLength"
        :report-id="reportId"
        :report-type="reportType"
        :y-sort-direction="ySortDirection"
        @handle-enquiry-drill="handleEnquiryDrill"
        @sort-on-y-axis="sortOnYAxis"
        @set-enquiry-data="setEnquiryData"
      />
      <NoResults v-if="paginationNoResults && isReportEnabled" />
    </section>
    <ReportFooter
      v-if="!paginationNoResults && isReportEnabled && !iframe"
      :dimensions="dimensions"
      :report-type="reportType"
      :sub-title="title"
    />
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { BufferImage } from '@sales-i/dsv3';
import ReportFooter from '@/intelligence/components/ReportFooter/ReportFooter';
import SVGTable from '@/intelligence/components/SalesVsGaps/SVGTable';
import NoResults from '@/intelligence/components/Shared/NoResults';
import { REPORTS_SORT_ASCENDING_KEY, REPORTS_SORT_DESCENDING_KEY } from '@/intelligence/store/data/apiInput';
import { intelligence_enquiries } from '@/shared/store/data/policies';
import runIfPermittedOrReject from '@/shared/store/utils/runIfPermittedOrReject';
import { SALES_VS_GAPS } from '@/intelligence/store/data/reportTypes';
import useShared from '@/intelligence/composables/useShared';
import useCalendar from '@/intelligence/composables/useCalendar';
import usePermissions from '@/shared/composables/usePermissions';
import useSystem from '@/shared/composables/useSystem';
import useEnquiries from '@/intelligence/composables/useEnquiries';

const props = defineProps({
  title: {
    required: true,
    type: String
  },
  dateParams: {
    type: Object,
    required: true
  },
  reportType: {
    type: String,
    default: SALES_VS_GAPS,
    required: true
  },
  isReportEnabled: {
    type: Boolean,
    default: false
  }
});

const store = useStore();
const emit = defineEmits(['handleEnquiryDrill', 'loaded', 'loading']);

const ySortDirection = ref(REPORTS_SORT_DESCENDING_KEY);
const ySortColumn = ref('total');
const reportId = ref(1);
    
const { dataLoading, getReportData, fetchReportData, applyDate } = useShared({ store });
const { fetchedDates, fetchDates } = useCalendar({ store });
const { permissions } = usePermissions({ store });
const { iframe } = useSystem({ store });
const { setSortingData } = useEnquiries({ store });

const enquiryData = computed(() => getReportData(reportId.value));
const dimensions = computed(() => enquiryData.value?.axis?.y?.[0].entity);
const paginationNoResults = computed(() => (!xHeadingsLength.value || !dataRowsLength.value) && !dataLoading.value);
const dataRowsLength = computed(() => enquiryData.value?.rows?.length || 0);
const xHeadingsLength = computed(() => enquiryData.value ? enquiryData.value.axis.x.headings.length : 0);
    
onMounted(() => setEnquiryData(true));
    
async function setEnquiryData(completeRefresh = false) {
  runIfPermittedOrReject(
    permissions.value,
    { name: intelligence_enquiries },
    async () => {
      emit('loading');
      await fetchDates(props.dateParams);
      applyDate(fetchedDates.value);
      setSortingData([ySortColumn.value, ySortDirection.value]);
      reportId.value = await fetchReportData({
        reportType: props.reportType,
        completeRefresh,
      });
      emit('loaded');
    }
  );
}

function handleEnquiryDrill(payload) {
  emit('handleEnquiryDrill', payload);
}

function sortOnYAxis(sortOrder, sortCol) {
  ySortDirection.value =
        sortOrder === REPORTS_SORT_ASCENDING_KEY
          ? REPORTS_SORT_ASCENDING_KEY
          : REPORTS_SORT_DESCENDING_KEY;
  ySortColumn.value = sortCol;
  setEnquiryData(true);
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.svg {
  width: 100%;
  margin: 0 auto auto;
  border-radius: var(--border-radius-1);
  position: relative;
  background: var(--colour-utility-white);
  box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-blur)
    var(--shadow-spread) var(--shadow-colour);
  display: flex;
  flex-direction: column;
  min-height: 200px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    min-height: 580px;

    &.iframe-height {
      min-height: 510px;
    }
  }

  .data-section {
    flex: 1;
    display: flex;
    position: relative;
    overflow: hidden;
    border-radius: var(--border-radius-1);
  }

  &.flex-space {
    justify-content: space-between;

    .data-section {
      flex: auto;
      display: block;
      margin-bottom: 56px;

      @media #{map-get($display-breakpoints, 'md-and-up')} {
        margin-bottom: 0;
      }
    }
  }
}

.header-section {
  position: relative;
  padding: var(--spacing-4) var(--spacing-4) var(--spacing-1);

  h3 {
    text-align: center;
    padding: 0 var(--spacing-2);
    font-weight: var(--font-weight-semibold);

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      padding: 0 var(--spacing-4);
    }
  }

  span {
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-small);
    display: block;
    text-align: center;
    color: var(--colour-utility-base);
  }
}

.loading-spinner {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
}
</style>
