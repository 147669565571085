<template>
  <div class="date-subheader">
    <p v-if="dateRangeNumber === 1">
      {{ dates.format(requestParameters?.date_from) }} - {{ dates.format(requestParameters?.date_to) }}
    </p>
    <div
      v-else
      class="date-range-two"
    >
      <p
        v-for="n in 2"
        :key="n"
      >
        {{ n }}:
        <IconBase
          icon-name="calendar"
          :height="16"
          :width="16"
        />
        {{ dates.format(requestParameters?.[`date_from${n === 1 ? '' : 2}`]) }} - 
        {{ dates.format(requestParameters?.[`date_to${n === 1 ? '' : 2}`]) }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { IconBase } from '@sales-i/dsv3';
import { dates } from '@sales-i/utils';

defineProps({
  dateRangeNumber: {
    type: Number,
    default: 1,
  },
  requestParameters: {
    type: Object,
    required: true,
  },
});
</script>

<style lang="scss" scoped>
.date-subheader {
  p {
    text-align: center;
    font-size: var(--font-size-small);
    line-height: var(--font-size-body);
    margin: var(--spacing-half) 0 0;
  }

  .date-range-two p {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
