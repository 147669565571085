<template>
  <CustomModal
    v-if="aimlPerm.read && 'products' in props.selectedOpportunity"
    :title="props.selectedOpportunity.entity_name === null ? '' : props.selectedOpportunity.entity_name"
    :show-modal="props.modalOpen"
    :max-width="800"
    @close-modal="closeModal"
  >
    <div
      id="missed-potential-opportunity-product-details"
      class="container modal-content"
    >
      <p
        v-if="['missed', 'missedgrp'].indexOf(props.selectedOpportunity.type.toLowerCase()) !== -1"
        class="opportunity-product-list"
      >
        {{ t('You sold') }}
        <strong
          v-for="(product, index) in getUnabridgedProductCodes(props.selectedOpportunity.products, 'Antecedent')"
          :key="product"
          class="opportunity-product-sold"
        >
          <span v-if="index === 0 || showAll"> <span v-if="index > 0">,&nbsp;</span>{{ product }} </span>
        </strong>
        <CustomButton
          v-if="showAll === false && antecedentCount - 1 > 0"
          purpose="text"
          class="show-more"
          role="button"
          tabindex="0"
          @click="toggleShowAll"
          @keydown.enter="toggleShowAll"
        >
          {{
            t(`And ${antecedentCount - 1} other items`, 'product_count_variable', {
              interpolations: { variable: antecedentCount - 1 },
            })
          }}
        </CustomButton>
      </p>

      <CRMCard
        purpose="alert"
        class="missed-details"
      >
        <template #info>
          <header>
            <h4>
              {{ isMissedOpportunity ? t('Missed Opportunities') : t('Potential Opportunities') }}
            </h4>
            <p>{{ isMissedOpportunity ? missedOppsDesc : potentialOppsDesc }}</p>
            <MenuFilter
              class="sort-filter"
              tabindex="0"
              :items="sortFilters"
              :selected-value="selectedSorting"
              menu-position="right"
              @on-change="handleSort"
            />
          </header>

          <CRMCard
            v-for="(product, index) in oppsProducts"
            :id="`opportunity_product_${index}`"
            :key="product.prod_code"
            :title="product.product_name"
            :currency="currency"
            :chips="getChips(product)"
            :links="getLinks(product)"
            purpose="reversed"
            class="details-container opportunity"
            @add-feedback="dismissProduct"
          >
            <template #info>
              {{ product.prod_code }}
            </template>
          </CRMCard>
        </template>
      </CRMCard>
    </div>
    <template
      v-if="canCreateInteractions"
      #footer
    >
      <ButtonGroup
        name="opportunity-actions"
        class="btn-group"
      >
        <CustomButton
          v-if="interactionsPerm.create"
          purpose="action"
          small
          @on-click="() => pursueItem()"
        >
          {{ t('Pursue as Interaction') }}
        </CustomButton>
      </ButtonGroup>
    </template>
  </CustomModal>
</template>

<script setup>
// MissedPotentialOpportunityModal
import { t } from '@sales-i/utils';
import { computed, ref, onBeforeUnmount, onMounted } from 'vue';
import { CRMCard, CustomButton, CustomModal, MenuFilter } from '@sales-i/dsv3';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import useCurrency from '@/shared/composables/useCurrency';
import usePermissions from '@/shared/composables/usePermissions';
import { interactions, opportunities, missed_potential_opportunities } from '@/shared/store/data/policies';

const { cs, currency } = useCurrency();
const { getPermissions } = usePermissions();
const interactionsPerm = getPermissions(interactions);
const aimlPerm = getPermissions(missed_potential_opportunities);
const opportunitiesPerm = getPermissions(opportunities);

const props = defineProps({
  modalOpen: {
    type: Boolean,
    default: false,
  },
  selectedOpportunity: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['close-modal', 'pursueas-interaction', 'add-feedback']);

const sortFilters = computed(() => [
  {
    text: t('Value DES - ASC'),
    value: 'value:desc',
    option: (a,b) => b.value - a.value,
  },
  {
    text: t('Value ASC - DES'),
    value: 'value:asc',
    option: (a,b) => a.value - b.value,
  },
  {
    text: t('% Probability DES - ASC'),
    value: 'confidence:desc',
    option: (a,b) => b.confidence - a.confidence,
  },
  {
    text: t('% Probability ASC - DES'),
    value: 'confidence:asc',
    option: (a,b) => a.confidence - b.confidence,
  },
  {
    text: t('Weighted Value DES - ASC'),
    value: 'weightedValue:desc',
    option: (a,b) => b.value * b.confidence - a.value * a.confidence,
  },
  {
    text: t('Weighted Value ASC - DES'),
    value: 'weightedValue:asc',
    option: (a,b) => a.value * a.confidence - b.value * b.confidence,
  },
]);

const showAll = ref(false);
const oppsProducts = ref([]);
const selectedSorting = ref(sortFilters.value[0]);

const antecedentCount = computed(() => getProductCount(props.selectedOpportunity.products, 'Antecedent'));
const isMissedOpportunity = computed(
  () => ['potential', 'potentialgrp'].indexOf(props.selectedOpportunity.type.toLowerCase()) === -1
);

const missedOppsDesc = computed(() => {
  const count = oppsProducts.value.length;
  return t(
    `sales-ai has identified ${count} associated products with this sale`,
    'sales-ai_has_identified_count_associated_products_with_this_sale',
    { interpolations: { count } }
  );
});

const potentialOppsDesc = computed(() => {
  const count = oppsProducts.value.length;
  return t(
    `sales-ai has identified ${count} associated products`,
    'sales-ai_has_identified_count_associated_products',
    {
      interpolations: { count },
    }
  );
});

onMounted(() => {
  oppsProducts.value = getUnabridgedProducts(
    props.selectedOpportunity.products,
    ['potential', 'potentialgrp'].indexOf(props.selectedOpportunity.type.toLowerCase()) !== -1
      ? 'POTENTIAL'
      : 'Consequent'
  );
  handleSort(selectedSorting.value);
});

onBeforeUnmount(() => {
  showAll.value = false;
});

function handleSort(item) {
  oppsProducts.value.sort(item.option);
  selectedSorting.value = item;
}

function toggleShowAll() {
  showAll.value = !showAll.value;
}

function getLinks(item) {
  let links = [];
  if (aimlPerm.create && opportunitiesPerm.delete) {
    links.push({
      text: t('Dismiss this item'),
      emit: 'add-feedback',
      id: item.prod_code,
    });
  }
  return links;
}

function getChips(item) {
  const chips = [
    {
      value: item.value,
      prefix: cs,
      abbr: true,
      green: 20,
      amber: 10,
    },
  ];

  if (props.selectedOpportunity.type.toLowerCase() !== 'potential') {
    chips.push({
      value: item.confidence,
      suffix: '%',
      green: 67,
      amber: 33,
    });
  }
  return chips;
}

function closeModal() {
  showAll.value = false;
  emit('close-modal');
}

function dismissProduct(product) {
  emit('add-feedback', {
    [product.id]: true,
  });
}

function pursueItem() {
  emit('pursueas-interaction', props.selectedOpportunity);
}

function getProductCount(products, type) {
  const items = [];
  for (let i = 0; i < products.length; i++) {
    if (products[i].prod_category === type) {
      items.push(products[i].product_name);
    }
  }
  return items.length;
}

function getUnabridgedProductCodes(products, type) {
  const items = [];
  for (let i = 0; i < products.length; i++) {
    if (products[i].prod_category === type) {
      items.push(products[i].product_name);
    }
  }
  return items;
}

function getUnabridgedProducts(products, type) {
  const items = [];
  for (let i = 0; i < products.length; i++) {
    if (products[i].prod_category === type) {
      items.push(products[i]);
    }
  }
  return items;
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

header {
  position: relative;
}

.sort-filter {
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}

.show-more {
  display: block;
}
.opportunity-product-list {
  padding: 0 var(--spacing-3) var(--spacing-3);
}

.opportunity {
  margin: 0 0 var(--spacing-1) 0;
}

.missed-details {
  header {
    text-align: center;
    color: black;
    margin: 0 var(--spacing-2) var(--spacing-2);
  }
}

:deep(.content) {
  padding: 0;

  .container {
    padding: 0;
  }
}
</style>
