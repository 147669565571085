import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute, } from 'vue-router';
import { deepClone, pluralise, t, strings } from '@sales-i/utils';
import { generateInteractionFields } from '@/crm/store/data/interaction';
import { SET_GENERATE_INTERACTIONS_REQUEST } from '@/crm/store/actionType';
import { useUsers } from '@/shared/composables/useUsers';
import useFields from '@/shared/composables/useFields';
import { LOCATION_PROSPECTS } from '@/crm/store/data/interactionStageNames';
import { navigateToUrl } from 'single-spa';

export const useGenerateInteractionValidation = (emit, selectedIndex, nextStep, options = {}) => {
  const store = useStore();
  const vroute = useRoute();

  const { usersOptions } = useUsers(store);
  const { fieldsOptions, getFields } = useFields({ store });

  const formData = ref([]);

  const saveFormValues = (values) => store.dispatch(`crm/interactions/${SET_GENERATE_INTERACTIONS_REQUEST}`, values);

  const entityType = computed(() => store.state.crm.interactions.currentlyEditing.entityType || '');
  const requestData = computed(() => store.state.crm.interactions.generateInteractions.data);
  const currentlyEditing = computed(() => store.state.crm.interactions.currentlyEditing);
  const userDetails = computed(() => store.state.userDetails.data);

  const formActions = ref({ 'form-actions': true });
  const formBody = ref({ 'form-body': true });
  const valid = computed(() => {
    if (options.valid) {
      return options.valid;
    }
    let isValid = true;
    formData.value.forEach(section => {
      // check if required fields have value
      isValid = section.fields.reduce(
        (prev, curr) => {
          return prev && (!curr.required || !!curr.value);
        },
        true
      );
    });
    return isValid;
  });
  const flatFields = computed(() => {
    if (options.flatFields) {
      return options.flatFields;
    }
    const exclusions = ['Number/duration', 'Exclusions'];
    return formData.value.reduce((prev, s) =>
      ({
        ...prev,
        ...s.fields.reduce((prevF, f) => ({
          ...prevF,
          [f.id]: exclusions.includes(s.sectionTitle) ? parseInt(f.value, 10) : f.value,
        }), {})
      }), {}
    );
  });
  const entityTypeTitle = computed(() => strings.capitalize(entityType.value, { onlyFirstLetter: true }));

  onMounted(async () => {
    //if not date set send to the first page
    if (!currentlyEditing.value.dateTime) {
      let pathOneLevelUp = vroute.path.split('/').slice(0,4).join('/');
      navigateToUrl(pathOneLevelUp);
    }

    if (!fieldsOptions?.value?.type?.length) {
      await getFields({ entity: 'interactions' });
    }

    formData.value = deepClone(generateInteractionFields[selectedIndex.value].sections);
    formData.value.forEach((section) => {
      section.fields.forEach((field) => {
        if (Object.keys(requestData.value).includes(field.id)) {
          field.value = requestData.value[field.id];
        }
        // retore values of radio buttons
        else if (field.type == 'radio'
          && Object.keys(requestData.value).includes(field.name)
          && requestData.value[field.name] // otherwise leave default value for field
        ) {
          field.value = requestData.value[field.name] === field.id;
        }
      });
    });
    if (selectedIndex.value === 0) {
      setInteractionType();
      setUser();
    }
    replaceFields();
  });

  const replaceFields = () => {
    formData.value.forEach(section => {
      // Change sections
      if (section.sectionTitle === t(LOCATION_PROSPECTS)) {
        section.sectionTitle = t(`Location of ${pluralise.string(entityType.value)}`);
      }

      // Change fields
      section.fields = section.fields.map(field => {
        if (field.id === 'exclude_recently_contacted_days') {
          field.label = t(`Exclude ${pluralise.string(entityType.value)} contacted within`);
        }
        if (field.id === 'search_location') {
          field.label = t(`${entityTypeTitle.value} location`);
        }

        return field;
      });
      return section;
    });
  };

  const setInteractionType = () => {
    if (fieldsOptions?.value?.type?.length) {
      formData.value[1].fields[0].options = fieldsOptions.value.type;
      if (Object.keys(requestData.value).includes(formData.value[1].fields[0].id)) {
        formData.value[1].fields[0].value = requestData.value[formData.value[1].fields[0].id];
      } else {
        formData.value[1].fields[0].value = fieldsOptions.value.type[0].value;
      }
    } else {
      setTimeout(() => {
        setInteractionType();
      }, 100);
    }
  };

  const setUser = () => {
    if (usersOptions.value?.length) {
      formData.value[0].fields[0].options = usersOptions.value;
      if (Object.keys(requestData.value).includes(formData.value[0].fields[0].id)) {
        formData.value[0].fields[0].value = requestData.value[formData.value[0].fields[0].id];
      } else {
        formData.value[0].fields[0].value = userDetails.value.identifier;
      }
    } else {
      setTimeout(() => {
        setUser();
      }, 100);
    }
  };

  const preSaveData = () => {
    saveFormValues(flatFields.value);
  };
  const goNextStep = () => {
    if (!valid.value) return;
    preSaveData();
    navigateToUrl(nextStep.value);
  };
  const handleKeydown = (event) => {
    emit('keydown', event);
  };
  const handleKeypress = (event) => {
    emit('keypress', event);
  };
  const handleKeyup = (event) => {
    emit('keyup', event);
  };
  const handleFieldValid = (event) => {
    emit('fieldValid', event);
  };

  return {
    entityType,
    flatFields,
    formActions,
    formBody,
    formData,
    handleKeydown,
    handleKeypress,
    handleKeyup,
    handleFieldValid,
    goNextStep,
    requestData,
  };
};