<template>
  <TableWrapper table-height="100%">
    <thead>
      <tr>
        <th
          v-for="(header, index) in tableHeadings"
          :key="index"
          :class="{
            'sorting-header': currentSortingIndex === index,
            'contains-chip-header': index,
          }"
          :title="tableSummary[index]"
          @click="applySort(index)"
        >
          <TableHeadContent>
            <template #icon>
              <IconBase
                v-if="currentSortingIndex === index && currentSortingIndex > 0"
                class="sort-icon"
                :icon-name="sortDescending ? 'arrow-down' : 'arrow-up'"
                :height="16"
                :width="16"
              />
            </template>
            {{ header }}
          </TableHeadContent>
          <p
            v-if="index"
            class="total-chip"
          >
            {{ formatValue(tableSummary[index]) }}
          </p>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(row, i) in tableRows"
        :key="i"
      >
        <td
          v-for="(unit, index) in row"
          :key="index"
          :class="{
            'first-column': !index,
            negative: unit < 0,
          }"
          :title="unit"
        >
          {{ index ? formatValue(unit) : unit }}
        </td>
      </tr>
    </tbody>
  </TableWrapper>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { IconBase } from '@sales-i/dsv3';
import TableWrapper from '@/shared/components/Tables/TableWrapper';
import TableHeadContent from '@/shared/components/Tables/TableHeadContent';
import useCurrency from '@/shared/composables/useCurrency';
import useShared from '@/intelligence/composables/useShared';

const props = defineProps({
  isSnapshot: {
    type: Boolean,
    default: false,
  },
  reportId: {
    type: Number,
    default: null,
  },
  isQuantityType: {
    type: Boolean,
    default: false,
  },
});

const store = useStore();

const sortDescending = ref(false);
const currentSortingIndex = ref(0);

const { cPrice, cNumber } = useCurrency({ store });
const { getReportData } = useShared({ store });

const tableData = computed(() => getReportData(props.reportId));
const tableHeadings = computed(() => tableData.value.headings);
const tableSummary = computed(() => ['', ...Object.values(tableData.value.summary)]);
const tableRows = computed(() => {
  const res = tableData.value.rows.map(row => {
    return [+row.period, row.value_1, row.value_2, row.value_variance];
  });

  return (currentSortingIndex.value == 0)
    ? res
    : res.sort((a, b) => (sortDescending.value ? -1 : 1) * (a[currentSortingIndex.value] - b[currentSortingIndex.value]));
});

const applySort = (index) => {
  sortDescending.value = (currentSortingIndex.value === index) ? !sortDescending.value : false;
  if (!props.isSnapshot) currentSortingIndex.value = index;
};

const formatValue = (value) => {
  return props.isQuantityType ? cNumber(value) : cPrice(value);
};
</script>

<style lang="scss" scoped>
.table-wrapper {
  overflow: auto;
  height: 330px;
}

table {
  position: relative;
  width: 100%;
}

tbody tr:first-child {
  height: var(--spacing-8);

  th,
  td {
    vertical-align: bottom;
  }
}

tr {
  background: var(--colour-panel-g-0);

  &:nth-child(even) {
    background: var(--colour-panel-g-2);
  }
}

th,
td {
  color: var(--colour-utility-black);
  padding: var(--spacing-2);
  border: none;
}

th {
  font-size: var(--font-size-4);
  background: var(--colour-panel-action);
  color: var(--colour-utility-action);
  position: sticky;
  top: 0;
  text-align: center;
  min-height: 70px;
  vertical-align: middle;
  text-decoration: underline;
  cursor: pointer;

  // &:first-child {
  //   cursor: auto;
  //   text-decoration: none;
  //   color: var(--colour-utility-black);
  // }

  &.contains-chip-header {
    padding-bottom: var(--spacing-3);
    min-width: 250px;
  }

  &.sorting-header {
    background: var(--colour-panel-g-2);
    color: var(--colour-utility-black);
    font-weight: var(--font-weight-medium);
    text-decoration: none;
  }
}

.svg-container {
  position: absolute;
  top: 0;
  right: 0;
}

td {
  font-size: var(--font-size-small);
  padding: var(--spacing-1);
  line-height: var(--spacing-2);
  text-align: center;

  &.first-column {
    background: var(--colour-panel-g-2);
    border-right: 1px solid var(--colour-panel-g-8);
    text-align: center;
  }
}

tr:nth-child(even) td.first-column {
  background: var(--colour-panel-g-4);
}

.sort-icon {
  display: inline-block;
  position: absolute;
}

.total-chip {
  position: absolute;
  width: 120px;
  color: var(--colour-utility-black);
  background: var(--colour-panel-g-0);
  box-shadow: 0 0 var(--border-radius-half) var(--shadow-spread) var(--shadow-colour);
  font-size: var(--font-size-5);
  font-weight: 500;
  letter-spacing: 0;
  line-height: var(--spacing-3);
  padding: var(--spacing-1);
  border-radius: var(--spacing-6);
  margin: auto;
  margin-top: var(--spacing-1);
  right: 50%;
  transform: translateX(50%);
}

.negative {
  color: var(--colour-data-mandy-label);
}
</style>
