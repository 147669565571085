<template>
  <div class="no-results flow">
    <h3>{{ t('No data available') }}</h3>
    <p>{{ t('Adjusting settings, dimensions and filters can help return better results') }}</p>
    <p>
      {{ t('You can also') }} <a href="mailto:support@sales-ai.com">{{ t('report issues here') }}.</a>
    </p>
  </div>
</template>

<script setup>
import { t } from '@sales-i/utils';
</script>

<style lang="scss" scoped>
.no-results {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: var(--colour-panel-g-2);
  padding: var(--spacing-4);
  text-align: center;
  flex: 1;

  h3 {
    font-weight: var(--font-weight-semibold);
  }
}
</style>
