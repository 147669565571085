<template>
  <div>
    <CustomButton
      class="export-button"
      purpose="action"
      small
      @click="modalTrigger"
    >
      {{ title }}
    </CustomButton>
    <Teleport to="#modal-teleport-target">
      <CustomModal
        v-if="isModalVisible"
        id="export-modal"
        :title="title"
        :show-modal="isModalVisible"
        @close-modal="closeModal"
      >
        <p class="subtitle">
          {{ t('Export as') }}
        </p>
        <CustomSelect
          v-if="props.reportType"
          :value="selectedFileFormat"
          :items="fileFormats"
          @on-change="onFileFormatChange"
        />
        <div>
          <CustomButton
            v-if="!downloading"
            purpose="action"
            @click="exportHandler"
          >
            {{ t('Export') }}
          </CustomButton>
          <BufferImage
            v-else
            color="black"
            float="center"
            class="loading-spinner"
          />
        </div>
      </CustomModal>
    </Teleport>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { t } from '@sales-i/utils';
import { CustomModal, CustomButton, CustomSelect, BufferImage } from '@sales-i/dsv3';
import {
  FULL_PICTURE,
  MATRIX,
  QUERY_BUILDER,
  SALES_VS_GAPS,
  VARIANCE
} from '@/intelligence/store/data/reportTypes';
import useCurrency from '@/shared/composables/useCurrency';
import useExport from '@/intelligence/composables/useExport';

const props = defineProps({
  activeColumnsData: {
    type: Array,
    default: () => [],
  },
  title: {
    type: String,
    default: ''
  },
  reportType: {
    type: String,
    default: ''
  },
  requestParameterOverride: {
    type: String,
    default: '',
  },
});

const { cs } = useCurrency();
const store = useStore();
const { 
  exportFullPictureReport, exportSvgReport, 
  exportMatrixReport, exportVarianceReport, exportReport } = useExport({ store });

const customerObject = ref({});
const selectedFileFormat = ref('CSV');
const isModalVisible = ref(false);
const downloading = ref(false);

const fileFormats = computed(() => [
  {
    text: 'CSV',
    value: 'CSV'
  },
  {
    text: 'PDF',
    value: 'PDF'
  },
  {
    text: 'XLSX',
    value: 'XLSX'
  },
].filter(format => props.reportType !== MATRIX || format.value !== 'PDF'));
const userDetails = computed(() => store.state.userDetails.data);
const platform = computed(() => store.state.platform);
const isCustomerModeEnabled = computed(() => store.state.admin.intelligence.isCustomerModeEnabled);

function modalTrigger() {
  isModalVisible.value = true;
}

function closeModal() {
  isModalVisible.value = false;
}

const sanitiseRequestParameterOverride = (parameters) => {
  parameters = parameters.replace(')', '');
  parameters = parameters.replace(/([a-zA-Z]+)\s?\(/, '');
  return parameters;
};

async function exportHandler() {
  const reporter = `${userDetails.value?.first_name} ${userDetails.value.last_name}`;
  const { name } = customerObject.value;
  const fileExt = selectedFileFormat.value;
  const customerMode = isCustomerModeEnabled.value;
  // Get the list of selected columns
  const cols = props.activeColumnsData;
  const exportData = {
    cols,
    fileExt,
    reporter,
    name,
    currency: cs,
    customerMode
  };

  if (platform.value.data === 'mobile') {
    const mobileData = {
      exportData,
    };
    try {
      window.webkit.messageHandlers.exportSnapshotCallbackHandler.postMessage(JSON.stringify(mobileData));
    } catch (err) {
      console.error('Mobile export failed:', err);
    }
  } else {
    downloading.value = true;

    const reportTypes = {
      [QUERY_BUILDER]: exportReport,
      [FULL_PICTURE]: exportFullPictureReport,
      [SALES_VS_GAPS]: exportSvgReport,
      [MATRIX]: exportMatrixReport,
      [VARIANCE]: exportVarianceReport
    };

    const exportFunction = reportTypes[props.reportType];

    try {
      if (exportFunction) {
        const exportArgs = [props.reportType, exportData];
        
        if (props.reportType === QUERY_BUILDER) {
          exportArgs[1] = {
            ...exportData,
            requestParameterOverride: sanitiseRequestParameterOverride(props.requestParameterOverride),
          };
        }

        await exportFunction(exportArgs);
      } else {
        console.error('Invalid report type:', props.reportType);
      }
      closeModal();
    } catch (err) {
      console.error('Export failed or restricted tenant user', err);
    } finally {
      downloading.value = false;
    }
  }
}

function onFileFormatChange(format) {
  selectedFileFormat.value = format;
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

#export-modal {
  .subtitle {
    margin-bottom: var(--spacing-1);
    color: var(--colour-utility-base);
  }

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    min-width: initial;
    max-width: 360px;
  }

  .content-wrapper .content{
    padding: 0 var(--spacing-3);
  }
}

.export-button {
  width: 100%;
}
</style>
