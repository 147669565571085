<template>
  <div class="edit-note-container">
    <IconButton
      class="note-button"
      icon-name="notes"
      icon-color="var(--colour-utility-white)"
      purpose="action"
      variant="round"
      icon-only
      :icon-size="18"
      @click.stop="toggleModal"
    />
    <CustomModal
      id="note-modal"
      class="note-modal"
      :show-modal="isModalVisible"
      position="top"
      @close-modal="toggleModal"
    >
      <BufferImage
        v-if="loading"
        class="loading-spinner"
        color="var(--colour-utility-black)"
        float="center"
      />
      <h2>{{ t('Update interaction note') }}</h2>
      <CustomTextarea
        v-model="note"
        class="note-area"
        :rows="10"
        :disabled="textAreaDisabled"
        :label="`${t('interaction note')}:`"
      />
      <template #footer>
        <CustomButton
          :label="t('Update')"
          purpose="action"
          small
          @click="updateNote"
        />
      </template>
    </CustomModal>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useInteraction } from '@/crm/composables/useInteraction';
import { IconButton, CustomModal, CustomButton, BufferImage, CustomTextarea } from '@sales-i/dsv3';
import { t } from '@sales-i/utils';

const props = defineProps({
  interactionNote: {
    type: String,
    default: '',
  },
  interactionId: {
    type: Number,
    default: null,
  },
});

const emit = defineEmits(['updateNote']);

const { currentlyEditing, getInteraction, putInteraction, setFurtherDetails } = useInteraction();

const isModalVisible = ref(false);
const loading = ref(false);
const note = ref('');
const textAreaDisabled = ref(false);

const toggleModal = () => {
  refreshNote();
  isModalVisible.value = !isModalVisible.value;
  textAreaDisabled.value = false;
};

const updateNote = async () => {
  textAreaDisabled.value = true;
  loading.value = true;
  await getInteraction({ id: props.interactionId });
  const furtherDetails = { ...currentlyEditing.value.furtherDetails };
  furtherDetails.interactionNote = note.value;
  setFurtherDetails(furtherDetails);
  await putInteraction({ id: props.interactionId });
  emit('updateNote', note.value);
  loading.value = false;
  toggleModal();
};

const refreshNote = () => {
  note.value = props.interactionNote;
};

watch(() => props.interactionNote, refreshNote);
</script>

<style lang="scss" scoped>
.note-footer {
  background: var(--colour-panel-action);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 72px;
}

h2 {
  text-align: center;
  margin-bottom: var(--spacing-2);
}

.note-area {
  padding: 0 var(--spacing-2);

  :deep(.form-group) {
    margin-bottom: 12px;
  }
}

#note-modal {
  :deep(.content-wrapper) {
    padding-bottom: 0;
  }
}

.loading-spinner {
  position: absolute;
  top: calc(50% - 50px);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
}
</style>