<template>
  <Field
    v-if="isCustomSection && options.length > 0"
    :id="customSectionId"
    v-model="selectedSection"
    type="select"
    :options="options"
  />
  <div class="form-field-grid">
    <Field
      v-if="selectedSection && selectedSectionFields.length > 0"
      v-model="selectedField"
      type="select"
      :label="t('Custom Section Fields')"
      required
      :options="selectedSectionFields"
    />
    <Field
      v-if="selectedField"
      :id="operatorId"
      v-model="operatorValue"
      required
      :error-message="t('Please choose an operator')"
      type="select"
      :options="textFilterOperators"
      :label="t('Operator')"
    />
    <Field
      v-if="selectedField && operatorValue"
      :id="valueId"
      v-model="filterValue"
      :error-message="t('Please enter a value')"
      required
      type="text"
      :label="t('Value')"
    />
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import Field from '@/shared/components/Form/Field.vue';
import { useStore } from 'vuex';
import { GET_ALL } from '@/shared/store/actionType';
import { t } from '@sales-i/utils';
import { useAlertBuilder } from '@/intelligence/composables/useAlertBuilder';

const props = defineProps({
  filter: {
    type: Object,
    default: undefined
  },
});

const emit = defineEmits(['update:filter']);
const store = useStore();
const { isEditMode } = useAlertBuilder({ store });

const selectedSection = ref(props.filter.entity || '');
const selectedField = ref(props.filter.field || '');
const operatorValue = ref(props.filter.is || '');
const filterValue = ref(props.filter.value || '');
const selectedSectionFields = ref([]);
const selectedCustomSection = ref(null);

const getCustomSections = (params) => store.dispatch(`crm/customFields/${GET_ALL}`, params);

onMounted(async () => {
  await getCustomSections('customers');
  if (isEditMode && sections.value?.length) {
    updateSelectedSectionFields(selectedSection.value);
  }
});

const textFilterOperators = computed(() => [
  { value: '=', text: t('Is Equal To') },
  { value: '!=', text: t('Does Not Equal') },
]);

const sections = computed(() => store.state.crm.customFields.sections);

const options = computed(() => {
  if (!sections.value) return [];

  return sections.value.map(({ name, id, fields }) => {
    const items = fields
      .filter(field => !field.enquiry_excluded && field.system_flag === 'custom')
      .map(({ name, id }) => ({ text: name, value: `customfield_${id}` }));
    return items.length ? { text: name, value: `customsection_${id}`, items } : null;
  }).filter(Boolean);
});

const isCustomSection = computed(() => selectedSection?.value.includes('customsection'));
const customSectionId = computed(() => `custom-${props.filter.id}`);
const operatorId = computed(() => `operator-${props.filter.id}`);
const valueId = computed(() => `value-${props.filter.id}`);

const updateSelectedSectionFields = (sectionId) => {
  const section = sections.value.find(({ id }) => id === parseInt(sectionId.split('_')[1]));
  selectedCustomSection.value = section;
  selectedSectionFields.value = section ? section.fields
    .filter(({ enquiry_excluded }) => !enquiry_excluded)
    .map(({ name, id }) => ({ text: name, value: `customfield_${id}` })) : [];
};

const emitFilterUpdate = () => {
  const matchedField = selectedSectionFields.value.find(field => field.value === selectedField.value);

  emit('update:filter', {
    entity: selectedSection.value,
    field: selectedField.value,
    id: props.filter.id,
    is: operatorValue.value,
    value: filterValue.value,
    fieldName: selectedCustomSection.value ? selectedCustomSection.value.name : '',
    sectionName: matchedField?.text || ''
  });
};

watch(sections, (newSections) => {
  if (newSections && newSections.length > 0 && selectedSection.value) {
    updateSelectedSectionFields(selectedSection.value);
  }
});

watch(selectedSection, (newVal) => {
  if (newVal) {
    updateSelectedSectionFields(newVal);
    selectedField.value = '';
    emitFilterUpdate();
  }
});

watch([selectedField, operatorValue, filterValue], () => {
  emitFilterUpdate();
});
</script>
