<template>
  <div class="flow">
    <h3>{{ t('Are there any specific locations you want to target?') }}</h3>
    <div class="form-field-flex">
      <Field
        :id="`location-type-${filter.id}`"
        required
        :error-message="t('Please choose a location type')"
        type="select"
        :options="locationOptions"
        :value="filter.field"
        :label="t('Location type')"
        @input="updateFilter('field', $event)"
      />
      <Field
        :id="`location-area-${filter.id}`"
        required
        :error-message="t(`Please enter a ${locationLabel.toLowerCase()}`)"
        type="text"
        :value="filter.value"
        :label="locationLabel"
        @input="updateFilter('value', $event)"
      />
      <Field
        :id="`this_location-${filter.id}`"
        :checked="filter.is === '='"
        type="radio"
        name="location"
        label="This location"
        value="="
        @input="updateFilter('is', '=')"
      />
      <Field
        :id="`not_this_location-${filter.id}`"
        :checked="filter.is === '!='"
        type="radio"
        name="location"
        label="Not this location"
        value="!="
        @input="updateFilter('is', '!=')"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import Field from '@/shared/components/Form/Field.vue';
import { t, strings } from '@sales-i/utils';

const props = defineProps({
  filter: {
    type: Object,
    default: undefined
  },
});

const emit = defineEmits(['update:filter']);

const locationOptions = [
  { text: t('Town'), value: 'town' },
  { text: t('Country'), value: 'country' },
  { text: t('Postcode/Zipcode'), value: 'post_code' },
];

const locationLabel = computed(() => {
  if (props.filter.field === 'post_code') return t('Postcode/Zipcode');
  return strings.capitalize(props.filter.field, { onlyFirstLetter: true }) || t('Location area');
});

function updateFilter(field, value) {
  emit('update:filter', {
    id: props.filter.id,
    [field]: value,
  });
}
</script>