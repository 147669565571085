import { ref, computed, } from 'vue';

export const paginationProps = {
  pageLimit: {
    type: Number,
    default: 50
  },
  pageOffset: {
    type: Number,
    default: 0
  },
  /** @todo replace by computed when attrs will work again */
  twoWayPagination: {
    type: Boolean,
    default: true
  }
};

export const paginationEmits = ['pageChanged'];

export function usePagination(props, loadPageData, emit) {
  const limit =  ref(props.pageLimit);
  const offsetOneWay = ref(props.pageOffset);
  const offset = computed(() => props.twoWayPagination ? props.pageOffset : offsetOneWay.value);

  function handlePageChanged(offsetValue) {
    offsetOneWay.value = offsetValue;
    if (loadPageData) { 
      loadPageData(); // provide this method in component
    }
    emit('pageChanged', offsetValue);
  }

  return {
    limit,
    offset,
    handlePageChanged
  };
}
