import { ref } from 'vue';
import { t } from '@sales-i/utils';
import useNotification from '@/shared/composables/useNotification';
import { useOpportunity } from '@/crm/composables/useOpportunity';

export const useOpportunityProductsModal = (loadData) => {
  const { sendSuccessAlert, sendErrorAlert } = useNotification();
  const { deleteOpportunity, deleteOpportunityWithProducts } = useOpportunity();

  const selectedOpportunity = ref(null);
  const modalOpen = ref(false);
  const dismissModalOpen = ref(false);
  const dismissedItems = ref({});
  const dismissOptions = ref({
    dismiss_for: '',
    dismiss_reason: '',
  });

  function openProductsModal(item) {
    selectedOpportunity.value = item;
    modalOpen.value = true;
  }
  
  function closeProductsModal() {
    selectedOpportunity.value = null;
    modalOpen.value = false;
  }
  
  function showDismissModal(dismissedItems) {
    dismissOptions.value = addFeedback({
      opportunity: selectedOpportunity.value,
      options: dismissOptions.value,
      products: dismissedItems,
    });
    dismissModalOpen.value = true;
  }
  
  function closeDismissModal() {
    selectedOpportunity.value = null;
    dismissModalOpen.value = false;
  }

  function willBeEmpty(opportunity, products) {
    let consequents;
  
    switch (opportunity.type) {
    case 'MISSED':
    case 'MISSEDGRP':
      consequents = opportunity.products.filter(p => {
        return p.prod_category.toLowerCase() === 'consequent';
      });
      return consequents.length === products.length;
    default:
      return products.length === opportunity.products.length;
    }
  }

  function addFeedback(feedbackData) {
    const { products, opportunity } = feedbackData;
    const productCodes = Object.keys(products);
    if (productCodes.length > 0) {
      // Build a list of dismiss options for each product
      const feedbackProducts = productCodes
        .map(prod => {
          if (products[prod]) {
            return {
              object: 'opportunity_product',
              object_id: prod,
              opportunity_id: opportunity.id.toString(),
            };
          }
        })
        .filter(o => o !== undefined);
  
      if (willBeEmpty(opportunity, feedbackProducts) === false && feedbackProducts.length) {
        return feedbackProducts;
      } else {
        return {
          object: 'opportunity',
          object_id: opportunity.id.toString(),
        };
      }
    } else {
      return {
        object: 'opportunity',
        object_id: opportunity.id.toString(),
      };
    }
  }

  async function postFeedback() {
    try {
      if (dismissOptions.value.constructor === Array) {
        // Delete the products
        const payload = {
          id: dismissOptions.value[0].opportunity_id,
          products: dismissOptions.value.map(prod => ({
            product_code: prod.object_id,
          })),
        };
        await deleteOpportunityWithProducts(payload);
      } else {
        await deleteOpportunity(dismissOptions.value.object_id);
      }
      sendSuccessAlert(t('Successfully deleted'));
      // Close all modals, and re-load the oppo data
      closeDismissModal();
      loadData();
    } catch (e) {
      sendErrorAlert(t('An error occurred deleting opportunity'));
    }
  }

  return {
    openProductsModal,
    closeProductsModal,
    showDismissModal,
    closeDismissModal,
    postFeedback,
    addFeedback,
    selectedOpportunity,
    modalOpen,
    dismissModalOpen,
    dismissedItems,
    dismissOptions,
  };
};

