import { ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { t } from '@sales-i/utils';
import { root, crmArea } from '@/admin/router/urlBits';
import {
  ADD_SECTION,
  MOVE_STAGE,
  GET_CUSTOM_SCHEMA,
  RESET_CHANGES,
  CLEAR_CUSTOM_SCHEMA,
} from '@/admin/store/actionType';
import {
  SET_CONFIRMATION_MODAL,
  CLEAR_CONFIRMATION_MODAL,
  SET_UPDATED_FLAG,
} from '@/shared/store/actionType';
import urlify from '@/shared/utils/urlify';
import { navigateToUrl } from 'single-spa';

export const useCustomSchema = ({
  store = useStore(),
  vroute = useRoute(),
  saveRef,
  enableOnMounted = true,
  enableWatch = true,
} = {}) => {

  if (enableWatch) watch(
    () => vroute.fullPath,
    (to, from) => {
      if (to !== from) {
        loadSchemaDetails();
        setActiveSection();
      }
    }
  );

  if (enableOnMounted) onMounted(async () => {
    await loadSchemaDetails();
    setActiveSection();
    const parts = vroute.fullPath.split('/');
    if (parts[parts.length - 1] === 'custom-sections') {
      isCustomSection.value = true;
    }
  });

  const saveChanges = saveRef?.value;
  const activeSection = ref(0);
  const subSection = ref(0);
  const isCustomSection = ref(false);
  const itemsLoading = ref(false);

  const customSchema = computed(() => store.state.admin.schema);

  const menuItems = computed(() => store.getters['admin/schema/getMenuItems']);
  const schemaSections = computed(() => store.getters['admin/schema/getSections']);

  const entity = computed(() => vroute.params.entity);

  const loadSchema = (params) => store.dispatch(`admin/schema/${GET_CUSTOM_SCHEMA}`, params);
  const clearSchema = () => store.dispatch(`admin/schema/${CLEAR_CUSTOM_SCHEMA}`);
  const resetChanges = () => store.dispatch(`admin/schema/${RESET_CHANGES}`);
  const move = (params) => store.dispatch(`admin/schema/${MOVE_STAGE}`, params);
  const showConfirmationModal = (payload) => store.dispatch(`confirmationModal/${SET_CONFIRMATION_MODAL}`, payload);
  const clearConfirmationModal = () => store.dispatch(`confirmationModal/${CLEAR_CONFIRMATION_MODAL}`);
  const setUpdating = (bool) => store.dispatch(`admin/schema/${SET_UPDATED_FLAG}`, bool);
  const addSection = (params) => store.dispatch(`admin/schema/${ADD_SECTION}`, params);

  const loadSchemaDetails = async () => {
    itemsLoading.value = true;
    await loadSchema({
      entity: entity.value,
      refreshCall: true,
    });
    itemsLoading.value = false;
  };

  const createSection = async () => {
    await addSection({ entity: entity.value, });
    const item = menuItems.value.find(
      (menu) => menu.name === t('Custom sections')
    );
    const latestSection = item.sections.length;
    setSubSection(latestSection);
  };

  const itemClicked = (item, index) => {
    if (customSchema.value.updatesNotSaved) {
      discardOrSaveChangesModal(() => {
        setUpdating(false);
        clearConfirmationModal();
        routeItem(item);
        setActiveSection(index);
      });
    } else {
      routeItem(item);
    }
  };

  const routeItem = (item) => {
    const route = item.name.toLowerCase();
    const directTo = item.id !== 1 ? urlify(route) : '';
    isCustomSection.value = item.isCustomSection === true;
    navigateToUrl(`/${root}/${crmArea}/${entity.value}/${directTo}`);
  };

  const setActiveSection = (index) => {
    if (customSchema.value.updatesNotSaved) return;
    if (entity.value === customSchema.value.entity) {
      if (index === undefined) {
        const ignore = ['', root, crmArea, entity.value];
        const parts = vroute.fullPath.split('/');
        parts.forEach((part) => {
          if (!ignore.includes(part)) {
            index = menuItems.value.findIndex(
              (item) => urlify(item.name) === part
            );
          }
        });
      }
      activeSection.value = Math.max(0, index || 0);
      setSubSection(0);
    } else {
      setTimeout(() => setActiveSection(), 100);
    }
  };

  const setSubSection = (index, directClick = false) => {
    if (!directClick && customSchema.value.updatesNotSaved) return;
    if (directClick && customSchema.value.updatesNotSaved) {
      discardOrSaveChangesModal(() => {
        setUpdating(false);
        clearConfirmationModal();
        subSection.value = index;
      });
    } else {
      subSection.value = index;
    }
  };

  const discardOrSaveChangesModal = (onCompleteFunc) => {
    showConfirmationModal({
      message: t(
        'You haven’t saved your changes',
        'you_havent_saved_your_changes'
      ),
      cancelLabel: t('Don’t Save', 'dont_save'),
      saveLabel: t('Save'),
      closeOnUpdate: true,
      closeOnCancel: false,
      updateFunction: async () => {
        try {
          await saveChanges();
          onCompleteFunc?.();
          return true;
        } catch (e) {
          return false;
        }
      },
      closeFunction: () => true,
      cancelFunction: () => {
        resetChanges();
        onCompleteFunc();
      },
    });
  };

  const deleteOption = (item, index) => {
    console.error('NOT IMPLEMENTED', item, index);
  };

  const addListItem = (item) => {
    console.error('NOT IMPLEMENTED', item);
  };

  return {
    activeSection,
    subSection,
    isCustomSection,
    itemsLoading,
    customSchema,
    menuItems,
    schemaSections,
    entity,
    loadSchema,
    clearSchema,
    resetChanges,
    move,
    showConfirmationModal,
    clearConfirmationModal,
    setUpdating,
    addSection,
    loadSchemaDetails,
    createSection,
    itemClicked,
    routeItem,
    setActiveSection,
    setSubSection,
    discardOrSaveChangesModal,
    deleteOption,
    addListItem,
  };
};