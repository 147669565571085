
import { computed } from 'vue';
import { useStore } from 'vuex';
import { storage } from '@sales-i/utils';
import { enquiryScope, baseUrl as intelligenceBase, reportScope } from '@/intelligence/router/urlBits';
import { APPLY_FILTERS_TO_QUERY, CLEAR_ENQUIRY_FILTERS, FETCH_THROWAWAY_BUBBLE_DATA, SET_FILTERS, FETCH_BUBBLE_VALUES, FETCH_BUBBLE_DATA, SET_DIMENSIONS_NUMBER, DELETE_FILTER, APPLY_OFFSET_X, SET_SHOW_GAPS, APPLY_MEASURE, SET_SORTING_DATA, APPLY_AXIS, APPLY_DIMS, SET_PERIOD_FILTER, SALES_DATA_CLAUSE, CRM_DATA_CLAUSE, PRODUCT_DATA_CLAUSE, SET_CRM_FILTERS, APPLY_SALES_FILTERS_TO_QUERY, APPLY_CRM_FILTERS_TO_QUERY, SET_SALES_FILTERS } from '@/intelligence/store/actionType';
import { FULL_PICTURE, MATRIX, SALES_VS_GAPS, TRENDS, VARIANCE } from '@/intelligence/store/data/reportTypes';

export default function useEnquiries({ store = useStore() } = {}) {
  const bubbleAreas = computed(() => store.state.intelligence.enquiry.bubbleAreas);
  const bubbleValues = computed(() => store.state.intelligence.enquiry.bubbleValues);
  const bubbleData = computed(() => store.state.intelligence.enquiry.bubbleData);
  const filtersApplied = computed(() => store.state.intelligence.enquiry.filtersApplied);
  const bubblesLoading = computed(() => store.state.intelligence.enquiry.bubblesLoading);
  const dimensionsNumber = computed(() => store.state.intelligence.enquiry.dimensionsNumber);
  const productDataClause = computed(() => store.state.intelligence.enquiry.productDataClause);

  const period = computed(() => store.getters['intelligence/enquiry/getPeriod']);

  const fetchThrowawayBubble = name => store.dispatch(`intelligence/enquiry/${FETCH_THROWAWAY_BUBBLE_DATA}`, name);
  const applyFiltersToQuery = (params) => store.dispatch(`intelligence/enquiry/${APPLY_FILTERS_TO_QUERY}`, params);
  const setFilters = params => store.dispatch(`intelligence/enquiry/${SET_FILTERS}`, params);
  const clearEnquiryFilters = params => store.dispatch(`intelligence/enquiry/${CLEAR_ENQUIRY_FILTERS}`, params);
  const fetchBubbleValues = params => store.dispatch(`intelligence/enquiry/${FETCH_BUBBLE_VALUES}`, params);
  const fetchBubbleData = params => store.dispatch(`intelligence/enquiry/${FETCH_BUBBLE_DATA}`, params);
  const setDimensionsNumber = params => store.dispatch(`intelligence/enquiry/${SET_DIMENSIONS_NUMBER}`, params);
  const deleteFilter = filter => store.dispatch(`intelligence/enquiry/${DELETE_FILTER}`, filter);
  const applyOffsetX = intValue => store.dispatch(`intelligence/enquiry/${APPLY_OFFSET_X}`, intValue);
  const setShowGaps = showGaps => store.dispatch(`intelligence/enquiry/${SET_SHOW_GAPS}`, showGaps);
  const applyMeasure = params => store.dispatch(`intelligence/enquiry/${APPLY_MEASURE}`, params);
  const setSortingData = arr => store.dispatch(`intelligence/enquiry/${SET_SORTING_DATA}`, arr);
  const applyAxis = params => store.dispatch(`intelligence/enquiry/${APPLY_AXIS}`, params);
  const applyDims = params => store.dispatch(`intelligence/enquiry/${APPLY_DIMS}`, params);
  const setPeriodFilter = params => store.dispatch(`intelligence/enquiry/${SET_PERIOD_FILTER}`, params);
  const updateSalesDataClause = (params) => store.dispatch(`intelligence/enquiry/${SALES_DATA_CLAUSE}`, params);
  const updateCrmDataClause = (params) => store.dispatch(`intelligence/enquiry/${CRM_DATA_CLAUSE}`, params);
  const updateProductDataClause = (params) => store.dispatch(`intelligence/enquiry/${PRODUCT_DATA_CLAUSE}`, params);
  const applyCrmFilters = () => store.dispatch(`intelligence/enquiry/${APPLY_CRM_FILTERS_TO_QUERY}`);
  const applySalesFilters = () => store.dispatch(`intelligence/enquiry/${APPLY_SALES_FILTERS_TO_QUERY}`);
  const setCrmFilter = (filters) => store.dispatch(`intelligence/enquiry/${SET_CRM_FILTERS}`, filters);
  const setSalesFilter = (filters) => store.dispatch(`intelligence/enquiry/${SET_SALES_FILTERS}`, filters);
  
  const openReportWithSingleFilter = async ({
    filterName = 'name', search, reportType = VARIANCE, 
    dimName = 'prod_group_level_1', dates
  }) => {
    const { date_from, date_to } = dates;
    const dimBubble = await fetchThrowawayBubble(dimName);
    const filterBubble = await fetchThrowawayBubble(filterName);
    const { selectedFilterType, selectedFieldTitle } = getFilterTitlesByFilter(filterName);
    storage.set('filters', [
      {
        title: 'Filter 1',
        selectedFilterType,
        selectedFieldTitle,
        description: `${selectedFilterType} > ${selectedFieldTitle}`,
        id: filterBubble[0].id,
        selected_values: [
          {
            value: search,
            id: search,
          },
        ],
        filter_type: 'include'
      },
      0,
    ]);
    window.open(
      `${intelligenceBase}/${enquiryScope}/${reportType}/${reportScope}?date_from=${date_from}&date_to=${date_to}&date_from2=${date_from}-1y&date_to2=${date_to}-1y&1-dim1=${dimBubble[0]?.id}`,
      '_blank'
    );
  };

  function getFilterTitlesByFilter(filterName) {
    switch (filterName) {
    case 'name':
      return { selectedFilterType: 'Customer', selectedFieldTitle: 'Name' };
    case 'prod_group_level_1_desc':
      return { selectedFilterType: 'Product', selectedFieldTitle: 'Product Group Level 1 Desc' };
    case 'product_group_level_1':
      return { selectedFilterType: 'Product', selectedFieldTitle: 'Product Group Level 1' };
    case 'product_name':
      return { selectedFilterType: 'Product', selectedFieldTitle: 'Name' };
    default:
      throw `Set selectedFilterType, selectedFieldTitle for ${filterName}`;
    }
  }
  
  async function applyFilterFromUrl(val, index) {
    const filterVal = val.split(',');
    const id = filterVal[0];
    const selectedFilterType = filterVal[2];
    const selectedFieldTitle = bubbleData.value.find(bbl => bbl.id === id)?.title;
    const bubbles = await fetchBubbleValues([filterVal[0], '']);
    const selectedVals = filterVal.slice(3).map(id => {
      const decodedId = decodeURIComponent(decodeURI(id));
      const value = bubbles.find(bubble => decodedId === bubble.id)?.value;
      return { id: decodedId, value };
    });
    const newFilter = {
      title: `Filter ${index + 1}`,
      description: `${selectedFilterType} > ${selectedFieldTitle}`,
      id,
      filter_type: filterVal[1],
      selectedFilterType,
      selected_values: selectedVals,
      selectedFieldTitle,
    };
    setFilters(newFilter);
  }

  function getEnquiryFiltersFromStorage() {
    return storage.get('enquiryFilters') || [];
  }

  function saveEnquiryFiltersToStorage() {
    // just apply all the existing filters in store to storage
    storage.set('enquiryFilters', filtersApplied.value);
  }

  function removeEnquiryFiltersFromStorage() {
    storage.remove('enquiryFilters');
  }

  function isEnquiryReport(reportType) {
    return [FULL_PICTURE, MATRIX, SALES_VS_GAPS, VARIANCE, TRENDS].includes(reportType);
  }
  
  return {
    bubbleAreas,
    bubbleValues,
    bubbleData,
    bubblesLoading,
    filtersApplied,
    period,
    dimensionsNumber,
    productDataClause,
    applyFiltersToQuery,
    applyOffsetX,
    setFilters,
    getEnquiryFiltersFromStorage,
    saveEnquiryFiltersToStorage,
    clearEnquiryFilters,
    fetchThrowawayBubble,
    openReportWithSingleFilter,
    fetchBubbleValues,
    fetchBubbleData,
    setDimensionsNumber,
    deleteFilter,
    applyFilterFromUrl,
    setShowGaps,
    applyMeasure,
    setSortingData,
    applyAxis,
    applyDims,
    setPeriodFilter,
    updateSalesDataClause,
    updateCrmDataClause,
    updateProductDataClause,
    applyCrmFilters,
    applySalesFilters,
    setCrmFilter,
    setSalesFilter,
    isEnquiryReport,
    removeEnquiryFiltersFromStorage,
  };
}