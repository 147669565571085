// Contacts Module
export const GET_CONTACTS = 'GET_CONTACTS';
export const GET_CONTACTS_LOADING = 'GET_CONTACTS_LOADING';
export const GET_CONTACTS_ERROR = 'GET_CONTACTS_ERROR';
export const CLEAR_CONTACTS = 'CLEAR_CONTACTS';

export const GET_CHILDREN = 'GET_CHILDREN';
export const GET_CHILDREN_LOADING = 'GET_CHILDREN_LOADING';
export const GET_CHILDREN_ERROR = 'GET_CHILDREN_ERROR';
export const CLEAR_CHILDREN = 'CLEAR_CHILDREN';
export const ROLL_UP = 'ROLL_UP';

// Entity Files by Id Module
export const UPDATE_ENTITY_FILE = 'UPDATE_ENTITY_FILE';
export const UPLOAD_ENTITY_FILE = 'UPLOAD_ENTITY_FILE';
export const UPLOAD_ENTITY_FILE_LOADING = 'UPLOAD_ENTITY_FILE_LOADING';
export const UPLOAD_ENTITY_FILE_ERROR = 'UPLOAD_ENTITY_FILE_ERROR';
export const DOWNLOAD_BY_ID = 'DOWNLOAD_BY_ID';
export const DOWNLOAD_BY_ID_ERROR = 'DOWNLOAD_BY_ID_ERROR';
export const SET_DOWNLOADING = 'SET_DOWNLOADING';

// Posts - by ID
export const GET_POST = 'GET_POST';
export const SET_POST = 'SET_POST';
export const SET_POST_LOADING = 'SET_POST_LOADING';
export const SET_POST_ERROR = 'SET_POST_ERROR';
export const CLEAR_POST = 'CLEAR_POST';
// Posts - multiple
export const GET_POSTS = 'GET_POSTS';
export const SET_POSTS = 'SET_POSTS';
export const SET_POSTS_LOADING = 'SET_POSTS_LOADING';
export const SET_POSTS_ERROR = 'SET_POSTS_ERROR';
export const CLEAR_POSTS = 'CLEAR_POSTS';

// Opportunity
// stages
export const GET_OPPORTUNITY_STAGES = 'GET_OPPORTUNITY_STAGES';
export const GET_OPPORTUNITY_STAGES_LOADING = 'GET_OPPORTUNITY_STAGES_LOADING';
export const GET_OPPORTUNITY_STAGES_ERROR = 'GET_OPPORTUNITY_STAGES_ERROR';
export const PUT_OPPORTUNITY_STAGE = 'PUT_OPPORTUNITY_STAGE';
export const PUT_OPPORTUNITY_STAGE_LOADING = 'PUT_OPPORTUNITY_STAGE_LOADING';
export const PUT_OPPORTUNITY_STAGE_ERROR = 'PUT_OPPORTUNITY_STAGE_ERROR';
// history
export const GET_HISTORY_BY_ID = 'GET_HISTORY_BY_ID';
export const GET_HISTORY_BY_ID_LOADING = 'GET_HISTORY_BY_ID_LOADING';
export const GET_HISTORY_BY_ID_ERROR = 'GET_HISTORY_BY_ID_ERROR';
export const CLEAR_HISTORY_BY_ID = 'CLEAR_HISTORY_BY_ID';

// Interactions
export const SET_DATE = 'SET_DATE';
export const SET_OPPORTUNITY = 'SET_OPPORTUNITY';
export const SET_FURTHER_DETAILS = 'SET_FURTHER_DETAILS';
export const SET_RELATED_TO = 'SET_RELATED_TO';
export const SET_ENTITY_ID = 'SET_ENTITY_ID';
export const REMOVE_OPPORTUNITY = 'REMOVE_OPPORTUNITY';
export const CLEAR_CURRENTLY_EDITING = 'CLEAR_CURRENTLY_EDITING';
export const SET_GENERATE_INTERACTIONS_REQUEST = 'SET_GENERATE_INTERACTIONS_REQUEST';
export const CLEAR_GENERATE_INTERACTIONS = 'CLEAR_GENERATE_INTERACTIONS';
export const POST_GENERATE_INTERACTIONS = 'POST_GENERATE_INTERACTIONS';
export const POST_GENERATE_INTERACTIONS_LOADING = 'POST_GENERATE_INTERACTIONS_LOADING';
export const SET_GENERATE_INTERACTIONS_ERROR = 'SET_GENERATE_INTERACTIONS_ERROR';
export const SET_GENERATE_INTERACTIONS_RESULT = 'SET_GENERATE_INTERACTIONS_RESULT';
export const RESET_NON_MANDATORY_FIELDS = 'RESET_NON_MANDATORY_FIELDS';

// Addresses
export const GET_ADDRESSES = 'GET_ADDRESSES';
export const GET_ADDRESSES_LOADING = 'GET_ADDRESSES_LOADING';
export const GET_ADDRESSES_ERROR = 'GET_ADDRESSES_ERROR';
export const CLEAR_ADDRESSES = 'CLEAR_ADDRESSES';

// Prospect
export const CHECK_URL = 'CHECK_URL';
export const GET_MERGE_ITEMS = 'GET_MERGE_ITEMS';
export const MERGE_PROSPECT = 'MERGE_PROSPECT';