import {
  PUT_BY_ID,
  PUT_BY_ID_LOADING,
  PUT_BY_ID_ERROR,
  POST_BY_ENTITY,
  POST_BY_ENTITY_LOADING,
  POST_BY_ENTITY_ERROR,
  SET_SELECTED,
  UPDATE_SELECTED,
  SET_ITEMS,
} from '@/shared/store/actionType';
import { dates, httpRequest } from '@sales-i/utils';
import { DateTime } from 'luxon';

export const updateGetters = {
  item: (state) => {
    return {
      status: 'open',
      value: 0,
      probability: 0,
      type: 'MANUAL',
      ...state.selected.data
    };
  },
  itemForAPI: (state, getters) => {
    const {
      id,
      entity_id,
      entity_type,
      contact_id,
      status,
      allocated_users = [],
      value = 0,
      probability = 0,
      decision_date,
      business_area_id,
      stage_id,
      next_step,
      percent_complete = 0,
      type = 'MANUAL',
    } = getters.item;
    const opportunity = {
      entity_id,
      entity_type,
      contact_id: +contact_id || null,
      status,
      allocated_users,
      value: +value,
      probability: +probability,
      decision_date,
      stage_id: +stage_id,
      next_step,
      percent_complete,
      type,
      ...(id ? {id} : {}),
    };

    if (business_area_id !== undefined) {
      opportunity.business_area_id = +business_area_id;
    }
    return opportunity;
  },
  isValid: (state) => {
    let { years } = dates.getDiff(state.selected.data.decision_date, DateTime.now().startOf('day'), ['years']);  
    return !!state.selected.data &&
    !!state.selected.data.entity_id &&
    state.selected.data.status &&
    state.selected.data.allocated_users &&
    state.selected.data.allocated_users.length > 0 &&
    // Why does this not use the custom schema setup?
    // state.selected.data.business_area_id &&
    state.selected.data.decision_date &&
    years <=5 && years >= -1;  
  },
};

// mutations
export const updateMutations = {
  [PUT_BY_ID]: (state, data) => {
    state.selected.data = {
      ...state.selected.data,
      ...data
    };
    state.selected.updated = true;
    state.selected.loading = false;
    state.selected.loaded = true;
    state.selected.error = undefined;
  },
  [PUT_BY_ID_LOADING]: (state) => {
    state.selected.loading = true;
    state.selected.loaded = false;
  },
  [PUT_BY_ID_ERROR]: (state, error) => {
    state.selected.updated = false;
    state.selected.loading = false;
    state.selected.loaded = false;
    state.selected.error = error;
  },
  [POST_BY_ENTITY]: (state, data) => {
    state.selected.data = data;
    state.selected.updated = true;
    state.selected.loading = false;
    state.selected.loaded = true;
    state.selected.error = undefined;
  },
  [POST_BY_ENTITY_LOADING]: (state) => {
    state.selected.loading = true;
    state.selected.loaded = false;
  },
  [POST_BY_ENTITY_ERROR]: (state, error) => {
    state.selected.updated = false;
    state.selected.loading = false;
    state.selected.loaded = false;
    state.selected.error = error;
  },
};

// actions
export const updateActions = {
  [PUT_BY_ID]: async ({ commit, dispatch }, payload) => {
    commit(PUT_BY_ID_LOADING);
    try {
      const responseData = await httpRequest('put', `opportunities/${payload.id}`, payload, { version: '2', throwErrors: false });
      commit(PUT_BY_ID, {
        ...payload,
        ...responseData,
        id: +responseData.id,
      });
      dispatch(`alerts/${SET_ITEMS}`, {
        type: 'success',
        message: 'Opportunity Saved',
        selfDismiss: true,
      }, {root: true});
      return true;
    } catch (error) {
      commit(PUT_BY_ID_ERROR, error);
      dispatch(`alerts/${SET_ITEMS}`, {
        type: 'error',
        message: JSON.stringify(error)
      }, {root: true});
      return false;
    }
  },
  [POST_BY_ENTITY]: async ({ commit, dispatch }, payload) => {
    commit(POST_BY_ENTITY_LOADING);
    try {
      const responseData = await httpRequest('post', 'opportunities', payload, { version: '2', throwErrors: false });
      commit(POST_BY_ENTITY, {
        ...payload,
        ...responseData,
        id: +responseData.id,
      });
      dispatch(`alerts/${SET_ITEMS}`, {
        type: 'success',
        message: 'Opportunity Added',
        selfDismiss: true,
      }, {root: true});
      return true;
    } catch (error) {
      commit(POST_BY_ENTITY_ERROR, error);
      dispatch(`alerts/${SET_ITEMS}`, {
        type: 'error',
        message: JSON.stringify(error)
      }, {root: true});
      return false;
    }
  },
  [SET_SELECTED]: async ({ commit }, data) => {
    if (!data) return;
    commit(data.id ? PUT_BY_ID : POST_BY_ENTITY, data);
  },
  [UPDATE_SELECTED]: async ({ getters, dispatch }) => {
    if (!getters.isValid) return false;
    return await dispatch(getters.itemForAPI.id ? PUT_BY_ID : POST_BY_ENTITY, getters.itemForAPI);
  }
};
