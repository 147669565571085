import { SET_ENTITY, CLEAR_ENTITY } from '@/shared/store/actionType';
import {
  SET_DATE,
  SET_OPPORTUNITY,
  SET_FURTHER_DETAILS,
  SET_ENTITY_ID,
  REMOVE_OPPORTUNITY,
  CLEAR_CURRENTLY_EDITING,
  SET_RELATED_TO,
  RESET_NON_MANDATORY_FIELDS,
} from '@/crm/store/actionType';
import { dates } from '@sales-i/utils';

export const currentlyEditingGetters = {
  startHours: (state) => dates.getStartHours(state.currentlyEditing.dateTime),
  startMinutes: (state) => dates.getStartMinutes(state.currentlyEditing.dateTime),
  durationHours: (state) => dates.getDurationHours(state.currentlyEditing.duration),
  durationMinutes: (state) => dates.getDurationMinutes(state.currentlyEditing.duration),
  entityIDs: (state) => state.currentlyEditing.entityIDs,
  entityType: (state) => state.currentlyEditing.entityType,
  schedule: (state) => state.currentlyEditing.schedule
};

// mutations
export const currentlyEditingMutations = {
  [SET_RELATED_TO]: (state, id) => {
    state.currentlyEditing.related_to = id || 0;
  },
  [SET_DATE]: (state, data) => {
    state.currentlyEditing.dateTime = data.date;
    state.currentlyEditing.duration = data.duration;
    state.currentlyEditing.backdated = data.backdated;
    state.currentlyEditing.schedule = data.schedule;
  },
  [SET_ENTITY]: (state, data) => {
    if (data.entityType) {
      state.currentlyEditing.entityType = data.entityType;
    }
    if (data.entity) {
      state.currentlyEditing.entity = data.entity;
    }
    if (data.contact) {
      state.currentlyEditing.contact = data.contact;
    }
    // clear selected opportunities if entity has changed
    state.currentlyEditing.opportunities = [];
  },
  [SET_ENTITY_ID]: (state, id) => {
    state.currentlyEditing.entityIDs = id;
  },
  [SET_OPPORTUNITY]: (state, opportunity) => {
    state.currentlyEditing.opportunities.push(opportunity);
  },
  [SET_FURTHER_DETAILS]: (state, details) => {
    state.currentlyEditing.furtherDetails = details;
  },
  [REMOVE_OPPORTUNITY]: (state, opportunity) => {
    state.currentlyEditing.opportunities = state.currentlyEditing.opportunities.filter(opp => opp.id !== opportunity.id);
  },
  [CLEAR_ENTITY]: (state, clear) => {
    if (clear.all || clear.entityType) {
      state.currentlyEditing.entityType = false;
    }
    if (clear.all || clear.entity) {
      state.currentlyEditing.entity = {};
    }
    if (clear.all || clear.contact) {
      state.currentlyEditing.contact = {};
    }
  },
  [CLEAR_CURRENTLY_EDITING]: (state) => {
    state.currentlyEditing = {
      dateTime: false,
      duration: {},
      entityType: false,
      entity: {},
      contact: {},
      opportunities: [],
      furtherDetails: {},
      schedule: '',
      backdated: false,
    };
  },
  [RESET_NON_MANDATORY_FIELDS]: (state) => {
    state.currentlyEditing.furtherDetails = {
      ...state.currentlyEditing.furtherDetails,
      interactionNote: '',
      nextAction: '',
      outcome: ''
    };
  },
};


// actions
export const currentlyEditingActions = {
  [SET_RELATED_TO]: ({commit}, id) => {
    commit(SET_RELATED_TO, +(id || 0));
  },
  [SET_DATE]: async ({commit}, data) => {
    commit(SET_DATE, data);
  },
  [SET_ENTITY]: async ({commit}, data) => {
    commit(SET_ENTITY, data);
  },
  [SET_ENTITY_ID]: async ({commit}, data) => {
    commit(SET_ENTITY_ID, data);
  },
  [SET_OPPORTUNITY]: async ({commit}, data) => {
    commit(SET_OPPORTUNITY, data.opportunity);
  },
  [SET_FURTHER_DETAILS]: async ({commit}, data) => {
    commit(SET_FURTHER_DETAILS, data);
  },
  [REMOVE_OPPORTUNITY]: async ({commit}, data) => {
    commit(REMOVE_OPPORTUNITY, data.opportunity);
  },
  [CLEAR_ENTITY]: async ({commit}, clear) => {
    commit(CLEAR_ENTITY, clear);
  },
  [CLEAR_CURRENTLY_EDITING]: async ({commit}) => {
    commit(CLEAR_CURRENTLY_EDITING);
  },
  [RESET_NON_MANDATORY_FIELDS]: async ({ commit }) => {
    commit(RESET_NON_MANDATORY_FIELDS);
  },
};
