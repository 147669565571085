<template>
  <div>
    <!-- [Compulsory Fields, Non Compulsory Fields] -->
    <div :class="formBody">
      <form
        class="form-grid"
        @submit.prevent="selectedIndex === finalIndex() ? submit($event, submitAction.onClick) : next"
      >
        <div class="row mb-2">
          <div class="col col-12">
            <Stage
              v-if="finalIndex() > 0 && !saved"
              :items="stages()"
              :selected-index="selectedIndex"
              @stage="stage"
            />
          </div>
        </div>
        <div
          v-for="(item, stageIndex) in loadedFormData"
          :key="stageIndex"
          class="row"
          :class="{
            [`stage-${stageIndex}`]: true,
            ['hide']: selectedIndex !== stageIndex || saved,
          }"
        >
          <div
            v-for="(field, fieldIndex) in item.fields.filter(e => !e.isContact)"
            :key="fieldIndex"
            :class="[
              'col',
              'col-12',
              `col-sm-${colSpan}`,
              `col-md-${colSpan}`,
              `col-lg-${colSpan}`,
              `col-xl-${colSpan}`,
            ]"
          >
            <Field
              v-bind="field"
              :class="field.id"
              @valid="validatedField"
              @keydown="handleKeydown"
              @keypress="handleKeypress"
              @keyup="handleKeyup"
            />
          </div>
          <div
            :class="[
              'col',
              'col-12',
              `col-sm-${colSpan}`,
              `col-md-${colSpan}`,
              `col-lg-${colSpan}`,
              `col-xl-${colSpan}`,
            ]"
          >
            <span
              v-if="item.fields.find(e => e.isContact)"
              class="field-label"
            >
              {{ t('Contact preferences') }}:
            </span>
            <div
              v-for="(field, fieldIndex) in item.fields.filter(e => e.isContact)"
              :key="fieldIndex"
            >
              <Field
                v-bind="field"
                :class="field.id"
                @valid="validatedField"
                @keydown="handleKeydown"
                @keypress="handleKeypress"
                @keyup="handleKeyup"
              />
            </div>
          </div>
        </div>
        <slot
          v-if="saved"
          name="savedContent"
        />
        <ButtonGroup :class="formActions">
          <template v-if="!isSubmitting">
            <CustomButton
              v-for="action in actionsToDisplay"
              :key="action.label"
              :label="action.label"
              :disabled="action.disabled"
              :purpose="action.isSubmit && selectedIndex === finalIndex() ? 'action' : action.purpose || 'action'"
              :small="action.small || false"
              :class="action.isSubmit ? `action ${allMandatoryValid ? '' : 'disabled'}` : 'action'"
              :type="action.isSubmit ? (selectedIndex === finalIndex() ? 'submit' : 'button') : 'button'"
              @on-click="action.isSubmit ? submit($event, action.onClick) : action.onClick($event)"
            />
            <CustomButton
              v-if="selectedIndex !== finalIndex() && !saved"
              label="Next"
              small
              type="submit"
              :class="`action ${valid ? '' : 'disabled'}`"
              @on-click="next"
            />
          </template>
          <template v-if="isSubmitting">
            <BufferImage :size="40" />
          </template>
        </ButtonGroup>
      </form>
    </div>
  </div>
</template>

<script>
import Stage from '@/shared/components/Stage/Stage.vue';
import Field from '@/shared/components/Form/Field.vue';
import { BufferImage, CustomButton } from '@sales-i/dsv3';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import { mapState, mapActions, mapGetters } from 'vuex';
import { GET_CUSTOM_SCHEMA } from '@/admin/store/actionType';
import { isEqualObjects, t } from '@sales-i/utils';

export default {
  components: {
    Stage,
    Field,
    CustomButton,
    BufferImage,
    ButtonGroup,
  },
  props: {
    validationSet: {
      type: String,
      default: '',
    },
    saved: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Array,
      default: () => [],
    },
    formdata: {
      type: Array,
      default: () => [],
    },
    apiErrors: {
      type: Array,
      default: () => [],
    },
    colSpan: {
      type: Number,
      default: 6,
    },
    stickyActions: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['keydown', 'keypress', 'keyup', 'showUnloadWarning'],
  data() {
    return {
      selectedIndex: 0,
      isSubmitting: false,
      loadedFormData: [],
      fields: [],
      initialFormValues: {},
    };
  },
  computed: {
    ...mapGetters({
      getSchemaSections: 'admin/schema/getSections',
    }),
    ...mapState({
      validationSchema: state => state.crm.customSchema.schema,
    }),
    formBody() {
      return {
        'form-body': true,
        'sticky-actions': this.stickyActions,
      };
    },
    formActions() {
      return {
        'form-actions': true,
        'sticky-actions': this.stickyActions,
      };
    },
    valid() {
      return this.validateForm(false);
    },
    allMandatoryValid() {
      return this.validateForm(false, true);
    },
    actionsToDisplay() {
      return this.actions.filter(a => a.display !== false);
    },
    submitAction() {
      return this.actionsToDisplay.filter(a => a.isSubmit)[0];
    },
    isFormDirty() {
      return !isEqualObjects(this.initialFormValues, this.getValues());
    },
  },
  watch: {
    apiErrors: {
      handler() {
        let dataTemp = this.loadedFormData;
        dataTemp = this.loadedFormData.map(data => {
          const fields = data.fields.map(field => {
            const fieldHasError = this.apiErrors.find(
              error => error.field && error.field.toLowerCase() === field.id.toLowerCase()
            );
            if (fieldHasError) {
              field.externalError = true;
            }
            return field;
          });
          return {
            ...data,
            fields,
          };
        });
        this.loadedFormData = dataTemp;
      },
      deep: true,
    },
    isFormDirty(value) {
      this.$emit('showUnloadWarning', value);
    },
  },
  mounted() {
    // Fetch any potential validation from custom schema we might need
    if (this.validationSet !== '') {
      this.loadValidationSet(this.validationSet);

      // If these fields come from the back-end, we need
      // to ignore the fields added via the front-end, and
      // use the ones powered by the back-end
      const formSections = this.getSchemaSections;

      this.loadedFormData = formSections;
    } else {
      this.loadedFormData = this.formdata;
    }

    this.fields = this.initialiseFields();

    // Create a deep copy of the initial values
    this.initialFormValues = this.getValues();
    return;
  },
  methods: {
    t,
    ...mapActions([`admin/schema/${GET_CUSTOM_SCHEMA}`]),
    initialiseFields() {
      let arr = [];

      this.loadedFormData.forEach((stage, stageIndex) => {
        stage.fields.forEach(field => {
          arr.push({
            id: field.id,
            valid: !!field.value || !field.required,
            value: field.value,
            stage: stageIndex,
          });
        });
      });
      return arr;
    },
    setSubmitting(isSubmitting = false) {
      this.isSubmitting = isSubmitting;
    },
    stage(index) {
      const valid = this.validateForm();
      if (!this.$props.loading && !this.$props.saved && valid) {
        this.selectedIndex = index;
      }
    },
    getValues() {
      let values = {};
      this.fields.forEach(field => {
        values[field.id] = field.value;
      });
      return values;
    },
    validatedField(field) {
      let updatedField = this.fields.find(item => item.id === field.id);
      updatedField.value = field.value;
      updatedField.valid = field.isValid;
    },
    validateForm(showUpdates = true, includeMandatory = false) {
      let valid = true;
      let focused = false;
      let firstFieldWithError = null;
      this.fields.forEach(field => {
        if (field.valid === false && field.stage === this.selectedIndex) {
          let fieldInDoc = document.querySelector(`#${field.id}`);
          if (fieldInDoc && showUpdates) {
            fieldInDoc.focus();
          }
          if (!focused) {
            focused = true;
            firstFieldWithError = fieldInDoc;
          }
          valid = false;
        }
        if (includeMandatory && field.valid === false) {
          valid = false;
        }
      });
      if (firstFieldWithError && showUpdates) {
        firstFieldWithError.focus();
      }
      return valid;
    },
    validateRequiredForm() {
      let valid = true;
      if (this.loadedFormData.length === 0) {
        return valid;
      }
      this.loadedFormData[this.selectedIndex].fields.forEach(field => {
        const { error } = this.blur(field, false);
        if (error) {
          valid = false;
        }
      });
      return valid;
    },
    loadValidationSet() {
      this[`admin/schema/${GET_CUSTOM_SCHEMA}`]({
        entity: this.validationSet,
      });
    },
    submit($event, onClick) {
      let valid = this.validateForm(false, true);
      if (valid) {
        let values = this.getValues();
        onClick(values, this.setSubmitting, $event);
      }
    },
    finalIndex() {
      return this.loadedFormData.length - 1;
    },
    stages() {
      return this.loadedFormData.map(e => e.stage);
    },
    next() {
      if (this.selectedIndex !== this.loadedFormData.length - 1) {
        let newStage = this.selectedIndex + 1;
        this.stage(newStage);
      }
    },
    handleKeydown(event) {
      this.$emit('keydown', event);
    },
    handleKeypress(event) {
      this.$emit('keypress', event);
    },
    handleKeyup(event) {
      this.$emit('keyup', event);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-body {
  width: 100%;
  .hide {
    display: none;
  }
  @media (min-height: 500px) {
    &.sticky-actions {
      margin-bottom: calc(70px);
    }
  }
  @media (min-width: 768px) {
    min-width: 600px;
    &.sticky-actions {
      margin-bottom: calc(90px);
    }
  }
}
.form-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: var(--spacing-2);
  background-color: var(--colour-panel-action);
  @media (min-height: 500px) {
    &.sticky-actions {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      margin-left: 0;
      width: 100%;
    }
  }
}
</style>
