<template>
  <form class="form-grid">
    <div
      v-if="item.entity_name && item.entity_id"
      class="row"
    >
      <div class="col col-12 col-sm-12 pb-0">
        <h4>{{ subtitle }}</h4>
        <small v-if="item.id && item.decision_date">
          {{
            t(`DD: ${formatDate(item.decision_date)}`, 'dd_date', {
              interpolations: {
                date: formatDate(item.decision_date),
              },
            })
          }}
        </small>
      </div>
      <div class="col col-12 col-sm-12 mt-0 pt-0">
        <Tag
          :id="item.id"
          entity="opportunity"
        />
      </div>
    </div>
    <div class="row highlight">
      <div class="col col-12">
        <small>{{ t(`Stage ${stageIndex + 1}`) }} of {{ stageOptions.length }}</small>
      </div>
      <div class="col col-12 stage">
        <Field
          v-bind="fields.stage_id"
          id="fldstage"
          :value="item.stage_id"
          @input="onFieldInput($event, 'stage_id')"
          @valid="isValid"
        />
      </div>
    </div>

    <div
      v-if="!item.id && !(item.entity_id && entityPreselected)"
      class="row highlight master pb-3"
    >
      <div class="col col-12 col-sm-12">
        <MenuFilter
          id="entity"
          :items="[
            {
              text: '* Customer',
              value: 'customer',
            },
            {
              text: '* Prospect',
              value: 'prospect',
            },
          ]"
          :disabled="!!item.id"
          :selected-value="item.entity_type || 'customer'"
          class="selector"
          menu-position="right"
          @on-change="selected => onEntitySearchInput(undefined, selected.value)"
        />
        <Field
          v-if="item.entity_type"
          v-bind="fields[item.entity_type]"
          :id="item.entity_type"
          :key="item.entity_type"
          :value="item.entity_name || ''"
          :open-on-load="!!item.entity_name"
          class="search"
          @input="value => onEntitySearchInput(value, item.entity_type)"
          @valid="isValid"
        />
      </div>
    </div>

    <div class="row py-3">
      <div class="col col-12 col-sm-12 align-end inline-flex">
        <Field
          v-bind="fields.value"
          id="fldvalue"
          :value="item.value"
          class="value-input"
          @input="onFieldInput($event, 'value')"
          @valid="isValid"
        />
        <RagChip
          :currency="currency"
          :value="+item.value"
          :green="150000"
          :amber="75000"
          :prefix="cs"
          abbr
          class="ml-2 mb-3"
        />
      </div>
      <div class="col col-12 col-sm-12 align-end inline-flex">
        <Field
          v-bind="fields.probability"
          id="fldpercentage"
          :value="item.probability"
          class="value-input"
          @input="onFieldInput($event, 'probability')"
          @valid="isValid"
        />
        <RagChip
          :value="+item.probability"
          suffix="%"
          :green="67"
          :amber="33"
          class="ml-2 mb-3"
        />
      </div>
    </div>
    <div class="row highlight">
      <div class="col col-12 status">
        <Field
          v-bind="fields.status"
          id="fldstatus"
          :value="item.status"
          @input="onFieldInput($event, 'status')"
          @valid="isValid"
        />
      </div>
    </div>

    <div class="row pt-2 pb-1">
      <div class="col col-12 col-sm-12 col-md-6 col-lg-6">
        <Field
          v-bind="fields.contact_id"
          id="fldkeycontact"
          :value="item.contact_id"
          @input="onFieldInput($event, 'contact_id')"
          @valid="isValid"
        />
      </div>
      <div class="col col-12 col-sm-12 col-md-6 col-lg-6">
        <Field
          v-bind="fields.allocated_users"
          id="fldallocatedUsers"
          :value="allocatedUsers"
          @input="onFieldInput($event, 'allocated_users')"
          @valid="isValid"
        />
      </div>
      <div class="col col-12 col-sm-12 col-md-6 col-lg-6">
        <Field
          v-bind="fields.business_area_id"
          id="fldbusinessArea"
          :value="item.business_area_id"
          @input="onFieldInput($event, 'business_area_id')"
          @valid="isValid"
        />
      </div>
    </div>

    <div class="row highlight">
      <div class="col col-12 col-sm-12">
        <h4>{{ t('Taking action') }}</h4>
      </div>
      <div class="col col-12 col-sm-12">
        <Field
          v-bind="fields.next_step"
          id="fldnext_step"
          :value="item.next_step"
          @input="onFieldInput($event, 'next_step')"
          @valid="isValid"
        />
      </div>
      <div class="col col-12 col-sm-12 col-md-6 col-lg-6">
        <Field
          v-bind="fields.decision_date"
          id="flddecision_date"
          :value="item.decision_date"
          @input="onFieldInput($event, 'decision_date')"
          @valid="isValid"
        />
      </div>
    </div>
    <div
      v-if="!isOpen"
      class="form-cover-prevent-edit"
    />
  </form>
</template>

<script>
const CUSTOMER = 'customer';

import { RagChip, MenuFilter } from '@sales-i/dsv3';
import Field from '@/shared/components/Form/Field.vue';
import Tag from '@/shared/components/Tags/Tag.vue';
import { DateTime } from 'luxon';
import useCurrency from '@/shared/composables/useCurrency';
import { dates, t } from '@sales-i/utils';
import { mapGetters } from 'vuex';

export default {
  name: 'EditOpportunityForm',
  components: {
    Field,
    RagChip,
    MenuFilter,
    Tag,
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        entity_type: CUSTOMER,
        entity_id: undefined,
        entity_name: '',
      }),
    },
    isOpen: {
      type: Boolean,
      default: true,
    },
    stageOptions: {
      type: Array,
      default: () => [],
    },
    businessAreaOptions: {
      type: Array,
      default: () => [],
    },
    statusOptions: {
      type: Array,
      default: () => [],
    },
    allocatedToOptions: {
      type: Array,
      default: () => [],
    },
    contactOptions: {
      type: Array,
      default: () => [],
    },
    entityPreselected: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'valid', 'entityChange'],
  setup() {
    let { cs, currency } = useCurrency();
    return { cs, currency };
  },
  data() {
    return {
      validSet: new Set(),
    };
  },
  computed: {
    ...mapGetters({
      schemaSections: 'admin/schema/getSections'
    }),
    item: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
        this.$emit('valid', this.valid);
      },
    },
    subtitle() {
      if (!this.item.entity_id) return '';
      return `${this.item.entity_type !== 'customer' ? 'Prospect - ' : ''}${this.item.entity_name || ''}`;
    },
    stageIndex() {
      return this.stageOptions.findIndex(x => x.value == this.item.stage_id);
    },
    stageText() {
      return this.stageOptions[this.stageIndex] && this.item.stage_id ? this.stageOptions[this.stageIndex].text : '';
    },
    valid() {
      return this.validSet.size == 0;
    },
    fields() {
      const { defaultSections } = this.schemaSections;
      const fields = {
        customer: {
          id: 'customer',
          type: 'customersearch',
          placeholder: t('Start typing name or account no...'),
          error: !this.item.customer,
          errorMessage: t('Please select a customer'),
          required: true,
          twoway: true,
        },
        prospect: {
          id: 'prospect',
          type: 'prospectsearch',
          placeholder: t('Start typing prospect name...'),
          error: !this.item.prospect,
          errorMessage: t('Please select a prospect'),
          required: true,
          twoway: true,
        },
        value: {
          id: 'value',
          label: t('Opportunity value'),
          type: 'number',
          placeholder: t(`${this.cs} value`, 'currency_value', {
            interpolations: { currency: this.cs },
          }),
          externalError: isNaN(this.item.value),
          errorMessage: t('Please input positive value or 0'),
          min: 0,
          required: false,
          twoway: true,
        },
        probability: {
          id: 'probability',
          label: t('% probability', 'percentage_probability'),
          type: 'number',
          placeholder: t('% probability', 'percentage_probability'),
          externalError: !Number.isInteger(+this.item.probability),
          errorMessage: t(
            'Please input probability in % as integer (0..100)',
            'please_input_probability_in_percent_as_integer_error'
          ),
          min: 0,
          max: 100,
          required: false,
          twoway: true,
        },
        contact_id: {
          id: 'keycontact',
          label: t('Key contact'),
          type: 'select',
          options: this.contactOptions,
          disabled: !this.contactOptions || !this.item.entity_id || !this.contactOptions.length,
          errorMessage: 'Please select key contact',
          placeholder: t('Select'),
          required: false,
          twoway: true,
        },
        allocated_users: {
          id: 'allocated_users',
          label: t('Allocated to'),
          type: 'multiselect',
          showSelected: true,
          options: this.allocatedToOptions,
          errorMessage: 'Please allocate to a colleague',
          twoway: true,
          required: true,
        },
        business_area_id: {
          id: 'business_area_id',
          label: t('Business area'),
          type: 'select',
          options: this.businessAreaOptions,
          required: false,
          errorMessage: 'Please select business area',
          twoway: true,
        },
        stage_id: {
          id: 'stage_id',
          type: 'select',
          placeholder: t('Select'),
          options: this.stageOptions,
          label: '',
          required: false,
          twoway: true,
        },
        status: {
          id: 'status',
          type: 'select',
          placeholder: t('Select'),
          options: this.statusOptions,
          label: t('Status'),
          required: false,
          twoway: true,
        },
        next_step: {
          id: 'next_step',
          type: 'textarea',
          label: t('Next step'),
          required: false,
          twoway: true,
          maxLength: 4000,
        },
        decision_date: {
          id: 'decision_date',
          type: 'date',
          label: t('Decision date'),
          required: true,
          externalError: !this.isValidDD,
          errorMessage: !this.item.decision_date ? t('Please select a decision date') : t('Date not valid'),
          twoway: true,
        },
      };
      const fieldKeys = Object.keys(fields);
      fieldKeys.forEach(key => {
        const schemaField = defaultSections[0]?.fields.find(f => f.name === key);
        fields[key].required = schemaField ? schemaField.required : fields[key].required;
        if (!fields[key].required) { fields[key].externalError = false; }
      });
      return fields;
    },
    allocatedUsers() {
      return (this.item.allocated_users || []).map(x => x.allocated_user_id);
    },
    isValidDD() {
      let { years } = dates.getDiff(this.item.decision_date, DateTime.now().startOf('day'), ['years']);
      return years <= 5 && years >= -1;
    },
  },
  methods: {
    t,
    formatDate: dates.format,
    isValid(checkResult) {
      if (checkResult.isValid) {
        this.validSet.delete(checkResult.id || 'unknown');
      } else this.validSet.add(checkResult.id || 'unknown');
      this.$emit('valid', this.valid);
    },
    onFieldInput(value, fieldName) {
      let contact_name = '';
      let valueToSave = value;
      if (value && fieldName === 'contact_id' && this.contactOptions.length) {
        contact_name = this.contactOptions.find(x => x.value == value).full_name;
      } else if (fieldName === 'allocated_users') {
        valueToSave = Array.isArray(value) ? value.map(x => ({ allocated_user_id: x.value })) : [];
      }

      this.item = {
        ...this.item,
        ...{ [fieldName]: valueToSave },
        ...(contact_name ? { contact_name } : {}),
      };
    },
    onEntitySearchInput(item, entity_type = 'customer') {
      let updatedItem = {
        ...this.item,
        ...{
          entity_name: item ? item.text || '' : '',
          entity_id: item && item.value ? item.value : undefined,
          entity_type,
        },
      };

      if (!item || item.value != this.item.entity_id || entity_type != this.item.entity_type) {
        this.$emit('entityChange', updatedItem);
      }

      this.item = updatedItem;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.menu-filter {
  min-width: 120px;
}

.align-end {
  align-items: end;
}

.inline-flex {
  display: inline-flex;
}

.stage :deep(.input-container),
.status :deep(.input-container) {
  background-color: var(--colour-panel-g-0);
}

.value-input {
  min-width: 150px;
  flex: 4;
}

.chip {
  height: 2rem;
  flex: 0 0 auto;
}

.row.master {
  background-color: var(--colour-panel-g-4);
  :deep(.form-group) {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    width: 100%;
  }

  :deep(.col-12) {
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .selector {
    margin-right: var(--spacing-2);
    flex: 0;
  }

  :deep(.form-group),
  :deep(.input) {
    margin-bottom: 0;
  }

  :deep(.form-group label) {
    margin-bottom: 0;
    margin-right: var(--spacing-1);
    flex: 0 0 auto;
  }

  :deep(.search) {
    flex: 1 1 auto;
  }
}

.form-grid {
  position: relative;
}

.form-cover-prevent-edit {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
</style>
