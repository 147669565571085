<template>
  <SearchField
    v-bind="props"
    :search-func="getSearchResults"
    @input="handleInput"
    @search="handleSearch"
    @blur="handleBlur"
    @focus="handleFocus"
    @valid="handleValid"
  />
</template>

<script setup>
import { ref } from 'vue';
import SearchField from '@/shared/components/Search/SearchField.vue';
import useSearchInput from './composables/useSearchInput';
import { baseProps, baseEmits } from './composables/searchInputPropsEmits';

const emit = defineEmits(baseEmits);
const props = defineProps({
  ...baseProps,
  label: {
    type: String,
    default: 'Prospect:',
  },
});
const url = ref('prospects');
const type = ref('prospect');

const {
  getSearchResults,
  handleInput,
  handleSearch,
  handleBlur,
  handleFocus,
  handleValid,
} = useSearchInput({ emit, url, type });
</script>
