<template>
  <div :class="formBody">
    <form
      v-if="fields?.length > 0"
      @submit.prevent="showNext ? next : submit($event, actions[0].onClick)"
    >
      <!-- need v-model values to be set before initialising fields  -->
      <div
        :class="{
          hide: saved,
        }"
      >
        <h3 class="px-2">
          {{ t('Company') }}
        </h3>
        <fieldset>
          <div
            v-for="(field, fieldIndex) in prospectCustomerDetails?.filter(item => ['customer_customer'].includes(item.id))"
            :key="'a' + fieldIndex"
            class="row"
          >
            <div class="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <Field
                v-bind="field"
                v-model="form.customer_name"
                :class="field.id"
                @valid="validatedField"
                @keydown="handleKeydown"
                @keypress="handleKeypress"
                @keyup="handleKeyup"
              />
            </div>
          </div>

          <div
            v-if="form.customer_name || nextMode"
            class="row"
          >
            <div
              v-for="(field, fieldIndex) in prospectCustomerDetails.filter(item => !['customer_customer'].includes(item.id))"
              :key="fieldIndex"
              :class="['col', 'col-12', `col-sm-${colSpan}`, `col-md-${colSpan}`, `col-lg-${colSpan}`, `col-xl-${colSpan}`]"
            >
              <Field
                v-bind="field"
                :class="field.id"
                @valid="validatedField"
                @keydown="handleKeydown"
                @keypress="handleKeypress"
                @keyup="handleKeyup"
              />
            </div>
          </div>

          <div
            v-if="!form.customer_name && !nextMode"
            class="px-2"
          >
            <CustomCheckbox
              id="no_company"
              :label="t('I don\'t have a company name')"
              :value="no_company"
              @input:value="handleInput('no_company')"
            />
          </div>
        </fieldset>

        <div v-if="contact_name || form.customer_name || nextMode">
          <h3 class="px-2 mt-4">
            {{ t('Contacts') }}
          </h3>

          <fieldset class="row">
            <div
              v-for="(field, fieldIndex) in prospectContactDetails.filter(item => ['contact_first_name'].includes(item.id))"
              :key="'d' + fieldIndex"
              class="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6"
            >
              <div>
                <Field
                  v-bind="field"
                  v-model="form.first_name"
                  :class="field.id"
                  @valid="validatedField"
                  @keydown="handleKeydown"
                  @keypress="handleKeypress"
                  @keyup="handleKeyup"
                />
              </div>
            </div>

            <div
              v-for="(field, fieldIndex) in prospectContactDetails.filter(item => ['contact_surname'].includes(item.id))"
              :key="'e' + fieldIndex"
              class="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6"
            >
              <div>
                <Field
                  v-bind="field"
                  v-model="form.surname"
                  :class="field.id"
                  @valid="validatedField"
                  @keydown="handleKeydown"
                  @keypress="handleKeypress"
                  @keyup="handleKeyup"
                />
              </div>
            </div>

            <div
              v-if="form.customer_name || form.first_name || form.surname || nextMode"
              class="row px-half"
            >
              <div
                v-for="(field, fieldIndex) in prospectContactDetails.filter(item => !['contact_first_name', 'contact_surname'].includes(item.id))"
                :key="'f' + fieldIndex"
                :class="['col', 'col-12', `col-sm-${colSpan}`, `col-md-${colSpan}`, `col-lg-${colSpan}`, `col-xl-${colSpan}`]"
              >
                <Field
                  v-bind="field"
                  :class="field.id"
                  @valid="validatedField"
                  @keydown="handleKeydown"
                  @keypress="handleKeypress"
                  @keyup="handleKeyup"
                />
              </div>
            </div>

            <div v-if="!form.customer_name && !nextMode">
              <CustomCheckbox
                v-if="!form.first_name && !form.surname"
                id="no_name"
                :label="t('I don\'t have a contact name')"
                :value="no_name"
                @input:value="handleInput('no_name')"
              />
            </div>
          </fieldset>
        </div>

        <fieldset
          v-if="form.customer_name || description || form.first_name || form.surname"
          class="px-2"
        >
          <h3 class="mt-3 mb-1">
            {{ t('Details') }}
          </h3>
          <div
            v-for="(field, fieldIndex) in descriptionField"
            :key="'b' + fieldIndex"
          >
            <Field
              v-bind="field"
              v-model="form.description"
              :class="field.id"
              @valid="validatedField"
              @keydown="handleKeydown"
              @keypress="handleKeypress"
              @keyup="handleKeyup"
            />
          </div>
        </fieldset>
      </div>

      <slot
        v-if="saved"
        name="savedContent"
      />

      <div :class="formActions">
        <template v-if="!isSubmitting">
          <div
            v-for="action in actions"
            :key="action.label"
          >
            <CustomButton
              v-if="action.display"
              :label="action.label"
              :purpose="action.isSubmit ? 'action' : action.purpose || 'action'"
              :small="action.small || false"
              :class="action.isSubmit ? `action ${valid ? '' : 'disabled'}` : 'action'"
              :type="action.isSubmit ? 'submit' : 'button'"
              @on-click="
                event => {
                  action.isSubmit ? submit(event, action.onClick) : action.onClick(event);
                }
              "
            />
          </div>
          <CustomButton
            v-if="showNext"
            label="Next"
            small
            type="submit"
            :class="`action ${computedName ? '' : 'disabled'}`"
            @on-click.prevent="next"
          />
        </template>
        <template v-if="isSubmitting">
          <BufferImage :size="40" />
        </template>
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import Field from '@/shared/components/Form/Field.vue';
import { CHECK_URL } from '@/crm/store/actionType';
import { SET_CONFIRMATION_MODAL } from '@/shared/store/actionType';
import { CustomButton, CustomCheckbox, BufferImage } from '@sales-i/dsv3';
import { isEqualObjects, t } from '@sales-i/utils';

const store = useStore();
const vroute = useRoute();

const props = defineProps({
  saved: {
    type: Boolean,
    default: false,
  },
  actions: {
    type: Array,
    default: () => [],
  },
  formdata: {
    type: Array,
    default: () => [],
  },
  colSpan: {
    type: Number,
    default: 6,
  },
  stickyActions: {
    type: Boolean,
    default: true,
  },
  prospectId: {
    type: [String, Number],
    default: '',
  },
});
const emit = defineEmits(['keydown', 'keypress', 'keyup', 'confirmingSave', 'showUnloadWarning']);

const nextMode = ref(false);
const isSubmitting = ref(false);
const fields = ref([]);
const contact_name = ref('');
const no_company = ref(false);
const no_name = ref(false);
const description = ref(false);
const form = ref({
  customer_name: '',
  contact_name: '',
  first_name: '',
  surname: '',
  description: '',
  email: '',
});
const initialFormValues = ref({});

const computedName = computed(() => 
  // Defines the 'name' of the prospect (either customer name, contact name or details)
  form.value.customer_name || fullName.value || form.value.description
);
const fullName = computed(() => {
  // If there's a first AND last name, concatenate them (including the space)
  // Otherwise return the first or last name
  if (form.value.first_name && form.value.surname) {
    return `${form.value.first_name} ${form.value.surname}`;
  } else {
    return form.value.first_name || form.value.surname;
  }
});
const prospectCustomerDetails = computed(() => props.formdata[0]?.fields);
const prospectContactDetails = computed(() => props.formdata[1]?.fields);
const descriptionField = computed(() => props.formdata[2]?.fields);
const formBody = computed(() => ({
  'form-body': true,
  'sticky-actions': props.stickyActions,
}));
const formActions = computed(() => ({
  'form-actions': true,
  'sticky-actions': props.stickyActions,
}));
const valid = computed(() => validateForm(false));
const fieldValues = computed(() => {
  let values = {};
  fields.value.forEach(field => {
    values[field.id] = field.value;
  });
  return values;
});

const showNext = computed(() => {
  let prospectCustomerHasMandatoryFields = false;
  let prospectContactHasMandatoryFields = false;
  for(let i = 0, len = prospectCustomerDetails.value?.length; i < len; i++) {
    if (prospectCustomerDetails.value[i].required) {
      prospectCustomerHasMandatoryFields = true;
      break;
    }
  }
  for(let i = 0, len = prospectContactDetails.value?.length; i < len; i++) {
    if (prospectContactDetails.value[i].required) {
      prospectContactHasMandatoryFields = true;
      break;
    }
  }

  if (form.value.customer_name) {
    return false;
  }
  if (!prospectCustomerHasMandatoryFields && fullName.value) {
    return false;
  }
  if (!prospectCustomerHasMandatoryFields && !prospectContactHasMandatoryFields) {
    return false;
  }
  return !nextMode.value;
});

const isFormDirty = computed(() => !isEqualObjects(initialFormValues.value, fieldValues.value));

watch(() => computedName.value, newVal => {
  fields.value[0].value = newVal;
});

watch(() => isFormDirty.value, newVal => {
  emit('showUnloadWarning', newVal);
});

onMounted(() => {
  fields.value = initialiseFields();
  addName();
  initialFormValues.value = { ...fieldValues.value };
});

const checkUrl = url => store.dispatch(`crm/prospects/${CHECK_URL}`, url);
const showConfirmationModal = params => store.dispatch(`confirmationModal/${SET_CONFIRMATION_MODAL}`, params);

const initialiseFields = () => {
  // Create an array of the values needed in the payload that don't come from the form fields
  const extraFields = [
    {
      id: 'name',
      valid: true,
      value: '',
    },
    {
      id: 'id',
      valid: true,
      value: +vroute.params.id,
    },
    {
      id: 'record_type',
      valid: true,
      value: 'P',
    },
  ];
  let arr = [...extraFields];
  let name = '';

  // Add the field values into the fields array for prospect customer details
  props.formdata.forEach(e => {
    e.fields.forEach(field => {
      if (field.id === 'customer_customer' && field.value) {
        name = field.value;
      }
      if (field.id === 'contact_first_name' && field.value) {
        form.value.first_name = field.value;
      }
      if (field.id === 'contact_surname' && field.value) {
        form.value.surname = field.value;
      }
      if (field.id === 'details_description' && field.value) {
        form.value.description = field.value;
      }
      arr.push({
        id: field.id,
        valid: !!field.value || !field.required,
        value: field.value,
      });
    });
  });
  if (name !== fullName.value && name !== form.value.description) {
    form.value.customer_name = name;
  }
  if (computedName.value) {
    if (!form.value.customer_name) {
      no_company.value = true;
      contact_name.value = true;
    }
    if (!fullName.value) {
      no_name.value = true;
    }
  }
  return arr;
};
// Update the 'names' entry in the array of fields and set its value to the computed name
const addName = () => {
  // Use the id name instead of the index
  fields.value[0] = {
    id: 'name',
    valid: true,
    value: '',
  };
};
const setSubmitting = (val = false) => {
  isSubmitting.value = val;
};
const handleInput = fieldName => {
  if (fieldName === 'no_company') {
    no_company.value = !no_company.value;
    if (no_company.value === true) {
      contact_name.value = true;
    } else {
      contact_name.value = false;
      no_name.value = false;
      description.value = false;
    }
  }

  if (fieldName === 'no_name') {
    no_name.value = !no_name.value;
    if (no_name.value === true) {
      description.value = true;
    } else {
      description.value = false;
    }
  }
};
const next = () => {
  if (computedName.value) {
    nextMode.value = true;
  }
};
const validatedField = field => {
  let updatedField = fields.value.find(item => item.id === field.id);
  updatedField.value = field.value;
  updatedField.valid = field.isValid;
};
const validateForm = (showUpdates = true) => {
  let valid = true;
  let focused = false;
  let firstFieldWithError = null;
  fields.value.forEach(field => {
    if (field.valid === false) {
      let fieldInDoc = document.querySelector(`#${field.id}`);
      if (fieldInDoc && showUpdates) {
        fieldInDoc.focus();
      }
      if (!focused) {
        focused = true;
        firstFieldWithError = fieldInDoc;
      }
      valid = false;
    }
  });

  // If there's no customer name, contact name or description then set the form as invalid
  if (!form.value.customer_name && !form.value.first_name && !form.value.surname && !form.value.description) {
    valid = false;
  }
  if (firstFieldWithError && showUpdates) {
    firstFieldWithError.focus();
  }
  return valid;
};
const submit = async(event, onClick) => {
  event.preventDefault();
  let valid = validateForm();
  if (valid) {
    const webAddressField = fields.value.find(item => item.id === 'customer_www');
    const isDuplicateWebAddress = webAddressField.value ?
      await checkUrl(webAddressField.value) : false;
    if (isDuplicateWebAddress) {
      emit('confirmingSave', true);
      showConfirmationModal({
        message: t(
          `A prospect already exists with the website url ${webAddressField.value}. Do you still want to proceed with the creation of this prospect?`,
          'a_prospect_already_exists_with_the_website_url_webaddress_do_you_still_want_to_proceed_with_the_creation_of_this_prospect',
          { interpolations: { webaddress: webAddressField.value }}
        ),
        updateFunction: async () => {
          try {
            await onClick(fieldValues.value, setSubmitting, event);
            return true;
          } catch (e) {
            return false;
          }
        },
        closeOnUpdate: true,
        finishFunction: () => {
          return true;
        },
        closeFunction: () => {
          emit('confirmingSave', false);
          return true;
        },
      });
    } else {
      onClick(fieldValues.value, setSubmitting, event);
    }
  }
};
const handleKeydown = (event) => {
  emit('keydown', event);
};
const handleKeypress = (event) => {
  emit('keypress', event);
};
const handleKeyup = (event) => {
  emit('keyup', event);
};
</script>

<style lang="scss" scoped>
fieldset {
  padding: 0;
}

.row {
  padding-left: var(--spacing-2);
  padding-right: var(--spacing-2);
}

.row > div > .row {
  padding-left: 0;
  padding-right: 0;
}

.hide {
  display: none;
}

.form-body {
  width: 100%;

  @media (min-height: 500px) {
    &.sticky-actions {
      margin-bottom: calc(70px);
    }
  }
  @media (min-width: 768px) {
    min-width: 600px;
    &.sticky-actions {
      margin-bottom: calc(90px);
    }
  }
}
.form-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70px;
  background-color: var(--colour-panel-action);
  .action {
    margin: 0 var(--spacing-1);
  }
  @media (min-height: 500px) {
    &.sticky-actions {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
    }
  }
  @media (min-width: 768px) {
    height: 90px;
  }
}
.error {
  background-color: var(--colour-brand-mandy);
  padding: var(--spacing-1);
  margin-bottom: var(--spacing-4);

  p {
    color: var(--colour-utility-white);
  }
}

.application p {
  margin-bottom: 0;
}
</style>
