<template>
  <div
    v-if="permissions.read"
    class="opportunities-list"
  >
    <div class="filter-container">
      <div class="primary-filter">
        <MenuFilter
          :items="filterOptions"
          :selected-value="filter"
          menu-position="right"
          class="mb-half"
          @on-change="handleFilterChanged"
        />
        <component
          :is="searchComponent[filter]"
          :id="`${filter}search`"
          label=""
          :value="searchLabel || search"
          :search-on-load="false"
          @search="handleSearchTextInput"
          @input="handleSearchItemInput"
        />
      </div>
      <div class="secondary-filter">
        <DateRangeFilter
          :label="t('Decision date')"
          :model-value="rangeFilterValue"
          :items="ddOptions"
          @update:model-value="handleDDRangeUpdated"
        />
      </div>
      <div class="tertiary-filter">
        <TabSwitch
          :selected-value="currentViewMode"
          :items="viewTabs"
          @switch-selected="switchMode"
        />
      </div>
    </div>
    <EntityList
      :filters="sortOptions"
      :selected-filter="sort"
      show-pagination
      :page-limit="limit"
      :page-offset="offset"
      :records-count="itemsCount"
      :loading="opportunitiesLoading && !opportunitiesLoaded"
      @filter-selected="handleSortChanged"
      @page-changed="handlePageChanged"
      @switch-view-mode="switchMode"
    >
      <div
        v-if="currentViewMode === 'grid-view'"
        role="list"
        class="list list-card"
      >
        <OpportunityCard
          v-for="(item, index) in opportunityItems"
          :id="`opportunity-card-${index + 1}`"
          :key="index"
          :links-enabled="linksEnabled"
          :custom-handlers="['edit']"
          :item="item"
          @open-item="openProductsModal"
          @edit="editOpportunity"
          @opportunity-deleted="handleOpportunityDeleted"
        />
      </div>
      <EntityListViewTable
        v-if="opportunitiesLoaded && currentViewMode === 'list'"
        class="entity-list-view-table"
        :entity="'opportunity'"
        :items="opportunityItems"
        :headings="opportunityHeadings"
        :status="status"
        @edit="editOpportunity"
        @opportunity-deleted="handleOpportunityDeleted"
      />
      <Accordion
        v-if="status === 'open'"
        class="accordion-opportunities"
        :title="`${t('AI generated opportunities')} (${aiOpportunityItems.length})`"
        :is-opened="isAccordionOpened"
        accordion-size="small"
      >
        <template #content>
          <EntityListViewTable
            class="entity-list-view-table"
            entity="opportunity"
            status="potential"
            :items="aiOpportunityItems"
            :headings="aiOpportunityHeadings"
            @open-modal="openProductsModal"
            @edit="editOpportunity"
            @opportunity-deleted="handleOpportunityDeleted"
          />
        </template>
      </Accordion>
    </EntityList>
  </div>

  <MissedPotentialOpportunityModal
    v-if="selectedOpportunity"
    :selected-opportunity="selectedOpportunity"
    :dismissed-items="dismissedItems"
    :modal-open="modalOpen"
    @close-modal="closeProductsModal"
    @add-feedback="showDismissModal"
  />

  <DismissModal
    :dismiss-modal-open="dismissModalOpen"
    :close-dismiss-modal="closeDismissModal"
    :dismiss-options="dismissOptions"
    object-type="opportunity"
    @post-feedback="postFeedback"
  />
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { DateTime } from 'luxon';
import { dates, t, isEqualObjects } from '@sales-i/utils';
import { MenuFilter, TabSwitch } from '@sales-i/dsv3';
import breakpoints from '@/shared/utils/breakpoints';
import OpportunityCard from '@/crm/components/Opportunity/OpportunityCard.vue';
import EntityList from '@/crm/components/EntityList/EntityList.vue';
import CustomerSearch from '@/shared/components/Search/CustomerSearch.vue';
import UserSearch from '@/shared/components/Search/UserSearch.vue';
import ProspectSearch from '@/shared/components/Search/ProspectSearch.vue';
import DateRangeFilter from '@/shared/components/Form/DateRangeFilter.vue';
import EntityListViewTable from '@/shared/components/Tables/EntityListViewTable';
import Accordion from '@/shared/components/Accordion/Accordion';
import MissedPotentialOpportunityModal from '@/crm/components/Opportunity/MissedPotentialOpportunityModal.vue';
import DismissModal from '@/admin/components/AiFeedback/DismissModal.vue';
import { useUsers } from '@/shared/composables/useUsers';
import { paginationEmits, paginationProps, usePagination } from '@/shared/composables/usePagination';
import { useRoute, } from 'vue-router';
import { useStore } from 'vuex';
import { useOpportunity } from '@/crm/composables/useOpportunity';
import { useOpportunityProductsModal } from '@/crm/composables/useOpportunityProductsModal';

const { limit, offset, handlePageChanged } = usePagination(props, loadData, emit);

const props = defineProps({
  ...paginationProps,
  status: {
    type: String,
    default: 'open',
  },
  opportunityType: {
    type: String,
    default: 'manual',
  },
  filter: {
    type: String,
    default: 'allocated_to',
  },
  search: {
    type: String,
    default: '',
  },
  searchLabel: {
    type: String,
    default: '',
  },
  maxHistoryDays: {
    type: Number,
    default: 31,
  },
  decisionDateFilter: {
    type: Object,
    default: () => ({
      dd_filter: '',
      date_from: '',
      date_to: '',
    }),
  },
  sort: {
    type: String,
    default: 'decision_date:desc',
  },
});
const emit = defineEmits(['sort', 'filterChanged', 'add', ...paginationEmits]);

const store = useStore();
const vroute = useRoute();

// load users if not loaded
useUsers(store, true, false);

const { 
  permissions,
  getOpportunities, 
  getOpportunitiesWithProducts, 
  clearOpportunities, 
  editOpportunity,
  opportunityItems,
  opportunitiesLoading,
  opportunitiesLoaded,
  aiOpportunityItems,
  sortOptions,
  opportunityHeadings, 
  aiOpportunityHeadings,
} = useOpportunity({ store, emit, vroute });
const {
  openProductsModal,
  closeProductsModal,
  closeDismissModal,
  showDismissModal,
  postFeedback,
  modalOpen,
  dismissModalOpen,
  dismissedItems,
  dismissOptions,
  selectedOpportunity,
} = useOpportunityProductsModal(loadData);

const currentViewMode = ref('list');
const isAccordionOpened = ref(true);

const queryParams = computed(() => ({
  sort: props.sort,
  limit: limit.value,
  offset: offset.value,
  type: props.opportunityType || 'manual',
  status: props.status || 'open',
  ...(props.filter && props.search ? { [props.filter]: props.search } : {}),
  days: props.maxHistoryDays,
  date_from: props.decisionDateFilter.date_from,
  date_to: props.decisionDateFilter.date_to,
}));

const rangeFilterValue = computed(() => ({
  option: props.decisionDateFilter.dd_filter || '30',
  start: dates.getDateTime(props.decisionDateFilter.date_from || DateTime.local().startOf('day')),
  end: dates.getDateTime(props.decisionDateFilter.date_to || DateTime.local().startOf('day').plus({ days: 30 })),
}));

const viewTabs = computed(() => {
  const gridTab = {
    title: t('Grid'),
    value: 'grid-view',
  };
  const listTab = {
    title: t('List'),
    value: 'list',
  };
  return [gridTab, listTab];
});

const filterOptions = [
  {
    text: t('Allocated to'),
    value: 'allocated_to',
  },
  {
    text: t('By customer'),
    value: 'customer',
  },
  {
    text: t('By prospect'),
    value: 'prospect',
  },
];

const ddOptions = [
  { text: t('Next 30 days', 'next_n_days', { interpolations: { n: 30 } }), value: '30' },
  { text: t('Next 60 days', 'next_n_days', { interpolations: { n: 30 } }), value: '60' },
  { text: t('Next 90 days', 'next_n_days', { interpolations: { n: 30 } }), value: '90' },
  { text: t('Custom range'), value: 'custom' },
];

const searchComponent = ref({
  allocated_to: UserSearch,
  customer: CustomerSearch,
  prospect: ProspectSearch,
});

const isMobileView = () => window.screen.width < breakpoints.breakpoints.md;

const linksEnabled = computed(() => {
  return [permissions.read ? 'open' : '', permissions.update ? 'edit' : '', permissions.delete ? 'delete' : ''].filter(x => !!x);
});

const itemsCount = computed(() => opportunityItems.value.length);

watch(
  () => ({ ...queryParams.value }),
  (newV, oldV) => {
    if (!oldV || isEqualObjects(oldV, newV)) return;
    if (newV?.offset === oldV?.offset) {
      handlePageChanged(0); //reset page if filter has changed
    } else {
      loadData();
    }
  },
  { deep: true, immediate: true }
);

onMounted(() => { 
  setDefaultView();
  loadData();
});

async function loadData() {
  // cancel reload of opportunities if add/edit button pressed
  // only for main opportunities page
  if (['AddOpportunityModalRoute', 'EditOpportunityModalRoute'].includes(vroute.name) && (opportunitiesLoaded.value || opportunitiesLoading.value)) {
    return;
  }
  await clearOpportunities();
  getOpportunities(queryParams.value);
  if (props.status === 'open') {
    getOpportunitiesWithProducts({ 
      ...queryParams.value,
      type: 'POTENTIAL',
    });
  }
}
function handleOpportunityDeleted() {
  loadData();
}
function handleSortChanged({ value }) {
  emit('sort', value);
}
function handleFilterChanged({ value }) {
  emit('filterChanged', {
    filter: value,
    search: '',
    searchLabel: '',
  });
}
function handleSearchTextInput(value) {
  if (props.filter == 'allocated_to') return;
  emit('filterChanged', {
    filter: props.filter,
    search: value || '',
    searchLabel: value || '',
  });
}
function handleSearchItemInput(value) {
  if (props.filter != 'allocated_to') return;
  emit('filterChanged', {
    filter: props.filter,
    search: value ? value.value : '',
    searchLabel: value ? value.text : '',
  });
}
function handleDDRangeUpdated(value) {
  if (!value)
    // reset to default that set by parent
    emit('filterChanged', {
      dd_filter: '',
      date_from: '',
      date_to: '',
    });
  else
    emit('filterChanged', {
      dd_filter: value.option,
      date_from: dates.forApi(value.start, true),
      date_to: dates.forApi(value.end, true),
    });
}
function setDefaultView() {
  currentViewMode.value = isMobileView() ? 'grid-view' : 'list';
}
function switchMode(view) {
  currentViewMode.value = view.value;
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.menu-filter {
  min-width: 124px;
}
.opportunities-list {
  padding-top: var(--spacing-3);
}

.filter-container {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;

  .tertiary-filter {
    margin-left: auto;
      
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      align-self: flex-end;
    }
  }
}
.filtersearch {
  margin-bottom: 0;

  :deep(.input) {
    margin-bottom: 0;
  }
}
.primary-filter {
  display: flex;
  flex-flow: column;
  margin-right: var(--spacing-2);
}
.secondary-filter {
  :deep(.form-group) {
    margin-bottom: 0;
  }
  :deep(label) {
    margin-bottom: var(--spacing-half);
  }
}
.entity-list-view-table :deep(.table-wrapper) {
  height: 100%;
}
.accordion-opportunities {
  margin-top: var(--spacing-1);
  box-shadow: none;

  :deep(.heading) {
    justify-content: center;
  }

  :deep(.heading h3) {
    color: var(--colour-utility-black);
    text-decoration: none;
  }

  :deep(.svg-container svg) {
    color: var(--colour-utility-black) !important;
  }
}

:deep(.click-outside) {
  position: static;
}
</style>
