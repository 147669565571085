<!-- eslint-disable vuejs-accessibility/no-static-element-interactions -->
<template>
  <div
    ref="draggableRef"
    :class="{ draggable: !props.disabled }"
    :draggable="!props.disabled"
    @dragstart="handleDragStart"
    @dragend="handleDragEnd"
  >
    <slot />
  </div>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
  transferData: {
    type: [String, Number, Object],
    default: undefined,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['dragStarted', 'dragEnded']);
const draggableRef = ref(null);

const handleDragStart = (event) => {
  event.dataTransfer.setData('value', JSON.stringify(props.transferData));
  emit('dragStarted', props.transferData);

  // Clone the draggable element
  const clone = draggableRef.value.cloneNode(true);
  clone.style.position = 'absolute';
  clone.style.top = '-9999px'; // Position it offscreen
  clone.style.pointerEvents = 'none'; // Make it non-interactive

  // Append the clone to the body
  document.body.appendChild(clone);

  // Set the drag image to the clone, offset to center
  event.dataTransfer.setDragImage(clone, clone.offsetWidth / 2, clone.offsetHeight / 2);

  // Remove the clone after a slight delay to allow Chrome to register the drag image
  setTimeout(() => document.body.removeChild(clone), 0);
};

const handleDragEnd = () => {
  emit('dragEnded');
  // Remove the clone if it wasn't removed already
  const clone = document.body.querySelector('.clone');
  if (clone) {
    document.body.removeChild(clone);
  }
};
</script>

<style lang="scss" scoped>
.draggable {
  display: flex;
}
</style>
