
import { computed } from 'vue';
import { useStore } from 'vuex';
import { APPLY_NEW_FILTER, APPLY_REPORT_TYPE, DELETE_FILTER, FETCH_CHART_DATA, FETCH_STAGES_DATA, FETCH_TABLE_DATA, SET_ANALYSIS_FIELD, SET_FILTER_TITLE } from '@/intelligence/store/actionType';

export default function usePerformanceInsight({ store = useStore() } = {}) {
  const stages = computed(() => store.state.intelligence.performanceInsight.stages);
  const stagesData = computed(() => store.state.intelligence.performanceInsight.stages.stages || []);
  const tableData = computed(() => store.state.intelligence.performanceInsight.tableData);
  const chartData = computed(() => store.state.intelligence.performanceInsight.chartData);
  const loading = computed(() => store.state.intelligence.performanceInsight.loading);
  const filters = computed(() => store.state.intelligence.performanceInsight.filters);
  const reportType = computed(() => store.state.intelligence.performanceInsight.report_type);
  const filterTitle = computed(() => store.state.intelligence.performanceInsight.filterTitle);

  const fetchTableData = (payload) => store.dispatch(`intelligence/performanceInsight/${FETCH_TABLE_DATA}`, payload);
  const fetchChartData = (payload) => store.dispatch(`intelligence/performanceInsight/${FETCH_CHART_DATA}`, payload);
  const removeFilter = filter => store.dispatch(`intelligence/performanceInsight/${DELETE_FILTER}`, filter);
  const applyFilter = filters => store.dispatch(`intelligence/performanceInsight/${APPLY_NEW_FILTER}`, filters);
  const setAnalysisField = params => store.dispatch(`intelligence/performanceInsight/${SET_ANALYSIS_FIELD}`, params);
  const applyReportType = params => store.dispatch(`intelligence/performanceInsight/${APPLY_REPORT_TYPE}`, params);
  const setFilterTitle = (params) => store.dispatch(`intelligence/performanceInsight/${SET_FILTER_TITLE}`, params);
  const fetchStagesData  = (params) => store.dispatch(`intelligence/performanceInsight/${FETCH_STAGES_DATA}`, params);

  return {
    stages,
    stagesData,
    tableData,
    chartData,
    loading,
    filters,
    reportType,
    filterTitle,
    applyFilter,
    fetchTableData,
    fetchChartData,
    removeFilter,
    setAnalysisField,
    applyReportType,
    setFilterTitle,
    fetchStagesData,
  };
}