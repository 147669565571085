<template>
  <!-- eslint-disable vue/no-v-html -->
  <div class="form-group">
    <label
      :class="[classes]"
      :for="id"
    >
      <div class="search">
        <span v-if="required && label">*</span> {{ t(label) }}
        <div
          class="input"
          :class="[classes]"
          :disabled="disabled"
        >
          <IconBase
            :width="iconSize"
            :height="iconSize"
            icon-name="search"
            :icon-color="searchIconsColour"
            class="icon"
            :disabled="disabled"
            @click="focus"
          />
          <input
            :id="id"
            ref="searchinputRef"
            v-model="searchInputRaw"
            type="text"
            :placeholder="t(placeholder)"
            class="search-input mt-0"
            :disabled="disabled"
            :tabindex="disabled ? -1 : 0"
            @keydown.esc.stop="onClose"
            @focus="handleFocus"
            @blur="handleBlur"
          >
          <IconButton
            v-show="searchInputResult"
            type="button"
            :width="btnSize"
            :height="btnSize"
            icon-name="close-line"
            :icon-color="searchIconsColour"
            class="close"
            :disabled="disabled"
            purpose="transparent"
            @click="clear"
            @keyup.enter.stop="clear"
          />
        </div>
        <slot
          v-if="!disabled"
          name="results"
          :results="results"
          :search="searchInputResult"
        >
          <div
            v-click-outside="onClose"
            class="dropdown-container"
          >
            <div
              v-show="isOpen"
              class="results"
            >
              <ul>
                <li
                  v-show="message"
                  class="message"
                  disabled
                  role="button"
                  tabindex="0"
                  @keydown.esc.prevent.stop="onClose"
                >
                  {{ t(message) }}
                </li>
                <template v-for="(resultItem, resultIndex) in results">
                  <slot
                    :index="resultIndex"
                    name="result"
                    :result="resultItem"
                    :search="searchInputResult"
                    :select-func="onSelect"
                    :close-func="onClose"
                  >
                    <li
                      :key="resultIndex"
                      class="result"
                      role="button"
                      tabindex="0"
                      @click="onSelect(resultItem)"
                      @keyup.enter.prevent.stop="onSelect(resultItem)"
                      @keydown.esc.prevent.stop="onClose"
                      v-html="highlight(resultItem.text, searchInputResult)"
                    />
                  </slot>
                </template>
                <li
                  v-show="hasNextPage"
                  ref="loadmoreRef"
                  class="loader"
                >
                  {{ t('Loading more options...') }}
                </li>
              </ul>
              <slot
                v-if="!disabled"
                name="buttons"
              />
            </div>
          </div>
        </slot>
        <small
          v-if="error && errorMessage"
          class="error-text"
        >{{ errorMessage }}</small>
      </div>
    </label>
  </div>
</template>

<script setup>
import { IconBase, IconButton } from '@sales-i/dsv3';
import { ClickOutside } from 'vue-click-outside-of';
import { highlight } from '@/shared/utils/highlight';
import { debounce, t } from '@sales-i/utils';
import { baseProps, baseEmits } from './composables/searchInputPropsEmits';
import { ref, computed, onMounted, onUnmounted, nextTick, watch, defineExpose } from 'vue';

const vClickOutside = ClickOutside;

const props = defineProps(baseProps);
const emit = defineEmits(baseEmits);
const observer = ref(null);
const isopen = ref(props.openOnLoad);
const limit = ref(props.pageSize);
const searchInputRaw = ref(props.value || '');
const searchInputResult = ref(props.value || '');
const results = ref([]);
const loading = ref(false);
const message = ref('');
const emitFocus = ref(true);
const searchinputRef = ref(null);
const loadmoreRef = ref(null);

const hasNextPage = computed(() => results.value.length == limit.value);
const classes = computed(() => ({
  'input-error': props.error,
  'mt-1': !!props.label,
}));

const isOpen = computed({
  get() {
    return isopen.value && searchInputResult.value && !props.disabled;
  },
  async set(v) {
    if (props.disabled) return;
    if (isopen.value && !v) {
      emit('close');
      isopen.value = false;
      limit.value = props.pageSize;
      results.value = [];
    } else if (!this.isopen && v) {
      emit('open');
      isopen.value = true;
    }
  }
});

const searchIconsColour = computed(() => props.disabled ? 'var(--colour-panel-g-16)' : 'var(--colour-utility-black)');

watch(searchInputRaw, (newVal) => {
  if (props.value === newVal) return;
  debouncedInputRawUpdate(newVal);
});

watch(() => props.value, (newVal) => {
  searchInputRaw.value = newVal;
});

onMounted(() => {
  if (loadmoreRef.value) {
    observer.value = new IntersectionObserver(infiniteScroll);
    observer.value.observe(loadmoreRef.value);
  }

  // if searchOnLoad = true it will search for initial value
  if (props.value && props.searchOnLoad) search();
});

onUnmounted(() => {
  if (observer.value) {
    observer.value.disconnect();
  }
});

const infiniteScroll = async([{ isIntersecting, target }]) => {
  if (isIntersecting) {
    const ul = target.offsetParent;
    const scrollTop = target.offsetParent.scrollTop;
    limit.value += props.pageSize;
    await getSearchResults();
    await nextTick();
    ul.scrollTop = scrollTop;
    emit('loadMore', limit.value);
  }
};

const debouncedInputRawUpdate = debounce((newVal) => {
  if (newVal === searchInputResult.value) return;
  searchInputResult.value = newVal;
  search();
}, 500);

const search = async() => {
  emit('search', searchInputResult.value);
  if (props.disabled) return;
  limit.value = props.pageSize;
  results.value = [];
  try {
    isOpen.value = true;
    message.value = t('Loading search results...');
    loading.value = true;
    await nextTick();
    await getSearchResults();
  } catch (error) {
    message.value = t('Cannot get search results');
  }
  message.value = results.value.length ? '' : t('No results were found');
  await nextTick();
  loading.value = false;
};
const getSearchResults = async() => {
  results.value = (await props.searchFunc(searchInputResult.value, limit.value)) || [];
};
const onSelect = result => {
  if (props.disabled) return;
  if (props.closeAfterSelecting) isOpen.value = false;
  searchInputRaw.value = result.text;
  searchInputResult.value = result.text;
  emit('input', result); // emit selected object
  emit('search', searchInputResult.value); // emit search text
  focus();
};
const clear = () => {
  if (props.disabled) return;
  searchInputRaw.value = '';
  searchInputResult.value = '';
  results.value = [];
  emit('input', undefined);
  emit('search', '');
  focus();
};
const focus = () => {
  emitFocus.value = false;
  searchinputRef.value.focus();
};
const onClose = event => {
  // Check if the clicked element is the input field or its parent element
  const inputField = searchinputRef.value;
  if (!inputField.contains(event?.target)) {
    // If the clicked element is outside the input field, close the results
    isOpen.value = false;
  }
};
const handleFocus = newValue => {
  if (emitFocus.value) emit('focus', newValue);
};
const handleBlur = newValue => {
  if (isOpen.value) return;
  // not always when input receives focus it means control got focus, probably it's already in focus state (closing dropdown)
  emitFocus.value = true;
  emit('blur', newValue);
};
const closeDropdown = () => {
  clear();
  onClose();
};
defineExpose({
  closeDropdown: closeDropdown,
});
</script>

<style lang="scss" scoped>
.search {
  display: flex;
  flex-flow: column nowrap;
}

label {
  margin-bottom: 0;
  width: 100%;
}

.input {
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  border-radius: 900px;
  width: 100%;
  border: 2px solid transparent;
  padding: 0 var(--spacing-1);
  background-color: var(--colour-panel-g-0);
  font-weight: var(--font-weight-medium);
  box-shadow: 0px 0px 0px 1px var(--border-utility-base);
  overflow: hidden;

  &:focus-within:not([disabled]) {
    box-shadow: 0px 0px 0px 4px var(--colour-utility-focus);
    border: 2px solid var(--colour-utility-checked);
    outline: 2px solid transparent;
  }

  &.input-error:not([disabled]) {
    box-shadow: 0px 0px 0px 4px var(--colour-utility-error);
    border: 2px solid var(--colour-utility-checked);
    outline: 2px solid transparent;
  }

  input,
  input:focus {
    border: none;
    box-shadow: none;
    outline: none;
    padding-left: var(--spacing-1);
  }

  &[disabled] {
    background-color: var(--colour-panel-g-4);
    box-shadow: 0 0 0 1px var(--colour-panel-g-16);

    input {
      background-color: var(--colour-panel-g-4);

      &::placeholder {
        color: var(--colour-panel-g-24);
      }
    }
  }

  .close {
    cursor: pointer;
  }
}

.dropdown-container {
  position: relative;
  display: inline-block;
  width: 100%;
  z-index: 2;
}

.results {
  position: absolute;
  min-width: 160px;
  max-height: 300px;
  overflow: auto;
  margin: 0;
  box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-blur) var(--shadow-colour);
  background-color: var(--colour-panel-g-0);
  width: 100%;

  .message {
    text-align: center;
    font-style: italic;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  .result {
    flex: 1;
    width: 100%;
    min-height: 1.5rem;
    padding: var(--spacing-1) var(--spacing-2);
    border-bottom: 0.5px solid var(--colour-panel-g-4);
    cursor: pointer;

    &:last-of-type {
      border: none;
    }

    &:hover,
    &:focus {
      background-color: var(--colour-panel-action);
    }

    :deep(span.highlight) {
      color: var(--colour-brand-puerto-rico);
    }
  }
}

.error-text {
  display: flex;
  align-self: flex-start;
}
</style>
