import { onMounted, onBeforeUnmount } from 'vue';
import { uuid, debounce } from '@sales-i/utils';
import * as d3 from 'd3';

export default function useCharts({ id, containerRef, generateChart, debounceTime = 250 }) {
  const uniqueId = id || uuid('chart');

  const debounceChartResize = debounce(handleChartResize, debounceTime);
  const containerResizeObserver = new ResizeObserver(debounceChartResize);
  
  onMounted(() => {
    containerResizeObserver.observe(containerRef.value);
  });
  
  onBeforeUnmount(() => {
    containerResizeObserver.disconnect();
    removeChart();
  });

  function removeChart() {
    d3.select(`#${uniqueId} svg`).selectAll('g').remove();
  }

  function getChartVersion() {
    return containerRef.value?.clientWidth <= 780 ? 'mobile' : 'desktop';
  }

  function handleChartResize() {
    removeChart();
    generateChart();
  }
  
  return {
    uniqueId,
    getChartVersion,
    removeChart,
    handleChartResize,
  };
}