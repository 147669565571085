<template>
  <li
    role="button"
    tabindex="0"
    :class="{ highlighted: item.checked }"
    @click.self="handleCheckbox"
    @keydown.enter="handleCheckbox"
  >
    <div class="checkbox-container">
      <CustomCheckbox
        class="checkbox"
        :value="item.checked"
        @input="handleCheckbox"
      />
    </div>
    {{ item.value }}
  </li>
</template>

<script setup>
import { CustomCheckbox } from '@sales-i/dsv3';

const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['onChange']);

const handleCheckbox = () => {
  const { value, id, checked } = props.item;
  emit('onChange', {
    value,
    id,
    checked: !checked,
  });
};
</script>

<style lang="scss" scoped>
li {
  position: relative;
  height: 36px;
  padding-left: var(--spacing-6);
  padding-top: var(--spacing-1);
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:nth-child(even) {
    background: var(--colour-panel-g-4);
  }

  &.highlighted {
    background: var(--colour-utility-black);
    color: var(--colour-utility-white);
  }

  .checkbox-container {
    background: var(--colour-data-puerto-rico);
    padding: var(--spacing-2) var(--spacing-half);
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 43px;

    .checkbox {
      border-radius: var(--border-radius-half);
      margin-top: var(--spacing-1);
      margin-bottom: 0;
    }
  }
}
</style>
