<template>
  <div 
    class="table-container" 
    :style="cssVars"
  >
    <template v-if="enablePagination">
      <PaginationButton
        v-for="button in paginationButtons"
        :key="button.position"
        :is-visible="button.isVisible"
        :position="button.position"
        :label="button.label"
        :limit="button.limit"
        @hide="hidePaginationButton"	
        @set-offset="handleSetOffset"
      />
    </template>
    <div 
      ref="tableWrapper" 
      class="table-wrapper" 
      @scroll="onScroll"
    >
      <component 
        :is="container" 
        ref="table"
      >
        <slot />
      </component>
    </div>
    <BufferImage
      v-if="isLoading"
      color="var(--colour-utility-black)"
      float="center"
      class="loading-spinner"
    />
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { debounce } from '@sales-i/utils';
import PaginationButton from '@/intelligence/components/Shared/PaginationButton';
import { useStore } from 'vuex';
import { BufferImage } from '@sales-i/dsv3';

const props = defineProps({
  tableHeight: {
    type: String,
    default: '330px',
  },
  offsetX: {
    type: Number,
    default: 0,
  },
  offsetY: {
    type: Number,
    default: 0,
  },
  enablePagination: {
    type: Boolean,
    default: false,
  },
  limit: {
    type: Number,
    default: 50,
  },
  limitX: {
    type: Number,
    default: 50,
  },
  enableLazyLoad: {
    type: Boolean,
    default: false,
  },
  noMoreResultsAvailable: {
    type: Boolean,
    default: false,
  },
  container: {
    type: String,
    default: 'table',
  },
  loading: {
    type: Boolean,
    default: undefined,
  },
  xHeadings: {
    type: Array,
    default: () => [],
  },
  yHeadings: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['setOffset']);
const store = useStore();

const isYScrollVisible = ref(false);
const paginationButtons = ref([]);
const tableWrapper = ref(null);
const table = ref(null);

const sharedLoading = computed(() => store.state.intelligence.shared.loading);

const isLoading = computed(() => (
  props.loading ||
    (props.loading === undefined &&
      sharedLoading.value &&
      props.enableLazyLoad)
));

const cssVars = computed(() => ({
  '--table-height': props.tableHeight,
  '--scrollbar-width': isYScrollVisible.value
    ? 'var(--spacing-1)'
    : '0',
}));

function initializePaginationButtons() {
  paginationButtons.value = [
    {
      position: 'top',
      label: 'previous',
      limit: props.limit,
      isVisible: false,
    },
    {
      position: 'bottom',
      label: 'next',
      limit: props.limit,
      isVisible: false,
    },
    {
      position: 'left',
      label: 'previous',
      limit: props.limitX,
      isVisible: false,
    },
    {
      position: 'right',
      label: 'next',
      limit: props.limitX,
      isVisible: false,
    },
  ];
}

function handleSetOffset(position) {
  emit('setOffset', position);
}

const onResize = debounce(() => {
  if (tableWrapper.value) {
    isYScrollVisible.value =
      tableWrapper.value.scrollHeight > tableWrapper.value.clientHeight;
  }
}, 250);

const onScroll = debounce(() => {
  if (!tableWrapper.value) return;

  const wrapper = tableWrapper.value;
  const scrollTop = wrapper.scrollTop;
  const scrollLeft = wrapper.scrollLeft;
  const maxScrollTop = wrapper.scrollHeight - wrapper.clientHeight;
  const maxScrollLeft = wrapper.scrollWidth - wrapper.clientWidth;
  const threshold = 10; // pixels from the edges

  // Update pagination button visibility
  paginationButtons.value.forEach((button) => {
    switch (button.position) {
    case 'top':
      // Show top button when user has scrolled to the top and offsetY > 0
      button.isVisible = scrollTop <= threshold && props.offsetY > 0;
      break;
    case 'bottom':
      // Show bottom button when scrolled to the bottom and more data is available
      button.isVisible =
        scrollTop >= maxScrollTop - threshold && !props.noMoreResultsAvailable && props.yHeadings.length == props.limit;
      break;
    case 'left':
      // Show left button when scrolled to the left and offsetX > 0
      button.isVisible = scrollLeft <= threshold && props.offsetX > 0;
      break;
    case 'right':
      // Show right button when scrolled to the right edge and more data is available
      button.isVisible =
        scrollLeft >= maxScrollLeft - threshold && !props.noMoreResultsAvailable && props.xHeadings.length == props.limitX;
      break;
    }
  });

  // Lazy load trigger (if needed)
  if (
    props.enableLazyLoad &&
    !isLoading.value &&
    !props.noMoreResultsAvailable &&
    scrollTop >= maxScrollTop - wrapper.clientHeight / 2
  ) {
    emit('setOffset');
  }
}, 200);

const hidePaginationButton = (position) => {
  const button = paginationButtons.value.find((b) => b.position === position);
  if (button) button.isVisible = false;
};

onMounted(() => {
  window.addEventListener('resize', onResize);
  onResize();
  if (!props.enablePagination) return;
  initializePaginationButtons();
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize);
});
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.table-container {
  --scrollbar-width: var(--spacing-1);

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    scrollbar-width: var(--spacing-2);
  }

  width: 100%;
  position: relative;

  table {
    width: 100%;
  }
}

.table-wrapper {
  overflow: auto;
  height: var(--table-height);
  width: 100%;
  position: relative;

  background:
    /* 'Hidden' left gradient */
    linear-gradient(
      to right,
      #fff 0%,
      transparent
    ),
    /* 'Hidden' right gradient */
    linear-gradient(
      to right,
      transparent,
      #fff 100%
    ) 0 100%,
    
    /* Left gradient */
    linear-gradient(
      to right,
      rgba(0, 0, 0, 0.3),
      transparent
    ),
    /* Right gradient */
    linear-gradient(
      to left,
      rgba(0, 0, 0, 0.3),
      transparent,
    ) 0 100%;  
  

    background-repeat: no-repeat;
    background-color: var(--colour-utility-white);

    background-size: 
      250px 100%, // Sets the width/height of the first 'hidden' gradient.
      250px 100%, // Sets the width/height of the second 'hidden' gradient.
      20px 100%,  // Sets the width/height of the left shading gradient.
      20px 100%;  // Sets the width/height of the right shading gradient.     
    
    background-position:
      0 0,   // Positions the first 'hidden' gradient horizonally/vertically.
      100%,  // Positions the second 'hidden' gradient at 100% of the width, effectively hiding it on the right side.
      0 0,   // Positions the left shading gradient horizonally/vertically
      right var(--scrollbar-width) bottom 0;   // Positions the right shading gradient at the rightmost edge of the table wrapper (taking into account scrollbar). 

    background-attachment:
      local, local,   // Sets the first two gradients to scroll with the content.
      scroll, scroll; // Sets the shading gradients to remain fixed as the content scrolls.
    
    transition: all 0.2s;
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    &::-webkit-scrollbar {
      padding: 0;
    }

    &::-webkit-scrollbar-track {
      border-radius: var(--border-radius-1);
      background-color: var(--colour-panel-g-16);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: var(--border-radius-1);
      background-color: var(--colour-panel-g-32);
    }
  }
}

.expanded {
  .table-container,
  .table-wrapper {
    height: 100%;
  }
  .footer-section {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.loading-spinner {
  position: absolute;
  z-index: 5;
  left: 0;
  bottom: var(--spacing-2);
  padding: var(--spacing-half);
  right: 0;
}
</style>
<style lang="scss">
.order-left-aligned {
  text-align: left;
}
</style>
