/* eslint-disable no-unused-vars */
import {
  GET_PRODUCTS,
  GET_PRODUCTS_ERROR,
  CLEAR_PRODUCTS,
  GET_PRODUCTS_LOADING,
  SET_PRODUCT_ASSOCIATION_DETAILS,
  GET_CRM_PRODUCTS,
  CLEAR_CRM_PRODUCTS,
  GET_CRM_PRODUCTS_LOADING,
  GET_CRM_PRODUCTS_ERROR,
} from '@/admin/store/actionType';
import {
  DELETE_BY_ID,
  DELETE_BY_ID_LOADING,
  DELETE_BY_ID_ERROR,
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';
import querystring from 'querystring';


// getters
export const getters = {
};

// mutations
export const productsMutations = {
  [GET_PRODUCTS]: (state, data) => {
    state.products.data = data;
    state.products.loaded = true;
    state.products.loading = false;
    state.products.errors = [];
  },
  [GET_PRODUCTS_LOADING]: (state, data) => {
    state.products.loaded = false;
    state.products.loading = true;
    state.products.errors = [];
  },
  [GET_PRODUCTS_ERROR]: (state) => {
    state.products.loaded = false;
    state.products.loading = true;
    state.products.errors = ['Failed to get products'];
  },
  [DELETE_BY_ID]: (state, parameters) => {
    state.products.loaded = true;
    state.products.loading = false;
    state.products.errors = [];
  },
  [DELETE_BY_ID_LOADING]: (state) => {
    state.products.loading = true;
    state.products.loaded = false;
    state.products.errors = [];
  },
  [DELETE_BY_ID_ERROR]: (state, e) => {
    state.products.loading = false;
    state.products.loaded = false;
    state.products.errors = ['Failed to delete'];
  },
  [CLEAR_PRODUCTS]: (state) => {
    state.products.data = [];
    state.products.loaded = false;
    state.products.loading = true;
    state.products.errors = undefined;
  },

  [SET_PRODUCT_ASSOCIATION_DETAILS]: (state, data) => {
    // Find the product
    const { item, value, key } = data;
    const productIndex = state.products.data.findIndex(
      o => o.antecedent_code === item.antecedent_code && o.consequent_code === item.consequent_code
    );
    if (productIndex === -1) {
      // Item not found, don't update it
      return;
    }
    item[key] = parseInt(value, 10);
    state.products.data[productIndex] = item;
  },

  [GET_CRM_PRODUCTS]: (state, data) => {
    state.crmProducts.data = data;
    state.crmProducts.loaded = true;
    state.crmProducts.loading = false;
    state.crmProducts.errors = [];
  },
  [GET_CRM_PRODUCTS_LOADING]: (state) => {
    state.crmProducts.loaded = false;
    state.crmProducts.loading = true;
  },
  [CLEAR_CRM_PRODUCTS]: (state) => {
    state.crmProducts.data = [];
    state.crmProducts.loaded = true;
    state.crmProducts.loading = false;
    state.crmProducts.errors = [];
  },
  [GET_CRM_PRODUCTS_ERROR]: (state, errors) => {
    state.crmProducts.loaded = true;
    state.crmProducts.loading = false;
    state.crmProducts.errors = errors;
  },
};

// actions
export const productsActions = {
  [GET_PRODUCTS]: async ({ commit, }, parameters) => {
    commit(GET_PRODUCTS_LOADING);
    try {
      const params = querystring.stringify(parameters);
      const data = await httpRequest('get', `associations?${params}`, {}, { area: 'associations' });
      commit(GET_PRODUCTS, data);
      return data;
    } catch (e) {
      commit(GET_PRODUCTS_ERROR);
      return null;
    }
  },
  [GET_CRM_PRODUCTS]: async ({ commit }, parameters) => {
    commit(GET_CRM_PRODUCTS_LOADING);
    try {
      let { q, limit, offset } = parameters;
      const params = querystring.stringify({ q, limit, offset });
      let data = await httpRequest('get', `products?${params}`);
      commit(GET_CRM_PRODUCTS, data);
    } catch (e) {
      commit(GET_CRM_PRODUCTS_ERROR, [e]);
    }
  },
  [DELETE_BY_ID]: async ({ commit, }, parameters) => {
    commit(DELETE_BY_ID_LOADING);
    try {
      await httpRequest('delete', 'associations', parameters, {
        area: 'associations',
      });
      commit(DELETE_BY_ID);
    } catch (e) {
      commit(DELETE_BY_ID_ERROR);
    }
  },
  [CLEAR_CRM_PRODUCTS]: ({ commit }) => {
    commit(CLEAR_CRM_PRODUCTS);
  },
  [CLEAR_PRODUCTS]: ({ commit, }) => {
    commit(CLEAR_PRODUCTS);
  },
  [SET_PRODUCT_ASSOCIATION_DETAILS]: ({ commit }, parameters) => {
    commit(SET_PRODUCT_ASSOCIATION_DETAILS, parameters);
  },
};
