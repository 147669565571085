import { useStore } from 'vuex';
import { POST_BY_ENTITY, SET_ITEMS } from '@/shared/store/actionType';

export default function useNotification({ store = useStore() } = {}) {
  const sendSuccessAlert = (message, options = { selfDismiss: true }) => 
    store.dispatch(`alerts/${SET_ITEMS}`, {
      type: 'success',
      message: message,
      ...options
    });
  
  const sendErrorAlert = (message, options = { selfDismiss: true }) => 
    store.dispatch(`alerts/${SET_ITEMS}`, {
      type: 'error',
      message: message,
      ...options
    });
  
  /**
   * 
   * @param {*} params 
   * {
        type: 'SharedEnquiry',
        title,
        description,
        path: enquiryUrl,
        subjects: assignedUsersArray
      });
    * @returns 
    */
  const sendNotification = params => store.dispatch(`notifications/${POST_BY_ENTITY}`, params);
  
  return {
    sendSuccessAlert,
    sendErrorAlert,
    sendNotification,
  };
}