<template>
  <div>
    <TableWrapper
      v-if="!dataLoading && dataRows.length"
      table-height="510px"
      :offset-x="offsetX"
      :offset-y="offsetY"
      @set-offset="loadData"
    >
      <thead>
        <th class="search-cell">
&nbsp;
        </th>
        <th
          v-for="(heading, index) in xHeadings"
          :key="index"
          :title="heading"
          :class="[{ 'drill-active': isDrillActive }, heading.toLowerCase()]"
          @click="sortColumns(index)"
        >
          <div class="heading">
            {{ heading }}
            <IconBase
              v-if="currentSort === index || (currentSort === 'both' && index === 3)"
              class="sort-icon"
              :icon-name="sortDescending ? 'arrow-down' : 'arrow-up'"
              :height="24"
              :width="24"
            />
          </div>
          <div
            class="chip"
            :title="getChipValue(index, 'full')"
          >
            {{ getChipValue(index) }}
          </div>
        </th>
      </thead>
      <tbody>
        <tr
          v-for="(cell, i) in dataRows"
          :key="i"
          :title="parseInt(cell, 10).toLocaleString('en-US')"
          :class="{ zero: parseInt(cell, 10) === 0 }"
        >
          <th
            :key="`th${i}`"
            :title="getMonthHeading(cell, i)"
            :class="{ 'drill-active': isDrillActive }"
          >
            <div class="title">
              {{ getMonthHeading(cell, i) }}
            </div>
          </th>
          <td
            v-for="(scell, si) in cell"
            :key="si"
            role="button"
            tabindex="0"
            :title="processData(scell)"
            :class="[{ zero: parseInt(cell, 10) === 0 }, si]"
            @click="handleItemClick(cell, si)"
            @keydown.enter="handleItemClick(cell, si)"
          >
            <div :class="i">
              <CustomButton
                v-if="si === 'product_1' || si === 'product_2'"
                purpose="text"
              >
                {{ processData(scell) }}
              </CustomButton>
              <span v-else>{{ processData(scell) }}</span>
            </div>
          </td>
        </tr>
      </tbody>
    </TableWrapper>
    <CustomModal
      id="associations-drill-down-table"
      :title="getModalTitle()"
      position="center"
      class="flow view-drill-down"
      :show-modal="showDrillModal"
      @close-modal="closeModal"
    >
      <TableWrapper table-height="unset">
        <thead>
          <tr>
            <th
              v-for="(dataType, index) in inputData.visualInput"
              :key="dataType.key"
              :class="{
                'sorting-header': currentSort === dataType.key && index !== 0,
                'contains-chip-header': dataType.includesChip,
              }"
              @click="applySort(dataType.key, index)"
            >
              {{ dataType.name }}
              <p
                v-if="dataType.includesChip"
                class="total-chip"
              >
                {{ currency.leftFormat(dataType.prefix ? cs : '', getTotal(dataType.key)) }}{{ dataType.suffix }}
              </p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(unit, i) in drilledData"
            :key="i"
          >
            <td
              v-for="(dataType, index) in inputData.visualInput"
              :key="dataType.key"
              :class="{ 'first-column': index === 0 }"
            >
              <CustomButton
                v-if="dataType.key === 'name'"
                purpose="text"
                @click.prevent="redirectToCustomer(unit)"
              >
                {{ currency.leftFormat(dataType.prefix ? cs : '', getFormattedNumber(unit[dataType.key], dataType.prefix))
                }}{{ dataType.suffix }}
              </CustomButton>
              <span v-else>
                {{ currency.leftFormat(dataType.prefix ? cs : '', getFormattedNumber(unit[dataType.key], dataType.prefix))
                }}{{ dataType.suffix }}
              </span>
            </td>
          </tr>
        </tbody>
      </TableWrapper>
    </CustomModal>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { CustomButton, CustomModal, IconBase } from '@sales-i/dsv3';
import { abbr, currency, gqlRequest, t, dates } from '@sales-i/utils';
import TableWrapper from '@/shared/components/Tables/TableWrapper';
import { REPORTS_SORT_DESCENDING_KEY } from '@/intelligence/store/data/apiInput';
import { HIGHEST_REVENUE_CUSTOMER } from '@/intelligence/store/data/reportTypes';
import useCurrency from '@/shared/composables/useCurrency';
import { navigateToUrl } from 'single-spa';
import { baseUrl, customersArea } from '@/crm/router/urlBits';
import useShared from '@/intelligence/composables/useShared';
import useCalendar from '@/intelligence/composables/useCalendar';
import useTemplateReport from '@/intelligence/composables/useTemplateReport';
import useEnquiries from '@/intelligence/composables/useEnquiries';

defineProps({
  ySortDirection: {
    type: String,
    default: REPORTS_SORT_DESCENDING_KEY,
  },
  isSnapshot: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['sortOnYAxis', 'handleEnquiryDrill', 'setEnquiryData', 'loading']);

const store = useStore();
const { cs } = useCurrency();

const offsetX = ref(0);
const offsetY = ref(0);
const showDrillModal = ref(false);
const codeBubble = ref({});
const drilledData = ref([]);
const currentSort = ref('');
const sortDescending = ref(false);
const product = ref({});
const period = ref(1);

const { fetchedCalendar } = useCalendar({ store });
const { 
  getMonth, isDrillActive, dataLoading, requestParameters, 
  getReportData, getInputData, applyOffset: applyOffsetY } = useShared({ store });
const { applyOffsetX } = useEnquiries({ store });

const { fetchBubbleIds } = useTemplateReport({ store });

const inputData = computed(() => getInputData(HIGHEST_REVENUE_CUSTOMER));
const enquiryData = computed(() => getReportData());

const dataRows = computed(() => {
  if (currentSort.value === '') {
    return enquiryData.value ? [...enquiryData.value.rows] : [];
  } else {
    const key = `product_${currentSort.value}`;
    return enquiryData.value
      ? [...enquiryData.value.rows].sort((a, b) => {
        if (a[key] > b[key]) {
          return sortDescending.value ? -1 : 1;
        } else if (a[key] < b[key]) {
          return sortDescending.value ? 1 : -1;
        }
        return 0;
      })
      : [];
  }
});

const xHeadings = computed(() => {
  const { products } = enquiryData.value;
  return ['Period', products[0].name, products[1].name, 'Both'];
});

onMounted(async () => {
  await fetchBubbleIds([
    'field',
    'product_code',
    bubbles => {
      codeBubble.value = bubbles[0];
    },
  ]);
});

const sortColumns = (index) => {
  if (index === 3) {
    index = 'both';
  }
  if (index === currentSort.value) {
    sortDescending.value = !sortDescending.value;
  } else {
    currentSort.value = index;
  }
};

const redirectToCustomer = (unit) => {
  return navigateToUrl(`${baseUrl}/${customersArea}/${unit.code}`);
};

const getModalTitle = () => {
  const { periods_current_fiscal_year } = fetchedCalendar.value;
  let currentPeriod = period.value;

  if (currentPeriod in periods_current_fiscal_year !== false) {
    const { start } = periods_current_fiscal_year[currentPeriod];
    const startParts = start.split('-');
    currentPeriod = getMonth(parseInt(startParts[1])).value;
  }

  return t(
    `Customers that purchased ${product.value.name} during ${currentPeriod + 1}`,
    'customers_that_purchased_itemname_during_period',
    {
      interpolations: {
        itemname: product.value.name,
        period: period.value + 1,
      },
    }
  );
};

const applySort = (headerKey, index) => {
  if (currentSort.value === headerKey && index !== 0) {
    sortDescending.value = !sortDescending.value;
    if (sortDescending.value) drilledData.value.sort((a, b) => b[headerKey] - a[headerKey]);
    else drilledData.value.sort((a, b) => a[headerKey] - b[headerKey]);
  } else {
    currentSort.value = headerKey;
    if (sortDescending.value) drilledData.value.sort((a, b) => b[headerKey] - a[headerKey]);
    else drilledData.value.sort((a, b) => a[headerKey] - b[headerKey]);
  }
};

// TODO: replace with formatter from utils
const getFormattedNumber = (number, prefix) => {
  return number.toLocaleString('en-US', {
    minimumFractionDigits: prefix ? 2 : 0,
    maximumFractionDigits: 2,
  });
};

const getTotal = (key) => {
  let total = 0;
  if (drilledData.value) {
    drilledData.value.forEach(unit => {
      total += unit[key];
    });
  }
  total = Math.round(total * 100) / 100;
  return abbr.float(total);
};

const closeModal = () => {
  showDrillModal.value = false;
};

const handleItemClick = async (cell, i) => {
  if (i.indexOf('product_') === -1 || i === 'product_both') {
    return;
  }
  const { periods_current_fiscal_year } = fetchedCalendar.value;
  const now = new Date();

  const { period } = cell;
  const fixedPeriod = parseInt(period) - 1;

  // Infer when their fiscal year begins
  let start_period = periods_current_fiscal_year[fixedPeriod];
  let dateRange = {
    ...start_period,
  };

  if (new Date(dateRange.start) > now) {
    dateRange.start = dates.getDateTime(dateRange.start).minus({year: 1}).toISO({
      suppressMilliseconds: true,
      includeOffset: false,
    }) + 'Z';
    dateRange.end = dates.getDateTime(dateRange.end).minus({year: 1}).toISO({
      suppressMilliseconds: true,
      includeOffset: false,
    }) + 'Z';
  }

  const { products } = enquiryData.value;
  const productIndex = parseInt(i.replace('product_', '')) - 1;
  const { start, end } = dateRange;
  let selectedProducts = [products[productIndex].code].join('","');
  emit('loading', true);

  const requestString = `
    {
      customersale(
        date_from:"${start.replace('Z', '+0000')}"
        date_to: "${end.replace('Z', '+0000')}"
        offset: 0
        limit: 1000
        sort: "revenue:desc"
        filters: [{
          id: "${codeBubble.value.id}"
          selected_values: ["${selectedProducts}"]
        }]
      )
      {
        name
        code
        cost
        revenue
        profit
        margin
      }
    }`;
  const response = await gqlRequest(requestString, {}, { debug: true });
  const { customersale } = response;

  drilledData.value = customersale;
  product.value = products[productIndex];
  period.value = fixedPeriod;
  emit('loading', false);
  showDrillModal.value = true;
};

const getMonthHeading = (row) => {
  if (row === undefined) {
    return;
  }
  const { periods_current_fiscal_year } = fetchedCalendar.value;
  // Infer when their fiscal year begins
  let start_period = parseInt(periods_current_fiscal_year[0].start.split('-')[1]) - 1;

  const periods = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(() => {
    start_period++;
    let period = start_period;

    if (period > 12) {
      start_period = 1;
      period = 1;
    }
    return { start: period };
  });

  const fiscalPeriod = periods[parseInt(row.period) - 1];
  if (fiscalPeriod === undefined) {
    return '';
  }
  const { start } = fiscalPeriod;

  return getMonth(start).value;
};

const processData = (cell) => {
  return cell ? abbr.int(cell) : '-';
};

const getChipValue = (index, format) => {
  let total = 0;
  let key = '';
  switch (index) {
  case 0:
    key = 'period';
    break;
  case 1:
    key = 'product_1';
    break;
  case 2:
    key = 'product_2';
    break;
  case 3:
    key = 'product_both';
    break;
  }

  dataRows.value.forEach(unit => {
    total += parseInt(unit[key], 10);
  });

  return format === 'full' ? total.toLocaleString('en-US') : abbr.float(total);
};

const loadData = (paginationDirection) => {
  switch (paginationDirection) {
  case 'right':
    offsetX.value += requestParameters.value.limit;
    break;
  case 'bottom':
    offsetY.value += requestParameters.value.limit;
    break;
  case 'left':
    offsetX.value -= requestParameters.value.limit;
    break;
  case 'top':
    offsetY.value -= requestParameters.value.limit;
    break;
  default:
    break;
  }
  applyOffsetX(offsetX.value);
  applyOffsetY(offsetY.value);
  emit('setEnquiryData');
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
table {
  td,
  th {
    vertical-align: middle;
    font-size: var(--font-size-body);
    color: var(--colour-utility-black);
    text-align: center;
    border: 0;
  }

  th.period,
  td.period {
    display: none;
  }

  td[title],
  th[title] {
    cursor: help;
  }

  td {
    padding: 0;

    &.zero {
      color: var(--colour-utility-error);
    }

    div {
      padding: var(--spacing-2);
    }
  }

  thead {
    th {
      min-width: 120px;
      padding: var(--spacing-2) var(--spacing-2) var(--spacing-4);
      background: var(--colour-panel-g-4);
      color: var(--colour-utility-black);
      border-bottom: 1px solid var(--colour-panel-g-16);
      position: sticky;
      top: 0;
      z-index: 1;
      text-decoration: none;
      font-weight: normal;

      @media #{map-get($display-breakpoints, 'sm-and-up')} {
        min-width: 195px;
      }

      .heading {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        height: 50px;
      }
    }
  }

  tr {
    &:first-of-type {
      th,
      td {
        padding-top: var(--spacing-3);
      }

      .drill {
        align-items: end;
      }
    }

    th {
      text-align: left;
      padding: 0;
      position: sticky;
      left: 0;
      z-index: 2;
      background: var(--colour-panel-g-2);

      &.drill-active {
        padding-left: var(--spacing-5);
      }

      .title {
        padding: var(--spacing-2);
        width: 140px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        @media #{map-get($display-breakpoints, 'sm-and-up')} {
          width: 195px;
        }
      }
    }
  }
}

.drill {
  background: var(--colour-panel-base);
  padding: var(--spacing-2) var(--spacing-1);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;

  .checkbox {
    background: var(--colour-utility-white);
    border-radius: var(--border-radius-half);
    margin-bottom: 0;
    width: 1.8em;
    height: 1.8em;
  }
}

.search-cell {
  background: var(--colour-utility-white);
  border-bottom: 0;
  z-index: 3;
  left: 0;

  .field {
    position: relative;
  }

  input {
    border-radius: var(--spacing-4);
    font-weight: var(--font-weight-regular);
    padding: var(--spacing-1) var(--spacing-2) var(--spacing-1) var(--spacing-4);
  }

  .search-button {
    background: var(--colour-panel-action);
    border-radius: 50%;

    &.active {
      background: transparent;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: var(--spacing-1);
    }
  }
}

.chip {
  position: absolute;
  width: 100px;
  color: var(--colour-utility-black);
  background: var(--colour-panel-g-0);
  box-shadow: 0 0 var(--border-radius-half) var(--shadow-spread) var(--shadow-colour);
  font-size: var(--font-size-5);
  font-weight: var(--font-weight-medium);
  letter-spacing: 0;
  line-height: var(--spacing-3);
  padding: var(--spacing-1);
  border-radius: var(--spacing-6);
  margin: auto;
  margin-top: var(--spacing-1);
  left: 0;
  right: 0;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    width: 120px;
  }
}

.sort-button {
  position: absolute;
  right: var(--spacing-half);
  top: calc(100% + var(--spacing-half));

  &.asc {
    transform: rotate(180deg);
  }
}

.view-drill-down {
  table {
    position: relative;
    width: 100%;
  }

  tbody tr:first-child {
    height: var(--spacing-8);

    th,
    td {
      vertical-align: bottom;
    }
  }

  tr {
    background: var(--colour-panel-g-0);

    &:nth-child(even) {
      background: var(--colour-panel-g-2);
    }
  }

  th,
  td {
    color: var(--colour-utility-black);
    padding: var(--spacing-2);
    border: none;
  }

  th {
    font-size: var(--font-size-4);
    background: var(--colour-panel-action);
    color: var(--colour-utility-action);
    position: sticky;
    top: 0;
    text-align: center;
    min-height: 70px;
    vertical-align: middle;
    text-decoration: underline;
    cursor: pointer;
    user-select: none;

    &:first-child {
      cursor: auto;
      text-decoration: none;
      color: var(--colour-utility-black);
    }

    &.contains-chip-header {
      padding-bottom: var(--spacing-3);
    }

    &.sorting-header {
      background: var(--colour-panel-g-2);
      color: var(--colour-utility-black);
      font-weight: var(--font-weight-medium);
      text-decoration: none;
    }

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      font-size: var(--font-size-5);
      padding: var(--spacing-2) var(--spacing-1);
      min-width: 100px;
    }
  }

  td {
    font-size: var(--font-size-small);
    padding: var(--spacing-1);
    line-height: var(--spacing-2);
    text-align: center;

    &.first-column {
      background: var(--colour-panel-g-2);
      border-right: 1px solid var(--colour-panel-g-8);
      text-align: left;
      padding-left: var(--spacing-6);

      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        padding-left: var(--spacing-1);
      }

      @media #{map-get($display-breakpoints, 'xs-only')} {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 140px;
      }
    }
  }

  tr:nth-child(even) td.first-column {
    background: var(--colour-panel-g-4);
  }

  .sort-icon {
    display: inline-block;
    position: absolute;
  }

  .total-chip {
    position: absolute;
    width: 120px;
    color: var(--colour-utility-black);
    background: var(--colour-panel-g-0);
    box-shadow: 0 0 var(--border-radius-half) var(--shadow-spread) var(--shadow-colour);
    font-size: var(--font-size-5);
    font-weight: 500;
    letter-spacing: 0;
    line-height: var(--spacing-3);
    padding: var(--spacing-1);
    border-radius: var(--spacing-6);
    margin: auto;
    margin-top: var(--spacing-1);
    right: 50%;
    transform: translateX(50%);

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      width: auto;
      min-width: 80px;
    }
  }
}

#associations-intelligence-chart-drill-down-export {
  display: flex;
  justify-content: right;
  padding-right: var(--spacing-3);
  position: relative;
  top: calc(var(--spacing-3) * -1);
}

.sort-icon {
  position: absolute;
  top: 10px;
  right: 0px;
}
</style>

<style lang="scss">
#associations-drill-down-table {
  .menu-dropdown {
    .list {
      z-index: 1000;
    }
  }

  .header {
    h3 {
      max-width: 90%;
    }
  }
}
</style>