<template>
  <div>
    <header>
      <h2>{{ t('Product Group Gaps') }}</h2>
    </header>
    <div
      v-if="hasGaps"
      class="list list-card"
      role="list"
    >
      <CustomerGap
        v-for="item in gaps"
        :key="item[0].id"
        :item="item"
        @reload-gaps="loadGaps"
      />
    </div>
    <div
      v-else
      class="mx-3"
    >
      <p class="text-center">
        {{ t('No product group gaps to follow up on.') }}
      </p>
    </div>
  </div>
</template>

<script setup>
// CustomerGaps
import { onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { GET_BY_ID } from '@/shared/store/actionType';
import { t } from '@sales-i/utils';
import CustomerGap from '@/crm/components/ProductGroups/CustomerGap.vue';

const store = useStore();
const vroute = useRoute();
const gaps = computed(() => store.getters['intelligence/productGroups/getDrilledSortedByLevelOne']);
const hasGaps = computed(() => Object.keys(gaps.value).length > 0);

const getCustomerGaps = id => store.dispatch(`intelligence/productGroups/${GET_BY_ID}`, { id });
const loadGaps = () => getCustomerGaps(vroute.params.id);

onMounted(() => {
  loadGaps();
});
</script>

<style lang="scss" scoped>
header {
  margin: var(--spacing-2) 0 var(--spacing-3);
  display: flex;
  flex-direction: column;
  text-align: center;

  h2 {
    display: flex;
    align-self: center;
  }
}

.list-card.list {
  padding: var(--spacing-2);
}
</style>
