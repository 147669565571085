import {
  RESET_REPORT_PARAMETERS,
  SAVE_REPORT_PARAMETERS,
} from '@/intelligence/store/actionType';
import { computed } from 'vue';
import { useStore } from 'vuex';

export default function useLazyLoading({ store = useStore() } = {}) {
  const rows = computed(() => store.state.intelligence.reportLazyLoad.rows);

  const saveReportParameters = (params) => store.dispatch(`intelligence/reportLazyLoad/${SAVE_REPORT_PARAMETERS}`, params);
  const resetReportParameters = (params) => store.dispatch(`intelligence/reportLazyLoad/${RESET_REPORT_PARAMETERS}`, params);
  
  return {
    rows,
    saveReportParameters,
    resetReportParameters,
  };
}