<template>
  <div>
    <div>
      {{ userFriendlyRestrictions }}
    </div>
    <div
      v-if="restrictions.length"
      class="rule-editable-preview"
    >
      <RecursiveRule
        :rules="restrictions"
        :clauses="clauses"
        @edit-rule="editRule"
        @edit-clause="editClause"
        @delete-rule="deleteRule"
        @delete-ruleset="deleteRuleset"
      />
    </div>
    <IconButton
      :label="t('Add a rule / rule group')"
      icon-name="plus"
      icon-align="left"
      icon-color="white"
      variant="round"
      @on-click="showAddRuleModal(true)"
    />
  </div>
  <Teleport to="#modal-teleport-target">
    <AddRulesModal
      v-if="addRuleModal"
      class="add-rules-modal"
      :selected-index="selectedIndex"
      :rule="rule"
      @add-rule="addRuleOrRuleGroup"
      @update-rule="updateRule"
      @tab-selected="tabSelected"
      @close-modal="closeModal"
    />
  </Teleport>
  <ButtonGroup class="form-actions">
    <template v-if="true">
      <CustomButton
        v-for="action in actions"
        :key="action.label"
        :label="action.label"
        :disabled="action.disabled"
        :purpose="action.isSubmit ? 'action' : action.purpose || 'action'"
        :small="action.small || false"
        :class="`action ${valid ? '' : 'disabled'}`"
        :type="action.isSubmit ? 'submit' : 'button'"
        @on-click="
          event => {
            action.onClick(event);
          }
        "
      />
    </template>
    <template v-if="false">
      <BufferImage :size="40" />
    </template>
  </ButtonGroup>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { t } from '@sales-i/utils';
import { BufferImage, CustomButton, IconButton } from '@sales-i/dsv3';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import AddRulesModal from '@/admin/components/Company/Permissions/Users/AddRulesModal.vue';
import { baseUrl, companyArea, permissionsSection, usersSection } from '@/admin/router/urlBits';
import {
  SET_USER_RESTRICTIONS,
  GET_RESTRICTIONS_BY_ID,
} from '@/admin/store/actionType';
import { GET_FIELDS, POST_BY_ENTITY, PUT_BY_ID } from '@/shared/store/actionType';
import {
  findAndUpdateRule,
  findAndUpdateClause,
  findAndDeleteRule,
  findAndDeleteRuleset,
  stringRulesFromArray,
  updateRulesAndClausesArray,
} from '@/admin/store/utils/dataRestrictionFormatting';
import RecursiveRule from './RecursiveRule.vue';
import { navigateToUrl } from 'single-spa';
import useAdminUsers from '@/admin/composables/useAdminUsers';

const emit = defineEmits(['stageUpdate']);
const store = useStore();
const vroute = useRoute();
const { fieldsLookup } = useAdminUsers({ store });

const valid = ref(true);
const addRuleModal = ref(false);
const rule = ref(null);
const selectedIndex = ref(0);
const analysisFields = ref([]);

const restrictions = computed(() => store.state.admin.users.currentlyEditing.restrictions);
const clauses = computed(() => store.state.admin.users.currentlyEditing.clauses);
const userFriendlyRestrictions = computed(() => stringRulesFromArray(restrictions.value, clauses.value));

const getFields = () => store.dispatch(`admin/users/${GET_FIELDS}`);
const setUserRestrictions = params => store.dispatch(`admin/users/${SET_USER_RESTRICTIONS}`, params);
const getUserRestrictions = params => store.dispatch(`admin/users/${GET_RESTRICTIONS_BY_ID}`, params);
const addUser = () => store.dispatch(`admin/users/${POST_BY_ENTITY}`);
const editUser = params => store.dispatch(`admin/users/${PUT_BY_ID}`, params);


const actions = computed(() => {
  const arr = [];
  if (vroute.params.addedit === 'add') {
    arr.push({
      label: t('No restrictions'),
      purpose: 'reversed',
      small: true,
      onClick: () => {
        submitUser();
      },
      display: true,
    });
  }
  arr.push({
    label: t('Complete'),
    small: true,
    onClick: () => {
      submitUser();
    },
    display: false,
    isSubmit: true,
  });
  return arr;
});

const showAddRuleModal = show => addRuleModal.value = show;

const tabSelected = index => selectedIndex.value = index;

const addRuleOrRuleGroup = rulesAndClauses => {
  const rulesArr = Array.isArray(rulesAndClauses.rules) ? rulesAndClauses.rules : [rulesAndClauses.rules];
  const restrictionsAndClauses = updateRulesAndClausesArray({
    rulesArr,
    clausesArr: rulesAndClauses.clauses,
    existingRules: restrictions.value,
  });
  setUserRestrictions(restrictionsAndClauses);
};

const updateRule = ruleAndClauses => {
  const newRules = findAndUpdateRule(
    restrictions.value,
    rule.value.findId,
    ruleAndClauses.rules
  );
  setUserRestrictions(newRules);
};

const editRule = newRule => {
  if (!newRule || !newRule.findId) {
    return;
  }

  rule.value = newRule;
  selectedIndex.value = 0;
  addRuleModal.value = true;
};

const editClause = (current, newClause) => {
  const newRules = findAndUpdateClause(
    restrictions.value,
    current.findId,
    newClause
  );
  setUserRestrictions(newRules);
};

const deleteRule = rule => {
  const newRules = findAndDeleteRule(
    restrictions.value,
    rule.findId
  );
  setUserRestrictions(newRules);
};

const deleteRuleset = ruleset => {
  const newRules = findAndDeleteRuleset(
    restrictions.value,
    ruleset
  );
  setUserRestrictions(newRules);
};

const closeModal = () => {
  showAddRuleModal(false);
  rule.value = null;
};

const submitUser = async() => {
  if (vroute.params.addedit === 'edit' && vroute.params.id) {
    await editUser({ id: vroute.params.id });
  } else {
    await addUser();
  }
  navigateToUrl(`${baseUrl}/${companyArea}/${permissionsSection}/${usersSection}`);
};

const getAnalysisFields = async (restrictions) => {
  // If there are no restrictions, return an empty array
  if (!Array.isArray(restrictions) || restrictions.length === 0) {
    return [];
  }

  // Process each restriction and match with field lookup data
  const processedFields = await Promise.all(
    restrictions
      .filter(restriction => restriction.type === 'analysis' && restriction.entity)
      .map(async restriction => {
        // Fetch the fields for the current entity
        await fieldsLookup({
          entity: restriction.entity,
          id: restriction.field_id
        });

        // Get the field data from the store
        const entityFields = store.state.admin.users.fields.data[restriction.entity] || [];

        // Find the matching field
        const fieldLookup = entityFields.find(field => field.id === restriction.field_lookup_id);

        return {
          entity: restriction.entity,
          fieldId: restriction.field_id,
          fieldLookupId: restriction.field_lookup_id,
          description: fieldLookup?.description || '',
          code: fieldLookup?.code || '',
          originalRestriction: restriction
        };
      })
  );

  return processedFields;
};

const addDescriptionsToRestrictions = (restrictions, analysisFields) => {
  // If there are no restrictions, return an empty array
  if (!Array.isArray(restrictions) || restrictions.length === 0) {
    return [];
  }

  return restrictions.map(restriction => {
    const matchingField = analysisFields.find(field => 
      field.entity === restriction.entity && 
      field.fieldId === restriction.field_id
    );
    if (matchingField) {
      return {
        ...restriction,
        value: `${matchingField.description} - ${matchingField.code}`
      };
    }
    return restriction;
  });
};

onMounted(async() => {
  const id = vroute.params.id;
  emit('stageUpdate', 'Data restrictions');
  await getFields();

  // If there is an id, get the restrictions.
  if (id) {
    await getUserRestrictions({ id });

    // Get and store all analysis fields with their lookup data
    analysisFields.value = await getAnalysisFields(restrictions.value);
    
    // Add descriptions to restrictions
    const updatedRestrictions = addDescriptionsToRestrictions(restrictions.value, analysisFields.value);
    setUserRestrictions(updatedRestrictions);
  }
});
</script>

<style lang="scss" scoped>
:deep(.modal) {
  overflow: unset;
}

.rule-editable-preview {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-1);
  padding: var(--spacing-2);
  margin: var(--spacing-1) 0;
  background-color: var(--colour-panel-action);
}
.form-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: var(--spacing-2);
  background-color: var(--colour-panel-action);
  @media (min-height: 500px) {
    &.sticky-actions {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      margin-left: 0;
      width: 100%;
    }
  }
}
</style>
