export const GET_MENU_OPTIONS = 'GET_MENU_OPTIONS';
export const CLEAR_MENU_OPTIONS = 'CLEAR_MENU_OPTIONS';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_LOADING = 'GET_PRODUCTS_LOADING';
export const GET_PRODUCTS_ERROR = 'GET_PRODUCTS_ERROR';
export const GET_CRM_PRODUCTS = 'GET_CRM_PRODUCTS';
export const GET_CRM_PRODUCTS_LOADING = 'GET_CRM_PRODUCTS_LOADING';
export const GET_CRM_PRODUCTS_ERROR = 'GET_CRM_PRODUCTS_ERROR';
export const CLEAR_CRM_PRODUCTS = 'CLEAR_CRM_PRODUCTS';
export const GET_PRODUCT_GROUPS = 'GET_PRODUCT_GROUPS';
export const GET_PRODUCT_GROUPS_LOADING = 'GET_PRODUCT_GROUPS_LOADING';
export const CLEAR_PRODUCT_GROUPS = 'CLEAR_PRODUCT_GROUPS';
export const GET_ALL_BLACKLIST = 'GET_ALL_BLACKLIST';
export const ADD_TO_BLACKLIST = 'ADD_TO_BLACKLIST';
export const REMOVE_FROM_BLACKLIST = 'REMOVE_FROM_BLACKLIST';
export const GET_ALL_BLACKLIST_LOADING = 'GET_ALL_BLACKLIST_LOADING';
export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS';
export const MUTE_BY_ID = 'MUTE_BY_ID';

export const GET_STAGES = 'GET_STAGES';
export const CLEAR_STAGES = 'CLEAR_STAGES';
export const PATCH_STAGES = 'PATCH_STAGES';
export const SET_STAGES = 'SET_STAGES';
export const ADD_STAGE = 'ADD_STAGE';
export const MOVE_STAGE = 'MOVE_STAGE';
export const EDIT_STAGE = 'EDIT_STAGE';
export const DELETE_STAGE = 'DELETE_STAGE';

// Custom schema - fields
export const ADD_FIELD = 'ADD_FIELD';
export const MOVE_FIELD = 'MOVE_FIELD';
export const SET_REQUIRED = 'SET_REQUIRED';
export const SET_INCLUDE_IN_ENQUIRY = 'SET_INCLUDE_IN_ENQUIRY';
export const DELETE_FIELD = 'DELETE_FIELD';
export const DELETE_LIST_OPTION = 'DELETE_LIST_OPTION';

// Custom schema
export const ADD_SECTION = 'ADD_SECTION';
export const ADD_CUSTOM_LIST_ITEM = 'ADD_CUSTOM_LIST_ITEM';
export const RESET_CHANGES = 'RESET_CHANGES';
export const CLEAR_CUSTOM_SCHEMA = 'CLEAR_CUSTOM_SCHEMA';
export const LOADING_CUSTOM_SCHEMA = 'LOADING_CUSTOM_SCHEMA';
export const GET_CUSTOM_SCHEMA = 'GET_CUSTOM_SCHEMA';
export const UPDATE_CUSTOM_SCHEMA = 'UPDATE_CUSTOM_SCHEMA';
export const UPDATE_CUSTOM_SCHEMA_ERROR = 'UPDATE_CUSTOM_SCHEMA_ERROR';
export const SAVE_CUSTOM_SCHEMA = 'SAVE_CUSTOM_SCHEMA';
export const UPDATE_CUSTOM_LIST_SCHEMA = 'UPDATE_CUSTOM_LIST_SCHEMA';

// Tag report
export const GET_REPORT = 'GET_REPORT';
export const GET_REPORT_LOADING = 'GET_REPORT_LOADING';
export const GET_REPORT_ERROR = 'GET_REPORT_ERROR';
export const CLEAR_REPORT = 'CLEAR_REPORT';

// Tag consolidation
export const SET_TAGS_TO_CONSOLIDATE = 'SET_TAGS_TO_CONSOLIDATE';
export const SET_TAG_TO_CONSOLIDATE_TO = 'SET_TAG_TO_CONSOLIDATE_TO';
export const DELETE_TAG_CONSOLIDATION = 'DELETE_TAG_CONSOLIDATION';

export const PUT_TAG_CONSOLIDATION = 'PUT_TAG_CONSOLIDATION';
export const PUT_TAG_CONSOLIDATION_LOADING = 'PUT_TAG_CONSOLIDATION_LOADING';
export const PUT_TAG_CONSOLIDATION_ERROR = 'PUT_TAG_CONSOLIDATION_ERROR';

export const GET_TAG_CONSOLIDATION = 'GET_TAG_CONSOLIDATION';
export const GET_TAG_CONSOLIDATION_LOADING = 'GET_TAG_CONSOLIDATION_LOADING';
export const GET_TAG_CONSOLIDATION_ERROR = 'GET_TAG_CONSOLIDATION_ERROR';


// Files
export const GET_TOTALS = 'GET_TOTALS';
export const GET_TOTALS_LOADING = 'GET_TOTALS_LOADING';
export const GET_TOTALS_ERROR = 'GET_TOTALS_ERROR';

// Dismiss module
export const DISMISS_ITEM = 'DISMISS_ITEM';

// Product associations
export const SET_PRODUCT_ASSOCIATION_DETAILS = 'SET_PRODUCT_ASSOCIATION_DETAILS';

// Industry type actions
export const ADD_INDUSTRY_TYPE_TO_ASSOCIATION = 'ADD_INDUSTRY_TYPE_TO_ASSOCIATION';
export const REMOVE_INDUSTRY_TYPE_FROM_ASSOCIATION = 'REMOVE_INDUSTRY_TYPE_FROM_ASSOCIATION';

// Intelligence settings
export const TOGGLE_CUSTOMER_MODE = 'TOGGLE_CUSTOMER_MODE';

// Communities
export const DELETE_PRODUCTS = 'DELETE_PRODUCTS';
export const DELETE_PRODUCTS_LOADING = 'DELETE_PRODUCTS_LOADING';
export const DELETE_PRODUCTS_ERROR = 'DELETE_PRODUCTS_ERROR';
export const CLEAR_CURRENTLY_LOADED = 'CLEAR_CURRENTLY_LOADED';
export const ADD_COMMUNITY = 'ADD_COMMUNITY';

// PBAC and User Management
export const GET_FEATURES = 'GET_FEATURES';
export const GET_FEATURES_LOADING = 'GET_FEATURES_LOADING';
export const GET_FEATURES_ERROR = 'GET_FEATURES_ERROR';
export const SET_USER_ROLES = 'SET_USER_ROLES';
export const SET_USER_RESTRICTIONS = 'SET_USER_RESTRICTIONS';
export const SET_USER_STATUS = 'SET_USER_STATUS';
export const GET_RESTRICTIONS_BY_ID = 'GET_RESTRICTIONS_BY_ID';
export const GET_RESTRICTIONS_BY_ID_LOADING = 'GET_RESTRICTIONS_BY_ID_LOADING';
export const GET_RESTRICTIONS_BY_ID_ERROR = 'GET_RESTRICTIONS_BY_ID_ERROR';
export const UPDATE_RESTRICTIONS_BY_ID = 'UPDATE_RESTRICTIONS_BY_ID';
export const UPDATE_RESTRICTIONS_BY_ID_LOADING = 'UPDATE_RESTRICTIONS_BY_ID_LOADING';
export const UPDATE_RESTRICTIONS_BY_ID_ERROR = 'UPDATE_RESTRICTIONS_BY_ID_ERROR';

export const FIELDS_LOOKUP = 'FIELDS_LOOKUP';
export const FIELDS_LOOKUP_LOADING = 'FIELDS_LOOKUP_LOADING';
export const FIELDS_LOOKUP_ERROR = 'FIELDS_LOOKUP_ERROR';

export const GET_REP_CODES = 'GET_REP_CODES';
export const GET_REP_CODES_LOADING = 'GET_REP_CODES_LOADING';
export const GET_REP_CODES_ERROR = 'GET_REP_CODES_ERROR';

export const GET_AUTH_URL = 'GET_AUTH_URL';
export const GET_AUTH_URL_LOADING = 'GET_AUTH_URL_LOADING';
export const GET_AUTH_URL_ERROR = 'GET_AUTH_URL_ERROR';
export const GET_AUTH_STATUS = 'GET_AUTH_STATUS';
export const GET_AUTH_STATUS_LOADING = 'GET_AUTHSTATUSL_LOADING';
export const GET_AUTH_STATUS_ERROR = 'GET_AUTH_STATUS_ERROR';
export const POST_AUTH_CODE = 'POST_AUTH_CODE';
export const POST_AUTH_CODE_LOADING = 'POST_AUTH_CODE_LOADING';
export const POST_AUTH_CODE_ERROR = 'POST_AUTH_CODE_ERROR';
export const DELETE_AUTH_CODE = 'DELETE_AUTH_CODE';
export const DELETE_AUTH_CODE_LOADING = 'DELETE_AUTH_CODE_LOADING';
export const DELETE_AUTH_CODE_ERROR = 'DELETE_AUTH_CODE_ERROR';

export const GET_CALENDAR = 'GET_CALENDAR';
export const GET_CALENDAR_LOADING = 'GET_CALENDAR_LOADING';
export const GET_CALENDAR_ERROR = 'GET_CALENDAR_ERROR';

export const PUT_PRIMARY_CALENDAR = 'PUT_PRIMARY_CALENDAR';
export const PUT_PRIMARY_CALENDAR_LOADING = 'PUT_PRIMARY_CALENDAR_LOADING';
export const PUT_PRIMARY_CALENDAR_ERROR = 'PUT_PRIMARY_CALENDAR_ERROR';

export const DELETE_CALENDAR = 'DELETE_CALENDAR';
export const DELETE_CALENDAR_LOADING = 'DELETE_CALENDAR_LOADING';
export const DELETE_CALENDAR_ERROR = 'DELETE_CALENDAR_ERROR';