<template>
  <div>
    <EnquiryNav
      :active-area-prop="reportArea"
      :report-type="reportType"
      hide-enquiry-buttons
    />
    <TwoColumnLayout
      title="Associations Report"
      :description="inputData.description"
      @sidebar-menu-button-click="showMenu"
    >
      <template #sidebar>
        <div
          v-if="associations.data.length"
          id="community-report-details-view-association-select"
          class="add-section"
        >
          <h3>{{ t('Product Associations') }}</h3>

          <CustomButton
            purpose="text"
            @click="toggleAssociationList"
          >
            {{ t('Choose a different association') }}
          </CustomButton>
        </div>
      </template>
      <template #content>
        <div v-if="associationSelection">
          <div class="card-container">
            <CustomCard
              v-for="item in associationList"
              :key="item.value"
              purpose="reversed"
              @click="changeAssociation(item.value)"
            >
              <div class="main-area">
                <header>
                  <h3>{{ item.text }}</h3>
                </header>
              </div>

              <template #footer>
                <CustomButton
                  purpose="text"
                  @click="changeAssociation(item.value)"
                >
                  {{ t('View Insights') }}
                </CustomButton>
              </template>
            </CustomCard>
          </div>

          <Pagination
            :offset="offset"
            :limit="limit"
            :records-count="associationList.length"
            @page-changed="changePage"
          />
        </div>
        <div
          v-if="associationSelection === false"
          class="container"
        >
          <LoadingOverlay
            v-if="loading || loadingSpinner"
          />

          <section
            v-if="!loading && products.length"
            class="custom-header-section"
          >
            <header>
              <h2>
                {{ products[0].name }}
              </h2>
              <div
                class="link"
              >
                <div />
                <div />
                <div />
              </div>
              <h2>
                {{ products[1].name }}
              </h2>
            </header>
          </section>

          <div class="chart">
            <AssociationChart
              v-if="!loading"
              :headings="headings"
            />
          </div>

          <div class="table">
            <AssociationsTable
              v-if="!loading"
              :current-sort="currentSort"
              @apply-new-sort="applyNewSort"
              @sort-on-y-axis="sortOnYAxis"
              @loading="handleLoadingSpinner"
            />
          </div>
        </div>
      </template>
    </TwoColumnLayout>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { LoadingOverlay, CustomCard, CustomButton } from '@sales-i/dsv3';
import TwoColumnLayout from '@/shared/components/Layout/TwoColumnLayout';
import AssociationChart from '@/admin/components/Associations/AssociationChart.vue';
import AssociationsTable from '@/intelligence/components/Associations/AssociationsTable.vue';
import EnquiryNav from '@/intelligence/components/EnquiryReport/EnquiryNav/EnquiryNav';
import Pagination from '@/shared/components/Pagination/Pagination.vue';
import { scrollIntoView } from '@/shared/directives';
import { AIML_PRODUCT_TRENDS } from '@/intelligence/store/data/reportTypes';
import { baseUrl, associationsScope, insightsScope, productsScope } from '@/intelligence/router/urlBits';
import { REPORT_AREA_PRODUCT } from '@/intelligence/store/data/areas';
import { t } from '@sales-i/utils';
import { navigateToUrl } from 'single-spa';
import useShared from '@/intelligence/composables/useShared';
import useCalendar from '@/intelligence/composables/useCalendar';
import useTemplateReport from '@/intelligence/composables/useTemplateReport';
import useEnquiries from '@/intelligence/composables/useEnquiries';
import { useAssociations } from '@/intelligence/composables/useAssociations';

const store = useStore();
const vroute = useRoute();

const offset = ref(0);
const limit = ref(50);
const reportType = ref(AIML_PRODUCT_TRENDS);
const currentSort = ref('');
const type_filter = ref('item_level');
const showMenu = ref(true);
const loading = ref(true);
const loadingSpinner = ref(false);
const reportArea = REPORT_AREA_PRODUCT;
const associationSelection = ref(false);

const { fetchedDates, fetchCalendar, fetchDates, processRequestParams } = useCalendar({ store });
const { getReportData, getInputData, applyDate, fetchReportData, clearReportData } = useShared({ store });
const { applyNewSort } = useTemplateReport({ store });
const { applyAxis } = useEnquiries({ store });
const { associations, getAssociations } = useAssociations({ store });

const inputData = computed(() => getInputData(AIML_PRODUCT_TRENDS));
const reportData = computed(() => getReportData());
const products = computed(() => reportData.value?.products || []);
const associationList = computed(() => associations.value.data.map(item => ({ 
  text: `${item.antecedent_name} to ${item.consequent_name}`, 
  value: `${item.antecedent_code}|||${item.consequent_code}` 
}))
);

const headings = computed(() => {
  if (reportData.value !== undefined) {
    const { products } = reportData.value;
    const productOneName = products[0].name.length >= 10 ? products[0].name.substr(0, 10) + '...' : products[0].name;
    const productTwoName = products[1].name.length >= 10 ? products[1].name.substr(0, 10) + '...' : products[1].name;
    return {
      1: {
        title: productOneName,
        subTitle: products[0].code,
      },
      2: {
        title: productTwoName,
        subTitle: products[1].code,
      },
      3: {
        title: t('BOTH'),
      },
    };
  }
  return {};
});

watch(() => vroute.fullPath, async () => {
  const { fullPath } = vroute;
  if (fullPath.indexOf(`${baseUrl}/${insightsScope}/${productsScope}/${associationsScope}`) !== -1) {
    await loadReportData();
  }
});

onMounted(async () => {
  await fetchCalendar();
  await changePage(0);
  await loadReportData();
});

onBeforeUnmount(() => {
  clearReportData();
});

const toggleAssociationList = () => {
  associationSelection.value = !associationSelection.value;
};

const changePage = async (page) => {
  offset.value = page;
  await getAssociations({
    type: type_filter.value,
    limit: limit.value,
    offset: offset.value,
  });
  scrollIntoView();
};

const handleLoadingSpinner = (loadingState) => {
  loadingSpinner.value = loadingState;
};

const loadReportData = async (completeRefresh = false) => {
  loading.value = true;
  await processFilterParams();
  await fetchReportData({ reportType: AIML_PRODUCT_TRENDS, completeRefresh });
  loading.value = false;
};

const processFilterParams = async () => {
  processRequestParams();

  const now = new Date();
  const then = new Date();
  then.setFullYear(then.getFullYear() - 1);
  then.setMonth(then.getMonth() + 1);
  then.setDate(1);

  await fetchDates({
    date_from: then.toISOString(),
    date_to: now.toISOString(),
  });

  applyDate(fetchedDates.value);

  const { query } = vroute;

  if (Object.keys(query).length === 0) {
    const { data } = associations.value;
    applyAxis({
      xAxis: data[0]?.antecedent_code,
      yAxis: data[0]?.consequent_code,
    });
  } else {
    applyAxis({
      xAxis: query.product_1,
      yAxis: query.product_2,
    });
  }
};

const changeAssociation = (event) => {
  const [product_1, product_2] = event.split('|||');
  associationSelection.value = false;
  navigateToUrl(`${baseUrl}/${insightsScope}/${productsScope}/${associationsScope}?product_1=${product_1}&product_2=${product_2}`);
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.custom-header-section {
  header {
    display: flex;

    h2 {
      width: 45%;
      text-align: center;
    }
  }
}
.chart {
  width: 100%;
}
.table {
  width: 100%;
}
.link {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: var(--spacing-2);
  padding-right: var(--spacing-2);
  height: 36px;
  div:first-child, div:last-child {
    height: 9px;
    width: 9px;
    border-radius: 50%;
    background-color: var(--colour-panel-g-16);
  }
  div:nth-child(2) {
    height: 2px;
    width: 26px;
    background-color: var(--colour-panel-g-16);
  }
}
.container-wrapper {
  position: absolute;
  top: var(--spacing-8);
  right: var(--spacing-3);

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    top: var(--spacing-3);
  }
}

.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
  flex-wrap: wrap;
  padding-block-start: var(--spacing-3);
  padding: var(--spacing-3);
  gap: var(--spacing-2);

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    grid-template-columns: repeat(3, 1fr);
  }

  h3 {
    font-size: var(--font-size-4);
  }

  :deep(.c-card) {
    width: 100%;
    margin: 0 0 var(--spacing-2) 0;
  }
}

.product-associations-selection {
  max-height: 250px;
  overflow-y: scroll;
}

</style>