<template>
  <footer>
    <template v-if="!loadingLogo">
      <div class="logo">
        <img
          v-if="customLogoUrl && breakpoints.mdAndUp.includes(mq.current)"
          :src="customLogoUrl"
          alt="Custom Logo"
          :width="164"
        >
        <IconLogo
          v-else-if="breakpoints.mdAndUp.includes(mq.current)"
          icon-name="logo"
          width="164"
        />
      </div>
    </template>

    <ul class="links">
      <li v-if="!iframe">
        <a
          data-nolt="button"
          href="https://sales-i-assisted-selling.nolt.io"
        >
          {{ t('Feedback') }}
        </a>
        <component
          :is="'script'"
          async
          src="https://cdn.nolt.io/widgets.js"
        />
      </li>
      <li>
        <CustomButton
          purpose="text"
          title="Sign out"
          class="sign-out"
          @click.stop="logout"
        >
          {{ t('Sign out') }}
        </CustomButton>
      </li>
    </ul>
  </footer>
</template>

<script setup>
// TheFooter
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import breakpoints from '@/shared/utils/breakpoints';
import { useMq } from 'vue3-mq';
import { CustomButton, IconLogo } from '@sales-i/dsv3';
import { LOG_OUT } from '@/shared/store/actionType';
import { t, httpRequest } from '@sales-i/utils';
import { navigateToUrl } from 'single-spa';
import useSystem from '@/shared/composables/useSystem';

const mq = useMq();
const store = useStore();
const { iframe } = useSystem({ store });

defineProps({
  navigation: {
    type: Array,
    default: () => [],
  },
});

const customLogoUrl = ref('');
const loadingLogo = ref(true);

const logOutUser = () => store.dispatch(`userDetails/${LOG_OUT}`);

onMounted(() => {
  getLogoAPI();
});

async function logout() {
  await logOutUser();
  if (!iframe.value) navigateToUrl('/');
  window.location.reload();
}
async function getLogoAPI() {
  try {
    const response = await httpRequest('get', 'tenant-logos', {}, {
      area: 'config',
      headers: { 'Content-Type': 'application/json' },
    });
    customLogoUrl.value = response.logo || '';
  } catch (error) {
    console.error('Error fetching logos:', error);
  } finally {
    loadingLogo.value = false;
  }
}
</script>

<style lang="scss" scoped>
footer {
  border-top: var(--spacing-1) solid var(--colour-brand-mandy);
  padding: var(--spacing-1) var(--spacing-2);
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: var(--spacing-1);

  .logo {
    display: flex;

    img {
      height: 50px;
      width: auto;
      max-height: none;
    }
  }
}

.links {
  display: flex;
  list-style-type: none;
}

.sign-out {
  font-weight: var(--font-weight-semibold);

  &:hover {
    color: var(--colour-brand-deluge);
  }
}

a {
  font-weight: var(--font-weight-semibold);
  text-decoration: underline;
  margin-right: var(--spacing-2);
}
</style>
