<template>
  <SearchField
    v-bind="props"
    :search-func="getSearchResults"
    @input="handleInput"
    @search="handleSearch"
    @blur="handleBlur"
    @focus="handleFocus"
    @valid="handleValid"
  >
    <template #result="{ result, selectFunc }">
      <CRMCard
        class="crm-card-result"
        :title="result.text"
        :on-click="() => selectFunc(result)"
      >
        <template #info>
          <div>{{ result.account_number }}</div>
          <div class="address-field">
            {{ address.asString(result.addresses) }}
          </div>
        </template>
      </CRMCard>
    </template>
  </SearchField>
</template>

<script setup>
import { ref } from 'vue';
import { address } from '@sales-i/utils';
import { CRMCard } from '@sales-i/dsv3';
import { baseProps, baseEmits } from './composables/searchInputPropsEmits';
import useSearchInput from './composables/useSearchInput';
import SearchField from '@/shared/components/Search/SearchField.vue';

const props = defineProps({
  ...baseProps,
  label: {
    type: String,
    default: 'Customer:',
  }
});

const emit = defineEmits(baseEmits);
const type = ref('customer');
const url = ref('customers');

const {
  getSearchResults,
  handleInput,
  handleSearch,
  handleBlur,
  handleFocus,
  handleValid
} = useSearchInput({ type, url, emit });
</script>

<style lang="scss" scoped>
.address-field {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.crm-card-result {
  margin: var(--spacing-1);
  width: calc(100% - var(--spacing-2));
}

:deep(.dropdown-container .results) {
  background-color: var(--colour-panel-g-2);
}
</style>
