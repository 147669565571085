import {
  GET_BY_ID,
  GET_BY_ID_LOADING,
  GET_BY_ID_ERROR,
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';

export const getByIdCommunitiesMutations = {
  [GET_BY_ID]: (state, data) => {
    state.selected = data;
    state.selected.loaded = true;
    state.selected.loading = false;
    state.selected.error = null;
  },
  [GET_BY_ID_LOADING]: (state) => {
    state.selected.loading = true;
    state.selected.loaded = false;
    state.selected.error = null;
  },
  [GET_BY_ID_ERROR]: (state, error) => {
    state.selected.selected = [];
    state.selected.loading = false;
    state.selected.loaded = true;
    state.selected.error = error;
  }
};

export const getByIdCommunitiesActions = {
  [GET_BY_ID]: async ({ commit }, id, options = {}) => {
    commit(GET_BY_ID_LOADING);
    try {
      const response = await httpRequest('get', `communities/${id}`, {}, { area: 'associations', ...options });
      commit(GET_BY_ID, response);
      return response;
    } catch (e) {
      commit(GET_BY_ID_ERROR, e);
      return false;
    }
  }
};