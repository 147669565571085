
import { useStore } from 'vuex';
import {
  EXPORT_REPORT_FULL_PICTURE,
  EXPORT_REPORT_MATRIX,
  EXPORT_REPORT_SALES_VS_GAPS,
  EXPORT_REPORT_VARIANCE,
  EXPORT_REPORT,
} from '@/intelligence/store/actionType';

export default function useExport({ store = useStore() } = {}) {
  
  const exportFullPictureReport = (payload) =>  store.dispatch(`intelligence/enquiry/${EXPORT_REPORT_FULL_PICTURE}`, payload);
  const exportSvgReport = (payload) =>  store.dispatch(`intelligence/enquiry/${EXPORT_REPORT_SALES_VS_GAPS}`, payload);
  const exportMatrixReport = (payload) =>  store.dispatch(`intelligence/enquiry/${EXPORT_REPORT_MATRIX}`, payload);
  const exportVarianceReport = (payload) =>  store.dispatch(`intelligence/enquiry/${EXPORT_REPORT_VARIANCE}`, payload);
  const exportReport = (payload) => store.dispatch(`intelligence/enquiry/${EXPORT_REPORT}`, payload);
  const exportTemplateReport = (payload) => store.dispatch(`intelligence/templateReport/${EXPORT_REPORT}`, payload);

  return {
    exportFullPictureReport,
    exportSvgReport,
    exportMatrixReport,
    exportVarianceReport,
    exportReport,
    exportTemplateReport,
  };
}