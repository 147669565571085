import {
  GET_ALL,
  GET_ALL_LOADING,
  GET_ALL_ERROR,
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';

export const getAllCommunitiesMutations = {
  [GET_ALL]: (state, data) => {
    state.all.data = data;
    state.all.loaded = true;
    state.all.loading = false;
    state.all.error = null;
  },
  [GET_ALL_LOADING]: (state) => {
    state.all.loading = true;
    state.all.loaded = false;
    state.all.error = null;
  },
  [GET_ALL_ERROR]: (state, error) => {
    state.all.data = [];
    state.all.loading = false;
    state.all.loaded = true;
    state.all.error = error;
  }
};

export const getAllCommunitiesActions = {
  [GET_ALL]: async ({ commit }, options = {}) => {
    commit(GET_ALL_LOADING);
    try {
      const response = await httpRequest('get', 'communities', {}, { area: 'associations', ...options });
      commit(GET_ALL, response);
      return response;
    } catch (e) {
      commit(GET_ALL_ERROR, e);
      return false;
    }
  }
};