<template>
  <div v-if="permissions.read">
    <SignPost
      :heading="t('Enquiries')"
      :description="t('Drill into your sales data with ease')"
      color="var(--colour-utility-action)"
      background-color="var(--colour-panel-action)"
      :links="enquiriesLinks"
    />
    <SignPost
      :heading="t('Insights')"
      :description="t('Better understand your sales data with helpful insights')"
      color="var(--colour-data-puerto-rico-dark)"
      background-color="var(--colour-panel-performance)"
      :links="insightsLinks"
    />
    <SignPost
      :heading="t('Alerts')"
      :description="t('Setup alerts to stay informed on what you care about')"
      color="var(--colour-data-turmeric-label)"
      background-color="var(--colour-data-turmeric-light)"
      :links="alertsLinks"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { t } from '@sales-i/utils';
import SignPost from '@/admin/views/SignPost.vue';
import usePermissions from '@/shared/composables/usePermissions';
import { intelligence_enquiries } from '@/shared/store/data/policies';
import { alertBuilderScope, alertsScope, baseUrl, enquiryScope, insightsScope } from '@/intelligence/router/urlBits';
import { FULL_PICTURE, MATRIX, SALES_VS_GAPS, TRENDS, VARIANCE } from '@/intelligence/store/data/reportTypes';
import { REPORT_AREA_CUSTOMER, REPORT_AREA_PERFORMANCE, REPORT_AREA_PRODUCT } from '@/intelligence/store/data/areas';

const { getPermissions } = usePermissions();
const permissions = getPermissions(intelligence_enquiries);

const enquiriesLinks = computed(() => {
  const arr = [];
  arr.push({
    title: t('Full Picture'),
    description: t('See the full picture'),
    href: `${baseUrl}/${enquiryScope}/${FULL_PICTURE}?cc=0`,
  });
  arr.push({
    title: t('Variance'),
    description: t('Gauge variance between two periods in up to four KPIs at once'),
    href: `${baseUrl}/${enquiryScope}/${VARIANCE}?cc=0`,
  });
  arr.push({
    title: t('Matrix'),
    description: t('Two dimensions are better than one'),
    href: `${baseUrl}/${enquiryScope}/${MATRIX}?cc=0`,
  });
  arr.push({
    title: t('Trends'),
    description: t('Compare year on year trends'),
    href: `${baseUrl}/${enquiryScope}/${TRENDS}?cc=0`,
  });
  arr.push({
    title: t('Sales vs Gaps'),
    description: t('Analyse gaps in your sales over time'),
    href: `${baseUrl}/${enquiryScope}/${SALES_VS_GAPS}?cc=0`,
  });
  return arr;
});

const insightsLinks = computed(() => {
  const arr = [];
  arr.push({
    title: t('Performance'),
    description: t('Reports to better understand sales performance'),
    href: `${baseUrl}/${insightsScope}/${REPORT_AREA_PERFORMANCE}`,
  });
  arr.push({
    title: t('Products'),
    description: t('See which products are selling well'),
    href: `${baseUrl}/${insightsScope}/${REPORT_AREA_PRODUCT}`,
  });
  arr.push({
    title: t('Customer'),
    description: t('Reports that enable you to sell smarter to your customers'),
    href: `${baseUrl}/${insightsScope}/${REPORT_AREA_CUSTOMER}`,
  });
  return arr;
});

const alertsLinks = computed(() => {
  const arr = [];
  arr.push({
    title: t('Alerts'),
    description: t('View previously created alerts'),
    href: `${baseUrl}/${alertsScope}`,
  });
  arr.push({
    title: t('Alert Builder'),
    description: t('Setup alerts for sales, CRM, and product data'),
    href: `${baseUrl}/${alertsScope}/${alertBuilderScope}`,
  });
  return arr;
});
</script>

<style lang="scss" scoped>
.enquiries-baseview-container {
  padding: var(--spacing-4);
  > div {
    border-radius: var(--border-radius-1);
  }
}
</style>
