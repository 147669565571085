<template>
  <div class="flow">
    <div class="container">
      <div class="intro-wrapper flow">
        <h2>
          {{
            t(`${actionText} sales data`, 'action_sales_data', {
              interpolations: {
                action: actionText,
              },
            })
          }}
        </h2>
        <p>{{ dateFilter }}</p>
      </div>
    </div>

    <FilterSummary v-if="salesFilters.length > 0" />

    <div class="container flow">
      <div class="flex">
        <TabSwitch
          v-if="!isFirstSalesSection"
          class="tab-switch"
          :items="clauseOptions"
          selected-value="AND"
          @switch-selected="handleSwitch($event)"
        />
        <h3>{{ t('Add sales data') }}</h3>
      </div>

      <div
        v-for="(filter, index) in salesFilters"
        :key="filter.id"
        class="flow"
      >
        <template v-if="index !== 0">
          <div class="flex align-centre">
            <TabSwitch
              v-if="index !== 0"
              class="tab-switch"
              :selected-value="filter.type"
              :items="clauseOptions"
              @switch-selected="handleFilterSwitch(filter, $event)"
            />
          </div>
        </template>
        <div class="form-field-grid">
          <Field
            :id="`filter-${filter.id}`"
            required
            :error-message="t('Please choose a filter')"
            type="select"
            :value="filter.field"
            :options="getSalesFilterOptionsFromAlertBuilder"
            :label="t('Filter')"
            @input="$event => setFilterOption(filter, 'field', $event)"
          />
          <Field
            :id="`operator-${filter.id}`"
            required
            :error-message="t('Please choose an operator')"
            type="select"
            :options="getFilterOperators(filter)"
            :value="filter.operator"
            :label="t('Operator')"
            @input="$event => setFilterOption(filter, 'operator', $event)"
          />
          <Field
            :id="`value-${filter.id}`"
            required
            :error-message="t('Please enter a value')"
            :type="getFieldInputType(filter)"
            :value="filter.value"
            :label="t('Value')"
            allow-zero-value
            nullable
            twoway
            @input="$event => setFilterOption(filter, 'value', $event)"
          />
        </div>

        <div class="delete-icon">
          <IconButton
            icon-name="trash"
            purpose="transparent"
            icon-color="var(--colour-utility-action)"
            variant="round"
            :icon-size="28"
            @on-click="removeSalesFilter(filter)"
          />
        </div>
      </div>

      <div
        class="add-button"
      >
        <CustomDropdown
          v-bind="propsForMenu"
          close-on-selection
          :items="clauseOptions"
          @click="addSalesFilter($event.value)"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { CustomDropdown, IconButton, TabSwitch } from '@sales-i/dsv3';
import { t } from '@sales-i/utils';
import Field from '@/shared/components/Form/Field.vue';
import FilterSummary from '@/intelligence/components/AlertBuilder/AlertWizard/FilterSummary.vue';
import { useAlertBuilder } from '@/intelligence/composables/useAlertBuilder';
import { AND_OR_OPTIONS } from '@/intelligence/store/data/filters';
import useSystem from '@/shared/composables/useSystem';

const props = defineProps({
  actionText: {
    type: String,
    default: '',
  },
  dateFilter: {
    type: String,
    default: '',
  },
  isEditMode: {
    type: Boolean,
    default: false,
  },
  isFirstSalesSection: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['valid']);

const store = useStore();
const {
  getNextId,
  salesFilters,
  salesDataClause,
  isVarianceOutput,
  addSalesFilterToQuery,
  updateSalesFilterInQuery,
  removeSalesFilterFromQuery,
  getSalesFilterOptions,
  getSalesFilterOptionsFromAlertBuilder,
} = useAlertBuilder({ store });

const { showConfirmationModal } = useSystem({ store });

const valid = ref(false);

// Update the main clause
const clauseOptions = AND_OR_OPTIONS;

const propsForMenu = {
  iconWidth: 32,
  iconHeight: 32,
  iconColor: 'var(--colour-utility-white)',
  toggleIcons: ['plus', 'close-line'],
};
    
watch(
  () => salesFilters.value,
  () => {
    valid.value = salesFilters.value.every(filter => {
      return filter.field && filter.operator && filter.value !== '' && filter.value !== null;
    });

    emit('valid', valid.value);
  }
);

onMounted(() => {
  // Add a sales filter if there are none
  if (salesFilters.value.length === 0) {
    addSalesFilter();
  }
  // If isEditMode is true, set 'valid' to true and emit it
  if (props.isEditMode) {
    valid.value = true;
    emit('valid', valid.value);
  }
});

function handleSwitch(event) {
  salesDataClause(event.value);
}

const valueFilterOperators = computed(() => {
  const operators = [
    {
      value: '>',
      text: t('Is Greater Than'),
    },
    {
      value: '<',
      text: t('Is Less Than'),
    },
    {
      value: '=',
      text: t('Is Equal To'),
    },
    {
      value: '!=',
      text: t('Does Not Equal'),
    },
  ];

  if (isVarianceOutput.value) {
    operators.push(
      {
        value: 'percent_up',
        text: t('% up'),
      },
      {
        value: 'percent_down',
        text: t('% down'),
      }
    );
  }

  return operators;
});

const textFilterOperators = computed(() => {
  return [
    {
      value: '=',
      text: t('Is Equal To'),
    },
    {
      value: '!=',
      text: t('Does Not Equal'),
    },
  ];
});

function getFilterOperators(filter) {
  // Get the 'type' from getSalesFilterOptions. If it's 'text' return the text filter operators else return the value filter operators.
  return getSalesFilterOptionsFromAlertBuilder.value.find(option => option.value === filter.field)?.type === 'text'
    ? textFilterOperators.value
    : valueFilterOperators.value;
}

async function removeSalesFilter(filter) {
  showConfirmationModal({
    message: t('Do you really want to delete this filter?', 'do_you_really_want_to_delete_this_filter'),
    updatedMessage: t('You have deleted this filter', 'you_have_deleted_this_filter'),
    updateFunction: async () => {
      try {
        await removeSalesFilterFromQuery(filter.id);
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
  });
}

// Get the field type for the value field based on the filter type chosen
function getFieldInputType(filter) {
  return getSalesFilterOptionsFromAlertBuilder.value.find(option => option.value === filter.field)?.type || 'number';
}

function setFilterOption(filter, field, value) {
  // If you select a field that requires a string (e.g. Rep) then select a field that can only accept a number (e.g. Profit), you get a db error. To fix this we need to set the value to an empty string.

  // If the user changes the 'Filter' field, clear the 'value' field.
  if (field === 'field') {
    updateSalesFilterInQuery({
      id: filter.id,
      field: value,
      value: '',
    });
  } else {
    updateSalesFilterInQuery({
      id: filter.id,
      [field]: value,
    });
  }
}

function addSalesFilter(type) {
  addSalesFilterToQuery({
    id: getNextId(salesFilters.value),
    type: type || 'AND',
    field: '',
    operator: '',
    value: '',
    section: 'sales',
  });
}

function handleFilterSwitch(filter, selectedValue) {
  // Update the filterclause for the specified index
  updateSalesFilterInQuery({
    id: filter.id,
    type: selectedValue.value,
  });
}

// Load the sales filter options
getSalesFilterOptions();
</script>

<styles lang="scss" scoped>
h3 {
  font-size: var(--font-size-3);
  font-weight: var(--font-weight-semibold);
}

.flex {
  gap: var(--spacing-2);
  align-items: center;
}
</styles>
