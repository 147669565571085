import { MATRIX, SALES_VS_GAPS } from '@/intelligence/store/data/reportTypes';
import { 
  TOGGLE_DRILL, 
  TOGGLE_SELECT_BUBBLES_POPUP, 
  DISABLE_SELECT_BUBBLES_POPUP
} from '@/intelligence/store/actionType';

// getters
export const drillGetters = {
  getDrillsAsQuery: (state, getters) => ([reportType, customDrills]) => {
    // EXAMPLE OF how drills object might look like
    // drills: [
    //   {
    //     dimensions: [
    //       { id: "2", values: [{ value: "Manders  Kingsmead  839", pair: "THO002"},{ value: "Creamline  Louth  Floral", pair: "LCC100"},] },
    //       { id: "1", values: [{ value: "MEATS", pair: "17"},{ value: "JUICE DRINKS", pair: "21"},] },
    //     ]
    //   },
    //   {
    //     dimensions: [ { id: "623", values: [] }]
    //   }
    // ]

    const drillingLevels = customDrills || state.requestParameters.drills;
    const levelsKeys = Object.keys(drillingLevels);
    const levelsCount = levelsKeys.length;
    let query = '';

    const querySchema = '[ ${drillingLevels} \n]';
    const drillingLevelSchema = '\n{ dimensions: [ ${dimensions} ] } ';
    const dimensionSchema = '{ id: "${id}", values: [ ${values} ] }';
    const dimensionValueSchema = '{ value: "${value}", pair: "${pair}" }';

    // STEP 1: check if drill has levels, and put querySchema if it exist
    if (levelsCount === 0) {
      return '[]';
    }

    query += querySchema;

    // STEP 2: replace drillingLevels with drillingLevelSchemas multiplied by real amount of levels
    let drillingLevelsValue = '';

    levelsKeys.forEach(level => {
      let drillingLevelValue = drillingLevelSchema;
      let dimensionsValue = '';

      // STEP 3: replace ${dimensions} value with real dimensions array
      drillingLevels[level].dimensions.forEach(dimension => {
        dimensionsValue += dimensionSchema + ',';
        let switchId = false;
        // STEP 3.1 replace ${id} with real dimension id
        if (reportType === MATRIX || reportType === SALES_VS_GAPS) {
          switchId = true;
        }
        
        if (!switchId) {
          // Step 3.1.0 This is basic behavior for FULL PICTURE etc
          dimensionsValue = dimensionsValue.replace('${id}', dimension.id);
        } else {
          // This is custom behavior for MATRIX and SALES_VS_GAPS          
          // Step 3.1.1: swap dimension id on first drilling level with y axis value
          if (level === 'level-1') {
            dimensionsValue = dimensionsValue.replace('${id}', 
              reportType === SALES_VS_GAPS 
                ? state.requestParameters.dim1
                : state.requestParameters.yAxis
            );
          } else {
            // Step 3.1.2: swap dimension id on every other level with first dimension id of previous drilling level 
            let lowerDimensionLevel = level.split('-')[1] - 1;
            dimensionsValue = dimensionsValue.replace('${id}', getters.getDrillingDimensionId(lowerDimensionLevel, 0));
          }
        }

        // STEP 3.2 replace ${values} with real rows values pairs
        let dimensionValues = '';
        dimension.values.forEach(value => {
          dimensionValues += dimensionValueSchema + ',';
          dimensionValues = dimensionValues.replace('${value}', value.value).replace('${pair}', value.pair);
        });
        dimensionsValue = dimensionsValue.replace('${values}', dimensionValues);
      });

      drillingLevelsValue += drillingLevelValue.replace('${dimensions}', dimensionsValue) + ',';
    });

    query =  query.replace('${drillingLevels}', drillingLevelsValue);

    return query;
  },
  getDrillsLevel: state => drillParam => {
    if (drillParam) {
      const selectedDrill = Object.entries(state.requestParameters.drills)
        .find(drill => drill[1].dimensions[0].id === drillParam);
      return selectedDrill ? selectedDrill[0].replace('level-','') : 0;
    }

    const levels =  Object.keys(state.requestParameters.drills);
    const levelsCount =  levels.length;
    return levelsCount ? Number(levels[levelsCount - 1].split('-')[1]) : 0;
  },
  getDrillsLevelBasedOnQuery: () => routerQuery => {
    if (!routerQuery) {
      return 0;
    }

    const levels = Object.keys(routerQuery).reduce((result, queryParamKey) => {
      const isDimension = new RegExp('\\d+-dim\\d+$').test(queryParamKey);
      if (isDimension) {
        const match = queryParamKey.match(/^(\d+)-dim/);
        if (match) result.push(Number(match[1]));
      }

      return result;
    }, []);

    return levels.length > 0 ? Math.max(...levels) : 0;
  },
  getDrillsQueryParams: state => {
    const levels = state.requestParameters.drills;
    const queryParams = {};

    Object.keys(levels).forEach(level => {
      levels[level].dimensions.forEach((dimension, dimensionIndex) => {
        let levelPrefix = level.split('-')[1];
        // step 1: create dimensions params
        queryParams[`${levelPrefix}-dim${dimensionIndex+1}`] = dimension.id;

        // step 2: create dimensions values params
        if (dimension.values.length) {
          queryParams[`${levelPrefix}-dim${dimensionIndex+1}values`] = dimension.values.map(x => `${x.value}:${x.pair}`);
        }
      });
    });

    return queryParams;
  },
  getDrillingDimensionId: state => (levelNumber, dimensionPosition) => {
    if (typeof levelNumber !== 'number' || typeof dimensionPosition !== 'number') {
      return null;
    }

    const level = state.requestParameters.drills[`level-${levelNumber}`];
    const dimension = level && level.dimensions.length > dimensionPosition ? level.dimensions[dimensionPosition] : null;

    return level && dimension ? dimension.id : null;
  },
  isSelectBubblesButtonActive: state => {
    return !!state.drillActiveRows.length;
  }
};

// mutations
export const drillMutations = {
  [TOGGLE_DRILL]: (state, isActive) => {
    state.isDrillActive = typeof isActive === 'boolean' ? isActive : !state.isDrillActive;
  },
  [TOGGLE_SELECT_BUBBLES_POPUP]: state => {
    state.isSelectBubblesPopupActive = !state.isSelectBubblesPopupActive;
  },
  [DISABLE_SELECT_BUBBLES_POPUP]: state => {
    state.isSelectBubblesPopupActive = false;
  },
  addDrillingDimensionOnLevel: (state, data) => {
    let { level, dimensionPosition, dimensionId, values } = data;
    let isLevelValid, levelDimensionsCount, drillingLevel;

    if (!dimensionId || !level) {
      return;
    }

    if (!values) {
      values = [];
    }

    isLevelValid = Object.keys(state.requestParameters.drills).includes(`level-${level}`);
    if (isLevelValid) {
      drillingLevel = state.requestParameters.drills[`level-${level}`];
      levelDimensionsCount = drillingLevel.dimensions.length;

      if (!dimensionPosition && levelDimensionsCount < 2) {
        drillingLevel.dimensions.push({ id: dimensionId, values });
      }

      else if (dimensionPosition && dimensionPosition >= 0 && dimensionPosition <= 1) {
        drillingLevel.dimensions[dimensionPosition] = { id: dimensionId, values };
      }
    } else {
      state.requestParameters.drills[`level-${level}`] = { dimensions: [] };
      drillingLevel = state.requestParameters.drills[`level-${level}`];
      drillingLevel.dimensions.push({ id: dimensionId, values });
    }
  },
  addDrillingDimensionRowValuesOnLevel: (state, data) => {
    let { level, values, isMatrixType } = data;
    let drillingLevel, dimensionValues, dimensionIndex;

    // For MATRIX report type we need to add dimension on new level always, so we increase given level by one
    if (isMatrixType) level += 1;

    if (typeof level !== 'number' || !level || !values) return;

    if (!state.requestParameters.drills[`level-${level}`]) {
      // this case where we don't have any drill level at the beginning can happen only for MATRIX report
      state.requestParameters.drills[`level-${level}`] = { dimensions: [] };
    }
    drillingLevel = state.requestParameters.drills[`level-${level}`];

    Object.keys(values).forEach(dimensionId => {
      dimensionIndex = drillingLevel.dimensions.findIndex(x => x.id === dimensionId);

      if (dimensionIndex === -1) {
        // In case there was no level, no dimension we can just create new dimension and directly put the value in values array
        drillingLevel.dimensions.push({ id: dimensionId, values: [ values[dimensionId] ] });
        return;
      }

      if (dimensionIndex > -1) {
        dimensionValues = drillingLevel.dimensions[dimensionIndex].values;
        dimensionValues.push(values[dimensionId]);
      }
    });
  },
  setDrillsDataByQuery: (state, queryParams) => {
    let drills = {};
    let otherParams = [];

    Object.keys(queryParams).forEach(queryParamKey => {
      if (isDimension(queryParamKey)) {
        // step 1: tranform dimensions params to drilling levels and dimensions
        setDimension(queryParamKey);
        return;
      }

      else if (isDimensionValue(queryParamKey)) {
        // step 2: transform dimensions values params and assign it to proper dimensions
        setDimensionValue(queryParamKey);
        return;
      }

      otherParams.push(queryParamKey);
    });

    state.requestParameters.drills = drills;

    function isDimension(queryParam) {
      return new RegExp('\\d-dim\\d+$').test(queryParam);
    }

    function isDimensionValue(queryParam) {
      return new RegExp('\\d-dim\\d+values$').test(queryParam);
    }

    function getParamsFromQueryParamKey(queryParam) {
      const values = queryParam.match(/\d+/g);

      return { level: values[0], dimensionPosition: values[1] - 1 };
    }

    function setDimension(queryParamKey) {
      let { level, dimensionPosition } = getParamsFromQueryParamKey(queryParamKey);

      if (!drills[`level-${level}`]) {
        drills[`level-${level}`] = { dimensions: [] };
      }
      if (!drills[`level-${level}`].dimensions[dimensionPosition]) {
        drills[`level-${level}`].dimensions[dimensionPosition] = { id: queryParams[queryParamKey], values: [] };
      } else {
        drills[`level-${level}`].dimensions[dimensionPosition].id = queryParams[queryParamKey];
      }
    }

    function setDimensionValue(queryParamKey) {
      let { level, dimensionPosition } = getParamsFromQueryParamKey(queryParamKey);

      if (!drills[`level-${level}`]) {
        drills[`level-${level}`] = { dimensions: [] };
      }

      if (!drills[`level-${level}`].dimensions[dimensionPosition]) {
        drills[`level-${level}`].dimensions[dimensionPosition] = { id: null, values: [] };
      }

      drills[`level-${level}`].dimensions[dimensionPosition].values.push(...translateDimensionValues(queryParamKey));
    }

    function translateDimensionValues(queryParam) {
      let values = [];
      let splittedQueryParam;

      // We don't know if given query param value is array or string in that case
      if (Array.isArray(queryParams[queryParam])) {
        queryParams[queryParam].forEach(item => {
          splittedQueryParam = item.split(':');
          values.push({ value: splittedQueryParam[0], pair: splittedQueryParam[1]});
        });
      } else {
        splittedQueryParam = queryParams[queryParam].split(':');
        values.push({ value: splittedQueryParam[0], pair: splittedQueryParam[1]});
      }

      return values;
    }
  },
  generateDrillActiveRows: (state, [selectedDrillId, isMatrixType]) => {
    state.drillActiveRows = [];

    const selectedDrill = Object.entries(state.requestParameters.drills)
      .find(drill => drill[1].dimensions[0].id === selectedDrillId);

    if (!selectedDrill) return;

    const selectedDrillLevel = selectedDrill[0].replace('level-','');
    const remainedDrillsObject = {};
    Object.entries(state.requestParameters.drills).slice(0,selectedDrillLevel)
      .forEach(el => remainedDrillsObject[el[0]] = el[1]);

    const dimensions = remainedDrillsObject[selectedDrill[0]].dimensions;
    const activeRows = [];

    dimensions[0].values.forEach((dimValue, index) => {
      const { value, pair } = dimValue;
      const activeRow = {
        checked: true,
        id: '',
        item: {}
      };

      activeRow.id = [value,pair].toString();
      isMatrixType ? activeRow.item = { value, pair } : activeRow.item[selectedDrillId] = { value, pair };
      if (dimensions.length === 2) {
        const value2 = dimensions[1].values[index].value;
        const pair2 = dimensions[1].values[index].pair;

        activeRow.id += `:${[value2,pair2].toString()}`;
        activeRow.item[dimensions[1].id] = { value: value2, pair: pair2 };
      }
      activeRows.push(activeRow);
    });
    
    state.drillActiveRows = activeRows;
  },
  setDrillActiveRows: (state, drillData) => {
    state.drillActiveRows = drillData;
  }
};

// actions
export const drillActions = {
  [TOGGLE_DRILL]: ({ commit }, isActive) => {
    commit(TOGGLE_DRILL, isActive);
  },
  [TOGGLE_SELECT_BUBBLES_POPUP]: ({ commit }) => {
    commit(TOGGLE_SELECT_BUBBLES_POPUP);
  },
  [DISABLE_SELECT_BUBBLES_POPUP]: ({ commit }) => {
    commit(DISABLE_SELECT_BUBBLES_POPUP);
  },
  addDrillingDimensionOnLevel: ({ commit }, data) => {
    commit('addDrillingDimensionOnLevel', data);
  },
  addDrillingDimensionRowValuesOnLevel: ({ commit }, data) => {
    commit('addDrillingDimensionRowValuesOnLevel', data);
  },
  setDrillsDataByQuery: ({ commit }, queryParams) => {
    commit('setDrillsDataByQuery', queryParams);
  },
  generateDrillActiveRows: ({ commit }, [selectedDrillId, isMatrixType]) => {
    commit('generateDrillActiveRows', [selectedDrillId, isMatrixType]);
  },
  setDrillActiveRows: ({ commit }, drillData) => {
    commit('setDrillActiveRows', drillData);
  }
};

export default {
  namespaced: true,
  drillGetters,
  drillActions,
  drillMutations
};
