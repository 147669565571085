<template>
  <div
    v-if="dateStamp"
    class="meta-date-stamp"
  >
    {{ t('Based on data from') }} {{ dates.format(dateStamp.value) }}
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { dates, t } from '@sales-i/utils';

const store = useStore();
const dateStamp = computed(() => store.state.intelligence.metadata.date_last_loaded);
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.meta-date-stamp {
  display: none;

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    text-align: center;
    font-size: var(--font-size-small);
    margin-left: 0;
    display: block;
    position: absolute;
    top: auto;
    bottom: auto;
    right: var(--spacing-3);
  }
}
</style>
